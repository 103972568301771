/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateEmailAddressDto,
  EmailAddressResponseDto,
  EmailsControllerGetEmailAddressesParams,
  EmailsControllerGetReceivedEmailsParams,
  PaginatedEmailAddressesResponseDto,
  PaginatedReceivedEmailsResponseDto,
  ReceivedEmailFullResponseDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const emailsControllerCreateEmailAddress = (
  teamCode: string,
  projectCode: string,
  createEmailAddressDto: CreateEmailAddressDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<EmailAddressResponseDto>(
    {
      url: `/v1/emails/${teamCode}/${projectCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createEmailAddressDto,
      signal,
    },
    options
  );
};

export const getEmailsControllerCreateEmailAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateEmailAddressDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateEmailAddressDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>,
    { teamCode: string; projectCode: string; data: CreateEmailAddressDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return emailsControllerCreateEmailAddress(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EmailsControllerCreateEmailAddressMutationResult = NonNullable<
  Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>
>;
export type EmailsControllerCreateEmailAddressMutationBody = CreateEmailAddressDto;
export type EmailsControllerCreateEmailAddressMutationError = ErrorType<unknown>;

export const useEmailsControllerCreateEmailAddress = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateEmailAddressDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof emailsControllerCreateEmailAddress>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateEmailAddressDto },
  TContext
> => {
  const mutationOptions = getEmailsControllerCreateEmailAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
export const emailsControllerGetEmailAddresses = (
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedEmailAddressesResponseDto>(
    { url: `/v1/emails/${teamCode}/${projectCode}`, method: "GET", params, signal },
    options
  );
};

export const getEmailsControllerGetEmailAddressesQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams
) => {
  return [`/v1/emails/${teamCode}/${projectCode}`, ...(params ? [params] : [])] as const;
};

export const getEmailsControllerGetEmailAddressesQueryOptions = <
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEmailsControllerGetEmailAddressesQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>> = ({ signal }) =>
    emailsControllerGetEmailAddresses(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EmailsControllerGetEmailAddressesQueryResult = NonNullable<
  Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>
>;
export type EmailsControllerGetEmailAddressesQueryError = ErrorType<unknown>;

export function useEmailsControllerGetEmailAddresses<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | EmailsControllerGetEmailAddressesParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetEmailAddresses<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetEmailAddresses<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useEmailsControllerGetEmailAddresses<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: EmailsControllerGetEmailAddressesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddresses>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEmailsControllerGetEmailAddressesQueryOptions(teamCode, projectCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const emailsControllerGetEmailAddressByCode = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<EmailAddressResponseDto>(
    { url: `/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}`, method: "GET", signal },
    options
  );
};

export const getEmailsControllerGetEmailAddressByCodeQueryKey = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string
) => {
  return [`/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}`] as const;
};

export const getEmailsControllerGetEmailAddressByCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEmailsControllerGetEmailAddressByCodeQueryKey(teamCode, projectCode, emailAddressCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>> = ({ signal }) =>
    emailsControllerGetEmailAddressByCode(teamCode, projectCode, emailAddressCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && emailAddressCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type EmailsControllerGetEmailAddressByCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>
>;
export type EmailsControllerGetEmailAddressByCodeQueryError = ErrorType<unknown>;

export function useEmailsControllerGetEmailAddressByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetEmailAddressByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetEmailAddressByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useEmailsControllerGetEmailAddressByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetEmailAddressByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEmailsControllerGetEmailAddressByCodeQueryOptions(
    teamCode,
    projectCode,
    emailAddressCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const emailsControllerGetReceivedEmails = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedReceivedEmailsResponseDto>(
    { url: `/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}/received`, method: "GET", params, signal },
    options
  );
};

export const getEmailsControllerGetReceivedEmailsQueryKey = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams
) => {
  return [`/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}/received`, ...(params ? [params] : [])] as const;
};

export const getEmailsControllerGetReceivedEmailsQueryOptions = <
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEmailsControllerGetReceivedEmailsQueryKey(teamCode, projectCode, emailAddressCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>> = ({ signal }) =>
    emailsControllerGetReceivedEmails(teamCode, projectCode, emailAddressCode, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && emailAddressCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type EmailsControllerGetReceivedEmailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>
>;
export type EmailsControllerGetReceivedEmailsQueryError = ErrorType<unknown>;

export function useEmailsControllerGetReceivedEmails<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params: undefined | EmailsControllerGetReceivedEmailsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetReceivedEmails<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetReceivedEmails<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useEmailsControllerGetReceivedEmails<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  params?: EmailsControllerGetReceivedEmailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmails>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEmailsControllerGetReceivedEmailsQueryOptions(
    teamCode,
    projectCode,
    emailAddressCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const emailsControllerGetReceivedEmailByCode = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ReceivedEmailFullResponseDto>(
    { url: `/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}/received/${emailCode}`, method: "GET", signal },
    options
  );
};

export const getEmailsControllerGetReceivedEmailByCodeQueryKey = (
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string
) => {
  return [`/v1/emails/${teamCode}/${projectCode}/${emailAddressCode}/received/${emailCode}`] as const;
};

export const getEmailsControllerGetReceivedEmailByCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEmailsControllerGetReceivedEmailByCodeQueryKey(teamCode, projectCode, emailAddressCode, emailCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>> = ({ signal }) =>
    emailsControllerGetReceivedEmailByCode(teamCode, projectCode, emailAddressCode, emailCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && emailAddressCode && emailCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type EmailsControllerGetReceivedEmailByCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>
>;
export type EmailsControllerGetReceivedEmailByCodeQueryError = ErrorType<unknown>;

export function useEmailsControllerGetReceivedEmailByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetReceivedEmailByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEmailsControllerGetReceivedEmailByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useEmailsControllerGetReceivedEmailByCode<
  TData = Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  emailAddressCode: string,
  emailCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof emailsControllerGetReceivedEmailByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEmailsControllerGetReceivedEmailByCodeQueryOptions(
    teamCode,
    projectCode,
    emailAddressCode,
    emailCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
