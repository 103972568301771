import React from "react";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { HeadersTable } from "@/components/headers-table";
import { EmailRender } from "@/lib/projects/emails/email/email-render";
import { EmailHeader } from "@/lib/projects/emails/email/email-header";
import { Separator } from "@/components/ui/separator";
import { LoadingSpinner } from "@/components/loading-spinner";
import { VerificationChecks } from "@/lib/projects/emails/email/verification-checks";
import { formatBytes } from "@/helpers/helpers";
import { Attachments } from "@/lib/projects/emails/email/attachments";
import { EmailMimeState } from "@/utils/parsing";
import { AlignLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ReceivedEmailInfoResponseDto, useEmailsControllerGetReceivedEmailByCode } from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useCurrentProject } from "../../context/project-context";

interface EmailDisplayProps {
  emailAddressCode: string;
  emailInfo: ReceivedEmailInfoResponseDto;
}

export const EmailDisplay: React.FC<EmailDisplayProps> = ({ emailAddressCode, emailInfo }) => {
  const navigate = useNavigate();
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const [mail, setMail] = React.useState<EmailMimeState>();

  const { isPending, isError, data } = useEmailsControllerGetReceivedEmailByCode(
    teamCode,
    projectCode,
    emailAddressCode,
    emailInfo.emailCode
  );

  React.useEffect(() => {
    if (data && data.content) {
      const dataContent = data.content;

      setMail(new EmailMimeState(dataContent));
    }
  }, [data]);

  return (
    <div>
      {isPending && (
        <div className="p-8 text-center text-sm font-medium">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <p className="p-8 text-center text-sm font-medium">Error loading message...</p>}
      {!isPending && !isError && mail && data.content && (
        <div>
          <div className="flex justify-between items-center gap-4 p-4">
            <div className="sm:hidden">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate(`/dashboard/${teamCode}/${projectCode}/emails/${emailAddressCode}`)}
              >
                <AlignLeft className="h-[1.2rem] w-[1.2rem]" />
              </Button>
            </div>
            <EmailHeader emailId={emailInfo.emailCode} rawEmail={data.content} email={mail} />
          </div>
          <Separator />

          <ContentPanel email={mail} emailInfo={emailInfo} />

          {mail.attachments.length > 0 ? (
            <div>
              <Separator />
              <Attachments attachments={mail.getAttachments()} />
            </div>
          ) : (
            <></>
          )}

          <Separator />
          <EmailRender rawMail={data.content} email={mail} />
        </div>
      )}
    </div>
  );
};

interface ContentPanelProps {
  email: EmailMimeState;
  emailInfo: ReceivedEmailInfoResponseDto;
}

const ContentPanel: React.FC<ContentPanelProps> = ({ email, emailInfo }) => {
  const parsedMessage = email.getParsedMessage();
  const mailSize = email.getSize();

  return (
    <>
      <div className="sm:hidden">
        <div className="flex flex-col gap-2">
          <VerificationChecks
            requestDetails={{
              host: parsedMessage.from?.email.split("@")[1] ?? "",
              date: parsedMessage.date?.toISOString() ?? "",
              size: formatBytes(mailSize) ?? "",
              id: emailInfo.emailCode ?? "",
            }}
            headers={parsedMessage.headers}
          />
          <Separator />
          <HeadersTable title={"Headers"} headers={parsedMessage.headers} type="mail-headers" />
        </div>
      </div>
      <div className="hidden sm:block">
        <ResizablePanelGroup direction="horizontal" className="max-h-[500px]">
          <ResizablePanel defaultSize={50} minSize={25}>
            <VerificationChecks
              requestDetails={{
                host: parsedMessage.from?.email.split("@")[1] ?? "",
                date: parsedMessage.date?.toISOString() ?? "",
                size: formatBytes(mailSize) ?? "",
                id: emailInfo.emailCode ?? "",
              }}
              headers={parsedMessage.headers}
            />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={50} minSize={25}>
            <HeadersTable title={"Headers"} headers={parsedMessage.headers} type="mail-headers" />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </>
  );
};
