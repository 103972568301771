import { WebhookReceivedRequestInfoDto } from "@/api/generated";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { RecentRequestItem } from "@/lib/webhooks/sidebar/recent-request-item";
import { formatDistanceToNow } from "date-fns";

interface RecentRequestProps {
  endpointCode: string;
  group: Record<string, WebhookReceivedRequestInfoDto[]>;
  selectedRequest?: WebhookReceivedRequestInfoDto;
}

export const RecentRequests: React.FC<RecentRequestProps> = ({ endpointCode, group, selectedRequest }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  let index = 0;
  return (
    <div className="flex flex-col gap-2">
      {Object.entries(group).map(([key, receivedRequests], groupIndex) => (
        <div key={groupIndex}>
          <div className="flex flex-col gap-2">
            <h4 className="text-sm font-semibold font-mono mt-4 break-all line-clamp-1 text-wrap">{key}</h4>
            {receivedRequests.map((item) => {
              index += 1;
              return (
                <RecentRequestItem
                  key={item.id}
                  label={index.toString() + "."}
                  item={{
                    name: item.method,
                    description: item.path,
                    info: formatDistanceToNow(new Date(item.date), {
                      addSuffix: true,
                    }),
                  }}
                  path={`/dashboard/${teamCode}/${projectCode}/webhooks/${endpointCode}/${item.requestCode}`}
                  height="h-full"
                  isSelected={item === selectedRequest}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
