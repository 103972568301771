/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  FeatureFlagDto,
  FeatureResponseDto,
  FeaturesControllerDisableFeaturesByProjectAndCodesParams,
  FeaturesControllerGetFeaturesForTeamAndProjectParams,
  FeaturesControllerGetFeaturesForTeamParams,
  PaginatedFeaturesResponseDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const featuresControllerGetFeature = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FeatureResponseDto>(
    { url: `/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}`, method: "GET", signal },
    options
  );
};

export const getFeaturesControllerGetFeatureQueryKey = (teamCode: string, projectCode: string, featureCode: string) => {
  return [`/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}`] as const;
};

export const getFeaturesControllerGetFeatureQueryOptions = <
  TData = Awaited<ReturnType<typeof featuresControllerGetFeature>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFeaturesControllerGetFeatureQueryKey(teamCode, projectCode, featureCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof featuresControllerGetFeature>>> = ({ signal }) =>
    featuresControllerGetFeature(teamCode, projectCode, featureCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && featureCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof featuresControllerGetFeature>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FeaturesControllerGetFeatureQueryResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerGetFeature>>
>;
export type FeaturesControllerGetFeatureQueryError = ErrorType<unknown>;

export function useFeaturesControllerGetFeature<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeature>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeature<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeature>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeature<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeature>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFeaturesControllerGetFeature<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeature>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeature>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFeaturesControllerGetFeatureQueryOptions(teamCode, projectCode, featureCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const featuresControllerCreateOrUpdateFeature = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  featureFlagDto: FeatureFlagDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FeatureResponseDto>(
    {
      url: `/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: featureFlagDto,
      signal,
    },
    options
  );
};

export const getFeaturesControllerCreateOrUpdateFeatureMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string; data: FeatureFlagDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string; data: FeatureFlagDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>,
    { teamCode: string; projectCode: string; featureCode: string; data: FeatureFlagDto }
  > = (props) => {
    const { teamCode, projectCode, featureCode, data } = props ?? {};

    return featuresControllerCreateOrUpdateFeature(teamCode, projectCode, featureCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeaturesControllerCreateOrUpdateFeatureMutationResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>
>;
export type FeaturesControllerCreateOrUpdateFeatureMutationBody = FeatureFlagDto;
export type FeaturesControllerCreateOrUpdateFeatureMutationError = ErrorType<unknown>;

export const useFeaturesControllerCreateOrUpdateFeature = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string; data: FeatureFlagDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof featuresControllerCreateOrUpdateFeature>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string; data: FeatureFlagDto },
  TContext
> => {
  const mutationOptions = getFeaturesControllerCreateOrUpdateFeatureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const featuresControllerDeleteFeature = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}`, method: "DELETE" },
    options
  );
};

export const getFeaturesControllerDeleteFeatureMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerDeleteFeature>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featuresControllerDeleteFeature>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featuresControllerDeleteFeature>>,
    { teamCode: string; projectCode: string; featureCode: string }
  > = (props) => {
    const { teamCode, projectCode, featureCode } = props ?? {};

    return featuresControllerDeleteFeature(teamCode, projectCode, featureCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeaturesControllerDeleteFeatureMutationResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerDeleteFeature>>
>;

export type FeaturesControllerDeleteFeatureMutationError = ErrorType<unknown>;

export const useFeaturesControllerDeleteFeature = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerDeleteFeature>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof featuresControllerDeleteFeature>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string },
  TContext
> => {
  const mutationOptions = getFeaturesControllerDeleteFeatureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const featuresControllerGetFeatureForEnvironment = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FeatureResponseDto>(
    { url: `/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}/${environmentCode}`, method: "GET", signal },
    options
  );
};

export const getFeaturesControllerGetFeatureForEnvironmentQueryKey = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string
) => {
  return [`/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}/${environmentCode}`] as const;
};

export const getFeaturesControllerGetFeatureForEnvironmentQueryOptions = <
  TData = Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFeaturesControllerGetFeatureForEnvironmentQueryKey(teamCode, projectCode, featureCode, environmentCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>> = ({ signal }) =>
    featuresControllerGetFeatureForEnvironment(
      teamCode,
      projectCode,
      featureCode,
      environmentCode,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && featureCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type FeaturesControllerGetFeatureForEnvironmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>
>;
export type FeaturesControllerGetFeatureForEnvironmentQueryError = ErrorType<unknown>;

export function useFeaturesControllerGetFeatureForEnvironment<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeatureForEnvironment<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeatureForEnvironment<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFeaturesControllerGetFeatureForEnvironment<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeatureForEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFeaturesControllerGetFeatureForEnvironmentQueryOptions(
    teamCode,
    projectCode,
    featureCode,
    environmentCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const featuresControllerEvaluateFeatureForEnvironment = (
  teamCode: string,
  projectCode: string,
  featureCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FeatureResponseDto>(
    {
      url: `/v1/feature-flags/${teamCode}/${projectCode}/${featureCode}/${environmentCode}/evaluate`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getFeaturesControllerEvaluateFeatureForEnvironmentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string; environmentCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>,
    { teamCode: string; projectCode: string; featureCode: string; environmentCode: string }
  > = (props) => {
    const { teamCode, projectCode, featureCode, environmentCode } = props ?? {};

    return featuresControllerEvaluateFeatureForEnvironment(
      teamCode,
      projectCode,
      featureCode,
      environmentCode,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type FeaturesControllerEvaluateFeatureForEnvironmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>
>;

export type FeaturesControllerEvaluateFeatureForEnvironmentMutationError = ErrorType<unknown>;

export const useFeaturesControllerEvaluateFeatureForEnvironment = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; featureCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof featuresControllerEvaluateFeatureForEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; featureCode: string; environmentCode: string },
  TContext
> => {
  const mutationOptions = getFeaturesControllerEvaluateFeatureForEnvironmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const featuresControllerDisableFeaturesByProjectAndCodes = (
  teamCode: string,
  projectCode: string,
  params: FeaturesControllerDisableFeaturesByProjectAndCodesParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/feature-flags/${teamCode}/${projectCode}`, method: "DELETE", params },
    options
  );
};

export const getFeaturesControllerDisableFeaturesByProjectAndCodesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>,
    TError,
    { teamCode: string; projectCode: string; params: FeaturesControllerDisableFeaturesByProjectAndCodesParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>,
  TError,
  { teamCode: string; projectCode: string; params: FeaturesControllerDisableFeaturesByProjectAndCodesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>,
    { teamCode: string; projectCode: string; params: FeaturesControllerDisableFeaturesByProjectAndCodesParams }
  > = (props) => {
    const { teamCode, projectCode, params } = props ?? {};

    return featuresControllerDisableFeaturesByProjectAndCodes(teamCode, projectCode, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeaturesControllerDisableFeaturesByProjectAndCodesMutationResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>
>;

export type FeaturesControllerDisableFeaturesByProjectAndCodesMutationError = ErrorType<unknown>;

export const useFeaturesControllerDisableFeaturesByProjectAndCodes = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>,
    TError,
    { teamCode: string; projectCode: string; params: FeaturesControllerDisableFeaturesByProjectAndCodesParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof featuresControllerDisableFeaturesByProjectAndCodes>>,
  TError,
  { teamCode: string; projectCode: string; params: FeaturesControllerDisableFeaturesByProjectAndCodesParams },
  TContext
> => {
  const mutationOptions = getFeaturesControllerDisableFeaturesByProjectAndCodesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const featuresControllerGetFeaturesForTeamAndProject = (
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedFeaturesResponseDto>(
    { url: `/v1/feature-flags/${teamCode}/${projectCode}`, method: "GET", params, signal },
    options
  );
};

export const getFeaturesControllerGetFeaturesForTeamAndProjectQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams
) => {
  return [`/v1/feature-flags/${teamCode}/${projectCode}`, ...(params ? [params] : [])] as const;
};

export const getFeaturesControllerGetFeaturesForTeamAndProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFeaturesControllerGetFeaturesForTeamAndProjectQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>> = ({
    signal,
  }) => featuresControllerGetFeaturesForTeamAndProject(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FeaturesControllerGetFeaturesForTeamAndProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>
>;
export type FeaturesControllerGetFeaturesForTeamAndProjectQueryError = ErrorType<unknown>;

export function useFeaturesControllerGetFeaturesForTeamAndProject<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeaturesForTeamAndProject<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeaturesForTeamAndProject<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFeaturesControllerGetFeaturesForTeamAndProject<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: FeaturesControllerGetFeaturesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFeaturesControllerGetFeaturesForTeamAndProjectQueryOptions(
    teamCode,
    projectCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const featuresControllerGetFeaturesForTeam = (
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedFeaturesResponseDto>(
    { url: `/v1/feature-flags/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getFeaturesControllerGetFeaturesForTeamQueryKey = (
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams
) => {
  return [`/v1/feature-flags/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getFeaturesControllerGetFeaturesForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFeaturesControllerGetFeaturesForTeamQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>> = ({ signal }) =>
    featuresControllerGetFeaturesForTeam(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FeaturesControllerGetFeaturesForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>
>;
export type FeaturesControllerGetFeaturesForTeamQueryError = ErrorType<unknown>;

export function useFeaturesControllerGetFeaturesForTeam<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | FeaturesControllerGetFeaturesForTeamParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeaturesForTeam<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFeaturesControllerGetFeaturesForTeam<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFeaturesControllerGetFeaturesForTeam<
  TData = Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: FeaturesControllerGetFeaturesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof featuresControllerGetFeaturesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFeaturesControllerGetFeaturesForTeamQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
