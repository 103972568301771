import { isNull, isUndefined } from "@wt-dev/common/utils";

export type ColorDefinition = Record<"bg" | "fg", string>;

export const ENV_COLORS = {
  $default: {
    fg: "#3a3a47",
    bg: "#3a3a4750",
  },
  $red: {
    fg: "#f20000",
    bg: "#ffd9d9",
  },
  $orange: {
    fg: "#ea580c",
    bg: "#fee9de",
  },
  $yellow: {
    fg: "#dd7802",
    bg: "#fef0df",
  },
  $green: {
    fg: "#16a34a",
    bg: "#c6f1d6",
  },
  $cyan: {
    fg: "#0891b2",
    bg: "#aae7f6",
  },
  $blue: {
    fg: "#2563eb",
    bg: "#e0e9fc",
  },
  $purple: {
    fg: "#9333ea",
    bg: "#f5ecfd",
  },
  $pink: {
    fg: "#db2777",
    bg: "#fdebf3",
  },
} satisfies Record<string, ColorDefinition>;

export function parseEnvColor(color?: string | null): ColorDefinition | undefined {
  if (color && color in ENV_COLORS) {
    return ENV_COLORS[color as keyof typeof ENV_COLORS];
  }

  if (!isUndefined(color) && !isNull(color)) {
    return { bg: color, fg: color };
  }

  return;
}
