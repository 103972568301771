import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const client = axios.create({
  baseURL: process.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

/* Orval */
export const AXIOS_INSTANCE = client;

export const customInstance = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = axios.CancelToken.source();

  const response = await AXIOS_INSTANCE({
    ...options,
    ...config,
    cancelToken: source.token,
  });

  // @ts-ignore
  response.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return response.data;
};

export type ErrorType<Error> = AxiosError<Error>;
