import { useAuthControllerRegister } from "@/api/generated";
import { useAuth } from "@/auth/useAuth";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { SignInWithGithub } from "@/lib/auth/github/sign-in-with-github";
import { onErrorToast } from "@/utils/api.util";
import React from "react";
import { UseFormReset } from "react-hook-form";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const registrationSchema = z
  .object({
    name: z.string(),
    email: z.string().email({ message: "Must be a valid email" }),
    password: z.string().min(6, "PPassword must be at least 6 characters long"),
    repeatPassword: z.string().min(6, "Password must be at least 6 characters long"),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Passwords do not match",
    path: ["repeatPassword"], // Set the error on repeatPassword field
  });

interface RegisterFormProps {
  isMarketplace?: boolean;
  regToken?: string;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({ isMarketplace }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { login } = useAuth();

  const [searchParams] = useSearchParams();

  const inviteCode = searchParams.get("inviteCode");

  const registerMutation = useAuthControllerRegister();

  const onSubmit = (
    values: z.infer<typeof registrationSchema>,
    reset: UseFormReset<z.infer<typeof registrationSchema>>
  ) => {
    registerMutation.mutate(
      {
        data: {
          email: values.email,
          password: values.password,
          name: values.name,
          inviteCode: inviteCode || undefined,
        },
      },
      {
        onSuccess(data) {
          if (data && data.accessToken) {
            login(data.accessToken);
            reset();
            navigate(searchParams.get("redirect") ?? "/dashboard");
          }
        },
        onError: onErrorToast,
      }
    );
  };

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex justify-center items-center gap-4">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
          {isMarketplace && (
            <>
              <p className="text-sm mt-1">&#x2715;</p>
              <div className="flex items-center gap-2">
                <h4 className="mt-1">AWS Marketplace</h4>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">Create an Account</h5>
        <small>Please enter your account details.</small>
      </div>

      <div className="flex flex-col gap-4">
        <SignInWithGithub />

        <div className="flex justify-center">
          <div className="flex items-center w-11/12 gap-2">
            <hr className="w-full border-t border-gray-500" />
            <span className="text-muted-foreground text-gray-800">or</span>
            <hr className="w-full border-t border-gray-500" />
          </div>
        </div>

        <Form schema={registrationSchema} onSubmit={onSubmit} defaultValues={{ email: state?.emailPrefill ?? "" }}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <TextField name="name" placeholder="Name" />
              <TextField name="email" placeholder="Email" />
              <TextField name="password" placeholder="Password" type="password" />
              <TextField name="repeatPassword" placeholder="Repeat Password" type="password" />
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={registerMutation.isPending}
              className="bg-secondary-950"
            >
              {registerMutation.isPending ? <LoadingSpinner message={"Signing up"} /> : "Sign Up"}
            </Button>
          </div>
        </Form>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <small>Already have an account?</small>
        <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign In</small>
        </Link>
      </div>
    </Card>
  );
};
