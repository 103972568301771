import { EnvironmentMiniResponseDto } from "@/api/generated";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { LayersIcon } from "lucide-react";
import React from "react";

interface EnvHeaderProps {
  environment: EnvironmentMiniResponseDto;
}

export const EnvHeader: React.FC<EnvHeaderProps> = ({ environment }) => {
  const envColor = parseEnvColor(environment.color);

  return (
    <div
      className="flex w-full h-full items-center px-2"
      style={{
        backgroundColor: envColor?.bg,
        color: envColor?.bg !== envColor?.fg ? envColor?.fg : undefined,
      }}
    >
      <code>
        <LayersIcon className="inline-block mr-2 size-4" />
        {environment.code}
      </code>
    </div>
  );
};
