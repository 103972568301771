import { Toast, toast } from "@/components/ui/use-toast";
import { isObject, isString } from "@wt-dev/common/utils";
import { isAxiosError } from "axios";

type ParsedErrror<T = unknown> = { title: T; description: T };

const DEFAULT_ERROR: ParsedErrror<string> = {
  title: "Uh oh! Something went wrong.",
  description: "There was a problem with your request. Please try again.",
};

function parseErrorData(data: any, defaults: ParsedErrror): ParsedErrror {
  if (!isObject(data)) return defaults;

  if ("error" in data) {
    defaults.title = `${data.error} Error`;
  }

  if ("message" in data) {
    defaults.description = data.message;

    if (isObject(data.message)) {
      return parseErrorData(data.message, defaults);
    }
  }

  return defaults;
}

function parseErrorBase(error?: any): { title: unknown; description: unknown } {
  if (isAxiosError(error)) {
    const data = error.response?.data as any;

    return parseErrorData(data, {
      title: error.code ?? `${error.status} Error`,
      description: error.message,
    });
  }

  if (error instanceof Error) {
    return {
      title: DEFAULT_ERROR.title,
      description: error.message,
    };
  }

  return DEFAULT_ERROR;
}

function valueToString(value: unknown): string {
  if (isString(value)) return value;

  try {
    return JSON.stringify(value);
  } catch (error1) {
    console.error(error1);

    try {
      return String(value);
    } catch (error2) {
      console.error(error2);
    }

    return "Internal Error";
  }
}

export function parseError(error?: any): { title: string; description: string } {
  const parsed = parseErrorBase(error);

  return {
    title: valueToString(parsed.title),
    description: valueToString(parsed.description),
  };
}

export function onErrorToast(error: any) {
  return toast({
    ...parseError(error),
    variant: "destructive",
  });
}

export function onErrorToastExtended(error: any, props: Toast = {}) {
  return toast({
    ...parseError(error),
    variant: "destructive",
    ...props,
  });
}
