import { useApiKeysControllerCreateApiKey } from "@/api/generated";
import { CopyableValue } from "@/components/copyable-input";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ApiKeyPermissionsBuilder } from "@/lib/teams/api-keys/api-key-permissions-builder";
import { onErrorToast } from "@/utils/api.util";
import { Plus } from "lucide-react";
import React from "react";
import { z } from "zod";

export const CreateKeySchema = z.object({
  permissions: z.string().min(1, { message: "Permissions must be at least 1 character long" }),
  description: z.optional(z.string().max(128, { message: "Description must be at most 128 character long" })),
});

interface AddApiKeyDialogProps {
  teamCode: string;
  onSuccess?: () => void;
  children: React.ReactNode;
}

export const AddApiKeyDialog: React.FC<AddApiKeyDialogProps> = ({ teamCode, onSuccess, children }) => {
  const [open, setOpen] = React.useState(false);

  const createMutation = useApiKeysControllerCreateApiKey({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const handleSetOpen = (open: boolean) => {
    if (!open) {
      setTimeout(() => {
        createMutation.reset();
      }, 0);
    }

    setOpen(open);
  };

  const createContent = (
    <>
      <DialogTitle className="text-wrap whitespace-break-spaces w-full">Create new API key</DialogTitle>
      <DialogDescription>Enter the permissions for the new API key.</DialogDescription>

      <Form
        schema={CreateKeySchema}
        onSubmit={(data, reset) => {
          createMutation.mutate(
            { teamCode, data },
            {
              onSuccess: () => reset(),
            }
          );
        }}
      >
        <ApiKeyPermissionsBuilder />

        <TextAreaField name="description" label="Description" placeholder="Description..." />

        <div className="flex justify-end mt-10">
          <Button variant="neu-flat" size="sm" className="bg-secondary-300" disabled={createMutation.isPending}>
            {createMutation.isPending ? (
              <LoadingSpinner message={"Creating"} />
            ) : (
              <>
                Create Key <Plus size={16} className="ml-1" />{" "}
              </>
            )}
          </Button>
        </div>
      </Form>
    </>
  );

  const displayContent = createMutation.data && (
    <>
      <DialogTitle className="text-wrap whitespace-pre truncate">API Key #{createMutation.data.id}</DialogTitle>
      <DialogDescription>Make sure you save the secret!</DialogDescription>

      <div className="flex flex-col gap-2">
        <Label className="text-sm font-semibold">Key</Label>
        <CopyableValue value={createMutation.data.key}>
          <Input variant="neu-flat" value={createMutation.data.key} />
        </CopyableValue>

        <Label className="text-sm font-semibold">Secret</Label>
        <CopyableValue value={createMutation.data.secret!}>
          <Input variant="neu-flat" type="password" value={createMutation.data.secret} />
        </CopyableValue>
      </div>
    </>
  );

  return (
    <Dialog open={open} onOpenChange={handleSetOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        {createMutation.data ? displayContent : createContent}
      </DialogContent>
    </Dialog>
  );
};
