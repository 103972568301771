import React from "react";

export interface TeamContextType {
  teamCode: string;
}

export const TeamContext = React.createContext<TeamContextType | null>(null);

export interface TeamContextProps {
  teamCode: string;
  children: React.ReactNode;
}

export const TeamProvider: React.FC<TeamContextProps> = ({ teamCode, children }) => {
  return (
    <TeamContext.Provider
      value={{
        teamCode,
      }}
      children={children}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentTeam = () => {
  const context = React.useContext(TeamContext);
  if (!context) {
    throw new Error(`${useCurrentTeam.name} must be used within ${TeamProvider.name}`);
  }

  return context;
};
