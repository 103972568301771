import {
  getProjectsControllerGetProjectsQueryKey,
  useProjectsControllerCreateEnvironment,
  useProjectsControllerCreateProject,
} from "@/api/generated";
import { ColorSelect } from "@/components/color-select";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { onErrorToast } from "@/utils/api.util";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { useQueryClient } from "@tanstack/react-query";
import { PaletteIcon, Plus, Trash } from "lucide-react";
import React from "react";
import { useFieldArray, useFormContext, UseFormReset } from "react-hook-form";
import { z } from "zod";

const createProjectSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Name must be at least 1 character long" })
    .max(64, { message: "Name can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Name can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.string().optional(),
  environments: z
    .array(
      z.object({
        code: z.union([
          z
            .string()
            .min(1, { message: "Code must be at least 1 character long" })
            .max(64, { message: "Code can be at most 64 characters long" })
            .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
              message:
                "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
            }),
          z.literal(""),
        ]),
        description: z.string().optional(),
        color: z.string().optional(),
      })
    )
    .min(1, { message: "At least one environment is required" })
    .refine(
      (environments) =>
        environments.every((env, index) =>
          index === 0 || env.code?.trim() ? env.code && env.code.trim().length > 0 : true
        ),
      {
        message: "At least one environment is required",
        path: ["[0].code"],
      }
    ),
});

interface AddProjectDialogProps {
  teamCode: string;
  children: React.ReactNode;
}

export const AddProjectDialog: React.FC<AddProjectDialogProps> = ({ teamCode, children }) => {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const createEnvironmentMutation = useProjectsControllerCreateEnvironment();

  const createProjectMutation = useProjectsControllerCreateProject();

  // TODO: One request for all?
  const onSubmit = async (
    values: z.infer<typeof createProjectSchema>,
    reset: UseFormReset<z.infer<typeof createProjectSchema>>
  ) => {
    const project = await createProjectMutation.mutateAsync(
      {
        teamCode: teamCode,
        data: {
          code: values.name,
          description: values.description,
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          reset();
          queryClient.invalidateQueries({
            queryKey: getProjectsControllerGetProjectsQueryKey(teamCode),
          });
        },
        onError: onErrorToast,
      }
    );

    const environments = values.environments.filter((env) => Boolean(env?.code)) as Array<{
      code: string;
      description?: string;
      color?: string;
    }>;

    await Promise.allSettled(
      environments.map(async (env, i) => {
        return await createEnvironmentMutation.mutateAsync(
          {
            teamCode: teamCode,
            data: {
              code: env.code,
              description: env.description,
              color: env.color,
              sortIndex: i + 1,
            },
            projectCode: project.code,
          },
          {
            onError: () => {
              toast({
                title: `Failed to create environment "${env.code}"`,
                description: "There was a problem with your request. Please try again.",
              });
            },
          }
        );
      })
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        className="max-w-3xl"
      >
        <DialogTitle>Create new project</DialogTitle>
        <DialogDescription>
          Create a new project to manage your secrets, hyperlinks, and feature flags. You can always edit the project
          later.
        </DialogDescription>
        <Form
          schema={createProjectSchema}
          onSubmit={onSubmit}
          defaultValues={{
            environments: [{ code: "default", description: "Default environment", color: "$default" }],
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="space-y-1">
              <TextField name="name" label="Name" placeholder="Input name..." />
            </div>
            <div className="space-y-1">
              <TextAreaField name="description" label="Description" placeholder="Input description..." />
            </div>

            <div>
              <h4>Environments</h4>
              <DialogDescription className="mt-2">
                Input at least one environment to create the project.
              </DialogDescription>
            </div>

            <EnvPresetEditor />

            <div className="flex justify-end mt-4">
              <Button
                type="submit"
                variant="neu-flat"
                size="sm"
                className="bg-tertiary-200"
                disabled={createProjectMutation.isPending}
              >
                {createProjectMutation.isPending ? (
                  <LoadingSpinner message={"Creating"} />
                ) : (
                  <>
                    Create Project <Plus size={16} className="ml-1" />
                  </>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const FieldEdit: React.FC<{
  index: number;
  field: {
    code: string;
    description?: string | undefined;
    color?: string | undefined;
  };
  onUpdate: (field: { code: string; description?: string | undefined; color?: string | undefined }) => void;
  onRemove?: () => void;
}> = ({ index, field, onUpdate, onRemove }) => {
  const [colorEdit, setColorEdit] = React.useState(false);

  const color = parseEnvColor(field.color);

  return (
    <>
      <div>
        <TextField
          style={{
            background: color?.bg,
          }}
          name={`environments.[${index}].code`}
          placeholder="Name..."
        />
      </div>

      {colorEdit && (
        <div className="self-center ml-4">
          <ColorSelect
            value={field.color}
            onValueChange={(color) => {
              onUpdate({ ...field, color });
            }}
          />
        </div>
      )}

      {!colorEdit && (
        <div>
          <TextAreaField
            style={{
              background: color?.bg,
            }}
            className="min-h-10 h-10"
            name={`environments.[${index}].description`}
            placeholder="Description..."
          />
        </div>
      )}

      <Button
        type="button"
        size="icon"
        onClick={() => setColorEdit((e) => !e)}
        style={{
          color: colorEdit ? color?.fg : undefined,
        }}
      >
        <PaletteIcon size={16} />
      </Button>
      {onRemove && (
        <Button type="button" size="icon" className="bg-red-200" onClick={() => onRemove()}>
          <Trash size={16} />
        </Button>
      )}
    </>
  );
};

const EnvPresetEditor: React.FC = () => {
  const [preset, setPreset] = React.useState<"default" | "test-sand-prod" | "3-custom">("default");

  const { control } = useFormContext<z.infer<typeof createProjectSchema>>();

  const { fields, remove, replace, append, update } = useFieldArray({
    control,
    name: "environments",
  });

  return (
    <div>
      <Label>Preset</Label>
      <Select
        value={preset}
        onValueChange={(p) => {
          const newPreset = p as typeof preset;
          setPreset(newPreset);

          setTimeout(() => {
            remove();

            switch (newPreset) {
              case "default":
                replace([{ code: "default", description: "Default environment", color: "$default" }]);
                break;
              case "test-sand-prod":
                replace([
                  { code: "test", description: "Test environment", color: "$green" },
                  { code: "sand", description: "Sandbox environment", color: "$yellow" },
                  { code: "prod", description: "Production environment", color: "$red" },
                ]);
                break;
              case "3-custom":
                replace([
                  { code: "", description: "" },
                  { code: "", description: "" },
                  { code: "", description: "" },
                ]);
                break;
            }
          }, 0);
        }}
      >
        <SelectTrigger className="mb-4">
          <SelectValue placeholder="Select Preset" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="default">Default</SelectItem>
          <SelectItem value="test-sand-prod">test/sand/prod</SelectItem>
          <SelectItem value="3-custom">Custom Environments</SelectItem>
        </SelectContent>
      </Select>

      {(preset === "test-sand-prod" || preset === "default") && (
        <div className="grid grid-cols-[1fr,2fr,min-content] gap-2">
          <Label className="text-sm font-semibold">Environment</Label>
          <Label className="text-sm font-semibold col-span-3">Description</Label>

          {fields.map((field, i) => {
            return <FieldEdit index={i} field={field} onUpdate={(newField) => update(i, newField)} />;
          })}
        </div>
      )}

      {preset === "3-custom" && (
        <div className="grid grid-cols-[1fr,2fr,min-content,min-content] gap-2">
          <Label className="text-sm font-semibold">Environment</Label>
          <Label className="text-sm font-semibold col-span-3">Description</Label>

          {fields.map((field, i) => {
            return (
              <FieldEdit
                index={i}
                field={field}
                onUpdate={(newField) => update(i, newField)}
                onRemove={() => remove(i)}
              />
            );
          })}

          <Button type="button" className="bg-secondary-200" onClick={() => append({ code: "", description: "" })}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
};
