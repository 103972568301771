/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateExceptionEventDto,
  ExceptionEventResponseDto,
  ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams,
  ExceptionsInboxControllerCaptureAnyRequestForProjectParams,
  ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  PaginatedExceptionEventsResponseDto,
  UpdateExceptionEventStatusDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const exceptionsInboxControllerGetExceptionEventsForTeam = (
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedExceptionEventsResponseDto>(
    { url: `/v1/exceptions-inbox/teams/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getExceptionsInboxControllerGetExceptionEventsForTeamQueryKey = (
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams
) => {
  return [`/v1/exceptions-inbox/teams/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getExceptionsInboxControllerGetExceptionEventsForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExceptionsInboxControllerGetExceptionEventsForTeamQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>> = ({
    signal,
  }) => exceptionsInboxControllerGetExceptionEventsForTeam(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ExceptionsInboxControllerGetExceptionEventsForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>
>;
export type ExceptionsInboxControllerGetExceptionEventsForTeamQueryError = ErrorType<unknown>;

export function useExceptionsInboxControllerGetExceptionEventsForTeam<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventsForTeam<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventsForTeam<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useExceptionsInboxControllerGetExceptionEventsForTeam<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getExceptionsInboxControllerGetExceptionEventsForTeamQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const exceptionsInboxControllerGetExceptionEventsForTeamAndProject = (
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedExceptionEventsResponseDto>(
    { url: `/v1/exceptions-inbox/teams/${teamCode}/${projectCode}`, method: "GET", params, signal },
    options
  );
};

export const getExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams
) => {
  return [`/v1/exceptions-inbox/teams/${teamCode}/${projectCode}`, ...(params ? [params] : [])] as const;
};

export const getExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>
  > = ({ signal }) =>
    exceptionsInboxControllerGetExceptionEventsForTeamAndProject(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>
>;
export type ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryError = ErrorType<unknown>;

export function useExceptionsInboxControllerGetExceptionEventsForTeamAndProject<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventsForTeamAndProject<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventsForTeamAndProject<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useExceptionsInboxControllerGetExceptionEventsForTeamAndProject<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventsForTeamAndProject>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getExceptionsInboxControllerGetExceptionEventsForTeamAndProjectQueryOptions(
    teamCode,
    projectCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a single exception event by Id
 */
export const exceptionsInboxControllerGetExceptionEventById = (
  exceptionEventId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ExceptionEventResponseDto>(
    { url: `/v1/exceptions-inbox/events/${exceptionEventId}`, method: "GET", signal },
    options
  );
};

export const getExceptionsInboxControllerGetExceptionEventByIdQueryKey = (exceptionEventId: number) => {
  return [`/v1/exceptions-inbox/events/${exceptionEventId}`] as const;
};

export const getExceptionsInboxControllerGetExceptionEventByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
  TError = ErrorType<unknown>,
>(
  exceptionEventId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExceptionsInboxControllerGetExceptionEventByIdQueryKey(exceptionEventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>> = ({
    signal,
  }) => exceptionsInboxControllerGetExceptionEventById(exceptionEventId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!exceptionEventId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ExceptionsInboxControllerGetExceptionEventByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>
>;
export type ExceptionsInboxControllerGetExceptionEventByIdQueryError = ErrorType<unknown>;

export function useExceptionsInboxControllerGetExceptionEventById<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
  TError = ErrorType<unknown>,
>(
  exceptionEventId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventById<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
  TError = ErrorType<unknown>,
>(
  exceptionEventId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerGetExceptionEventById<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
  TError = ErrorType<unknown>,
>(
  exceptionEventId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a single exception event by Id
 */

export function useExceptionsInboxControllerGetExceptionEventById<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>,
  TError = ErrorType<unknown>,
>(
  exceptionEventId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerGetExceptionEventById>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getExceptionsInboxControllerGetExceptionEventByIdQueryOptions(exceptionEventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const exceptionsInboxControllerChangeExceptionEventStatusWithNote = (
  teamCode: string,
  projectCode: string,
  exceptionEventId: number,
  updateExceptionEventStatusDto: UpdateExceptionEventStatusDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/exceptions-inbox/teams/${teamCode}/projects/${projectCode}/events/${exceptionEventId}/status`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateExceptionEventStatusDto,
      signal,
    },
    options
  );
};

export const getExceptionsInboxControllerChangeExceptionEventStatusWithNoteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>,
    TError,
    { teamCode: string; projectCode: string; exceptionEventId: number; data: UpdateExceptionEventStatusDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>,
  TError,
  { teamCode: string; projectCode: string; exceptionEventId: number; data: UpdateExceptionEventStatusDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>,
    { teamCode: string; projectCode: string; exceptionEventId: number; data: UpdateExceptionEventStatusDto }
  > = (props) => {
    const { teamCode, projectCode, exceptionEventId, data } = props ?? {};

    return exceptionsInboxControllerChangeExceptionEventStatusWithNote(
      teamCode,
      projectCode,
      exceptionEventId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ExceptionsInboxControllerChangeExceptionEventStatusWithNoteMutationResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>
>;
export type ExceptionsInboxControllerChangeExceptionEventStatusWithNoteMutationBody = UpdateExceptionEventStatusDto;
export type ExceptionsInboxControllerChangeExceptionEventStatusWithNoteMutationError = ErrorType<unknown>;

export const useExceptionsInboxControllerChangeExceptionEventStatusWithNote = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>,
    TError,
    { teamCode: string; projectCode: string; exceptionEventId: number; data: UpdateExceptionEventStatusDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exceptionsInboxControllerChangeExceptionEventStatusWithNote>>,
  TError,
  { teamCode: string; projectCode: string; exceptionEventId: number; data: UpdateExceptionEventStatusDto },
  TContext
> => {
  const mutationOptions = getExceptionsInboxControllerChangeExceptionEventStatusWithNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exceptionsInboxControllerCreatePresignedSubmitUrl = (
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<string>(
    {
      url: `/v1/exceptions-inbox/teams/${teamCode}/projects/${projectCode}/create-link/${apiKey}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getExceptionsInboxControllerCreatePresignedSubmitUrlQueryKey = (
  teamCode: string,
  projectCode: string,
  apiKey: string
) => {
  return [`/v1/exceptions-inbox/teams/${teamCode}/projects/${projectCode}/create-link/${apiKey}`] as const;
};

export const getExceptionsInboxControllerCreatePresignedSubmitUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getExceptionsInboxControllerCreatePresignedSubmitUrlQueryKey(teamCode, projectCode, apiKey);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>> = ({
    signal,
  }) => exceptionsInboxControllerCreatePresignedSubmitUrl(teamCode, projectCode, apiKey, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && apiKey), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ExceptionsInboxControllerCreatePresignedSubmitUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>
>;
export type ExceptionsInboxControllerCreatePresignedSubmitUrlQueryError = ErrorType<unknown>;

export function useExceptionsInboxControllerCreatePresignedSubmitUrl<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerCreatePresignedSubmitUrl<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerCreatePresignedSubmitUrl<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useExceptionsInboxControllerCreatePresignedSubmitUrl<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  apiKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerCreatePresignedSubmitUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getExceptionsInboxControllerCreatePresignedSubmitUrlQueryOptions(
    teamCode,
    projectCode,
    apiKey,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const exceptionsInboxControllerCaptureAnyRequestForProject = (
  teamCode: string,
  projectCode: string,
  createExceptionEventDto: CreateExceptionEventDto,
  params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/exceptions-inbox/teams/${teamCode}/projects/${projectCode}/submit*`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createExceptionEventDto,
      params,
      signal,
    },
    options
  );
};

export const getExceptionsInboxControllerCaptureAnyRequestForProjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    data: CreateExceptionEventDto;
    params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>,
    {
      teamCode: string;
      projectCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams;
    }
  > = (props) => {
    const { teamCode, projectCode, data, params } = props ?? {};

    return exceptionsInboxControllerCaptureAnyRequestForProject(teamCode, projectCode, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExceptionsInboxControllerCaptureAnyRequestForProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>
>;
export type ExceptionsInboxControllerCaptureAnyRequestForProjectMutationBody = CreateExceptionEventDto;
export type ExceptionsInboxControllerCaptureAnyRequestForProjectMutationError = ErrorType<unknown>;

export const useExceptionsInboxControllerCaptureAnyRequestForProject = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProject>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    data: CreateExceptionEventDto;
    params: ExceptionsInboxControllerCaptureAnyRequestForProjectParams;
  },
  TContext
> => {
  const mutationOptions = getExceptionsInboxControllerCaptureAnyRequestForProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  createExceptionEventDto: CreateExceptionEventDto,
  params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/exceptions-inbox/teams/${teamCode}/projects/${projectCode}/environments/${environmentCode}/submit*`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createExceptionEventDto,
      params,
      signal,
    },
    options
  );
};

export const getExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      environmentCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    environmentCode: string;
    data: CreateExceptionEventDto;
    params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>,
    {
      teamCode: string;
      projectCode: string;
      environmentCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams;
    }
  > = (props) => {
    const { teamCode, projectCode, environmentCode, data, params } = props ?? {};

    return exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment(
      teamCode,
      projectCode,
      environmentCode,
      data,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>
>;
export type ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentMutationBody = CreateExceptionEventDto;
export type ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentMutationError = ErrorType<unknown>;

export const useExceptionsInboxControllerCaptureAnyRequestForProjectEnvironment = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      environmentCode: string;
      data: CreateExceptionEventDto;
      params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exceptionsInboxControllerCaptureAnyRequestForProjectEnvironment>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    environmentCode: string;
    data: CreateExceptionEventDto;
    params: ExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentParams;
  },
  TContext
> => {
  const mutationOptions = getExceptionsInboxControllerCaptureAnyRequestForProjectEnvironmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exceptionsInboxControllerReturnSubmitExample = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/v1/exceptions-inbox/schema`, method: "GET", signal }, options);
};

export const getExceptionsInboxControllerReturnSubmitExampleQueryKey = () => {
  return [`/v1/exceptions-inbox/schema`] as const;
};

export const getExceptionsInboxControllerReturnSubmitExampleQueryOptions = <
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExceptionsInboxControllerReturnSubmitExampleQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>> = ({
    signal,
  }) => exceptionsInboxControllerReturnSubmitExample(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ExceptionsInboxControllerReturnSubmitExampleQueryResult = NonNullable<
  Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>
>;
export type ExceptionsInboxControllerReturnSubmitExampleQueryError = ErrorType<unknown>;

export function useExceptionsInboxControllerReturnSubmitExample<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerReturnSubmitExample<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useExceptionsInboxControllerReturnSubmitExample<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useExceptionsInboxControllerReturnSubmitExample<
  TData = Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof exceptionsInboxControllerReturnSubmitExample>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getExceptionsInboxControllerReturnSubmitExampleQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
