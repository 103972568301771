import React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock, ChevronDown, ChevronUp, CheckCircle, XCircle, AlertTriangle, Maximize } from "lucide-react";
import { PuppeteerJobPageLoadData } from "@/lib/projects/monitoring/run-details/url-health-browser-run-details";

export type PuppeteerJobScreenshotData = {
  screenshotStorageId: string;
  screenshotPresignedUrl: string;
  screenshotUploadTimeMs: number;
  browserViewport: {
    width: number;
    height: number;
    sizeText: string;
  };
};

type PuppeteerJobData = PuppeteerJobPageLoadData & PuppeteerJobScreenshotData;

interface UrlHealthScreenshotRunDetailsProps {
  rawData: any;
}

export const UrlHealthScreenshotRunDetails: React.FC<UrlHealthScreenshotRunDetailsProps> = ({ rawData }) => {
  const data = rawData as PuppeteerJobData;
  const [isFailedRequestsExpanded, setIsFailedRequestsExpanded] = React.useState(false);

  const getResultBadge = (result: string) => {
    switch (result) {
      case "success":
        return (
          <Badge className="bg-green-500 hover:bg-green-600">
            <CheckCircle className="mr-1 h-4 w-4" />
            Success
          </Badge>
        );
      case "success-with-errors":
        return (
          <Badge className="bg-yellow-500 hover:bg-yellow-600">
            <AlertTriangle className="mr-1 h-4 w-4" />
            Success with Errors
          </Badge>
        );
      case "failure":
        return (
          <Badge className="bg-red-500 hover:bg-red-600">
            <XCircle className="mr-1 h-4 w-4" />
            Failure
          </Badge>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="text-lg font-semibold leading-none tracking-tight">URL Health (Screenshot)</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Page Load Time</h2>
          <p className="text-lg">
            <Clock className="inline mr-2 h-5 w-5" />
            {data.pageLoadTimeMs} ms
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Result</h2>
          {getResultBadge(data.pageLoadResult.result)}
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Result Description</h2>
          <p className="text-lg">{data.pageLoadResult.resultDesc}</p>
        </div>
        {data.pageLoadResult.responseStatus && (
          <div className="space-y-2">
            <h2 className="text-sm font-medium text-gray-500">Response Status</h2>
            <p className="text-lg">{data.pageLoadResult.responseStatus}</p>
          </div>
        )}
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Browser Viewport</h2>
          <p className="text-lg">
            <Maximize className="inline mr-2 h-5 w-5" />
            {data.browserViewport.width} x {data.browserViewport.height} ({data.browserViewport.sizeText})
          </p>
        </div>

        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Screenshot</h2>
          <img src={data.screenshotPresignedUrl} alt="Screenshot" className="w-full" />
        </div>

        {data.pageLoadResult.failedRequests && data.pageLoadResult.failedRequests.length > 0 && (
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-medium text-gray-500">Failed Requests</h2>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsFailedRequestsExpanded(!isFailedRequestsExpanded)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {isFailedRequestsExpanded ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Collapse
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    Expand
                  </>
                )}
              </Button>
            </div>
            {isFailedRequestsExpanded && (
              <ul className="list-disc list-inside space-y-2 text-red-600 break-all text-wrap">
                {data.pageLoadResult.failedRequests.map((request, index) => (
                  <li key={index}>
                    <span className="font-semibold">{request.url}</span>
                    <br />
                    <span className="text-sm">{request.error}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
