import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import React, { useState } from "react";
import {
  getNotificationTopicsControllerGetChannelsPaginatedQueryKey,
  NotificationChannelResponseDto,
  useNotificationTopicsControllerDeleteChannel
} from "@/api/generated";
import {onErrorToastExtended} from "@/utils/api.util.ts";
import {ToastAction} from "@/components/ui/toast.tsx";
import {useQueryClient} from "@tanstack/react-query";

interface NotificationChannelConfirmDeleteProps {
  channel: NotificationChannelResponseDto;
}

export const NotificationChannelConfirmDelete: React.FC<NotificationChannelConfirmDeleteProps> = ({
  channel
}) => {
  const [opened, setOpened] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useNotificationTopicsControllerDeleteChannel({
    mutation: {
      onSuccess: () => {
        queryClient
          .invalidateQueries({
            queryKey: getNotificationTopicsControllerGetChannelsPaginatedQueryKey(channel.teamCode),
          })
          .then(() => setOpened(false));
      },
      onError: (error) => {
        onErrorToastExtended(error, { action: <ToastAction altText="Try again">Try again</ToastAction> });
      },
    },
  });

  const handleDelete = async () => {
    deleteMutation.mutate(
      {
        teamCode: channel.teamCode,
        channelCode: channel.code,
      }
    );
  };

  return (
    <AlertDialog open={opened}>
      <AlertDialogTrigger asChild onClick={() => setOpened(true)}>
        <Button size="sm" className="bg-red-400 text-white">
          <Trash2 className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to delete this channel?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the notification channel "
            <strong>{channel.code}</strong>" and remove all associated configurations.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpened(false)}>Cancel</AlertDialogCancel>
          <AlertDialogAction className="bg-red-400" onClick={handleDelete}>
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
