import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/utils/ui.util";
import { CheckCircle2 } from "lucide-react";
import React from "react";

interface KeyFeaturesProps {
  features?: Array<{
    title: React.ReactNode;
    description?: React.ReactNode;
  }>;
  className?: string;
  onClick?: () => void;
}

export const KeyFeatures: React.FC<KeyFeaturesProps> = ({ features, className, onClick }) => {
  return (
    <div className={cn("flex flex-col gap-3", className)}>
      {features?.map((feature, i) => {
        const button = (
          <Button
            key={`feature-${i}`}
            onClick={onClick}
            className={cn(
              "neu-button !px-4 !py-2 h-fit flex items-center !justify-start !text-left gap-4 rounded-md bg-tertiary-200 hover:bg-white active:bg-white active:border-tertiary-700 active:text-tertiary-700 select-none",
              !feature.description && "pointer-events-none"
            )}
          >
            <CheckCircle2 className="h-8 w-8 current" />
            <p className="w-full">{feature.title}</p>
          </Button>
        );

        if (!feature.description) return button;

        return (
          <Popover>
            <PopoverTrigger asChild>{button}</PopoverTrigger>
            <PopoverContent>{feature.description}</PopoverContent>
          </Popover>
        );
      })}
    </div>
  );
};
