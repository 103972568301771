import { useAuth } from "@/auth/useAuth";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { ApiMutation } from "@/old/api";
import { VerificationType } from "@/old/api/types/request";
import { onErrorToast } from "@/utils/api.util";
import { useFormik } from "formik";
import React from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ResendVerificationCode } from "./elements/resend-verification-code";

interface LoginFormProps {
  title: string;
  description: string;
}

export const VerifyUserForm: React.FC<LoginFormProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();

  const verifyUserMutation = ApiMutation.useVerifyUser({
    onSuccess: (data) => {
      login(data.accessToken);
      navigate(searchParams.get("redirect") ?? "/dashboard");
    },
    onError: onErrorToast,
  });

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    onSubmit: (values) => {
      verifyUserMutation.mutate({ email: state.email, token: values.token });
    },
  });

  if (!state || !state.email) {
    return <Navigate to="/auth" replace={true} />;
  }

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h5 className="font-semibold">{title}</h5>
        <small className="text-center">{description}</small>
      </div>
      <div className="flex flex-col gap-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1.5">
              <Input
                id="token"
                placeholder="Confirmation Code"
                type="text"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                required
                value={formik.values.token}
                onChange={formik.handleChange}
              />
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={verifyUserMutation.isPending}
              className="bg-secondary-950"
            >
              {verifyUserMutation.isPending ? <LoadingSpinner message={"Confirming"} /> : "Confirm"}
            </Button>
          </div>
        </form>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <small>Didn't receive the code?</small>
        <ResendVerificationCode email={state.email} type={VerificationType.VerifyUser} />
      </div>
    </Card>
  );
};
