import { format } from "date-fns/format";
import { DownloadBlob } from "@/components/download-blob";
import { Button } from "@/components/ui/button";
import { ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ParserInitialSetup } from "@/routes/tools/tools-samples-inputs";
import { EmailMimeState } from "@/utils/parsing";
import { useCurrentTeam } from "@/lib/teams/context/team-context";

interface EmailHeaderProps {
  emailId: string;
  rawEmail: string;
  email: EmailMimeState;
}

export const EmailHeader: React.FC<EmailHeaderProps> = ({ emailId, rawEmail, email }) => {
  const navigate = useNavigate();
  const { teamCode } = useCurrentTeam();

  const parsedMessage = email.getParsedMessage();

  const from = parsedMessage.from?.email ?? "";
  const subject = parsedMessage.subject ?? "";
  const date = parsedMessage.date ?? new Date();

  const blob = new Blob([rawEmail], { type: "text/plain;charset=utf-8" });

  function navigateToTools(): void {
    const state: ParserInitialSetup = {
      input: { type: "string", value: rawEmail },
      initialSnake: [{ transformationId: "to-email-mime" }],
      name: "Imported Email",
    };
    navigate(`/dashboard/${teamCode}/tools`, { state: state });
  }

  return (
    <>
      <div className="flex items-start gap-4 text-sm">
        <div className="grid gap-1">
          <p className="font-semibold text-sm">{from}</p>
          <p className="text-sm break-all line-clamp-1 text-wrap">{subject}</p>
          <div className="flex gap-1">
            <p className="font-medium text-xs">Reply-To:</p>
            <p className="text-xs break-all line-clamp-1 text-wrap">{from}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {date && <p className="hidden sm:block text-xs whitespace-nowrap">{format(new Date(date), "PPpp")}</p>}
        <DownloadBlob blob={blob} filename={emailId} />
        <Button
          variant="ghost"
          size="sm"
          className="hidden sm:flex items-center gap-1 bg-secondary-200"
          onClick={navigateToTools}
        >
          Explore
          <ArrowUpRight size={16} />
        </Button>
      </div>
    </>
  );
};
