import { Button } from "@/components/ui/button";
import { UserDropdown } from "@/layouts/elements/user-dropdown";
import { ChevronLeftIcon } from "lucide-react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { LoadingSpinner } from "@/components/loading-spinner";
import { cn } from "@/utils/ui.util";
import { CreditCardIcon, ShieldEllipsis, UserIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { UserResponseDto, useUsersControllerGetLoggedInUser } from "@/api/generated";

const settingsNav = {
  items: [
    {
      title: "Personal Profile",
      path: "/settings",
      icon: <UserIcon size={16} />,
    },
    {
      title: "Password & Credentials",
      path: "/settings/credentials",
      icon: <ShieldEllipsis size={16} />,
      nonOauthOnly: true,
    },
    {
      title: "Payments and Subscriptions",
      path: "/settings/payments",
      icon: <CreditCardIcon size={16} />,
    },
  ],
};

export const SettingsLayout: React.FC = () => {
  return (
    <div className="min-h-dvh bg-secondary-200">
      <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-primary-300/90 border-b-[2px] border-secondary-950">
        <div className="flex justify-between px-4 w-full">
          <div className="flex items-center gap-2">
            <Button asChild variant="ghost" size="sm" className="bg-transparent hover:underline p-0">
              <Link to="/dashboard" className="no-underline">
                <ChevronLeftIcon size={18} />
                Dashboard
              </Link>
            </Button>
          </div>

          <div>
            <UserDropdown />
          </div>
        </div>
      </header>

      {/* Content */}
      <Settings />
    </div>
  );
};

export interface SettingsOutletContext {
  user: UserResponseDto;
}

export const Settings: React.FC = () => {
  const { data: user, isPending, isError } = useUsersControllerGetLoggedInUser();

  return (
    <div className="flex p-2 md:p-10 justify-center">
      <div className="w-full max-w-7xl">
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && user && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col space-y-2">
              <h4 className="font-bold tracking-tight">Settings</h4>
              <small>Manage your account settings, credentials, and other preferences.</small>
            </div>

            <div className="grid md:grid-cols-12 gap-8">
              {/* Sidebar */}
              <div className="grid col-span-12 lg:col-span-3 grid-rows-[min-content,1fr] overflow-hidden ">
                <div className="py-4">
                  <div className="flex flex-col gap-1">
                    {settingsNav.items.map((item) => {
                      if (item.nonOauthOnly && user.registrationType === "github") {
                        return null;
                      }

                      return (
                        <SidebarNavItem
                          key={item.path}
                          icon={item.icon}
                          isSelected={window.location.pathname === item.path}
                          title={item.title}
                          path={item.path}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="grid col-span-12 lg:col-span-9 border-secondary-950 overflow-hidden">
                <div className="py-4 px-1">
                  <div className="neu rounded-lg bg-white py-8 px-2 sm:px-4">
                    <Outlet
                      context={
                        {
                          user: user,
                        } as SettingsOutletContext
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface SidebarNavItemProps {
  title: string;
  icon: React.ReactNode;
  isSelected: boolean;
  path: string;
}

const SidebarNavItem: React.FC<SidebarNavItemProps> = ({ title, icon, isSelected, path }) => {
  return (
    <Button
      asChild
      variant={"neu-flat"}
      scheme={isSelected ? "black" : "white-black"}
      size="sm"
      className={cn("justify-start", !isSelected && "bg-white-")}
    >
      {/* {title} */}
      <Link to={path} className="flex gap-2 items-center no-underline">
        <div>{icon}</div>
        <p className="text-sm">{title}</p>
      </Link>
    </Button>
  );
};
