/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateHyperlinkDto,
  HyperlinksHyperlinksControllerDeleteHyperlinksParams,
  HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  PaginatedHyperlinksResponseDto,
  ProjectHyperlinkResponseDto,
  UpdateHyperlinkDto,
  UpdateHyperlinkEnvironmentDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Creates new hyperlink item
 */
export const hyperlinksHyperlinksControllerCreateHyperlink = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  createHyperlinkDto: CreateHyperlinkDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectHyperlinkResponseDto>(
    {
      url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createHyperlinkDto,
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinksControllerCreateHyperlinkMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateHyperlinkDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: CreateHyperlinkDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateHyperlinkDto }
  > = (props) => {
    const { teamCode, projectCode, itemCode, data } = props ?? {};

    return hyperlinksHyperlinksControllerCreateHyperlink(teamCode, projectCode, itemCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HyperlinksHyperlinksControllerCreateHyperlinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>
>;
export type HyperlinksHyperlinksControllerCreateHyperlinkMutationBody = CreateHyperlinkDto;
export type HyperlinksHyperlinksControllerCreateHyperlinkMutationError = ErrorType<unknown>;

/**
 * @summary Creates new hyperlink item
 */
export const useHyperlinksHyperlinksControllerCreateHyperlink = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateHyperlinkDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerCreateHyperlink>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: CreateHyperlinkDto },
  TContext
> => {
  const mutationOptions = getHyperlinksHyperlinksControllerCreateHyperlinkMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates a Hyperlink
 */
export const hyperlinksHyperlinksControllerUpdateHyperlink = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  updateHyperlinkDto: UpdateHyperlinkDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProjectHyperlinkResponseDto>(
    {
      url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateHyperlinkDto,
    },
    options
  );
};

export const getHyperlinksHyperlinksControllerUpdateHyperlinkMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateHyperlinkDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: UpdateHyperlinkDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateHyperlinkDto }
  > = (props) => {
    const { teamCode, projectCode, itemCode, data } = props ?? {};

    return hyperlinksHyperlinksControllerUpdateHyperlink(teamCode, projectCode, itemCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HyperlinksHyperlinksControllerUpdateHyperlinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>
>;
export type HyperlinksHyperlinksControllerUpdateHyperlinkMutationBody = UpdateHyperlinkDto;
export type HyperlinksHyperlinksControllerUpdateHyperlinkMutationError = ErrorType<unknown>;

/**
 * @summary Updates a Hyperlink
 */
export const useHyperlinksHyperlinksControllerUpdateHyperlink = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateHyperlinkDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlink>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: UpdateHyperlinkDto },
  TContext
> => {
  const mutationOptions = getHyperlinksHyperlinksControllerUpdateHyperlinkMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets hyperlink by code
 */
export const hyperlinksHyperlinksControllerGetHyperlink = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectHyperlinkResponseDto>(
    { url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`, method: "GET", signal },
    options
  );
};

export const getHyperlinksHyperlinksControllerGetHyperlinkQueryKey = (
  teamCode: string,
  projectCode: string,
  itemCode: string
) => {
  return [`/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`] as const;
};

export const getHyperlinksHyperlinksControllerGetHyperlinkQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHyperlinksHyperlinksControllerGetHyperlinkQueryKey(teamCode, projectCode, itemCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>> = ({ signal }) =>
    hyperlinksHyperlinksControllerGetHyperlink(teamCode, projectCode, itemCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && itemCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinksControllerGetHyperlinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>
>;
export type HyperlinksHyperlinksControllerGetHyperlinkQueryError = ErrorType<unknown>;

export function useHyperlinksHyperlinksControllerGetHyperlink<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlink<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlink<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets hyperlink by code
 */

export function useHyperlinksHyperlinksControllerGetHyperlink<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlink>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinksHyperlinksControllerGetHyperlinkQueryOptions(
    teamCode,
    projectCode,
    itemCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates a Hyperlink Value for a single Environment
 */
export const hyperlinksHyperlinksControllerUpdateHyperlinkForEnv = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  updateHyperlinkEnvironmentDto: UpdateHyperlinkEnvironmentDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProjectHyperlinkResponseDto>(
    {
      url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateHyperlinkEnvironmentDto,
    },
    options
  );
};

export const getHyperlinksHyperlinksControllerUpdateHyperlinkForEnvMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: UpdateHyperlinkEnvironmentDto;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    itemCode: string;
    environmentCode: string;
    data: UpdateHyperlinkEnvironmentDto;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: UpdateHyperlinkEnvironmentDto;
    }
  > = (props) => {
    const { teamCode, projectCode, itemCode, environmentCode, data } = props ?? {};

    return hyperlinksHyperlinksControllerUpdateHyperlinkForEnv(
      teamCode,
      projectCode,
      itemCode,
      environmentCode,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type HyperlinksHyperlinksControllerUpdateHyperlinkForEnvMutationResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>
>;
export type HyperlinksHyperlinksControllerUpdateHyperlinkForEnvMutationBody = UpdateHyperlinkEnvironmentDto;
export type HyperlinksHyperlinksControllerUpdateHyperlinkForEnvMutationError = ErrorType<unknown>;

/**
 * @summary Updates a Hyperlink Value for a single Environment
 */
export const useHyperlinksHyperlinksControllerUpdateHyperlinkForEnv = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: UpdateHyperlinkEnvironmentDto;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerUpdateHyperlinkForEnv>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    itemCode: string;
    environmentCode: string;
    data: UpdateHyperlinkEnvironmentDto;
  },
  TContext
> => {
  const mutationOptions = getHyperlinksHyperlinksControllerUpdateHyperlinkForEnvMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes hyperlinks
 */
export const hyperlinksHyperlinksControllerDeleteHyperlinks = (
  teamCode: string,
  projectCode: string,
  params: HyperlinksHyperlinksControllerDeleteHyperlinksParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}`, method: "DELETE", params },
    options
  );
};

export const getHyperlinksHyperlinksControllerDeleteHyperlinksMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>,
    TError,
    { teamCode: string; projectCode: string; params: HyperlinksHyperlinksControllerDeleteHyperlinksParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>,
  TError,
  { teamCode: string; projectCode: string; params: HyperlinksHyperlinksControllerDeleteHyperlinksParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>,
    { teamCode: string; projectCode: string; params: HyperlinksHyperlinksControllerDeleteHyperlinksParams }
  > = (props) => {
    const { teamCode, projectCode, params } = props ?? {};

    return hyperlinksHyperlinksControllerDeleteHyperlinks(teamCode, projectCode, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HyperlinksHyperlinksControllerDeleteHyperlinksMutationResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>
>;

export type HyperlinksHyperlinksControllerDeleteHyperlinksMutationError = ErrorType<unknown>;

/**
 * @summary Deletes hyperlinks
 */
export const useHyperlinksHyperlinksControllerDeleteHyperlinks = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>,
    TError,
    { teamCode: string; projectCode: string; params: HyperlinksHyperlinksControllerDeleteHyperlinksParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerDeleteHyperlinks>>,
  TError,
  { teamCode: string; projectCode: string; params: HyperlinksHyperlinksControllerDeleteHyperlinksParams },
  TContext
> => {
  const mutationOptions = getHyperlinksHyperlinksControllerDeleteHyperlinksMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a paginated list of Hyperlinks by Project and Environment
 */
export const hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedHyperlinksResponseDto>(
    {
      url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/environments/${environmentCode}/items`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryKey = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams
) => {
  return [
    `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/environments/${environmentCode}/items`,
    ...(params ? [params] : []),
  ] as const;
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryKey(
      teamCode,
      projectCode,
      environmentCode,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>
  > = ({ signal }) =>
    hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment(
      teamCode,
      projectCode,
      environmentCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>
>;
export type HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryError = ErrorType<unknown>;

export function useHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params: undefined | HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Hyperlinks by Project and Environment
 */

export function useHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinksHyperlinksControllerGetHyperlinksForProjectAndEnvironmentQueryOptions(
    teamCode,
    projectCode,
    environmentCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a paginated list of Hyperlinks by Project
 */
export const hyperlinksHyperlinksControllerGetHyperlinksForProject = (
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedHyperlinksResponseDto>(
    { url: `/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items`, method: "GET", params, signal },
    options
  );
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams
) => {
  return [`/v1/hyperlinks/teams/${teamCode}/projects/${projectCode}/items`, ...(params ? [params] : [])] as const;
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>> = ({
    signal,
  }) => hyperlinksHyperlinksControllerGetHyperlinksForProject(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinksControllerGetHyperlinksForProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>
>;
export type HyperlinksHyperlinksControllerGetHyperlinksForProjectQueryError = ErrorType<unknown>;

export function useHyperlinksHyperlinksControllerGetHyperlinksForProject<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForProject<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForProject<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Hyperlinks by Project
 */

export function useHyperlinksHyperlinksControllerGetHyperlinksForProject<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryOptions(
    teamCode,
    projectCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a paginated list of Hyperlinks for a team
 */
export const hyperlinksHyperlinksControllerGetHyperlinksForTeam = (
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedHyperlinksResponseDto>(
    { url: `/v1/hyperlinks/teams/${teamCode}/items`, method: "GET", params, signal },
    options
  );
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForTeamQueryKey = (
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams
) => {
  return [`/v1/hyperlinks/teams/${teamCode}/items`, ...(params ? [params] : [])] as const;
};

export const getHyperlinksHyperlinksControllerGetHyperlinksForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHyperlinksHyperlinksControllerGetHyperlinksForTeamQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>> = ({
    signal,
  }) => hyperlinksHyperlinksControllerGetHyperlinksForTeam(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinksControllerGetHyperlinksForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>
>;
export type HyperlinksHyperlinksControllerGetHyperlinksForTeamQueryError = ErrorType<unknown>;

export function useHyperlinksHyperlinksControllerGetHyperlinksForTeam<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForTeam<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinksControllerGetHyperlinksForTeam<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Hyperlinks for a team
 */

export function useHyperlinksHyperlinksControllerGetHyperlinksForTeam<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: HyperlinksHyperlinksControllerGetHyperlinksForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof hyperlinksHyperlinksControllerGetHyperlinksForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinksHyperlinksControllerGetHyperlinksForTeamQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
