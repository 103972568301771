import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { ResponseTimeChart } from "@/lib/projects/monitoring/elements/response-time-chart";
import { MonitorEventInfoDto } from "@/api/generated";
import { DisplayMonitorEvents } from "@/lib/projects/monitoring/elements/display-monitor-events";
import { MonitoringJobs } from "@/lib/projects/monitoring/utils";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/utils/ui.util";

interface CurrentStatus {
  status: string;
  color: string;
}

interface BasicStats {
  title: string;
  description: string;
  value: string;
}

interface MonitorDisplayProps {
  monitorEvents: MonitorEventInfoDto[];
  selectedMonitor: string;
  hyperlinkCode: string;
  hyperlinkItemValueId: number;
}

export const MonitorDisplay: React.FC<MonitorDisplayProps> = ({
  hyperlinkCode,
  hyperlinkItemValueId,
  monitorEvents,
  selectedMonitor,
}) => {
  const monitorEventsStats: BasicStats[] = React.useMemo(() => {
    const successCount = monitorEvents.filter((event) => event.result === "success").length;
    const failedCount = monitorEvents.filter((event) => event.result === "failure").length;

    const responseTimes = monitorEvents
      .filter((event) => event.checkDurationMillis !== null)
      .map((event) => event.checkDurationMillis!);

    const avgResponse = Number((responseTimes.reduce((acc, curr) => acc + curr, 0) / responseTimes.length).toFixed(1));

    const uptime = Number(((successCount / monitorEvents.length) * 100).toFixed(1));

    return [
      {
        title: "Response",
        description: "(Current)",
        value: Number(responseTimes[responseTimes.length - 1]).toFixed(1) + " ms",
      },
      {
        title: "Avg. Response",
        description: "(8-hour)",
        value: avgResponse.toFixed(1) + " ms",
      },
      {
        title: "Uptime",
        description: "(8-hour)",
        value: uptime.toFixed(1) + "%",
      },
      {
        title: "Success Rate",
        description: "(8-hour)",
        value: Number(((successCount / monitorEvents.length) * 100).toFixed(1)) + "%",
      },
      {
        title: "Failed Rate",
        description: "(8-hour)",
        value: Number(((failedCount / monitorEvents.length) * 100).toFixed(1)) + "%",
      },
    ];
  }, [monitorEvents]);

  const currentStatus: CurrentStatus = React.useMemo(() => {
    const lastEvent = monitorEvents[monitorEvents.length - 1];
    if (lastEvent.result === "success") {
      return {
        status: "Up",
        color: "bg-green-500",
      };
    }

    if (lastEvent.result === "success-with-warning") {
      return {
        status: "Warning",
        color: "bg-yellow-500",
      };
    }

    if (lastEvent.result === "failure") {
      return {
        status: "Down",
        color: "bg-red-500",
      };
    }

    return {
      status: "Unknown",
      color: "bg-gray-500",
    };
  }, [monitorEvents]);

  const selectedMonitorLabel = React.useMemo(() => {
    return Object.values(MonitoringJobs).find((job) => job.key === selectedMonitor)?.label;
  }, [selectedMonitor]);

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        {/* Title */}
        <h4 className="font-bold tracking-tight">{selectedMonitorLabel}</h4>
      </div>

      <div className="flex items-start justify-between">
        {/* Status Columns */}
        <div className="space-y-1">
          <DisplayMonitorEvents
            monitor={selectedMonitor}
            hyperlinkCode={hyperlinkCode}
            hyperlinkItemValueId={hyperlinkItemValueId}
            monitorEvents={monitorEvents}
          />
          <p className="text-sm text-gray-600">Check every 15 min</p>
        </div>

        {/* Current status */}
        <Badge
          className={cn(
            "flex justify-center text-white h-8 min-w-16 text-base rounded-lg font-bold",
            currentStatus.color,
            `hover:${currentStatus.color}`
          )}
        >
          {currentStatus.status}
        </Badge>
      </div>

      {/* Metrics Grid with Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {monitorEventsStats.map((stat) => (
          <Card key={stat.title} className="bg-secondary-200">
            <CardContent className="p-4 space-y-2">
              <div className="space-y-1">
                <h3 className="text-sm font-medium">{stat.title}</h3>
                <p className="text-xs text-gray-600">{stat.description}</p>
              </div>
              <p className="text-xl font-semibold">{stat.value}</p>
            </CardContent>
          </Card>
        ))}
      </div>

      <ResponseTimeChart monitorEvents={monitorEvents} />
    </div>
  );
};
