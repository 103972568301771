import { ColumnDef } from "@tanstack/react-table";
import { NotificationTopicConfirmDelete } from "@/lib/teams/notifications/topics/notification-topics-confirm-delete-dialog.tsx";

import { Button } from "@/components/ui/button.tsx";
import { NotificationTopicPostToTopicDialog } from "@/lib/teams/notifications/topics/notificaiton-topics-post-to-topic.tsx";
import { NotificationTopicUpsertDialog } from "@/lib/teams/notifications/topics/notification-topics-create-dialog.tsx";
import { NotificationTopicResponseDto } from "@/api/generated";

export const notificationTopicsColumns: ColumnDef<NotificationTopicResponseDto>[] = [
  {
    accessorKey: "id",
    header: "ID",
    cell: ({ row }) => {
      const id = row.getValue("id") as number;
      return <span>{id}</span>;
    },
  },
  {
    accessorKey: "teamCode",
    header: "Team Code",
    cell: ({ row }) => {
      const teamCode = row.getValue("teamCode") as string;
      return <span className="font-mono font-semibold">{teamCode}</span>;
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      const name = row.getValue("name") as string;
      return <span>{name}</span>;
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: ({ row }) => {
      const description = row.getValue("description") as string;
      return <span>{description}</span>;
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex items-center justify-end space-x-3">
          <NotificationTopicUpsertDialog teamCode={row.original.teamCode} existingTopic={row.original}>
            <Button variant="neu" className=" justify-self-end">
              Edit
            </Button>
          </NotificationTopicUpsertDialog>
          <NotificationTopicPostToTopicDialog topic={row.original} />
          <NotificationTopicConfirmDelete topic={row.original} />
        </div>
      );
    },
  },
];
