import {
  CreateSecretDtoEnvValues,
  useSecretsSecretsControllerCreateSecret,
  useSecretsSecretsControllerUpdateSecret,
} from "@/api/generated";
import { FieldError } from "@/components/field-error";
import { TextFieldBase } from "@/components/form/elements/text-field";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { cn } from "@/utils/ui.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { isFunction } from "@wt-dev/common/utils";
import { ExpandIcon, Plus, ShrinkIcon, Trash } from "lucide-react";
import React from "react";
import { FieldErrors, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useCurrentProject } from "../context/project-context";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { Textarea } from "@/components/ui/textarea";
import { useAutoResize } from "@/hooks/use-auto-resize";
import { parseEnvColor } from "@/utils/colors/color-definitions";

interface EditSecretDialogProps {
  item?: Fixed__ProjectSecretResponseDto;
  children: React.ReactNode;
  onSuccess?: () => void;
}

export const EditSecretDialog: React.FC<EditSecretDialogProps> = ({ item, children, onSuccess }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="max-w-4xl min-h-[40vh] max-h-screen overflow-hidden grid grid-rows-[min-content,1fr] gap-y-8"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        {item ? <DialogTitle>Edit Secret: {item.code}</DialogTitle> : <DialogTitle>Create Secret</DialogTitle>}

        {open && (
          <Editor
            item={item}
            onSuccess={() => {
              onSuccess?.();
              setOpen(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface EditorProps {
  item?: Fixed__ProjectSecretResponseDto;
  onSuccess?: () => void;
}

const Editor: React.FC<EditorProps> = ({ item, onSuccess }) => {
  const [otherValues, setOtherValues] = React.useState<SecretsEditorCodeInputs>();

  if (otherValues) {
    return <EditorValues item={item} otherValues={otherValues} onSuccess={onSuccess} />;
  }

  return <EditorCode item={item} onSubmit={(data) => setOtherValues(data)} />;
};

export const SecretsEditorCodeSchema = z.object({
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.optional(z.string()),
});

export type SecretsEditorCodeInputs = z.infer<typeof SecretsEditorCodeSchema>;

interface EditorCodeProps {
  item?: Fixed__ProjectSecretResponseDto;
  onSubmit: SubmitHandler<SecretsEditorCodeInputs>;
}

const EditorCode: React.FC<EditorCodeProps> = ({ item, onSubmit }) => {
  const form = useForm<SecretsEditorCodeInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SecretsEditorCodeSchema),
  });

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2 justify-between">
      <div>
        <div className="mb-2">
          <TextFieldBase
            label="Code"
            defaultValue={item?.code}
            description="Code is used to access this secret via API"
            error={form.formState.errors.code}
            {...form.register("code", { required: true })}
          />
        </div>

        <div className="mb-4">
          <TextFieldBase
            label="Description"
            defaultValue={item?.description ?? ""}
            error={form.formState.errors.description}
            {...form.register("description", { required: false })}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <Button variant="neu-flat" type="submit" className="bg-tertiary-200">
          Next
        </Button>
      </div>
    </form>
  );
};

export const SecretsEditorValuesSchema = z.object({
  envValues: z.record(
    z
      .array(
        z.object({
          key: z.string(),
          value: z.string().min(1, "Value must be defined"),
        })
      )
      .refine(
        (items) => {
          const keys = items.map((item) => item.key);
          return keys.length === new Set(keys).size;
        },
        {
          message: "Keys must be unique",
        }
      )
  ),
});

// eslint-disable-next-line react-refresh/only-export-components
export function keyValueToArray(values: Record<string, string>): Array<{ key: string; value: string }> {
  return objectKeys(values).map((key) => ({ key, value: String(values[key]) }));
}

// eslint-disable-next-line react-refresh/only-export-components
export function keyValueFromArray(values: Array<{ key: string; value: string }>): Record<string, string> {
  return values.reduce(
    (p, c) => {
      p[c.key] = c.value;
      return p;
    },
    {} as Record<string, string>
  );
}

export type SecretsEditorValuesInputs = z.infer<typeof SecretsEditorValuesSchema>;

interface EditorValuesProps {
  item?: Fixed__ProjectSecretResponseDto;
  otherValues: SecretsEditorCodeInputs;
  onSuccess?: () => void;
}

const EditorValues: React.FC<EditorValuesProps> = ({ item, otherValues, onSuccess }) => {
  const { projectCode, projectEnvironments } = useCurrentProject();
  const [environment, setEnvironment] = React.useState(() => projectEnvironments[0].code);

  const { teamCode } = useCurrentTeam();

  const createMutation = useSecretsSecretsControllerCreateSecret({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const updateMutation = useSecretsSecretsControllerUpdateSecret({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const form = useForm<SecretsEditorValuesInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SecretsEditorValuesSchema),
    disabled: createMutation.isPending || updateMutation.isPending,
    defaultValues: {
      envValues: objectKeys(item?.envValues ?? {}).reduce(
        (p, key) => {
          p[key] = keyValueToArray(item?.envValues?.[key] ?? {});
          return p;
        },
        {} as Record<string, Array<{ key: string; value: string }>>
      ),
    },
  });

  const onSubmit: SubmitHandler<SecretsEditorValuesInputs> = (data) => {
    if (item) {
      updateMutation.mutate({
        teamCode,
        projectCode,
        data: {
          description: otherValues.description,
          envValues: objectKeys(data.envValues)
            .filter((envCode) => Boolean(data.envValues[envCode]) && data.envValues[envCode]?.length)
            .reduce((p, envCode) => {
              p[envCode] = keyValueFromArray(data.envValues[envCode] ?? []);

              return p;
            }, {} as CreateSecretDtoEnvValues),
        },
        itemCode: item.code,
      });
    } else {
      createMutation.mutate({
        teamCode,
        projectCode,
        data: {
          description: otherValues.description,
          envValues: objectKeys(data.envValues)
            .filter((envCode) => Boolean(data.envValues[envCode]) && data.envValues[envCode]?.length)
            .reduce((p, envCode) => {
              p[envCode] = keyValueFromArray(data.envValues[envCode] ?? []);

              return p;
            }, {} as CreateSecretDtoEnvValues),
        },
        itemCode: otherValues.code,
      });
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="grid grid-rows-[min-content,1fr,min-content] grid-cols-1 gap-2 justify-between overflow-hidden"
    >
      <Tabs value={environment} onValueChange={setEnvironment}>
        <TabsList className="w-full bg-transparent gap-2 h-fit p-1 mb-4">
          {projectEnvironments.map((env) => {
            const count = (() => {
              if (!item) {
                return 0;
              }

              const envValue = item.envValues[env.code];
              if (!envValue) {
                return 0;
              }

              return objectKeys(envValue).length;
            })();

            return (
              <TabsTrigger
                key={`env-${env.code}`}
                value={env.code}
                type="button"
                className="text-sm data-[state=active]:opacity-100 data-[state=active]:bg-white opacity-40 w-full"
                style={{
                  backgroundColor: parseEnvColor(env.color)?.bg,
                }}
              >
                {env.code} {!!count && `(${count})`}
              </TabsTrigger>
            );
          })}
        </TabsList>
      </Tabs>

      <div className="overflow-hidden">
        <EditEnvSecret
          env={environment}
          item={item}
          onChange={(value) => {
            form.setValue(`envValues.[${environment}]`, value);
          }}
          errors={form.formState.errors}
          name={(i) => `envValues.[${environment}].[${i}]`}
        />

        <FieldError errors={form.formState.errors} name={`envValues.[${environment}]`} />
      </div>

      <div className="flex justify-end mt-4">
        <Button variant="neu-flat" type="submit" className="bg-tertiary-200">
          Save
        </Button>
      </div>
    </form>
  );
};

export function EditEnvSecret<T extends FieldValues = FieldValues>({
  env,
  item,
  onChange,
  errors,
  name,
}: {
  item?: Fixed__ProjectSecretResponseDto;
  env: string;
  onChange: (
    v: {
      key: string;
      value: string;
    }[]
  ) => void;
  errors?: FieldErrors<T>;
  name?: (index: number) => string;
}) {
  const [value, setValue] = React.useState(() => keyValueToArray(item?.envValues?.[env] ?? {}));

  const handleSetValue = React.useCallback(
    (newValues: React.SetStateAction<typeof value>) => {
      setValue((values) => {
        const result = isFunction(newValues) ? newValues(values) : newValues;

        onChange(result);

        return result;
      });
    },
    [onChange]
  );

  const editPair = React.useCallback(
    (index: number, value: Partial<{ key: string; value: string }>) => {
      handleSetValue((oldValues) => {
        return oldValues?.map((v, i) => {
          if (i === index) {
            return {
              key: value.key ?? v.key ?? "",
              value: value.value ?? v.value ?? "",
            };
          }

          return v;
        });
      });
    },
    [handleSetValue]
  );

  return (
    <div className="neu-flat rounded-md pr-4 overflow-y-auto max-h-full">
      <div className="grid grid-cols-[2fr,3fr,min-content,min-content] items-start gap-x-2 gap-y-2 max-h-full overflow-y-auto pr-2 py-4 pl-4 ">
        {!!value?.length && (
          <>
            <Label className="font-bold">Key</Label>
            <Label className="font-bold col-span-3">Value</Label>
          </>
        )}

        {value?.map(({ key, value }, i) => {
          return (
            <KeyValueInput
              key={`key-value-${i}`}
              keyValue={key}
              value={value}
              onEdit={(v) => editPair(i, v)}
              name={name?.(i) ?? ""}
              onDelete={() => setValue((v) => v.filter((_, j) => i !== j))}
              errors={errors}
            />
          );
        })}

        <Button
          variant="neu-flat"
          className="bg-tertiary-200"
          type="button"
          onClick={() => setValue((v) => [...(v ?? []), { key: "", value: "" }])}
        >
          <Plus size={16} className="mr-2" /> Add Pair
        </Button>
      </div>
    </div>
  );
}

interface KeyValueInputProps {
  keyValue: string;
  value: string;
  name: string;
  onEdit: (value: Partial<{ key: string; value: string }>) => void;
  onDelete: () => void;
  errors?: FieldErrors<any>;
}

const KeyValueInput: React.FC<KeyValueInputProps> = ({ keyValue: key, value, name, onEdit, onDelete, errors }) => {
  const [expand, setExpand] = React.useState(false);

  const ref = React.useRef<HTMLTextAreaElement>(null);
  useAutoResize(ref, [expand]);

  const valueInput = (
    <Textarea
      ref={ref}
      variant="neu-flat"
      placeholder="Value"
      value={value}
      onChange={(e) => onEdit({ value: e.target.value })}
      rows={expand ? 3 : 1}
      className={cn(
        "bg-secondary-100 font-mono",
        !expand && "min-h-10 resize-none max-h-10",
        expand && "overflow-hidden resize-none"
      )}
    />
  );

  return (
    <>
      <div className={expand ? "col-span-2" : ""}>
        <Input
          variant="neu-flat"
          placeholder="Key"
          value={key}
          className="bg-primary-100 font-mono"
          onChange={(e) => onEdit({ key: e.target.value })}
        />
        {errors && name && <FieldError errors={errors} name={name + ".key"} />}
      </div>
      {!expand && (
        <div>
          {valueInput}
          {errors && name && <FieldError errors={errors} name={name + ".value"} />}
        </div>
      )}
      <Button
        type="button"
        size="icon"
        className={expand ? "bg-white" : "bg-white"}
        onClick={() => setExpand((e) => !e)}
      >
        {expand ? <ShrinkIcon size={16} /> : <ExpandIcon size={16} />}
      </Button>
      <Button type="button" size="icon" className="bg-red-200" onClick={onDelete}>
        <Trash size={16} />
      </Button>

      {expand && (
        <div className="col-span-4">
          {valueInput}
          {errors && name && <FieldError errors={errors} name={name + ".value"} />}
        </div>
      )}

      {errors && name && <FieldError errors={errors} name={name} className="col-span-4" />}
    </>
  );
};
