import React from "react";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { Button } from "@/components/ui/button";
import { useWebhooksControllerCreateWebhook, useWebhooksControllerGetWebhooks } from "@/api/generated";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { Navigate, useNavigate } from "react-router-dom";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { webhooksColumns } from "@/lib/webhooks/dashboard/webhooks-columns";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Plus } from "lucide-react";
import { useCurrentProject } from "@/lib/projects/context/project-context";

export const WebhooksDashboard: React.FC = () => {
  const { toast } = useToast();
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const navigate = useNavigate();

  const createWebhookMutation = useWebhooksControllerCreateWebhook({
    mutation: {
      onSuccess: (data) => {
        navigate(`/dashboard/${teamCode}/${projectCode}/webhooks/${data.endpointCode}`);
      },
      onError: (error) => {
        const message = (error.response?.data as { message?: string })?.message;

        if (message) {
          toast({ title: "Oops!", description: message });
        } else {
          toast(defaultError);
        }
      },
    },
  });

  const handleCreateWebhook = () => {
    createWebhookMutation.mutate({
      teamCode,
      projectCode,
      data: {
        enabled: true,
        endpointCode: Math.random().toString(36).substring(2, 9),
        maxItems: 10,
      },
    });
  };

  const pagination = usePagination();

  const {
    data: webhooks,
    isPending,
    isError,
  } = useWebhooksControllerGetWebhooks(teamCode, projectCode, pagination.params);

  return (
    <FeaturePage
      title="Webhooks"
      description="Create temporary webhook endpoints to capture and inspect the data your systems sends."
      isLoading={isPending}
      actions={
        <Button className="bg-secondary-200" onClick={handleCreateWebhook} disabled={createWebhookMutation.isPending}>
          {createWebhookMutation.isPending ? (
            "Creating Webhook..."
          ) : (
            <div className="flex items-center">
              Create Webhook
              <Plus size={16} className="ml-1" />
            </div>
          )}
        </Button>
      }
    >
      <>
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && (
          <>
            <ManagerTable columns={webhooksColumns} data={webhooks.results ?? []} />

            <Pagination className="pt-4" pagination={pagination} numberOfPages={webhooks?.numberOfPages} />
          </>
        )}
      </>
    </FeaturePage>
  );
};
