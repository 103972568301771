import React from "react";
import { RecentRequests } from "@/lib/webhooks/sidebar/recent-requests";
import { CopyableInput } from "@/components/copyable-input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns/format";
import { RequestHelp } from "@/lib/webhooks/help/request-help";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TooltipBadge } from "@/components/tooltip-badge";
import { GroupByOption, SortOption, WebhookService } from "@/utils/webhooks/webhook-service";
import { OptionsPopover } from "@/lib/webhooks/sidebar/options-dropdown";
import { WebhookStatusCard } from "@/lib/webhooks/help/webhook-status-card";
import { AlignLeft, Lock } from "lucide-react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useOutletContext, useParams } from "react-router-dom";
import {
  PaginatedWebhookReceivedRequestsDto,
  useWebhooksControllerGetWebhookReceivedRequests,
  WebhookReceivedRequestInfoDto,
  WebhookResponseDto,
} from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { LoadingSpinner } from "@/components/loading-spinner";
import { RequestDisplay } from "@/lib/webhooks/request/request-display";
import { ResponseDisplay } from "@/lib/webhooks/response/response-display";
import { SocketProvider } from "@/lib/websockets/websocket-provider";
import { constructEndpointUrl } from "./webhook-endpoint-builder";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { WebhooksEvent } from "@/lib/websockets/websocket-events";
import { ServiceType } from "@wt-dev/common/ws";

export interface WebhookStatus {
  isEndpointEnabled: boolean;
  maxItems: number;
}

interface WebhookPageOutletContext {
  webhook: WebhookResponseDto;
}

export const WebhookPage: React.FC = () => {
  const { webhook } = useOutletContext<WebhookPageOutletContext>();
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { requestCode } = useParams<{ requestCode: string }>();

  const endpointStatus = React.useMemo<WebhookStatus>(() => {
    const isEndpointEnabled = webhook.enabled;
    const maxItems = webhook.maxItems;

    return { isEndpointEnabled, maxItems };
  }, [webhook]);

  const {
    data: received,
    isPending,
    isError,
    refetch,
  } = useWebhooksControllerGetWebhookReceivedRequests(teamCode, projectCode, webhook.endpointCode);

  const handleNewEvent = React.useCallback(
    (event: WebhooksEvent) => {
      if (event.data.endpointCode === webhook.endpointCode) {
        console.log(event.data.message);
        refetch();
      }
    },
    [refetch, webhook]
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <SocketProvider<WebhooksEvent>
          config={{
            service: ServiceType.WEBHOOKS,
            teamCode: teamCode,
            projectCode: projectCode,
            handleEvent: handleNewEvent,
          }}
        >
          <WebhookPageCore
            endpointCode={webhook.endpointCode}
            requestCode={requestCode}
            received={received}
            endpointStatus={endpointStatus}
          />
        </SocketProvider>
      )}
    </>
  );
};

interface WebhookPageCoreProps {
  endpointCode: string;
  requestCode?: string;
  received: PaginatedWebhookReceivedRequestsDto;
  endpointStatus: WebhookStatus;
}

export const WebhookPageCore: React.FC<WebhookPageCoreProps> = ({
  endpointCode,
  requestCode,
  received,
  endpointStatus,
}) => {
  const endpoint = constructEndpointUrl(endpointCode);
  const navigate = useNavigate();

  const selected: WebhookReceivedRequestInfoDto | undefined = React.useMemo(
    () => received.results.find((item) => item.requestCode === requestCode),
    [received, requestCode]
  );

  const selectedIndex = React.useMemo(() => {
    return received.results.findIndex((item) => item.id === selected?.id);
  }, [selected, received]);

  const sidebar = (
    <Sidebar
      endpointCode={endpointCode}
      endpoint={endpoint}
      received={received}
      endpointStatus={endpointStatus}
      selected={selected}
    />
  );

  return (
    <div className="flex flex-col md:flex-row w-full gap-6">
      {/* Sidebar */}
      <div className="flex flex-col md:w-4/12 2xl:w-3/12">
        <div className="neu rounded-lg bg-secondary-200 h-[calc(100dvh-120px)]">{sidebar}</div>
      </div>

      {/* Content */}
      <div className="flex flex-col md:w-8/12 2xl:w-9/12">
        <div className="flex flex-col">
          {(!received.count || !requestCode) && (
            <RequestHelp
              endpointCode={endpointCode}
              endpoint={endpoint}
              showRequestsHelp={true}
              endpointStatus={endpointStatus}
            />
          )}
          {received.count > 0 && selectedIndex < received.count && selected && (
            <>
              <Tabs key={selectedIndex} defaultValue="request">
                <div className="flex justify-between items-center mt-2 px-2 gap-2">
                  {/* Mobile Sidebar */}
                  <div className="sm:hidden">
                    <Button variant="ghost" size="icon" onClick={() => navigate(`/webhooks/${endpointCode}`)}>
                      <AlignLeft className="h-[1.2rem] w-[1.2rem]" />
                    </Button>
                  </div>
                  <TabsList className="grid grid-cols-3 bg-transparent">
                    <TabsTrigger
                      value="request"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Request
                    </TabsTrigger>
                    <TabsTrigger
                      value="response"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Response
                    </TabsTrigger>
                    <TabsTrigger
                      value="help"
                      className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
                    >
                      Help
                    </TabsTrigger>
                  </TabsList>
                  <p className="hidden sm:block text-xs">{format(new Date(selected.date), "PPpp")}</p>
                </div>
                <TabsContent value="request">
                  <RequestDisplay endpointCode={endpointCode} requestCode={selected.requestCode} />
                </TabsContent>
                <TabsContent value="response">
                  <ResponseDisplay endpointCode={endpointCode} requestCode={selected.requestCode} />
                </TabsContent>
                <TabsContent value="help">
                  <RequestHelp
                    endpointCode={endpointCode}
                    endpoint={endpoint}
                    resourceId={selected.id}
                    showRequestsHelp={true}
                    endpointStatus={endpointStatus}
                  />
                </TabsContent>
              </Tabs>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface SidebarProps {
  endpointCode: string;
  endpoint: string;
  received: PaginatedWebhookReceivedRequestsDto;
  endpointStatus: WebhookStatus;
  selected: WebhookReceivedRequestInfoDto | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ endpointCode, endpoint, received, endpointStatus, selected }) => {
  const [selectedGroupOption, setSelectedGroupOption] = React.useState<GroupByOption>("method");
  const [selectedSortOption, setSelectedSortOption] = React.useState<SortOption>("date");
  const [ascending, setAscending] = React.useState<boolean>(false);

  const group = React.useMemo(() => {
    return WebhookService.sortAndGroup(received.results, selectedSortOption, ascending, selectedGroupOption);
  }, [received, selectedSortOption, selectedGroupOption, ascending]);

  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-col gap-4 py-6 px-4 border-b-2 border-secondary-950">
          <div className="flex gap-2 items-start justify-between">
            <div className="flex items-center">
              <h4 className="font-semibold tracking-tight">HTTP Endpoint</h4>
            </div>
            <div className="flex gap-1">
              <TooltipBadge
                title="private"
                message="Only you can access requests sent to this endpoint."
                icon={<Lock className="w-3 h-3" />}
              />
              <TooltipBadge
                title={`Limit: ${endpointStatus.maxItems}`}
                message="The maximum number of requests that can be sent to this endpoint."
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-600">Send your request here:</p>
              <CopyableInput value={endpoint} />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[calc(100%-186px)]">
        <ScrollArea className="h-full w-full">
          <div className="py-4">
            <div className="flex items-center mb-2 px-4">
              <h5 className="font-semibold tracking-tight">Requests</h5>
              <OptionsPopover
                selectedGroupOption={selectedGroupOption}
                setSelectedGroupOption={setSelectedGroupOption}
                selectedSortOption={selectedSortOption}
                setSelectedSortOption={setSelectedSortOption}
                ascending={ascending}
                setAscending={setAscending}
              />
            </div>
            <div className="flex flex-col gap-4 px-4">
              {received.count === 0 && (
                <div className="mt-4 m-auto">
                  <p className="text-sm text-gray-600">Received requests will appear here.</p>
                </div>
              )}
              {received.count > 0 && (
                <RecentRequests endpointCode={endpointCode} group={group} selectedRequest={selected} />
              )}
              {!endpointStatus.isEndpointEnabled && <WebhookStatusCard message={"Webhook is disabled."} />}
            </div>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
