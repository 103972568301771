import {
  EnvironmentMiniResponseDto,
  ExceptionEventResponseDto,
  ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams,
  ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectStatus,
  useExceptionsInboxControllerGetExceptionEventsForTeamAndProject,
} from "@/api/generated";
import { CreatedUpdatedFormat } from "@/components/create-updated-format";
import { LoadingSpinner } from "@/components/loading-spinner";
import { MultiSelect } from "@/components/multiselect";
import { PageSizeSelect, Pagination, usePagination } from "@/components/pagination";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/components/ui/use-toast";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { ExceptionStatusBadge } from "@/lib/projects/exception-inbox/exception-status-badge";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ExceptionsEvent } from "@/lib/websockets/websocket-events";
import { SocketProvider } from "@/lib/websockets/websocket-provider";
import { objectKeys } from "@/utils";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { cn } from "@/utils/ui.util";
import { ServiceType } from "@wt-dev/common/ws";
import { CalendarIcon, CornerRightUpIcon, FilterIcon, LayersIcon, LinkIcon, RotateCwIcon } from "lucide-react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

export const ExceptionInbox: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode, projectEnvironments } = useCurrentProject();
  const [openFilters, setOpenFilters] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const [filters, setFilters] = React.useState<
    Omit<ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams, "page" | "pageSize">
  >({});

  const [debouncedFilters] = useDebounce(filters, 150);

  const [selectedEvent, setSelectedEvent] = React.useState<number | null>(null);

  const pagination = usePagination();
  const {
    isFetching,
    data: events,
    refetch,
  } = useExceptionsInboxControllerGetExceptionEventsForTeamAndProject(
    teamCode,
    projectCode,
    { ...pagination.params, ...debouncedFilters },
    {
      query: {
        placeholderData: (d) => d,
      },
    }
  );

  const handleNewEvent = React.useCallback(
    (_: ExceptionsEvent) => {
      refetch();

      toast({
        title: "New Events!",
        variant: "default",
        description: "Events list was updated",
      });
    },
    [refetch]
  );

  return (
    <SocketProvider<ExceptionsEvent>
      config={{
        service: ServiceType.EXCEPTIONS,
        teamCode,
        projectCode,
        handleEvent: handleNewEvent,
      }}
    >
      <div className="absolute top-6 bottom-6 neu rounded-md justify-between bg-secondary-200 w-full max-w-[24rem] overflow-y-hidden grid grid-cols-1 grid-rows-[min-content,1fr,min-content]">
        <div className="px-4 py-2 items-center grid grid-cols-[1fr,min-content] border-b-2 gap-x-2 gap-y-0 border-b-secondary-950">
          <h4 className="font-semibold tracking-tight">Exception Inbox </h4>
          <div className="flex items-center gap-2">
            <Button
              size="icon"
              className="bg-secondary-100 w-7 h-7 inline-flex"
              onClick={() => navigate("presigned-url")}
            >
              <LinkIcon size={16} />
            </Button>

            <Button
              size="icon"
              className="bg-primary-100 w-7 h-7 inline-flex data-[state=active]:bg-primary-50"
              data-state={openFilters ? "active" : undefined}
              onClick={() => setOpenFilters((v) => !v)}
            >
              <FilterIcon size={16} />
            </Button>

            <Button size="icon" className="bg-tertiary-100 w-7 h-7 inline-flex" onClick={() => refetch()}>
              <RotateCwIcon size={16} />
            </Button>

            <PageSizeSelect pagination={pagination} className="neu" />
          </div>

          <Filters
            filters={filters}
            setFilters={setFilters}
            open={openFilters}
            setOpen={setOpenFilters}
            projectEnvironments={projectEnvironments}
          />
        </div>

        <ScrollArea className="bg-secondary-100 relative">
          <div className="flex flex-col gap-2 p-4 relative">
            {events?.results.map((event: ExceptionEventResponseDto) => {
              const isSelected = selectedEvent === event.id;

              return (
                <Button
                  key={`event-${event.id}`}
                  type="button"
                  className={cn(
                    "h-fit disabled:opacity-100 p-2 grid grid-cols-[min-content,1fr,min-content] items-baseline gap-2 text-left bg-white hover:bg-primary-100 transition-colors",
                    isSelected && "!bg-tertiary-100"
                  )}
                  onClick={() => {
                    setSelectedEvent(event.id);
                    navigate({
                      pathname: `${event.id}`,
                      search: location.search,
                    });
                  }}
                >
                  <ExceptionStatusBadge status={event.status} />

                  <div className="text-sm truncate">#{event.id}</div>

                  <div className="text-secondary-950/60 whitespace-nowrap text-xs">
                    <CreatedUpdatedFormat value={event} />
                  </div>

                  <div className="text-xs truncate col-span-2">{event.notes}</div>

                  {event.environmentCode && (
                    <div className="text-sm justify-self-end text-secondary-400">
                      <LayersIcon className="inline-block size-4 mr-2" />
                      {event.environmentCode}
                    </div>
                  )}
                </Button>
              );
            })}
          </div>

          {!events?.results.length && <div className="flex w-full h-full items-center justify-center">No events.</div>}

          {isFetching && (
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary-100/60 flex items-center pointer-events-none justify-center cursor-default text-primary-400">
              <LoadingSpinner size={48} />
            </div>
          )}
        </ScrollArea>

        <Pagination
          className="px-4 py-2 border-t-2 border-t-secondary-950 rounded-b-md"
          pagination={pagination}
          numberOfPages={events?.numberOfPages}
          hideSizeSelect
        />
      </div>
      <div className="ml-[26rem] min-h-full">
        <Outlet context={{ refetch }} />
      </div>
    </SocketProvider>
  );
};

interface FiltersProps {
  filters: Omit<ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams, "page" | "pageSize">;
  setFilters: React.Dispatch<
    React.SetStateAction<Omit<ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectParams, "page" | "pageSize">>
  >;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectEnvironments: EnvironmentMiniResponseDto[];
}

const Filters: React.FC<FiltersProps> = ({ filters, setFilters, open, setOpen, projectEnvironments }) => {
  return (
    <Accordion
      type="single"
      value={open ? "filters" : undefined}
      onValueChange={(v) => setOpen(v === "filters" ? true : false)}
      collapsible
      className="w-full col-span-2 m-0"
    >
      <AccordionItem value="filters" className="border-none">
        <AccordionContent className="mb-2 mt-4 neu-flat rounded-md bg-secondary-100 grid grid-cols-2 gap-x-2 gap-y-2 w-full p-2 overflow-hidden">
          <div className="col-span-2">
            <Label className="font-semibold">Status</Label>
            <div className="flex items-center gap-2">
              <Select
                value={filters.status}
                onValueChange={(value) => {
                  setFilters((f) => ({
                    ...f,
                    status: value as ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectStatus,
                  }));
                }}
              >
                <SelectTrigger className="neu w-full py-1 pr-1 pl-2 bg-tertiarty-300 font-bold text-xs bg-white h-8">
                  <SelectValue placeholder="Select Status" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  {objectKeys(ExceptionsInboxControllerGetExceptionEventsForTeamAndProjectStatus).map((status) => {
                    return (
                      <SelectItem key={`event-status-${status}`} value={status} className="uppercase">
                        {status}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div>
            <Label className="font-semibold">From Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button className="w-full justify-start h-8 text-sm">
                  <CalendarIcon className="mr-2 h-4 w-4 shrink-0" />
                  <span className="truncate text-xs font-bold">
                    {filters.dateFrom ? new Date(filters.dateFrom).toLocaleString() : <span>Pick a date</span>}
                  </span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <div className="text-sm text-center py-2 border-b-2 border-b-secondary-950">
                  {filters.dateFrom && new Date(filters.dateFrom).toLocaleString()}
                </div>

                <Calendar
                  mode="single"
                  selected={filters.dateFrom ? new Date(filters.dateFrom) : undefined}
                  onSelect={(date) => {
                    setFilters((f) => ({
                      ...f,
                      dateFrom: date?.toISOString(),
                    }));
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>

          <div>
            <Label className="font-semibold">To Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button className="w-full justify-start h-8 text-sm">
                  <CalendarIcon className="mr-2 h-4 w-4 shrink-0" />
                  <span className="truncate text-xs font-bold">
                    {filters.dateTo ? new Date(filters.dateTo).toLocaleString() : <span>Pick a date</span>}
                  </span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <div className="text-sm text-center py-2 border-b-2 border-b-secondary-950">
                  {filters.dateTo && new Date(filters.dateTo).toLocaleString()}
                </div>
                <Calendar
                  mode="single"
                  selected={filters.dateTo ? new Date(filters.dateTo) : undefined}
                  onSelect={(date) => {
                    setFilters((f) => ({
                      ...f,
                      dateTo: date?.toISOString(),
                    }));
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>

          <Button
            className="w-full h-8 text-sm bg-secondary-50 font-bold items-center justify-between"
            onClick={() => {
              const fromDate = new Date();
              const toDate = new Date();

              fromDate.setHours(fromDate.getHours() - 1);

              setFilters((f) => ({
                ...f,
                dateFrom: fromDate.toISOString(),
                dateTo: toDate.toISOString(),
              }));
            }}
          >
            Last 1h <CornerRightUpIcon className="h-4 w-4 shrink-0" />
          </Button>
          <Button
            className="w-full h-8 text-sm bg-secondary-50 font-bold items-center justify-between"
            onClick={() => {
              const fromDate = new Date();
              const toDate = new Date();

              fromDate.setHours(fromDate.getHours() - 24);

              setFilters((f) => ({
                ...f,
                dateFrom: fromDate.toISOString(),
                dateTo: toDate.toISOString(),
              }));
            }}
          >
            Last 24h <CornerRightUpIcon className="h-4 w-4 shrink-0" />
          </Button>

          <div className="col-span-2">
            <Label className="font-semibold">Categories</Label>
            <Input
              className="h-8"
              placeholder="category1, category2, ..."
              value={filters.categories}
              onChange={(e) => {
                setFilters((f) => ({
                  ...f,
                  categories: e.target.value,
                }));
              }}
            />
          </div>

          <div className="col-span-2">
            <Label className="font-semibold">Environments</Label>
            <MultiSelect
              options={projectEnvironments.map((env) => ({
                label: env.code,
                value: env.code,
                icon: () => {
                  return (
                    <LayersIcon className="inline-block size-4 mr-2" style={{ color: parseEnvColor(env.color)?.fg }} />
                  );
                },
              }))}
              onValueChange={(values) => {
                setFilters((f) => ({
                  ...f,
                  environments: values.join(","),
                }));
              }}
              value={filters.environments?.split(",")}
              className="!bg-white min-h-8"
            />
          </div>

          <div className="flex items-center gap-2 mt-2">
            <Switch
              checked={!!filters.invalidFormat}
              onCheckedChange={(checked) => {
                setFilters((f) => ({
                  ...f,
                  invalidFormat: checked,
                }));
              }}
            />
            <Label className="font-semibold">Invalid Format</Label>
          </div>

          <div className="flex items-center gap-2 mt-2">
            <Switch
              checked={!!filters.payloadTruncated}
              onCheckedChange={(checked) => {
                setFilters((f) => ({
                  ...f,
                  payloadTruncated: checked,
                }));
              }}
            />
            <Label className="font-semibold">Truncated</Label>
          </div>

          <Button
            size="sm"
            className="bg-red-100 justify-self-end col-span-2 mt-2"
            onClick={() => {
              setFilters({});
              setOpen(false);
            }}
          >
            Clear
          </Button>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
