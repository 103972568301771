import { SingleEnvironmentWithHyperlinksMonitorDto } from "@/api/generated";
import { MonitoringColumnsInput } from "@/lib/projects/monitoring/dashboard/monitoring-columns";

export const MonitoringJobs = {
  domainCert: {
    key: "domain-cert",
    label: "Domain Cert",
  },
  domainDns: {
    key: "domain-dns",
    label: "Domain DNS",
  },
  urlHealthBrowser: {
    key: "url-health-browser",
    label: "URL Health (Browser)",
  },
  urlHealthRest: {
    key: "url-health-rest",
    label: "URL Health (REST)",
  },
  urlHealthScreenshot: {
    key: "url-health-screenshot",
    label: "URL Health (Screenshot)",
  },
};

export const transformToMonitoringColumnsInput = (
  envData: SingleEnvironmentWithHyperlinksMonitorDto,
  teamCode: string,
  projectCode: string
): MonitoringColumnsInput[] => {
  const hyperlinks = envData.hyperlinks;

  return Object.entries(hyperlinks)
    .map(([code, data]) => {
      const monitorEvents = data.monitorEvents;
      const domainCert = monitorEvents ? monitorEvents[MonitoringJobs.domainCert.key] : [];
      const domainDns = monitorEvents ? monitorEvents[MonitoringJobs.domainDns.key] : [];
      const urlHealthBrowser = monitorEvents ? monitorEvents[MonitoringJobs.urlHealthBrowser.key] : [];
      const urlHealthRest = monitorEvents ? monitorEvents[MonitoringJobs.urlHealthRest.key] : [];
      const urlHealthScreenshot = monitorEvents ? monitorEvents[MonitoringJobs.urlHealthScreenshot.key] : [];

      return {
        hyperlink: {
          code: code,
          hyperlinkItemValueId: data.hyperlinkItemValueId,
          lastModifiedAt: data.lastModifiedAt,
          teamCode: teamCode,
          projectCode: projectCode,
        },
        domainCert,
        domainDns,
        urlHealthBrowser,
        urlHealthRest,
        urlHealthScreenshot,
      };
    })
    .filter(
      (item) =>
        item.domainCert.length > 0 ||
        item.domainDns.length > 0 ||
        item.urlHealthBrowser.length > 0 ||
        item.urlHealthRest.length > 0 ||
        item.urlHealthScreenshot.length > 0
    );
};
