import React from "react";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/form/form";
import { Label } from "@/components/ui/label";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useFormContext } from "react-hook-form";
import { MultiSelect } from "@/components/multiselect";
import { z } from "zod";
import { NotificationsConfigDto, NotificationTopicResponseDto } from "@/api/generated";
import { SelectField } from "@/components/form/elements/select-field.tsx";

const notifyModeOptions = {
  exceptions: ["disabled", "immediate", "summary-hourly", "summary-daily"] as const,
  hyperlinks: ["disabled", "immediate", "summary-hourly"] as const,
  documents: ["disabled", "immediate"] as const,
} as const;

export const notificationsConfigSchema = z.object({
  exceptionsInbox: z.object({
    notifyMode: z.enum(notifyModeOptions.exceptions),
    notificationTopicIds: z.array(z.number()).nullable()
  }).optional(),
  hyperlinkMonitor: z.object({
    notifyMode: z.enum(notifyModeOptions.hyperlinks),
    notificationTopicIds: z.array(z.number()).nullable()
  }).optional(),
  documents: z.object({
    notifyMode: z.enum(notifyModeOptions.documents),
    notificationTopicIds: z.array(z.number()).nullable()
  }).optional(),
});

const environmentNotificationsConfigSchema = z.object({
  notificationsConfig: notificationsConfigSchema.optional()
});
type EnvironmentNotificationsConfigInput = z.infer<typeof environmentNotificationsConfigSchema>;

// export type NotificationsConfigFormData = z.infer<typeof notificationsConfigSchema>;

interface SelectNotificationTopicsProps {
  availableTopics: NotificationTopicResponseDto[];
  topicIdsKey: string;
}

export const SelectNotificationTopics: React.FC<SelectNotificationTopicsProps> = ({
  availableTopics,
  topicIdsKey
}) => {
  const { setValue, watch } = useFormContext<{ [key: string]: number[] | null }>();
  const selectedTopicIds = watch(topicIdsKey) || [];

  // selectedTopicIds are currently stored as JSON on the backend, thus some of them might not exist in availableTopics
  const validTopicIds = new Set(availableTopics.map(topic => topic.id));
  const validSelectedTopicIds: string[] = selectedTopicIds.filter(id => validTopicIds.has(id)).map(id => id.toString());

  const handleValueChange = (values: string[]) => {
    setValue(topicIdsKey, values.length > 0 ? values.map(v => parseInt(v)) : null, { shouldValidate: false });
  };

  const topicOptions = availableTopics.map(topic => ({
    label: topic.name,
    value: topic.id.toString(),
    description: topic.description
  }));

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Label>Notification Topics</Label>
      <MultiSelect
        options={topicOptions}
        value={validSelectedTopicIds}
        defaultValue={validSelectedTopicIds}
        onValueChange={handleValueChange}
        placeholder="Select topics..."
        modalPopover={true}
      />
    </div>
  );
};

export const NotificationsConfigForm: React.FC<{
  onSubmit: (values: EnvironmentNotificationsConfigInput) => void;
  currentConfig: {notificationsConfig: NotificationsConfigDto};
  availableTopics: NotificationTopicResponseDto[];
  isPending: boolean;
}> = ({ onSubmit, currentConfig, availableTopics, isPending}) => {
  return (
    <Form
      schema={environmentNotificationsConfigSchema}
      defaultValues={currentConfig}
      onSubmit={onSubmit}
    >
      <div className="space-y-6">

        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="text-lg font-medium">Exceptions Inbox Notifications</h3>
          <div className="space-y-2">
            <Label>Notification sending Mode</Label>
            <SelectField
              name="notificationsConfig.exceptionsInbox.notifyMode"
              placeholder="Select notify mode"
              options={notifyModeOptions.exceptions.map((m)=>({value: m, label: m.charAt(0).toUpperCase() + m.slice(1).replace("-", " ")}))}
            >
              {/*<SelectTrigger>*/}
              {/*  <SelectValue placeholder="Select notification mode" />*/}
              {/*</SelectTrigger>*/}
              {/*<SelectContent>*/}
              {/*  {notifyModeOptions.exceptions.map(mode => (*/}
              {/*    <SelectItem key={mode} value={mode}>*/}
              {/*      {mode.charAt(0).toUpperCase() + mode.slice(1).replace('-', ' ')}*/}
              {/*    </SelectItem>*/}
              {/*  ))}*/}
              {/*</SelectContent>*/}
            </SelectField>
            <SelectNotificationTopics
              topicIdsKey="notificationsConfig.exceptionsInbox.notificationTopicIds"
              availableTopics={availableTopics}
            />
          </div>
        </div>

        {/*<div className="space-y-4 border rounded-lg p-4">
          <h3 className="text-lg font-medium">Hyperlink Monitor Notifications</h3>
          <div className="space-y-2">
            <Label>Notification sending Mode</Label>
            <Select
              name="hyperlinkMonitor.notifyMode"
              defaultValue={currentConfig.hyperlinkMonitor?.notifyMode}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select notification mode" />
              </SelectTrigger>
              <SelectContent>
                {notifyModeOptions.hyperlinks.map(mode => (
                  <SelectItem key={mode} value={mode}>
                    {mode.charAt(0).toUpperCase() + mode.slice(1).replace('-', ' ')}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <SelectNotificationTopics
              topicIdsKey="hyperlinkMonitor.notificationTopicIds"
              availableTopics={availableTopics}
            />
          </div>
        </div>

        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="text-lg font-medium">Documents Notifications</h3>
          <div className="space-y-2">
            <Label>Notification sending Mode</Label>
            <Select
              name="documents.notifyMode"
              defaultValue={currentConfig.documents?.notifyMode}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select notification mode" />
              </SelectTrigger>
              <SelectContent>
                {notifyModeOptions.documents.map(mode => (
                  <SelectItem key={mode} value={mode}>
                    {mode.charAt(0).toUpperCase() + mode.slice(1).replace('-', ' ')}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <SelectNotificationTopics
              topicIdsKey="documents.notificationTopicIds"
              availableTopics={availableTopics}
            />
          </div>
        </div>*/}

        <div className="flex justify-end">
          <Button type="submit" className="bg-secondary-300" disabled={isPending}>
            {isPending ? <LoadingSpinner message="Saving..." /> : "Save Changes"}
          </Button>
        </div>
      </div>
    </Form>
  );
};
