import React from "react";
import { CodeBlock } from "@/components/code-block";
import { Webhook } from "@/lib/external/webhooks/standard-webhook";
import { v4 as uuidv4 } from "uuid";
import { ChevronDown, ExternalLink, WebhookIcon } from "lucide-react";
import { LoadingSpinner } from "@/components/loading-spinner";
import { HoverInfo } from "@/components/hover-info";
import { SelectOption } from "@/components/select-option";
import { QuickOption } from "@/components/quick-option";
import { WebhookStatusCard } from "@/lib/webhooks/help/webhook-status-card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { WebhookStatus } from "@/routes/webhooks/webhooks-page";

const sampleBody = {
  type: "wt.dev.sample.body",
  timestamp: new Date().toISOString(),
  data: {
    description: "Standard webhook request body",
    url: "https://www.standardwebhooks.com/",
  },
};

const curlSample = `curl -X POST -H "Content-Type: application/json" -d '${JSON.stringify(sampleBody)}' \${endpoint}`;

const wgetSample = `wget \${endpoint}`;

const codeSample = `async function request() {
  const result = await fetch("\${endpoint}", {
    method: "POST",
    body: JSON.stringify({ hello: "world" }),
  });
  return result.json();
}`;

const vsCodeRestClient = `###
POST \${endpoint}
Content-Type: application/json

${JSON.stringify(sampleBody)}`;

const axiosCodeSample = `import axios from 'axios';

const result = await axios.post("\${endpoint}",  ${JSON.stringify(sampleBody)});`;

export interface RequestHelpProps {
  endpointCode: string;
  endpoint: string;
  resourceJwt?: string;
  resourceId?: number;
  showRequestsHelp: boolean;
  endpointStatus: WebhookStatus;
}

export const RequestHelp: React.FC<RequestHelpProps> = ({
  endpointCode,
  endpoint,
  showRequestsHelp,
  resourceJwt,
  resourceId,
  endpointStatus,
}) => {
  const navigate = useNavigate();
  const hasCli = resourceJwt;

  const [selectedSample, setSelectedSample] = React.useState("curl");
  const [disabledItem, setDisabledItem] = React.useState<string | null>(null);
  const [selectedCLI, setSelectedCLI] = React.useState("Forward requests");

  const quickOptions = {
    items: [
      {
        id: "get_request_new_window",
        name: "GET Request",
        description: "Opens in new window",
        icon: <ExternalLink size={22} />,
        action: () => window.open(endpoint, "_blank"),
      },
    ],
    fetch: [
      {
        id: "get_request_fetch",
        name: "GET Request",
        description: "GET Request using Fetch API",
        icon: <WebhookIcon size={22} />,
        action: async () => await fetch(endpoint, { method: "GET" }),
      },
      {
        id: "post_request_fetch",
        name: "POST Request",
        description: "Standard Webhook POST request",
        icon: <WebhookIcon size={22} />,
        action: async () => {
          const stdWebhook = new Webhook("mysecret", { format: "raw" });
          const body = JSON.stringify(sampleBody);
          const webhookId = uuidv4();
          const webhookTimestamp = new Date();
          const signature = stdWebhook.sign(webhookId, webhookTimestamp, body);
          if (!signature) throw new Error("Failed to sign the request");

          await fetch(endpoint, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "webhook-id": webhookId,
              "webhook-signature": signature,
              "webhook-timestamp": (webhookTimestamp.getTime() / 1000).toString(),
            },
            body: JSON.stringify(sampleBody),
          });
        },
      },
      {
        id: "put_request_fetch",
        name: "DELETE Request",
        description: "DELETE to /test with Basic auth",
        icon: <WebhookIcon size={22} />,
        action: async () =>
          await fetch(`${endpoint}/test`, {
            method: "DELETE",
            headers: { Authorization: "Basic bXl1c2VyOm15cGFzcw==" },
          }),
      },
    ],
  };

  const samples = {
    items: [
      {
        name: "curl",
        description: "",
        sample: curlSample,
        language: "shell",
      },
      {
        name: "wget",
        description: "",
        sample: wgetSample,
        language: "shell",
      },
      {
        name: "Fetch API",
        description: "",
        sample: codeSample,
        language: "typescript",
      },
      {
        name: "Axios",
        description: "",
        sample: axiosCodeSample,
        language: "typescript",
      },
      {
        name: "VSCode REST Client",
        description: "REST Client extension for Visual Studio Code.",
        sample: vsCodeRestClient,
        language: "text",
      },
    ],
  };

  const cli = {
    items: [
      {
        name: "Forward requests",
        label: "forwardRequests",
        description: "Forward requests sent to this endpoint to your development server",
        sample: `npx @wt-dev/wt-cli  fw -f http://localhost:3000 ${resourceJwt}`,
        language: "shell",
      },
      {
        name: "Replay requests",
        label: "replayRequests",
        description: "Send send this requests to your localhost (any number of times)",
        sample: `npx @wt-dev/wt-cli replay -f http://localhost:3000 ${resourceJwt} ${resourceId}`,
        language: "shell",
      },
    ],
  };

  const webhookStatusMessage = React.useMemo<string>(() => {
    if (!endpointStatus.isEndpointEnabled) {
      return "Webhook is disabled.";
    }

    return "";
  }, [endpointStatus]);

  return (
    <div className="flex flex-col gap-8 p-2 w-full">
      {webhookStatusMessage && <WebhookStatusCard message={webhookStatusMessage} />}

      {hasCli && resourceJwt && (
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <h5 className="font-semibold tracking-tight">CLI</h5>
            <HoverInfo message="Command Line Interface (CLI) allows you to interact with your development server" />
          </div>
          <div className="flex flex-col gap-2 sm:gap-4">
            {resourceId && (
              <>
                <div className="hidden sm:flex gap-2">
                  {cli.items.map((sample) => (
                    <SelectOption
                      key={sample.name}
                      value={sample.name}
                      selected={selectedCLI === sample.name}
                      handleOnClick={() => setSelectedCLI(sample.name)}
                    />
                  ))}
                </div>

                {/* Mobile dropdown */}
                <div className="sm:hidden flex">
                  <DropdownWrapper
                    selected={selectedCLI}
                    dropdownItems={cli.items.map((item) => ({
                      title: item.name,
                      handleOnClick: () => setSelectedCLI(item.name),
                    }))}
                  />
                </div>
              </>
            )}
            <CLISelectContent
              sample={cli.items.find((sample) => sample.name === selectedCLI)?.sample ?? ""}
              language={cli.items.find((sample) => sample.name === selectedCLI)?.language}
            />
          </div>
        </div>
      )}

      <div className="flex flex-col gap-2">
        <h5 className="font-semibold tracking-tight">Quick Options</h5>
        <div className="hidden sm:flex flex-col md:flex-row gap-4">
          {quickOptions.items.map((item) => (
            <QuickOption
              key={item.name}
              name={item.name}
              description={item.description}
              icon={item.icon}
              handleOnClick={item.action}
              className="w-full sm:w-60"
            />
          ))}
        </div>

        <div className="grid sm:hidden grid-cols-2 gap-2">
          {quickOptions.items.map((item) => (
            <QuickOption
              key={item.name}
              name={item.name}
              description={item.description}
              icon={item.icon}
              handleOnClick={item.action}
              className="w-full sm:w-60 bg-secondary-200"
            />
          ))}

          {quickOptions.fetch.map((item) => (
            <QuickOption
              key={item.name}
              name={item.name}
              description={item.description}
              icon={disabledItem !== item.id ? item.icon : <LoadingSpinner />}
              handleOnClick={async () => {
                setDisabledItem(item.id);
                await item.action();
                setDisabledItem(null);
                navigate(`/webhooks/${endpointCode}`);
              }}
              disabled={disabledItem === item.id || !endpointStatus.isEndpointEnabled}
              className="w-full sm:w-60"
            />
          ))}
        </div>
      </div>

      <div className="hidden sm:flex flex-col gap-2">
        <h5 className="font-semibold tracking-tight">Fetch Options</h5>
        <div className="flex flex-col md:flex-row gap-4">
          {quickOptions.fetch.map((item) => (
            <QuickOption
              key={item.name}
              name={item.name}
              description={item.description}
              icon={disabledItem !== item.id ? item.icon : <LoadingSpinner />}
              handleOnClick={async () => {
                setDisabledItem(item.id);
                await item.action();
                setDisabledItem(null);
              }}
              disabled={disabledItem === item.id || !endpointStatus.isEndpointEnabled}
              className="w-full sm:w-60"
            />
          ))}
        </div>
      </div>

      {showRequestsHelp && (
        <div className="flex flex-col gap-2">
          <h5 className="font-semibold tracking-tight">Sending requests</h5>
          <div className="flex flex-col gap-2 sm:gap-4">
            <div className="hidden sm:flex flex-col md:flex-row gap-2">
              {samples.items.map((sample) => (
                <SelectOption
                  key={sample.name}
                  value={sample.name}
                  selected={selectedSample === sample.name}
                  handleOnClick={() => setSelectedSample(sample.name)}
                />
              ))}
            </div>

            {/* Mobile dropdown */}
            <div className="sm:hidden flex">
              <DropdownWrapper
                selected={selectedSample}
                dropdownItems={samples.items.map((item) => ({
                  title: item.name,
                  handleOnClick: () => setSelectedSample(item.name),
                }))}
              />
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-sm">{samples.items.find((sample) => sample.name === selectedSample)?.description}</p>
              <SelectContent
                sample={samples.items.find((sample) => sample.name === selectedSample)?.sample ?? ""}
                endpoint={endpoint}
                language={samples.items.find((sample) => sample.name === selectedSample)?.language}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface SelectContentProps {
  sample: string;
  endpoint: string;
  language?: string;
}

const SelectContent: React.FC<SelectContentProps> = ({ sample, endpoint, language }) => {
  return (
    <CodeBlock
      code={sample.replace("${endpoint}", endpoint)}
      language={language ?? "text"}
      readonly={false}
      minHeight={230}
      resizable={false}
      className="min-h-[230px]"
    />
  );
};

interface CLISelectContentProps {
  sample: string;
  language?: string;
}

const CLISelectContent: React.FC<CLISelectContentProps> = ({ sample, language }) => {
  return (
    <CodeBlock
      code={sample}
      language={language ?? "text"}
      readonly={true}
      minHeight={100}
      resizable={false}
      className="min-h-[100px]"
    />
  );
};

interface DropdownWrapperProps {
  selected: string;
  dropdownItems: {
    title: string;
    handleOnClick: () => void;
  }[];
}

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({ selected, dropdownItems }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" className="bg-transparent items-center gap-1">
          {selected}
          <ChevronDown size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {dropdownItems.map((item) => (
          <DropdownMenuItem key={item.title} onClick={item.handleOnClick}>
            {item.title}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
