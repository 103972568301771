import {
  TeamMemberResponseDto,
  useTeamsControllerGetTeamMembers,
  useTeamsControllerRemoveUserFromTeam,
} from "@/api/generated";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import React from "react";
import { SendInvitationDialog } from "../invitations/send-invitation-dialog";
import { Button } from "@/components/ui/button";
import { MoreVertical, Settings, Trash2, UserPlus } from "lucide-react";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useAuth } from "@/auth/useAuth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Link } from "react-router-dom";

export const TeamMembers: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const { isPending, isError, refetch, data: teamMembers } = useTeamsControllerGetTeamMembers(teamCode);

  return (
    <Card>
      <CardHeader>
        <div>
          <h4 className="font-semibold text-lg">Team Members</h4>
          <p className="text-sm text-gray-600">Invite your team members to collaborate.</p>
        </div>
      </CardHeader>
      <CardContent className="grid gap-6">
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && teamMembers && (
          <>
            {teamMembers.map((teamMember) => (
              <CardTeamMember key={teamMember.userId} teamMember={teamMember} refetch={refetch} />
            ))}
          </>
        )}

        <SendInvitationDialog>
          <Button size="sm" className="gap-2 bg-secondary-300">
            <UserPlus className="size-4" />
            <span>Invite Members</span>
          </Button>
        </SendInvitationDialog>
      </CardContent>
    </Card>
  );
};

interface CardTeamMemberProps {
  teamMember: TeamMemberResponseDto;
  refetch: () => void;
}

const CardTeamMember: React.FC<CardTeamMemberProps> = ({ teamMember, refetch }) => {
  const { getLoggedInUser } = useAuth();
  const userId = getLoggedInUser();

  const chars = React.useMemo(() => {
    if (!teamMember) return "?";

    return teamMember.userFullName.charAt(0) ?? "?";
  }, [teamMember]);

  return (
    <div className="flex items-center justify-between space-x-4">
      <div className="flex items-center space-x-4">
        <Avatar className="relative bg-white border-2 border-secondary-950">
          <AvatarFallback className="uppercase">{chars}</AvatarFallback>
        </Avatar>

        <div className="space-y-1">
          <p className="text-sm font-semibold leading-none">{teamMember.userFullName}</p>
          <p className="text-sm text-gray-700">{teamMember.email}</p>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Badge variant="outline" className="bg-gray-200">
          {teamMember.teamAccessLevel}
        </Badge>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="size-8">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">Open menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {teamMember.email !== userId && teamMember.teamAccessLevel !== "admin" && (
              <RemoveUserDialog teamMember={teamMember} refetch={refetch}>
                <DropdownMenuItem
                  onSelect={(e) => e.preventDefault()} // Prevent dropdown from closing
                  className="text-red-600"
                >
                  <Trash2 size={16} className="mr-2" />
                  <span>Remove member</span>
                </DropdownMenuItem>
              </RemoveUserDialog>
            )}

            {teamMember.email === userId && (
              <DropdownMenuItem asChild>
                <Link to="/settings" className="no-underline text-secondary-950">
                  <Settings size={16} className="mr-2" />
                  <span>Settings</span>
                </Link>
              </DropdownMenuItem>
            )}

            {teamMember.teamAccessLevel === "admin" && (
              <DropdownMenuItem>
                <Settings size={16} className="mr-2" />
                <span>Admin Panel</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

interface RemoveUserDialogProps {
  teamMember: TeamMemberResponseDto;
  refetch: () => void;
  children: React.ReactNode;
}

const RemoveUserDialog: React.FC<RemoveUserDialogProps> = ({ teamMember, children, refetch }) => {
  const { teamCode } = useCurrentTeam();

  const [open, setOpen] = React.useState(false);
  const removeUserMutation = useTeamsControllerRemoveUserFromTeam();

  const handleRemoveUser = () => {
    removeUserMutation.mutate(
      { teamCode, userId: teamMember.userId },
      {
        onSuccess: () => {
          toast({ title: "Success", description: "User removed from team." });
          setOpen(false);
          refetch();
        },
        onError: (error) => {
          const message = (error.response?.data as { message?: string })?.message;

          if (message) {
            toast({ title: "Oops!", description: message });
          } else {
            toast(defaultError);
          }
        },
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Remove Team Member</DialogTitle>
        <DialogDescription>
          User <span className="font-semibold text-secondary-950">{teamMember.userFullName}</span> will be removed from
          the team. Are you sure?
        </DialogDescription>

        <div className="flex justify-end mt-4">
          <Button
            variant="neu-flat"
            size="sm"
            className="bg-red-400"
            disabled={removeUserMutation.isPending}
            onClick={handleRemoveUser}
          >
            {removeUserMutation.isPending ? (
              <LoadingSpinner message={"Removing"} />
            ) : (
              <>
                <Trash2 className="size-4 mr-2" />
                Remove
              </>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
