import React from "react";

export function useContinuation() {
  const [tokens, setTokens] = React.useState<string[]>([]);
  const [index, setIndex] = React.useState(-1);

  return {
    token: index >= 0 ? tokens[index] : undefined,
    next(token?: string) {
      if (token) {
        if (index < tokens.length - 1) {
          setTokens((t) => {
            const clone = [...t];
            clone[index] = token;

            return clone;
          });
          setIndex((i) => Math.min(tokens.length - 1, i + 1));
        } else {
          const newTokens = [...tokens, token];
          setTokens(newTokens);
          setIndex(newTokens.length - 1);
        }
      } else {
        setIndex((i) => Math.min(tokens.length - 1, i + 1));
      }
    },
    hasNext() {
      return index < tokens.length - 1;
    },
    previous() {
      setIndex((i) => {
        return Math.max(-1, i - 1);
      });
    },
    hasPrevious() {
      return index > -1;
    },
    reset() {
      setTokens([]);
      setIndex(-1);
    },
    getPageIndex() {
      return index+1;
    }
  };
}
