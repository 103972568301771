import { formatCount } from "@/utils";
import { isUndefined } from "@wt-dev/common/utils";
import { Loader2Icon } from "lucide-react";

export interface LoadingCountProps {
  count?: number;
  item: string;
}

export const LoadingCount: React.FC<LoadingCountProps> = ({ count, item }) => {
  if (isUndefined(count)) {
    return (
      <>
        <Loader2Icon className="animate-spin size-3 inline-block" />
        {` ${item}s`}
      </>
    );
  }

  return formatCount(count, item);
};
