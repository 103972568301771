import { Button } from "@/components/ui/button";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { cn } from "@/utils/ui.util";
import { ArrowUpRight } from "lucide-react";
import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { BoomBox } from "../components/boom-box";
import { KeyFeatures } from "../components/key-features";
import { LayeredTitle } from "../components/layered-title";
import { LandingSection } from "./landing-section";

export const PipelinesHome: React.FC = () => {
  return <LandingPageWrapper head={<PipelinesHomeHead />} children={<PipelinesHomeContent />} />;
};

export const PipelinesHomeHead: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <div className="container px-4 sm:px-8 pt-8 2xl:pt-16">
      <div className="flex flex-col gap-8 md:flex-row items-center">
        <div className="flex flex-col gap-6 w-full">
          <div className="space-y-4">
            <div className="flex mb-12">
              <div className="relative">
                <LayeredTitle
                  title="Pipelines"
                  className="text-6xl lg:text-8xl/none font-black font-[system-ui] tracking-wide"
                />

                <svg
                  className="absolute rotate-[24deg] w-24 -top-[2rem] -right-[3.5rem] lg:w-36 lg:-top-[3rem] lg:-right-[5rem]"
                  viewBox="0 0 436.75089 436.65052"
                  version="1.1"
                >
                  <path
                    d="m 299.77555,251.67572 136.5,-33.4 -137.6,-33.6 73.8,-121.500405 -120.8,73.400405 -33.3,-136.10039608 -33.2,136.10039608 -120.800202,-73.400405 73.700202,121.500405 -137.60019682,33.6 136.50019682,33.4 -72.600202,119.7 120.300202,-73.1 33.7,137.9 33.7,-137.9 120.4,73.1 z"
                    className="fill-tertiary-400"
                    stroke="#000"
                    strokeWidth={6}
                  />
                  <text
                    className="font-[900] text-[130px] font-[system-ui]"
                    fill="#fff"
                    stroke="#000"
                    strokeWidth={6}
                    x="50%"
                    y="53%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    NEW
                  </text>
                </svg>
              </div>
            </div>
            <p className="mx-auto text-muted-foreground md:text-xl">
              Streamline your CI/CD pipelines with an intuitive interface that helps you monitor workflows and
              deployments in real-time.
            </p>
          </div>

          <div className="flex gap-2">
            <Button asChild id="get-started-button">
              <Link to="/dashboard" className="no-underline">
                <div className="flex items-center gap-1">
                  Get Started
                  <ArrowUpRight size={20} />
                </div>
              </Link>
            </Button>
          </div>
        </div>

        <div className={cn("relative block w-full overflow-hidden", loaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/06.webm"}
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            loop={true}
            onReady={() => setLoaded(true)}
          />
        </div>
      </div>
    </div>
  );
};

export const PipelinesHomeContent: React.FC = () => {
  const [quickPreviewLoaded, setQuickPreviewLoaded] = React.useState(false);

  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24">
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-xl text-justify">
            Our Pipelines Tool is designed for developers who need <b>clarity</b> and <b>control</b> over their CI/CD
            workflows on <b className="brush-highlight">GitHub</b>. Whether you're handling multiple jobs, repositories,
            or complex workflows, the tool provides a clear, <b>visual overview</b> of everything happening in your
            pipelines. No more chaotic GitHub Actions dashboards—just the information you need.
            <br />
            <br />
            Looking ahead, we’re working on integrating other CI/CD providers like <b>AWS</b>, so you’ll be able to
            manage all your pipelines from one place. Our goal is to create a unified platform where GitHub pipelines
            interact seamlessly with other services.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={[
            { title: "Real-Time Monitoring" },
            { title: "Clean UI" },
            { title: "Team-Wide Visibility" },
            { title: "Custom Alerts" },
            { title: "Future Integrations" },
          ]}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Why you should use our Pipelines Tool?
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Managing GitHub pipelines can quickly become a mess, especially when you're juggling multiple repositories or
          complex workflows. Our tool is designed to reduce that confusion, <b>save time</b>, and give you the{" "}
          <b>clarity</b> you need to keep things moving smoothly.
        </p>

        <div className="grid grid-cols-2 gap-6">
          <BoomBox
            title="Clarity in Chaos"
            boom="Instant clarity and a bird’s-eye view, saving time and reducing errors from missed tasks or hidden failed jobs."
            description={
              <>
                CI/CD pipelines are essential, but GitHub’s default view can quickly become overwhelming, especially
                with multiple workflows, jobs, and repositories. Our Pipeline UI cuts through the noise by offering a
                clean, visual representation of exactly what matters. Instead of endlessly scrolling or switching
                between tabs, you can see the most important workflows in a single glance.
              </>
            }
          />
          <BoomBox
            title="Instant Troubleshooting"
            boom="Dive straight into failed jobs with detailed logs that are just a click away."
            description={
              <>
                The last thing you want is to spend time searching for what went wrong. Our tool highlights any failed
                jobs and lets you see the logs right away—no more digging through layers of tabs or menus.
              </>
            }
          />
          <BoomBox
            title="Stay Focused with Alerts"
            boom="Custom alerts that notify you of issues as they happen, so you can fix them before they escalate."
            description={
              <>
                Get notified the moment something fails. With customizable alerts, you stay informed without being
                overwhelmed, ensuring that issues don’t snowball into bigger problems.
              </>
            }
          />
          <BoomBox
            title="Full Control Over What You See"
            boom="Customize your dashboard to show exactly what matters most to you."
            description={
              <>
                Whether you want to monitor specific jobs, stages, or repositories, our Pipeline UI gives you full
                control over the data you see. No more clutter—just the workflows and information that matter to you and
                your team.
              </>
            }
          />
          <BoomBox
            title="Visualize All Pipelines at Once"
            boom="See all your GitHub pipelines from multiple repositories on one clean dashboard."
            description={
              <>
                Instead of bouncing between different GitHub repos and jobs, you can view everything in one place. This
                makes it easier to get a complete overview of your CI/CD process and spot issues early.
              </>
            }
          />
          <BoomBox
            title="Future-Ready and Scalable"
            boom="We’re working on integrating other CI/CD providers like AWS, so you’ll soon be able to manage all your pipelines from one place."
            description={
              <>
                Today, you get a clean and focused view of your GitHub pipelines. In the future, this same simplicity
                will extend to other providers, so you can handle everything in one dashboard, no matter where your
                workflows live.
              </>
            }
          />
        </div>
      </LandingSection>

      <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-12 text-center">
          Real Life Examples
        </h2>

        <div className="grid grid-cols-[1fr,1fr] gap-16">
          <img src="/tmp/pipelines-irl.jpg" className="neu rounded-md" />
          <div>
            <p className="text-muted-foreground md:text-lg text-justify mb-8">
              Here’s how our team is putting our <b>Pipeline Dashboard</b> to work in real environments. Whether it’s on
              a wall-mounted tablet or a dedicated monitor, this setup gives you <b>live</b>, <b>visual feedback</b> on
              your CI/CD progress, making it easy to keep track of deployments and job statuses at a glance.
            </p>
          </div>
          <div className="text-justify">
            <ul className="list-disc space-y-4 text-lg">
              <li>
                <b>Wall-Mounted Tablets:</b> Ideal for shared spaces like <b>meeting rooms</b> or{" "}
                <b>team collaboration areas</b>, a wall-mounted tablet gives everyone instant visibility into the status
                of key pipelines, perfect for quick glances.
              </li>
              <li>
                <b>Dedicated Monitors:</b> For larger teams or <b>DevOps rooms</b>, having a bigger screen allows you to
                monitor multiple pipelines at once. With a full view of ongoing workflows, it's easy to catch issues
                before they escalate.
              </li>
            </ul>
          </div>
          <img src="/tmp/pipelines-irl-2.jpg" className="neu rounded-md" />
        </div>
      </LandingSection>

      <LandingSection className="bg-tertiary-100">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">Quick Preview</h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Here’s a quick demo of how our tool simplifies the management of your GitHub CI/CD workflows.
        </p>

        <div className={cn("overflow-hidden", quickPreviewLoaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/05.webm"}
            width="100%"
            height="100%"
            controls={true}
            muted={true}
            onReady={() => setQuickPreviewLoaded(true)}
          />
        </div>
      </LandingSection>
    </div>
  );
};
