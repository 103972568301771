import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import React from "react";
import { MonitorEventInfoDto } from "@/api/generated";

const chartConfig = {
  checkDurationMillis: {
    label: "Response Time",
    color: "#757cff", // Fix this to secondary.400
  },
} satisfies ChartConfig;

interface ResponseTimeChartProps {
  monitorEvents: MonitorEventInfoDto[];
}

export const ResponseTimeChart: React.FC<ResponseTimeChartProps> = ({ monitorEvents }) => {
  const [timeRange, setTimeRange] = React.useState("1d");

  const { filteredData } = React.useMemo(() => {
    const now = Date.now();
    const timeRanges: Record<string, number> = {
      "1h": now - 1000 * 60 * 60,
      "4h": now - 1000 * 60 * 60 * 4,
      "8h": now - 1000 * 60 * 60 * 8,
      "1d": now - 1000 * 60 * 60 * 24,
      "7d": now - 1000 * 60 * 60 * 24 * 7,
    };

    const startTime = timeRanges[timeRange] || now;

    const filtered = monitorEvents.filter((item) => {
      if (!item.checkDurationMillis || !item.checkStartedAt) {
        return false;
      }
      const checkStartedAt = new Date(item.checkStartedAt);
      return checkStartedAt > new Date(startTime);
    });

    return {
      filteredData: [...filtered],
      domainStart: new Date(startTime).toISOString(),
      domainEnd: new Date(now).toISOString(),
    };
  }, [monitorEvents, timeRange]);

  const getTimeRangeLabel = (range: string) => {
    const labels: Record<string, string> = {
      "1h": "1 hour",
      "4h": "4 hours",
      "8h": "8 hours",
      "1d": "24 hours",
      "7d": "7 days",
    };
    return labels[range] || range;
  };

  const formatDate = (value: number) => {
    const date = new Date(value);
    if (timeRange === "7d") {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    }
    if (timeRange === "1d") {
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle className="text-lg">Response Time</CardTitle>
          <CardDescription>Showing response times for the last {getTimeRangeLabel(timeRange)}</CardDescription>
        </div>
        <Select value={timeRange} onValueChange={setTimeRange}>
          <SelectTrigger className="w-[160px] rounded-lg sm:ml-auto" aria-label="Select a value">
            <SelectValue placeholder="Select a time range" />
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            <SelectItem value="7d" className="rounded-lg">
              Last 7 days
            </SelectItem>
            <SelectItem value="1d" className="rounded-lg">
              Last 24 hours
            </SelectItem>
            <SelectItem value="8h" className="rounded-lg">
              Last 8 hours
            </SelectItem>
            <SelectItem value="4h" className="rounded-lg">
              Last 4 hours
            </SelectItem>
            <SelectItem value="1h" className="rounded-lg">
              Last 1 hour
            </SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
          <BarChart data={filteredData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="checkStartedAt"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={formatDate}
            />
            <YAxis />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    const checkStartedAt = new Date(value);
                    return checkStartedAt.toLocaleTimeString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    });
                  }}
                  indicator="dot"
                />
              }
            />
            <Bar dataKey="checkDurationMillis" fill="var(--color-checkDurationMillis)" radius={[4, 4, 0, 0]} />
            <ChartLegend content={<ChartLegendContent />} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};
