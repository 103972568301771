import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Trash2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  getNotificationTopicsControllerGetTopicsPaginatedQueryKey,
  NotificationTopicResponseDto,
  useNotificationTopicsControllerDeleteTopic,
} from "@/api/generated";

export function NotificationTopicConfirmDelete({ topic }: { topic: NotificationTopicResponseDto }) {
  const [opened, setOpened] = useState(false);

  const deleteMutation = useNotificationTopicsControllerDeleteTopic();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    deleteMutation.mutate(
      {
        teamCode: topic.teamCode,
        topicId: topic.id,
      },
      {
        onSuccess: () =>
          queryClient
            .invalidateQueries({
              queryKey: getNotificationTopicsControllerGetTopicsPaginatedQueryKey(topic.teamCode),
            })
            .then(() => setOpened(false)),
      }
    );
  };

  return (
    <AlertDialog open={opened}>
      <AlertDialogTrigger asChild onClick={() => setOpened(true)}>
        <Button size="sm" className="bg-red-400 text-white">
          <Trash2 className=" h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to delete this topic?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the notification topic "
            <strong>{topic.name}</strong>" and remove all associated notifications.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction className="bg-red-400" onClick={handleDelete}>
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
