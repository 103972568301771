import { TeamResponseDto, useTeamsControllerGetTeam, useTeamsControllerListTeams } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { TeamIcon } from "@/components/project-icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { SidebarMenuButton, useSidebar } from "@/components/ui/sidebar";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useSwitchTeam } from "@/lib/teams/hooks/use-switch-team";
import { cn } from "@/utils/ui.util";
import { ChevronsUpDown, Plus } from "lucide-react";
import React from "react";
import { Link, Navigate } from "react-router-dom";

export const TeamSelectDropdown: React.FC = () => {
  const { isPending, isError, data: teams } = useTeamsControllerListTeams();

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && teams && (
        <>
          {teams.length === 0 && <Navigate to="/welcome" replace={true} />}
          {teams.length > 0 && <TeamSelectDropdownContent teams={teams} />}
        </>
      )}
    </>
  );
};

interface TeamSelectDropdownContentProps {
  teams: TeamResponseDto[];
}

export const TeamSelectDropdownContent: React.FC<TeamSelectDropdownContentProps> = ({ teams }) => {
  const { switchTeam } = useSwitchTeam();
  const { teamCode } = useCurrentTeam();

  const { data: team, isLoading } = useTeamsControllerGetTeam(teamCode);
  const { open } = useSidebar();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton
          size="lg"
          className="pl-1 pr-2 data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground border-[2px] border-transparent data-[state=open]:neu-flat"
        >
          <TeamIcon
            url={team?.iconUrl}
            loading={isLoading}
            variant="neu-flat"
            className={cn("size-9 mr-2", !open && "size-7 border-[1px]")}
          />

          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-semibold">{team?.name ?? teamCode}</span>
            <span className="truncate text-xs">Team</span>
          </div>
          <ChevronsUpDown className="ml-auto" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        align="start"
        side="bottom"
        sideOffset={4}
      >
        <DropdownMenuLabel className="text-xs text-muted-foreground">Teams</DropdownMenuLabel>
        <ScrollArea>
          <div className="max-h-[50dvh]">
            {teams.map((team, index) => (
              <DropdownMenuItem
                key={team.code}
                onClick={() => {
                  switchTeam(team.code);
                }}
                className="gap-2 p-2"
              >
                {team.code}
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
          </div>
        </ScrollArea>

        <DropdownMenuSeparator />
        <DropdownMenuItem className="gap-2 p-2" asChild>
          <Link to="/create-team" className="flex items-center gap-2 text-secondary-950 no-underline">
            <div className="flex size-6 items-center justify-center rounded-md border bg-background">
              <Plus className="size-4" />
            </div>
            <div className="font-medium text-muted-foreground">Add team</div>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
