import React from "react";
import { useNavigate } from "react-router-dom";
import { ParserInitialSetup, samples } from "@/routes/tools/tools-samples-inputs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { QuickOption } from "@/components/quick-option";
import { ArrowRightLeft, Check, Clapperboard, Copy, RotateCcw, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { encode } from "@/utils/parsing/utils/compression-utils";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentTeam } from "@/lib/teams/context/team-context";

interface ToolsSamplesProps {
  parserSetup: ParserInitialSetup;
  resetHistory: () => void;
}

export const ToolsSamples: React.FC<ToolsSamplesProps> = ({ parserSetup, resetHistory }) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { teamCode } = useCurrentTeam();

  const { toast, dismiss } = useToast();
  const [copy, setCopy] = React.useState<{
    copying: boolean;
    isRejected: boolean;
  }>({
    copying: false,
    isRejected: false,
  });

  const handleCopy = async () => {
    const encoded = encode(parserSetup);
    const url = `${location.origin}/dashboard/${teamCode}/tools?restore=${encoded}`;
    let description = "Copied to clipboard!";

    if (url.length < 2048) {
      await navigator.clipboard.writeText(url);
      setCopy({ copying: true, isRejected: false });
    } else {
      description = "Data too large to copy!";
      setCopy({ copying: true, isRejected: true });
    }

    const { id } = toast({
      description: description,
    });

    setTimeout(() => {
      setCopy({ copying: false, isRejected: false });
      dismiss(id);
    }, 1500);
  };

  return (
    <>
      <div className="flex gap-1">
        <Button
          size="sm"
          variant="neu-flat"
          onClick={() => {
            resetHistory();
            navigate(`/dashboard/${teamCode}/tools`);
          }}
          className="flex items-center gap-2 bg-secondary-300"
        >
          <div className="hidden sm:block">Reset</div>
          <RotateCcw size={16} />
        </Button>
        <Button
          size="sm"
          variant="neu-flat"
          onClick={() => setDialogOpen(!dialogOpen)}
          className="flex items-center gap-2 bg-secondary-300"
        >
          <div className="hidden sm:block">Samples</div>
          <Clapperboard size={16} />
        </Button>
        <Button size="sm" scheme="black" variant="neu-flat" className="gap-2 items-center" onClick={handleCopy}>
          <div className="hidden sm:block">Share</div>
          {!copy.copying && <Copy size={16} />}
          {copy.copying && !copy.isRejected && <Check size={16} />}
          {copy.copying && copy.isRejected && <X size={16} />}
        </Button>
      </div>
      <Dialog open={dialogOpen} onOpenChange={() => setDialogOpen(!dialogOpen)}>
        <DialogContent className="flex flex-col h-[90%] min-w-[60%] 2xl:min-w-[40%] max-w-[720px] 2xl:px-12 px-2 md:px-6">
          <ScrollArea>
            <div className="flex justify-center">
              <div className="px-1 md:p-4">
                <div className="flex flex-col gap-8">
                  <div>
                    <h4 className="font-bold tracking-tight">Samples</h4>
                    <small>
                      Select a tool to help you parse and inspect data from your systems. These tools are designed to
                      help you quickly understand the structure of your data and how to extract the information you
                      need.
                    </small>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {Object.entries(samples).map(([key, sample]) => {
                      if (key === "default") {
                        return null;
                      }

                      return (
                        <QuickOption
                          key={key}
                          name={sample.name}
                          description={sample.description ?? ""}
                          icon={sample.icon ?? <ArrowRightLeft size={16} />}
                          className="w-full h-[152px] bg-secondary-200"
                          handleOnClick={() => {
                            navigate(`/dashboard/${teamCode}/tools/${key}`);
                            setDialogOpen(false);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};
