import { ExcludeFunctionKeys, formatCount } from "@/utils";
import { isNull, isFunction } from "@wt-dev/common/utils";
import { cn } from "@/utils/ui.util";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

const DEFAULTS = {
  pageSize: 10,
  pageIndex: 0,
};

interface PaginationConfig {
  pageIndex: number;
  pageSize: number;

  params: {
    page: number;
    pageSize: number;
  };

  setPageIndex(pageIndex: number): void;
  setPageSize(pageSize: number): void;
  nextPage(): void;
  previousPage(): void;
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePagination(defaults: Partial<ExcludeFunctionKeys<PaginationConfig>> = {}): PaginationConfig {
  const [searchParams, setSearchParams] = useSearchParams();

  const getNumberParam = React.useCallback(
    (name: string): number | null => {
      const value = searchParams.get(name);
      if (isNull(value)) return null;

      const numberValue = Number(value);
      if (Number.isNaN(numberValue)) return null;

      return Math.max(0, numberValue);
    },
    [searchParams]
  );

  const [pageIndex, setPageIndex] = React.useState<number>(
    () => getNumberParam("pageIndex") ?? defaults.pageIndex ?? DEFAULTS.pageIndex
  );
  const [pageSize, setPageSize] = React.useState<number>(
    () => getNumberParam("pageSize") ?? defaults.pageSize ?? DEFAULTS.pageSize
  );

  const handleSetPageIndex = React.useCallback(
    (newPageIndex: SetStateAction<typeof pageIndex>) => {
      setPageIndex((pageIndex) => {
        const updatedPageIndex = Math.max(0, isFunction(newPageIndex) ? newPageIndex(pageIndex) : newPageIndex);

        setSearchParams((params) => {
          params.set("pageIndex", updatedPageIndex.toString());
          return params;
        });

        return updatedPageIndex;
      });
    },
    [setSearchParams]
  );

  const handleSetPageSize = React.useCallback(
    (newPageSize: SetStateAction<typeof pageSize>) => {
      setPageSize((pageSize) => {
        const updatedPageSize = Math.max(0, isFunction(newPageSize) ? newPageSize(pageSize) : newPageSize);

        setSearchParams((params) => {
          params.set("pageSize", updatedPageSize.toString());
          return params;
        });

        return updatedPageSize;
      });
    },
    [setSearchParams]
  );

  React.useEffect(() => {
    const parsedPageSize = getNumberParam("pageSize") ?? defaults.pageSize ?? DEFAULTS.pageSize;

    if (parsedPageSize !== pageSize) {
      setPageSize(parsedPageSize);
    }

    const parsedIndex = getNumberParam("pageIndex") ?? defaults.pageIndex ?? DEFAULTS.pageIndex;

    if (parsedIndex !== pageIndex) {
      setPageIndex(parsedIndex);
    }
  }, [defaults.pageIndex, defaults.pageSize, getNumberParam, pageIndex, pageSize]);

  return {
    pageIndex,
    pageSize,

    params: {
      page: pageIndex,
      pageSize,
    },

    setPageIndex: handleSetPageIndex,
    setPageSize: handleSetPageSize,
    nextPage: () => handleSetPageIndex((p) => p + 1),
    previousPage: () => handleSetPageIndex((p) => p - 1),
  };
}

// eslint-disable-next-line react-refresh/only-export-components
export function useSimplePagination(defaults: Partial<ExcludeFunctionKeys<PaginationConfig>> = {}): PaginationConfig {
  const [pageIndex, setPageIndex] = React.useState<number>(() => defaults.pageIndex ?? DEFAULTS.pageIndex);
  const [pageSize, setPageSize] = React.useState<number>(() => defaults.pageSize ?? DEFAULTS.pageSize);

  const handleSetPageIndex = React.useCallback((newPageIndex: SetStateAction<typeof pageIndex>) => {
    setPageIndex((pageIndex) => {
      return Math.max(0, isFunction(newPageIndex) ? newPageIndex(pageIndex) : newPageIndex);
    });
  }, []);

  const handleSetPageSize = React.useCallback((newPageSize: SetStateAction<typeof pageSize>) => {
    setPageSize((pageSize) => {
      return Math.max(0, isFunction(newPageSize) ? newPageSize(pageSize) : newPageSize);
    });
  }, []);

  return {
    pageIndex,
    pageSize,

    params: {
      page: pageIndex,
      pageSize,
    },

    setPageIndex: handleSetPageIndex,
    setPageSize: handleSetPageSize,
    nextPage: () => handleSetPageIndex((p) => p + 1),
    previousPage: () => handleSetPageIndex((p) => p - 1),
  };
}

interface PaginationProps {
  pagination: PaginationConfig;
  numberOfPages?: number;
  showOnlyChevrons?: boolean;
  className?: string;
  hideSizeSelect?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  numberOfPages,
  showOnlyChevrons,
  className,
  hideSizeSelect,
}) => {
  if (hideSizeSelect) {
    return (
      <PaginationPages
        className={className}
        pagination={pagination}
        numberOfPages={numberOfPages ?? 0}
        showOnlyChevrons={showOnlyChevrons ?? false}
      />
    );
  }

  return (
    <div className={cn("grid grid-cols-[1fr,min-content,1fr]", className)}>
      <div />
      <PaginationPages
        pagination={pagination}
        numberOfPages={numberOfPages ?? 0}
        showOnlyChevrons={showOnlyChevrons ?? false}
      />
      <PageSizeSelect pagination={pagination} />
    </div>
  );
};

interface PaginationPagesProps {
  pagination: PaginationConfig;
  numberOfPages: number;
  showOnlyChevrons: boolean;
  className?: string;
}

const PaginationPages: React.FC<PaginationPagesProps> = ({
  pagination,
  numberOfPages,
  showOnlyChevrons,
  className,
}) => {
  const PageButton: React.FC<{ pageIndex: number }> = React.useCallback(
    ({ pageIndex }) => {
      return (
        <Button
          size="xs"
          className={cn(
            "aspect-square hover:bg-white",
            pageIndex === pagination.pageIndex ? "bg-primary-100" : "bg-tertiary-100"
          )}
          disabled={pagination.pageIndex === pageIndex}
          onClick={() => pagination.setPageIndex(pageIndex)}
        >
          {pageIndex + 1}
        </Button>
      );
    },
    [pagination]
  );

  const pages = React.useMemo(() => {
    if (numberOfPages < 8) {
      return Array(numberOfPages)
        .fill(0)
        .map((_, i) => <PageButton key={`page-button-${i}`} pageIndex={i} />);
    }

    const isStart = pagination.pageIndex <= numberOfPages - 5;
    const isEnd = pagination.pageIndex >= 4;
    const isMiddle = isStart && isEnd;

    if (isMiddle) {
      return (
        <>
          <PageButton pageIndex={0} />
          <DotsHorizontalIcon />
          <PageButton pageIndex={pagination.pageIndex - 1} />
          <PageButton pageIndex={pagination.pageIndex} />
          <PageButton pageIndex={pagination.pageIndex + 1} />
          <DotsHorizontalIcon />
          <PageButton pageIndex={numberOfPages - 1} />
        </>
      );
    }

    if (isStart) {
      return (
        <>
          <PageButton pageIndex={0} />
          <PageButton pageIndex={1} />
          <PageButton pageIndex={2} />
          <PageButton pageIndex={3} />
          <PageButton pageIndex={4} />
          <DotsHorizontalIcon />
          <PageButton pageIndex={numberOfPages - 1} />
        </>
      );
    }

    return (
      <>
        <PageButton pageIndex={0} />
        <DotsHorizontalIcon />
        <PageButton pageIndex={numberOfPages - 5} />
        <PageButton pageIndex={numberOfPages - 4} />
        <PageButton pageIndex={numberOfPages - 3} />
        <PageButton pageIndex={numberOfPages - 2} />
        <PageButton pageIndex={numberOfPages - 1} />
      </>
    );
  }, [PageButton, numberOfPages, pagination.pageIndex]);

  return (
    <div className={cn("flex flex-col gap-2 items-center", className)}>
      <div className="flex items-center gap-2">
        <Button
          size="xs"
          className="aspect-square bg-secondary-200"
          disabled={pagination.pageIndex < 1}
          onClick={pagination.previousPage}
        >
          <ChevronLeft size={16} />
        </Button>

        {!showOnlyChevrons && pages}

        <Button
          size="xs"
          className="aspect-square bg-secondary-200"
          disabled={pagination.pageIndex >= numberOfPages - 1}
          onClick={pagination.nextPage}
        >
          <ChevronRight size={16} />
        </Button>
      </div>

      {showOnlyChevrons && (
        <p className="text-xs text-gray-700 whitespace-nowrap">
          {pagination.pageIndex + 1} of {numberOfPages}
        </p>
      )}
    </div>
  );
};

const sizes = [5, 10, 20, 30, 40, 50];

interface PageSizeSelectProps {
  pagination: PaginationConfig;
  className?: string;
}

export const PageSizeSelect: React.FC<PageSizeSelectProps> = ({ pagination, className }) => {
  return (
    <Select
      value={String(pagination.pageSize)}
      onValueChange={(value) => {
        const numberValue = Number(value);
        if (Number.isNaN(numberValue)) return;

        return pagination.setPageSize(numberValue);
      }}
    >
      <SelectTrigger className={cn("h-7 pr-1 pl-2 rounded-md text-xs w-fit place-self-end", className)}>
        <SelectValue children={formatCount(pagination.pageSize, "item")} placeholder="Select Type" />
      </SelectTrigger>
      <SelectContent>
        {sizes.map((size) => {
          return (
            <SelectItem key={`size-${size}`} value={String(size)}>
              {formatCount(size, "item")}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

/**
 * @depricted - use {@link usePagination}
 */
function parsePagination(
  searchParams: URLSearchParams,
  options: { defaultPageSize: number }
): {
  pageSize: number;
  pageIndex: number;
} {
  return {
    pageSize: Number(searchParams.get("pageSize")) || options.defaultPageSize,
    pageIndex: Number(searchParams.get("pageIndex")) || 0,
  };
}

/**
 * @depricted - use {@link usePagination}
 */
// eslint-disable-next-line react-refresh/only-export-components
export function usePaginationFromQueryParam({ defaultPageSize: defaultPageSize }: { defaultPageSize: number }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = React.useState(() => parsePagination(searchParams, { defaultPageSize }));

  const { pageSize, pageIndex } = pagination;

  const handleSetPagination: typeof setPagination = React.useCallback(
    (newPagination: SetStateAction<typeof pagination>) => {
      setPagination((pagination) => {
        const updatedPagination = isFunction(newPagination) ? newPagination(pagination) : newPagination;

        setSearchParams({
          pageSize: updatedPagination.pageSize.toString(),
          pageIndex: updatedPagination.pageIndex.toString(),
        });

        return updatedPagination;
      });
    },
    [setSearchParams]
  );

  React.useEffect(() => {
    const parsed = parsePagination(searchParams, { defaultPageSize });

    if (parsed.pageSize !== pagination.pageSize || parsed.pageIndex !== pagination.pageIndex) {
      setPagination(parsed);
    }
  }, [searchParams, pagination, setSearchParams, defaultPageSize, handleSetPagination]);

  return {
    limit: pageSize,
    onPaginationChange: handleSetPagination,
    pagination,
    skip: pageSize * pageIndex,
  };
}
