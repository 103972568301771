import React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { GroupByOption, SortOption, WebhookService } from "@/utils/webhooks/webhook-service";
import classes from "@/lib/webhooks/webhooks.module.scss";
import { ArrowDown, ArrowDownNarrowWide, ArrowUp, ArrowUpNarrowWide } from "lucide-react";

interface OptionsPopoverProps {
  selectedGroupOption: GroupByOption;
  setSelectedGroupOption: (option: GroupByOption) => void;
  selectedSortOption: SortOption;
  setSelectedSortOption: (option: SortOption) => void;
  ascending: boolean;
  setAscending: (ascending: boolean) => void;
}

export const OptionsPopover: React.FC<OptionsPopoverProps> = ({
  selectedGroupOption,
  setSelectedGroupOption,
  selectedSortOption,
  setSelectedSortOption,
  ascending,
  setAscending,
}) => (
  <Popover>
    <PopoverTrigger asChild>
      <Button variant="ghost" size="icon" className={`bg-transparent h-fit text-md ${classes["icon-button"]}`}>
        <span>{!ascending ? <ArrowDownNarrowWide size={15} /> : <ArrowUpNarrowWide size={15} />}</span>
        <span>
          {!ascending ? (
            <ArrowDownNarrowWide size={15} className={"text-secondary-400"} />
          ) : (
            <ArrowUpNarrowWide size={17} />
          )}
        </span>
      </Button>
    </PopoverTrigger>
    <PopoverContent align="start" className="min-w-[340px] overflow-x-hidden bg-primary-100">
      <OptionsPopoverContent
        selectedGroupOption={selectedGroupOption}
        setSelectedGroupOption={setSelectedGroupOption}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
        ascending={ascending}
        setAscending={setAscending}
      />
    </PopoverContent>
  </Popover>
);

const OptionsPopoverContent: React.FC<OptionsPopoverProps> = ({
  selectedGroupOption,
  setSelectedGroupOption,
  selectedSortOption,
  setSelectedSortOption,
  ascending,
  setAscending,
}) => {
  const renderOptions = (
    options: SortOption[] | GroupByOption[],
    selectedOption: SortOption | GroupByOption,
    setSelectedOption: (option: SortOption | GroupByOption) => void,
    isSortOption: boolean = false
  ) =>
    options.map((option) => (
      <div key={option}>
        {option === selectedOption ? (
          <Badge
            variant="secondary"
            className="bg-secondary-950 hover:cursor-pointer select-none"
            onClick={() => isSortOption && setAscending(!ascending)}
          >
            <div className="flex items-center gap-1">
              {option}
              {isSortOption && (ascending ? <ArrowUp className="w-3 h-3" /> : <ArrowDown className="w-3 h-3" />)}
            </div>
          </Badge>
        ) : (
          <Badge
            variant="outline"
            className="bg-white hover:cursor-pointer select-none"
            onClick={() => setSelectedOption(option)}
          >
            {option}
          </Badge>
        )}
      </div>
    ));

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <p className="w-16 text-sm font-semibold text-end">Group By</p>
        <div className="flex gap-1 items-center">
          {renderOptions(WebhookService.groupByOptions, selectedGroupOption, setSelectedGroupOption)}
        </div>
      </div>

      <div className="flex items-center gap-4">
        <p className="w-16 text-sm font-semibold text-end">Sort By</p>
        <div className="flex items-center">
          <div className="flex gap-1 items-center">
            {renderOptions(WebhookService.sortOptions, selectedSortOption, setSelectedSortOption, true)}
          </div>
        </div>
      </div>
    </div>
  );
};
