/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateInviteRequestDto,
  InvitationResponseCheckDto,
  InvitationsControllerGetAllForMeParams,
  PaginatedInvitationsResponse,
  StatusMessageDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 
      - **Logged-in users**: 
        If the user is logged in, the `checkInvite` API must be called to verify whether the invite is for the current organization.
        - If the invite is valid for the current organization, the user is navigated to `/invites/{inviteCode}`.
        - UI displays pending invites.
        - A user can then accept an invite

      - **Logged-out users**:
        A public `checkInvite` API call is made.
        - If a user is found, they're redirected to login with pre-filled email.
        - If no user is found, redirected to signup with inviteCode auto-filled.
 * @summary Create a pending invite and generate a link to the frontend
 */
export const invitationsControllerCreateInvite = (
  createInviteRequestDto: CreateInviteRequestDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<StatusMessageDto>(
    {
      url: `/v1/invitations`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createInviteRequestDto,
      signal,
    },
    options
  );
};

export const getInvitationsControllerCreateInviteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateInvite>>,
    TError,
    { data: CreateInviteRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerCreateInvite>>,
  TError,
  { data: CreateInviteRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerCreateInvite>>,
    { data: CreateInviteRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerCreateInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerCreateInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerCreateInvite>>
>;
export type InvitationsControllerCreateInviteMutationBody = CreateInviteRequestDto;
export type InvitationsControllerCreateInviteMutationError = ErrorType<unknown>;

/**
 * @summary Create a pending invite and generate a link to the frontend
 */
export const useInvitationsControllerCreateInvite = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateInvite>>,
    TError,
    { data: CreateInviteRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerCreateInvite>>,
  TError,
  { data: CreateInviteRequestDto },
  TContext
> => {
  const mutationOptions = getInvitationsControllerCreateInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Return a paginated list of invitations for my current user
 */
export const invitationsControllerGetAllForMe = (
  params?: InvitationsControllerGetAllForMeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedInvitationsResponse>(
    { url: `/v1/invitations`, method: "GET", params, signal },
    options
  );
};

export const getInvitationsControllerGetAllForMeQueryKey = (params?: InvitationsControllerGetAllForMeParams) => {
  return [`/v1/invitations`, ...(params ? [params] : [])] as const;
};

export const getInvitationsControllerGetAllForMeQueryOptions = <
  TData = Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
  TError = ErrorType<unknown>,
>(
  params?: InvitationsControllerGetAllForMeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInvitationsControllerGetAllForMeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>> = ({ signal }) =>
    invitationsControllerGetAllForMe(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvitationsControllerGetAllForMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>
>;
export type InvitationsControllerGetAllForMeQueryError = ErrorType<unknown>;

export function useInvitationsControllerGetAllForMe<
  TData = Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | InvitationsControllerGetAllForMeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvitationsControllerGetAllForMe<
  TData = Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
  TError = ErrorType<unknown>,
>(
  params?: InvitationsControllerGetAllForMeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvitationsControllerGetAllForMe<
  TData = Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
  TError = ErrorType<unknown>,
>(
  params?: InvitationsControllerGetAllForMeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Return a paginated list of invitations for my current user
 */

export function useInvitationsControllerGetAllForMe<
  TData = Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>,
  TError = ErrorType<unknown>,
>(
  params?: InvitationsControllerGetAllForMeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerGetAllForMe>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getInvitationsControllerGetAllForMeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check whether an Invitation can be accepted. (A user could in the meantime already become a member or join an organization.)
 */
export const invitationsControllerCheckInvite = (
  inviteCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<InvitationResponseCheckDto>(
    { url: `/v1/invitations/check-invite/${inviteCode}`, method: "GET", signal },
    options
  );
};

export const getInvitationsControllerCheckInviteQueryKey = (inviteCode: string) => {
  return [`/v1/invitations/check-invite/${inviteCode}`] as const;
};

export const getInvitationsControllerCheckInviteQueryOptions = <
  TData = Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
  TError = ErrorType<unknown>,
>(
  inviteCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInvitationsControllerCheckInviteQueryKey(inviteCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>> = ({ signal }) =>
    invitationsControllerCheckInvite(inviteCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!inviteCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvitationsControllerCheckInviteQueryResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerCheckInvite>>
>;
export type InvitationsControllerCheckInviteQueryError = ErrorType<unknown>;

export function useInvitationsControllerCheckInvite<
  TData = Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
  TError = ErrorType<unknown>,
>(
  inviteCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvitationsControllerCheckInvite<
  TData = Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
  TError = ErrorType<unknown>,
>(
  inviteCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvitationsControllerCheckInvite<
  TData = Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
  TError = ErrorType<unknown>,
>(
  inviteCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Check whether an Invitation can be accepted. (A user could in the meantime already become a member or join an organization.)
 */

export function useInvitationsControllerCheckInvite<
  TData = Awaited<ReturnType<typeof invitationsControllerCheckInvite>>,
  TError = ErrorType<unknown>,
>(
  inviteCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof invitationsControllerCheckInvite>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getInvitationsControllerCheckInviteQueryOptions(inviteCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Accept an invitation
 */
export const invitationsControllerAcceptInvite = (
  inviteCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/v1/invitations/accept-invite/${inviteCode}`, method: "POST", signal }, options);
};

export const getInvitationsControllerAcceptInviteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>,
    { inviteCode: string }
  > = (props) => {
    const { inviteCode } = props ?? {};

    return invitationsControllerAcceptInvite(inviteCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerAcceptInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>
>;

export type InvitationsControllerAcceptInviteMutationError = ErrorType<unknown>;

/**
 * @summary Accept an invitation
 */
export const useInvitationsControllerAcceptInvite = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const mutationOptions = getInvitationsControllerAcceptInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
