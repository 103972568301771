import React from "react";
import * as dns from "dns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { ChevronDown, ChevronUp, Globe } from "lucide-react";
import { Button } from "@/components/ui/button";

type DomainsDNSRecord = {
  type: string;
  value:
    | string
    | dns.MxRecord
    | dns.NaptrRecord
    | dns.SoaRecord
    | dns.SrvRecord
    | dns.ResolveWithTtlOptions
    | dns.AnyRecord;
};

interface DomainDnsRunDetailsProps {
  rawData: any;
}

export const DomainDnsRunDetails: React.FC<DomainDnsRunDetailsProps> = ({ rawData }) => {
  const data = rawData as DomainsDNSRecord[];
  const [expandedRecords, setExpandedRecords] = React.useState<{ [key: string]: boolean }>({});

  const toggleRecordExpansion = (index: number) => {
    setExpandedRecords((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const getRecordTypeColor = (type: string) => {
    const colors: { [key: string]: string } = {
      A: "bg-blue-500",
      AAAA: "bg-green-500",
      CNAME: "bg-yellow-500",
      MX: "bg-purple-500",
      TXT: "bg-pink-500",
      NS: "bg-indigo-500",
      SOA: "bg-red-500",
      SRV: "bg-orange-500",
    };
    return colors[type] || "bg-gray-500";
  };

  const renderRecordValue = (value: DomainsDNSRecord["value"]) => {
    if (typeof value === "string") {
      return value;
    } else if ("exchange" in value && "priority" in value) {
      return `Priority: ${value.priority}, Exchange: ${value.exchange}`;
    } else if ("flags" in value && "service" in value && "regexp" in value && "replacement" in value) {
      return `Flags: ${value.flags}, Service: ${value.service}, RegExp: ${value.regexp}, Replacement: ${value.replacement}`;
    } else if ("nsname" in value && "hostmaster" in value) {
      return `NS: ${value.nsname}, Hostmaster: ${value.hostmaster}, Serial: ${value.serial}, Refresh: ${value.refresh}, Retry: ${value.retry}, Expire: ${value.expire}, Minttl: ${value.minttl}`;
    } else if ("priority" in value && "weight" in value && "port" in value && "name" in value) {
      return `Priority: ${value.priority}, Weight: ${value.weight}, Port: ${value.port}, Name: ${value.name}`;
    } else {
      return JSON.stringify(value, null, 2);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg font-semibold leading-none tracking-tight">Domain DNS</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {data.map((record, index) => (
          <div key={index} className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex gap-4 items-center">
                <h2 className="text-sm font-medium text-gray-500 flex items-center">
                  <Globe className="mr-2 h-5 w-5" />
                  {record.type} Record
                </h2>
                <Badge variant="outline" className={`text-xs ${getRecordTypeColor(record.type)} text-white`}>
                  {record.type}
                </Badge>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => toggleRecordExpansion(index)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {expandedRecords[index] ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Collapse
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    Expand
                  </>
                )}
              </Button>
            </div>
            {expandedRecords[index] && (
              <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm">
                {renderRecordValue(record.value)}
              </pre>
            )}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
