import React from "react";
import { Outlet } from "react-router-dom";

export const ToolsRoute: React.FC = () => {
  return (
    <div>
      {/* Content */}
      <Outlet />
    </div>
  );
};
