/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateDocumentDto,
  CreateDocumentVersionDto,
  DocumentResponseDto,
  DocumentVersionResponseDto,
  DocumentsControllerGetDocumentVersionsParams,
  DocumentsControllerGetDocumentsParams,
  PaginatedDocumentVersionsResponseDto,
  PaginatedDocumentsResponseDto,
  UpdateDocumentDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Gets paginated list of documents in this project
 */
export const documentsControllerGetDocuments = (
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedDocumentsResponseDto>(
    { url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents`, method: "GET", params, signal },
    options
  );
};

export const getDocumentsControllerGetDocumentsQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams
) => {
  return [`/v1/documents/teams/${teamCode}/projects/${projectCode}/documents`, ...(params ? [params] : [])] as const;
};

export const getDocumentsControllerGetDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDocumentsControllerGetDocumentsQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof documentsControllerGetDocuments>>> = ({ signal }) =>
    documentsControllerGetDocuments(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DocumentsControllerGetDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerGetDocuments>>
>;
export type DocumentsControllerGetDocumentsQueryError = ErrorType<unknown>;

export function useDocumentsControllerGetDocuments<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | DocumentsControllerGetDocumentsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocuments<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocuments<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets paginated list of documents in this project
 */

export function useDocumentsControllerGetDocuments<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocuments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: DocumentsControllerGetDocumentsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocuments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDocumentsControllerGetDocumentsQueryOptions(teamCode, projectCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Gets document by filename
 */
export const documentsControllerGetDocumentByFilename = (
  teamCode: string,
  projectCode: string,
  filename: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DocumentResponseDto>(
    { url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/by-filename/${filename}`, method: "GET", signal },
    options
  );
};

export const getDocumentsControllerGetDocumentByFilenameQueryKey = (
  teamCode: string,
  projectCode: string,
  filename: string
) => {
  return [`/v1/documents/teams/${teamCode}/projects/${projectCode}/by-filename/${filename}`] as const;
};

export const getDocumentsControllerGetDocumentByFilenameQueryOptions = <
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  filename: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDocumentsControllerGetDocumentByFilenameQueryKey(teamCode, projectCode, filename);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>> = ({ signal }) =>
    documentsControllerGetDocumentByFilename(teamCode, projectCode, filename, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && filename), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DocumentsControllerGetDocumentByFilenameQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>
>;
export type DocumentsControllerGetDocumentByFilenameQueryError = ErrorType<unknown>;

export function useDocumentsControllerGetDocumentByFilename<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  filename: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentByFilename<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  filename: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentByFilename<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  filename: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets document by filename
 */

export function useDocumentsControllerGetDocumentByFilename<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  filename: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentByFilename>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDocumentsControllerGetDocumentByFilenameQueryOptions(
    teamCode,
    projectCode,
    filename,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Creates new document in the project
 */
export const documentsControllerCreateDocument = (
  teamCode: string,
  projectCode: string,
  createDocumentDto: CreateDocumentDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DocumentResponseDto>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createDocumentDto,
      signal,
    },
    options
  );
};

export const getDocumentsControllerCreateDocumentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerCreateDocument>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateDocumentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof documentsControllerCreateDocument>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateDocumentDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentsControllerCreateDocument>>,
    { teamCode: string; projectCode: string; data: CreateDocumentDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return documentsControllerCreateDocument(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DocumentsControllerCreateDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerCreateDocument>>
>;
export type DocumentsControllerCreateDocumentMutationBody = CreateDocumentDto;
export type DocumentsControllerCreateDocumentMutationError = ErrorType<unknown>;

/**
 * @summary Creates new document in the project
 */
export const useDocumentsControllerCreateDocument = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerCreateDocument>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateDocumentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof documentsControllerCreateDocument>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateDocumentDto },
  TContext
> => {
  const mutationOptions = getDocumentsControllerCreateDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the document
 */
export const documentsControllerGetDocument = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DocumentResponseDto>(
    { url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}`, method: "GET", signal },
    options
  );
};

export const getDocumentsControllerGetDocumentQueryKey = (
  teamCode: string,
  projectCode: string,
  documentId: number
) => {
  return [`/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}`] as const;
};

export const getDocumentsControllerGetDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof documentsControllerGetDocument>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDocumentsControllerGetDocumentQueryKey(teamCode, projectCode, documentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof documentsControllerGetDocument>>> = ({ signal }) =>
    documentsControllerGetDocument(teamCode, projectCode, documentId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && documentId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof documentsControllerGetDocument>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DocumentsControllerGetDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerGetDocument>>
>;
export type DocumentsControllerGetDocumentQueryError = ErrorType<unknown>;

export function useDocumentsControllerGetDocument<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocument>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocument<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocument>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocument<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocument>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets the document
 */

export function useDocumentsControllerGetDocument<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocument>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocument>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDocumentsControllerGetDocumentQueryOptions(teamCode, projectCode, documentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates the document
 */
export const documentsControllerUpdateDocumentInfo = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  updateDocumentDto: UpdateDocumentDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateDocumentDto,
    },
    options
  );
};

export const getDocumentsControllerUpdateDocumentInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; data: UpdateDocumentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; data: UpdateDocumentDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>,
    { teamCode: string; projectCode: string; documentId: number; data: UpdateDocumentDto }
  > = (props) => {
    const { teamCode, projectCode, documentId, data } = props ?? {};

    return documentsControllerUpdateDocumentInfo(teamCode, projectCode, documentId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DocumentsControllerUpdateDocumentInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>
>;
export type DocumentsControllerUpdateDocumentInfoMutationBody = UpdateDocumentDto;
export type DocumentsControllerUpdateDocumentInfoMutationError = ErrorType<unknown>;

/**
 * @summary Updates the document
 */
export const useDocumentsControllerUpdateDocumentInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; data: UpdateDocumentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof documentsControllerUpdateDocumentInfo>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; data: UpdateDocumentDto },
  TContext
> => {
  const mutationOptions = getDocumentsControllerUpdateDocumentInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes the document
 */
export const documentsControllerDeleteDocument = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}`, method: "DELETE" },
    options
  );
};

export const getDocumentsControllerDeleteDocumentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerDeleteDocument>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof documentsControllerDeleteDocument>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentsControllerDeleteDocument>>,
    { teamCode: string; projectCode: string; documentId: number }
  > = (props) => {
    const { teamCode, projectCode, documentId } = props ?? {};

    return documentsControllerDeleteDocument(teamCode, projectCode, documentId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DocumentsControllerDeleteDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerDeleteDocument>>
>;

export type DocumentsControllerDeleteDocumentMutationError = ErrorType<unknown>;

/**
 * @summary Deletes the document
 */
export const useDocumentsControllerDeleteDocument = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerDeleteDocument>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof documentsControllerDeleteDocument>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number },
  TContext
> => {
  const mutationOptions = getDocumentsControllerDeleteDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets paginated list of document versions
 */
export const documentsControllerGetDocumentVersions = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedDocumentVersionsResponseDto>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getDocumentsControllerGetDocumentVersionsQueryKey = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams
) => {
  return [
    `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDocumentsControllerGetDocumentVersionsQueryOptions = <
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDocumentsControllerGetDocumentVersionsQueryKey(teamCode, projectCode, documentId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>> = ({ signal }) =>
    documentsControllerGetDocumentVersions(teamCode, projectCode, documentId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && documentId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DocumentsControllerGetDocumentVersionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>
>;
export type DocumentsControllerGetDocumentVersionsQueryError = ErrorType<unknown>;

export function useDocumentsControllerGetDocumentVersions<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  params: undefined | DocumentsControllerGetDocumentVersionsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentVersions<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentVersions<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets paginated list of document versions
 */

export function useDocumentsControllerGetDocumentVersions<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  params?: DocumentsControllerGetDocumentVersionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDocumentsControllerGetDocumentVersionsQueryOptions(
    teamCode,
    projectCode,
    documentId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Creates a new document version
 */
export const documentsControllerCreateDocumentVersion = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  createDocumentVersionDto: CreateDocumentVersionDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DocumentVersionResponseDto>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createDocumentVersionDto,
      signal,
    },
    options
  );
};

export const getDocumentsControllerCreateDocumentVersionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; data: CreateDocumentVersionDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; data: CreateDocumentVersionDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>,
    { teamCode: string; projectCode: string; documentId: number; data: CreateDocumentVersionDto }
  > = (props) => {
    const { teamCode, projectCode, documentId, data } = props ?? {};

    return documentsControllerCreateDocumentVersion(teamCode, projectCode, documentId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DocumentsControllerCreateDocumentVersionMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>
>;
export type DocumentsControllerCreateDocumentVersionMutationBody = CreateDocumentVersionDto;
export type DocumentsControllerCreateDocumentVersionMutationError = ErrorType<unknown>;

/**
 * @summary Creates a new document version
 */
export const useDocumentsControllerCreateDocumentVersion = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; data: CreateDocumentVersionDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof documentsControllerCreateDocumentVersion>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; data: CreateDocumentVersionDto },
  TContext
> => {
  const mutationOptions = getDocumentsControllerCreateDocumentVersionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets specific version of the document
 */
export const documentsControllerGetDocumentVersion = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DocumentVersionResponseDto>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions/${versionId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDocumentsControllerGetDocumentVersionQueryKey = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number
) => {
  return [
    `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions/${versionId}`,
  ] as const;
};

export const getDocumentsControllerGetDocumentVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDocumentsControllerGetDocumentVersionQueryKey(teamCode, projectCode, documentId, versionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>> = ({ signal }) =>
    documentsControllerGetDocumentVersion(teamCode, projectCode, documentId, versionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && documentId && versionId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type DocumentsControllerGetDocumentVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>
>;
export type DocumentsControllerGetDocumentVersionQueryError = ErrorType<unknown>;

export function useDocumentsControllerGetDocumentVersion<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentVersion<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDocumentsControllerGetDocumentVersion<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets specific version of the document
 */

export function useDocumentsControllerGetDocumentVersion<
  TData = Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof documentsControllerGetDocumentVersion>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDocumentsControllerGetDocumentVersionQueryOptions(
    teamCode,
    projectCode,
    documentId,
    versionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Sets specific document version as current (revert)
 */
export const documentsControllerSetDocumentVersionAsCurrent = (
  teamCode: string,
  projectCode: string,
  documentId: number,
  versionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/documents/teams/${teamCode}/projects/${projectCode}/documents/${documentId}/versions/${versionId}/set-current`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getDocumentsControllerSetDocumentVersionAsCurrentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; versionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; versionId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>,
    { teamCode: string; projectCode: string; documentId: number; versionId: number }
  > = (props) => {
    const { teamCode, projectCode, documentId, versionId } = props ?? {};

    return documentsControllerSetDocumentVersionAsCurrent(teamCode, projectCode, documentId, versionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DocumentsControllerSetDocumentVersionAsCurrentMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>
>;

export type DocumentsControllerSetDocumentVersionAsCurrentMutationError = ErrorType<unknown>;

/**
 * @summary Sets specific document version as current (revert)
 */
export const useDocumentsControllerSetDocumentVersionAsCurrent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>,
    TError,
    { teamCode: string; projectCode: string; documentId: number; versionId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof documentsControllerSetDocumentVersionAsCurrent>>,
  TError,
  { teamCode: string; projectCode: string; documentId: number; versionId: number },
  TContext
> => {
  const mutationOptions = getDocumentsControllerSetDocumentVersionAsCurrentMutationOptions(options);

  return useMutation(mutationOptions);
};
