import { formatDistanceToNow } from "date-fns/formatDistanceToNow";

import { RecentRequestItem } from "@/lib/webhooks/sidebar/recent-request-item";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ReceivedEmailInfoResponseDto } from "@/api/generated";
import { useCurrentProject } from "@/lib/projects/context/project-context";

interface RecentEmailsProps {
  emailAddressCode: string;
  received: ReceivedEmailInfoResponseDto[];
  selected?: ReceivedEmailInfoResponseDto;
}

export const RecentEmails: React.FC<RecentEmailsProps> = ({ emailAddressCode, received, selected }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  return (
    <div className="flex flex-col gap-2">
      {received.map((item, index) => (
        <div key={index}>
          <RecentRequestItem
            item={createItem(
              item.sender,
              item.subject,
              formatDistanceToNow(new Date(item.date), {
                addSuffix: true,
              })
            )}
            path={`/dashboard/${teamCode}/${projectCode}/emails/${emailAddressCode}/${item.emailCode}`}
            height="h-full"
            isSelected={item === selected}
          />
        </div>
      ))}
    </div>
  );
};

const createItem = (from: string, subject: string, info: string) => {
  return {
    name: (
      <div className="flex flex-col justify-start items-start">
        <p className="text-sm font-semibold break-all line-clamp-1 text-wrap">{from}</p>
        <small className="text-xs break-all line-clamp-1 text-wrap">{subject}</small>
      </div>
    ),
    description: "",
    info: (
      <div className="flex items-center gap-1">
        <p className="text-xs">{info}</p>
      </div>
    ),
  };
};
