import React from "react";

export function updateTextAreaHeight(
  textarea: HTMLTextAreaElement | null | undefined,
  initial?: boolean,
  scrollBottom?: boolean
) {
  if (!textarea) return;

  if (!initial) {
    textarea.style.height = "auto";
  }
  textarea.style.height = textarea.scrollHeight + "px";

  if (scrollBottom) {
    const parentsParent = textarea.parentElement?.parentElement;
    if (parentsParent) {
      parentsParent.scrollTop = parentsParent.scrollHeight;
    }
  }
}

export function useAutoResize(ref: React.RefObject<HTMLTextAreaElement>, deps: React.DependencyList = []) {
  React.useEffect(() => {
    if (!ref.current) return;

    const textarea = ref.current;
    updateTextAreaHeight(textarea, true);

    function onInput(this: HTMLTextAreaElement) {
      updateTextAreaHeight(this);
    }

    textarea.addEventListener("input", onInput.bind(textarea));

    return () => {
      textarea.removeEventListener("input", onInput.bind(textarea));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);
}
