import { ColumnDef } from "@tanstack/react-table";
import { DisplayMonitorEvents } from "../elements/display-monitor-events";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { MonitorEventInfoDto } from "@/api/generated";
import { formatDistanceToNow } from "date-fns";
import { Activity } from "lucide-react";
import { MonitoringJobs } from "../utils";

type HyperlinkInfo = {
  code: string;
  hyperlinkItemValueId: number;
  lastModifiedAt?: string;
  teamCode: string;
  projectCode: string;
};

export type MonitoringColumnsInput = {
  hyperlink: HyperlinkInfo;
  domainCert: MonitorEventInfoDto[];
  domainDns: MonitorEventInfoDto[];
  urlHealthBrowser: MonitorEventInfoDto[];
  urlHealthRest: MonitorEventInfoDto[];
  urlHealthScreenshot: MonitorEventInfoDto[];
};
export const monitoringColumns: ColumnDef<MonitoringColumnsInput>[] = [
  {
    accessorKey: "hyperlink",
    header: "Hyperlink",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      return (
        <div className="space-y-1">
          <Button
            variant="ghost"
            className="text-md text-primary-600 [word-wrap:break-word] bg-transparent p-0 h-full"
            asChild
          >
            <Link to={`/dashboard/${hyperlink.teamCode}/${hyperlink.projectCode}/monitoring/${hyperlink.code}`}>
              {hyperlink.code} <Activity className="ml-1 size-3" />
            </Link>
          </Button>

          {hyperlink.lastModifiedAt && (
            <p className="text-xs text-gray-600">
              Updated: {formatDistanceToNow(new Date(hyperlink.lastModifiedAt))} ago
            </p>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "domainCert",
    header: "Domain Cert",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      const domainCert = row.getValue("domainCert") as MonitorEventInfoDto[];
      return (
        <DisplayMonitorEvents
          monitor={MonitoringJobs.domainCert.key}
          hyperlinkCode={hyperlink.code}
          hyperlinkItemValueId={hyperlink.hyperlinkItemValueId}
          monitorEvents={domainCert}
          description={true}
        />
      );
    },
  },
  {
    accessorKey: "domainDns",
    header: "Domain DNS",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      const domainDns = row.getValue("domainDns") as MonitorEventInfoDto[];
      return (
        <DisplayMonitorEvents
          monitor={MonitoringJobs.domainDns.key}
          hyperlinkCode={hyperlink.code}
          hyperlinkItemValueId={hyperlink.hyperlinkItemValueId}
          monitorEvents={domainDns}
          description={true}
        />
      );
    },
  },
  {
    accessorKey: "urlHealthBrowser",
    header: "URL Health Browser",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      const urlHealthBrowser = row.getValue("urlHealthBrowser") as MonitorEventInfoDto[];
      return (
        <DisplayMonitorEvents
          monitor={MonitoringJobs.urlHealthBrowser.key}
          hyperlinkCode={hyperlink.code}
          hyperlinkItemValueId={hyperlink.hyperlinkItemValueId}
          monitorEvents={urlHealthBrowser}
          description={true}
        />
      );
    },
  },
  {
    accessorKey: "urlHealthRest",
    header: "URL Health REST",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      const urlHealthRest = row.getValue("urlHealthRest") as MonitorEventInfoDto[];
      return (
        <DisplayMonitorEvents
          monitor={MonitoringJobs.urlHealthRest.key}
          hyperlinkCode={hyperlink.code}
          hyperlinkItemValueId={hyperlink.hyperlinkItemValueId}
          monitorEvents={urlHealthRest}
          description={true}
        />
      );
    },
  },
  {
    accessorKey: "urlHealthScreenshot",
    header: "URL Health Screenshot",
    cell: ({ row }) => {
      const hyperlink = row.getValue("hyperlink") as HyperlinkInfo;
      const urlHealthScreenshot = row.getValue("urlHealthScreenshot") as MonitorEventInfoDto[];
      return (
        <DisplayMonitorEvents
          monitor={MonitoringJobs.urlHealthScreenshot.key}
          hyperlinkCode={hyperlink.code}
          hyperlinkItemValueId={hyperlink.hyperlinkItemValueId}
          monitorEvents={urlHealthScreenshot}
          description={true}
        />
      );
    },
  },
];
