import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/auth/useAuth";

export const GithubVerify: React.FC = () => {
  const { login } = useAuth();
  const [searchParams] = useSearchParams();

  const verificationToken = searchParams.get("token");

  if (!verificationToken) {
    return <Navigate to="/auth" replace={true} />;
  }

  login(verificationToken);

  return <Navigate to={searchParams.get("redirect") ?? "/dashboard"} replace={true} />;
};
