import React from "react";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";

interface HoverInfoProps {
  message?: string;
  content?: React.ReactNode;
  children?: React.ReactNode;
}

export const HoverInfo: React.FC<HoverInfoProps> = ({ message, content, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip open={isOpen}>
        <TooltipTrigger onClick={handleToggle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} asChild>
          {children ?? <Info className="w-4" />}
        </TooltipTrigger>
        <TooltipContent>
          <Badge className="bg-white max-w-[256px] hover:bg-white">
            {content ?? <p className="text-xs text-slate-950 font-semibold">{message}</p>}
          </Badge>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
