import { useAuthControllerLogin } from "@/api/generated";
import { useAuth } from "@/auth/useAuth";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { SignInWithGithub } from "@/lib/auth/github/sign-in-with-github";
import { onErrorToast } from "@/utils/api.util";
import React from "react";
import { UseFormReset } from "react-hook-form";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const loginSchema = z.object({
  email: z.string().email({ message: "Must be a valid email" }),
  password: z.string().min(6, { message: "Password must be at least 6 characters long" }),
});

interface LoginFormProps {
  title: string;
  description: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const { login } = useAuth();

  const loginMutation = useAuthControllerLogin();

  const onSubmit = (values: z.infer<typeof loginSchema>, reset: UseFormReset<z.infer<typeof loginSchema>>) => {
    loginMutation.mutate(
      {
        data: {
          email: values.email,
          password: values.password,
        },
      },
      {
        onSuccess(data) {
          if (data && data.accessToken) {
            login(data.accessToken);
            reset();
            navigate(searchParams.get("redirect") ?? state?.redirect ?? "/dashboard");
          }
        },
        onError: onErrorToast,
      }
    );
  };

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small>{description}</small>
      </div>

      <div className="flex flex-col gap-4">
        <SignInWithGithub />

        <div className="flex justify-center">
          <div className="flex items-center w-11/12 gap-2">
            <hr className="w-full border-t border-gray-500" />
            <span className="text-muted-foreground text-gray-800">or</span>
            <hr className="w-full border-t border-gray-500" />
          </div>
        </div>

        <Form schema={loginSchema} onSubmit={onSubmit} defaultValues={{ email: state?.emailPrefill ?? "" }}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <TextField name="email" placeholder="Email" />
              <TextField name="password" placeholder="Password" type="password" />
            </div>
            <Button
              type="submit"
              scheme="black"
              size="sm"
              disabled={loginMutation.isPending}
              className="bg-secondary-950"
            >
              {loginMutation.isPending ? <LoadingSpinner message={"Signing In"} /> : "Sign In"}
            </Button>
          </div>
        </Form>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <small>Don't have an account?</small>

        <Link to="/auth/register" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign Up</small>
        </Link>
      </div>
      <div className="flex justify-center gap-2 text-sm">
        <Link to="/auth/forgot-password" className="underline hover:cursor-pointer text-secondary-950">
          <small>Forgot Password?</small>
        </Link>
      </div>
    </Card>
  );
};
