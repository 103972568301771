import { CodeBlock } from "@/components/code-block";
import { FrameWrapper } from "@/components/frame-wrapper";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { EmailMimeState } from "@/utils/parsing";

interface EmailRenderProps {
  rawMail: string;
  email: EmailMimeState;
}

export const EmailRender: React.FC<EmailRenderProps> = ({ rawMail, email }) => {
  const parsedMessage = email.getParsedMessage();

  return (
    <ScrollArea className="h-full max-w-full">
      <div className="flex flex-col gap-4 p-4 mb-4">
        <Tabs defaultValue="html">
          <TabsList className="grid w-[300px] grid-cols-3 bg-transparent p-0 gap-1">
            <TabsTrigger
              value="html"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              Html
            </TabsTrigger>
            <TabsTrigger
              value="text"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              Text
            </TabsTrigger>
            <TabsTrigger
              value="raw"
              className="text-sm data-[state=active]:bg-secondary-950 data-[state=active]:text-white bg-secondary-100"
            >
              Raw
            </TabsTrigger>
          </TabsList>
          <TabsContent value="html" className="bg-white">
            <FrameWrapper src={parsedMessage.body.html ?? ""} />
          </TabsContent>
          <TabsContent value="text" className="flex flex-col gap-4">
            <p className="text-sm whitespace-pre-wrap">{parsedMessage.body.text}</p>
          </TabsContent>
          <TabsContent value="raw">
            <CodeBlock code={rawMail} minHeight={300} />
          </TabsContent>
        </Tabs>
      </div>
    </ScrollArea>
  );
};
