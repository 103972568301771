import React from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";

interface ConfirmDialogProps {
  title: React.ReactNode;
  desciption?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  children: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, desciption, onConfirm, onCancel, children }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle children={title} />

        {desciption && <DialogDescription children={desciption} />}

        <div className="flex items-center justify-end gap-2 mt-4">
          <Button
            className="bg-red-200"
            onClick={() => {
              onCancel?.();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="bg-tertiary-200"
            onClick={() => {
              onConfirm?.();
              setOpen(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
