import { Button } from "@/components/ui/button";
import { SettingConfig } from "./settings-config";
import { Link } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useCurrentProject } from "../context/project-context";
import { cn } from "@/utils/ui.util";

export interface SettingsCardProps {
  setting: SettingConfig;
}

export const SettingsCard: React.FC<SettingsCardProps> = ({ setting }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  return (
    <Link
      to={`/dashboard/${teamCode}/${projectCode}/settings${setting.path}`}
      className={cn("no-underline text-start", setting.disabled && "pointer-events-none")}
    >
      <Button
        className="p-4 grid grid-cols-[1fr,min-content] grid-rows-[min-content,1fr] min-h-[96px] gap-y-2 gap-x-2 items-center text-left w-full h-full bg-tertiary-100 hover:bg-secondary-200"
        disabled={setting.disabled}
      >
        <h3 className="font-semibold text-sm">{setting.title}</h3>
        <setting.icon className="size-5" />
        <p className="text-xs text-muted-foreground col-span-2 text-wrap self-start">{setting.description}</p>
      </Button>
    </Link>
  );
};
