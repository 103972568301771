import { CodeBlock } from "@/components/code-block";
import { LoadingSpinner } from "@/components/loading-spinner";
import { SingleAccordion } from "@/components/single-accordion";
import { Button } from "@/components/ui/button";
import { onErrorToast } from "@/utils/api.util";
import { CurlHelper } from "@/utils/curl-helper";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { CodeIcon, XIcon } from "lucide-react";
import React from "react";

export interface TestApiProps {
  config: AxiosRequestConfig;
  exampleData?: string;
}

export const TestPresignedApi: React.FC<TestApiProps> = ({ config, exampleData }) => {
  const [openCurl, setOpenCurl] = React.useState<boolean>(false);

  const [data, setData] = React.useState<string | undefined>(() => exampleData);

  const curl = React.useMemo(() => {
    return new CurlHelper(config).generateCommand();
  }, [config]);

  const hasBody = React.useMemo(() => {
    return config.method && config.method.toUpperCase() !== "GET";
  }, [config.method]);

  const mutation = useMutation({
    mutationFn: async (data?: string) => {
      if (!hasBody) {
        return axios(config);
      }

      return await axios({
        ...config,
        data: JSON.parse(data ?? "{}"),
      });
    },
    onError: onErrorToast,
  });

  return (
    <div className="space-y-2">
      <div className="neu rounded-md pt-2 grid grid-cols-1 grid-rows-[min-content,min-content,1fr] bg-tertiary-200 overflow-y-hidden">
        <div className="px-4 pb-2 grid grid-cols-[1fr,min-content,min-content] items-center gap-2">
          <h5 className="font-semibold tracking-tight whitespace-nowrap">Test Request</h5>

          <Button
            size="icon"
            className="bg-white/50 w-9 h-9 inline-flex data-[state=active]:bg-white"
            data-state={openCurl ? "active" : undefined}
            onClick={() => setOpenCurl((v) => !v)}
          >
            <CodeIcon size={16} />
          </Button>
          <Button
            size="sm"
            className="bg-white hover:bg-white/50"
            loading={mutation.isPending}
            onClick={() => {
              mutation.mutate(data ?? "{}");
            }}
          >
            Send
          </Button>
        </div>

        <div className="relative bg-white/50 flex flex-col">
          <SingleAccordion open={openCurl} setOpen={setOpenCurl} classNames={{ root: "p-0" }}>
            <div className="px-4 py-2 border-t-2 border-t-secondary-950 max-w-full overflow-x-hidden [word-wrap:break-word] break-all text-justify">
              {curl}
            </div>
          </SingleAccordion>

          {hasBody && (
            <CodeBlock
              language="json"
              code={data ?? ""}
              readonly={false}
              theme="light"
              handleCodeChange={setData}
              prettyPrint
              className="rounded-t-none rounded-b-md border-t-2 border-t-secondary-950"
            />
          )}

          {mutation.isPending && (
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
              <LoadingSpinner size={48} />
            </div>
          )}
        </div>
      </div>

      {mutation.data && (
        <div className="neu rounded-md grid grid-cols-1 grid-rows-[min-content,1fr] bg-tertiary-100">
          <div className="border-b-2 border-b-secondary-950 px-4 py-1 font-semibold text-sm flex items-center justify-between">
            <div>Response</div>
            <Button
              size="icon"
              className="bg-red-200 w-7 h-7 inline-flex data-[state=active]:bg-white -mr-2"
              data-state={openCurl ? "active" : undefined}
              onClick={() => mutation.reset()}
            >
              <XIcon size={16} />
            </Button>
          </div>
          <CodeBlock
            language="json"
            code={JSON.stringify(mutation.data.data, null, 2)}
            theme="light"
            className="rounded-t-none rounded-b-md"
          />
        </div>
      )}
    </div>
  );
};
