import React, { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Button } from "@/components/ui/button.tsx";
import { SendIcon } from "lucide-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  NotificationTopicResponseDto,
  useNotificationTopicsControllerGetNotificationTopic,
  useNotificationTopicsControllerPostToTopic,
} from "@/api/generated";

interface NotificationTopicPostToTopicDialog {
  topic: NotificationTopicResponseDto;
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
});

export const NotificationTopicPostToTopicDialog: React.FC<NotificationTopicPostToTopicDialog> = ({ topic }) => {
  const [open, setOpen] = useState(false);

  const extendTopicResult = useNotificationTopicsControllerGetNotificationTopic(topic.teamCode, topic.id);

  const sendMutation = useNotificationTopicsControllerPostToTopic();

  const formik = useFormik({
    validationSchema,
    initialValues: {
      message: "",
    },
    onSubmit: async (values) => {
      if (extendTopicResult.data) {
        sendMutation.mutate({
          teamCode: topic.teamCode,
          topicId: topic.id,
          data: {
            text: values.message,
          },
        });
        setOpen(false);
        formik.resetForm();
      }
    },
  });

  if (extendTopicResult.isLoading) {
    return <div>Loading...</div>;
  }

  if (extendTopicResult.isError) {
    return <div>Error loading topic details</div>;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size="sm" variant="neu" className="bg-secondary-300">
          <SendIcon className="mr-1 h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="max-h-[90vh] overflow-y-auto" // Ensure scrollability
      >
        <DialogTitle>Send Notification to Topic: {topic.name}</DialogTitle>
        <DialogDescription>
          You are about to send a notification to the selected channels for this topic. Construct your message below.
        </DialogDescription>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 mt-4">
          <div>
            <label className="text-sm font-semibold">Message</label>
            <Textarea
              id="message"
              placeholder="Enter your message here"
              value={formik.values.message}
              onChange={formik.handleChange}
            />
            {formik.errors.message && <div className="text-red-500 mt-1 text-sm">{formik.errors.message}</div>}
          </div>

          <div className="flex justify-end mt-4">
            <Button type="submit" className="bg-secondary-300">
              Send
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
