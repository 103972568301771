import { Task } from "@lilbunnyrabbit/task-manager";
import completeFileUploadTask from "./task/complete-file-upload.task";
import prepareUploadTask from "./task/prepare-upload.task";
import uploadPartsTask from "./task/upload-parts.task";

interface UploadTasksProps {
  files: File[];
  teamCode: string;
  projectCode: string;
}

export default function createFileUploadTasks({ files, teamCode, projectCode }: UploadTasksProps): Task[] {
  return files.flatMap((file) => [
    prepareUploadTask({ file, teamCode, projectCode }),
    uploadPartsTask(file),
    completeFileUploadTask({ file, teamCode }),
  ]);
}
