import React from "react";
import { TeamProjectList } from "@/lib/teams/dashboard/team-project-list";
import { TeamToolbox } from "@/lib/teams/dashboard/team-toolbox";
import { TeamMembers } from "@/lib/teams/dashboard/team-members";
import { NotificationTopicsPreview } from "@/lib/teams/dashboard/notification-topics-preview";
import { ToolsPreview } from "@/lib/teams/dashboard/tools-preview";

export const TeamIndex: React.FC = () => {
  return (
    <>
      <h3 className="font-bold tracking-tight">Team Dashboard</h3>

      {/* Projects */}
      <TeamProjectList />

      {/* Platform */}
      <TeamToolbox />

      {/* Team Members and Uploaded Files */}
      <div className="grid xl:grid-cols-12 gap-6">
        <div className="xl:col-span-7">
          <NotificationTopicsPreview />
        </div>
        <div className="xl:col-span-8">
          <ToolsPreview />
        </div>
        <div className="xl:col-span-4">
          <TeamMembers />
        </div>
      </div>
    </>
  );
};
