import { FileResponseDto, filesControllerCompleteUpload } from "@/api/generated";
import { createTask } from "@lilbunnyrabbit/task-manager";
import prepareUploadTask from "./prepare-upload.task";
import uploadPartsTask from "./upload-parts.task";

export default createTask<{ file: File; teamCode: string }, FileResponseDto>({
  name: "Complete file upload",

  parse() {
    switch (this.status) {
      default:
      case "idle": {
        return {
          status: "Complete file upload.",
        };
      }
      case "in-progress": {
        return {
          status: "Completing upload...",
        };
      }
      case "error": {
        return {
          status: "Failed to complete upload...",
        };
      }
      case "success": {
        if (this.result.isPresent()) {
          return {
            status: `"${this.data.file.name}" uploaded!`,
            result: JSON.stringify(this.result.get(), null, 2),
          };
        }

        return {
          status: `"${this.data.file.name}" uploaded!`,
        };
      }
    }
  },

  async execute({ teamCode }) {
    const { uploadId } = this.manager.getLastTaskResult(prepareUploadTask);
    const parts = this.manager.getLastTaskResult(uploadPartsTask);

    return await filesControllerCompleteUpload(teamCode, { parts, uploadId });
  },
});
