/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateNotificationChannelRequest,
  CreateTopicRequest,
  NotificationChannelResponseDto,
  NotificationTopicResponseDto,
  NotificationTopicsControllerGetChannelsPaginatedParams,
  NotificationTopicsControllerGetTopicsPaginatedParams,
  PaginatedChannelsResponse,
  PaginatedTopicsResponse,
  PostToTopicRequest,
  UpdateNotificationChannelRequest,
  UpdateTopicRequest,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const notificationTopicsControllerCreateChannel = (
  teamCode: string,
  createNotificationChannelRequest: CreateNotificationChannelRequest,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationChannelResponseDto>(
    {
      url: `/v1/notifications/channels/${teamCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createNotificationChannelRequest,
      signal,
    },
    options
  );
};

export const getNotificationTopicsControllerCreateChannelMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>,
    TError,
    { teamCode: string; data: CreateNotificationChannelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>,
  TError,
  { teamCode: string; data: CreateNotificationChannelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>,
    { teamCode: string; data: CreateNotificationChannelRequest }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return notificationTopicsControllerCreateChannel(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerCreateChannelMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>
>;
export type NotificationTopicsControllerCreateChannelMutationBody = CreateNotificationChannelRequest;
export type NotificationTopicsControllerCreateChannelMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerCreateChannel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>,
    TError,
    { teamCode: string; data: CreateNotificationChannelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateChannel>>,
  TError,
  { teamCode: string; data: CreateNotificationChannelRequest },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerCreateChannelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerGetChannelsPaginated = (
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedChannelsResponse>(
    { url: `/v1/notifications/channels/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getNotificationTopicsControllerGetChannelsPaginatedQueryKey = (
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams
) => {
  return [`/v1/notifications/channels/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getNotificationTopicsControllerGetChannelsPaginatedQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationTopicsControllerGetChannelsPaginatedQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>> = ({
    signal,
  }) => notificationTopicsControllerGetChannelsPaginated(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationTopicsControllerGetChannelsPaginatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>
>;
export type NotificationTopicsControllerGetChannelsPaginatedQueryError = ErrorType<unknown>;

export function useNotificationTopicsControllerGetChannelsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | NotificationTopicsControllerGetChannelsPaginatedParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetChannelsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetChannelsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useNotificationTopicsControllerGetChannelsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetChannelsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannelsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getNotificationTopicsControllerGetChannelsPaginatedQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const notificationTopicsControllerUpdateChannel = (
  teamCode: string,
  channelCode: string,
  updateNotificationChannelRequest: UpdateNotificationChannelRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotificationChannelResponseDto>(
    {
      url: `/v1/notifications/channels/${teamCode}/${channelCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateNotificationChannelRequest,
    },
    options
  );
};

export const getNotificationTopicsControllerUpdateChannelMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>,
    TError,
    { teamCode: string; channelCode: string; data: UpdateNotificationChannelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>,
  TError,
  { teamCode: string; channelCode: string; data: UpdateNotificationChannelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>,
    { teamCode: string; channelCode: string; data: UpdateNotificationChannelRequest }
  > = (props) => {
    const { teamCode, channelCode, data } = props ?? {};

    return notificationTopicsControllerUpdateChannel(teamCode, channelCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerUpdateChannelMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>
>;
export type NotificationTopicsControllerUpdateChannelMutationBody = UpdateNotificationChannelRequest;
export type NotificationTopicsControllerUpdateChannelMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerUpdateChannel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>,
    TError,
    { teamCode: string; channelCode: string; data: UpdateNotificationChannelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateChannel>>,
  TError,
  { teamCode: string; channelCode: string; data: UpdateNotificationChannelRequest },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerUpdateChannelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerDeleteChannel = (
  teamCode: string,
  channelCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/notifications/channels/${teamCode}/${channelCode}`, method: "DELETE" },
    options
  );
};

export const getNotificationTopicsControllerDeleteChannelMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>,
    TError,
    { teamCode: string; channelCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>,
  TError,
  { teamCode: string; channelCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>,
    { teamCode: string; channelCode: string }
  > = (props) => {
    const { teamCode, channelCode } = props ?? {};

    return notificationTopicsControllerDeleteChannel(teamCode, channelCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerDeleteChannelMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>
>;

export type NotificationTopicsControllerDeleteChannelMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerDeleteChannel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>,
    TError,
    { teamCode: string; channelCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteChannel>>,
  TError,
  { teamCode: string; channelCode: string },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerDeleteChannelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerGetChannel = (
  teamCode: string,
  channelCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationChannelResponseDto>(
    { url: `/v1/notifications/channels/${teamCode}/${channelCode}`, method: "GET", signal },
    options
  );
};

export const getNotificationTopicsControllerGetChannelQueryKey = (teamCode: string, channelCode: string) => {
  return [`/v1/notifications/channels/${teamCode}/${channelCode}`] as const;
};

export const getNotificationTopicsControllerGetChannelQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  channelCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationTopicsControllerGetChannelQueryKey(teamCode, channelCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>> = ({ signal }) =>
    notificationTopicsControllerGetChannel(teamCode, channelCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && channelCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationTopicsControllerGetChannelQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>
>;
export type NotificationTopicsControllerGetChannelQueryError = ErrorType<unknown>;

export function useNotificationTopicsControllerGetChannel<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  channelCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetChannel<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  channelCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetChannel<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  channelCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useNotificationTopicsControllerGetChannel<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  channelCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetChannel>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getNotificationTopicsControllerGetChannelQueryOptions(teamCode, channelCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const notificationTopicsControllerGetAllChannelsForTeam = (
  teamCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationChannelResponseDto[]>(
    { url: `/v1/notifications/channels/${teamCode}/-all`, method: "GET", signal },
    options
  );
};

export const getNotificationTopicsControllerGetAllChannelsForTeamQueryKey = (teamCode: string) => {
  return [`/v1/notifications/channels/${teamCode}/-all`] as const;
};

export const getNotificationTopicsControllerGetAllChannelsForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationTopicsControllerGetAllChannelsForTeamQueryKey(teamCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>> = ({
    signal,
  }) => notificationTopicsControllerGetAllChannelsForTeam(teamCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationTopicsControllerGetAllChannelsForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>
>;
export type NotificationTopicsControllerGetAllChannelsForTeamQueryError = ErrorType<unknown>;

export function useNotificationTopicsControllerGetAllChannelsForTeam<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetAllChannelsForTeam<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetAllChannelsForTeam<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useNotificationTopicsControllerGetAllChannelsForTeam<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetAllChannelsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getNotificationTopicsControllerGetAllChannelsForTeamQueryOptions(teamCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Post a test message to a Notification Channel
 */
export const notificationTopicsControllerPostTestMessageToChannel = (
  teamCode: string,
  channelCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    { url: `/v1/notifications/channels/${teamCode}/${channelCode}/test`, method: "POST", signal },
    options
  );
};

export const getNotificationTopicsControllerPostTestMessageToChannelMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>,
    TError,
    { teamCode: string; channelCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>,
  TError,
  { teamCode: string; channelCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>,
    { teamCode: string; channelCode: string }
  > = (props) => {
    const { teamCode, channelCode } = props ?? {};

    return notificationTopicsControllerPostTestMessageToChannel(teamCode, channelCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerPostTestMessageToChannelMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>
>;

export type NotificationTopicsControllerPostTestMessageToChannelMutationError = ErrorType<unknown>;

/**
 * @summary Post a test message to a Notification Channel
 */
export const useNotificationTopicsControllerPostTestMessageToChannel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>,
    TError,
    { teamCode: string; channelCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerPostTestMessageToChannel>>,
  TError,
  { teamCode: string; channelCode: string },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerPostTestMessageToChannelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerCreateTopic = (
  teamCode: string,
  createTopicRequest: CreateTopicRequest,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationTopicResponseDto>(
    {
      url: `/v1/notifications/topics/${teamCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createTopicRequest,
      signal,
    },
    options
  );
};

export const getNotificationTopicsControllerCreateTopicMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>,
    TError,
    { teamCode: string; data: CreateTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>,
  TError,
  { teamCode: string; data: CreateTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>,
    { teamCode: string; data: CreateTopicRequest }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return notificationTopicsControllerCreateTopic(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerCreateTopicMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>
>;
export type NotificationTopicsControllerCreateTopicMutationBody = CreateTopicRequest;
export type NotificationTopicsControllerCreateTopicMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerCreateTopic = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>,
    TError,
    { teamCode: string; data: CreateTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerCreateTopic>>,
  TError,
  { teamCode: string; data: CreateTopicRequest },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerCreateTopicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerGetTopicsPaginated = (
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedTopicsResponse>(
    { url: `/v1/notifications/topics/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getNotificationTopicsControllerGetTopicsPaginatedQueryKey = (
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams
) => {
  return [`/v1/notifications/topics/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getNotificationTopicsControllerGetTopicsPaginatedQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationTopicsControllerGetTopicsPaginatedQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>> = ({
    signal,
  }) => notificationTopicsControllerGetTopicsPaginated(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationTopicsControllerGetTopicsPaginatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>
>;
export type NotificationTopicsControllerGetTopicsPaginatedQueryError = ErrorType<unknown>;

export function useNotificationTopicsControllerGetTopicsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | NotificationTopicsControllerGetTopicsPaginatedParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetTopicsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetTopicsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useNotificationTopicsControllerGetTopicsPaginated<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: NotificationTopicsControllerGetTopicsPaginatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetTopicsPaginated>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getNotificationTopicsControllerGetTopicsPaginatedQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const notificationTopicsControllerPostToTopic = (
  teamCode: string,
  topicId: number,
  postToTopicRequest: PostToTopicRequest,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/notifications/topics/${teamCode}/${topicId}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postToTopicRequest,
      signal,
    },
    options
  );
};

export const getNotificationTopicsControllerPostToTopicMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>,
    TError,
    { teamCode: string; topicId: number; data: PostToTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>,
  TError,
  { teamCode: string; topicId: number; data: PostToTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>,
    { teamCode: string; topicId: number; data: PostToTopicRequest }
  > = (props) => {
    const { teamCode, topicId, data } = props ?? {};

    return notificationTopicsControllerPostToTopic(teamCode, topicId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerPostToTopicMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>
>;
export type NotificationTopicsControllerPostToTopicMutationBody = PostToTopicRequest;
export type NotificationTopicsControllerPostToTopicMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerPostToTopic = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>,
    TError,
    { teamCode: string; topicId: number; data: PostToTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerPostToTopic>>,
  TError,
  { teamCode: string; topicId: number; data: PostToTopicRequest },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerPostToTopicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerGetNotificationTopic = (
  teamCode: string,
  topicId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationTopicResponseDto>(
    { url: `/v1/notifications/topics/${teamCode}/${topicId}`, method: "GET", signal },
    options
  );
};

export const getNotificationTopicsControllerGetNotificationTopicQueryKey = (teamCode: string, topicId: number) => {
  return [`/v1/notifications/topics/${teamCode}/${topicId}`] as const;
};

export const getNotificationTopicsControllerGetNotificationTopicQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  topicId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationTopicsControllerGetNotificationTopicQueryKey(teamCode, topicId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>> = ({
    signal,
  }) => notificationTopicsControllerGetNotificationTopic(teamCode, topicId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && topicId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationTopicsControllerGetNotificationTopicQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>
>;
export type NotificationTopicsControllerGetNotificationTopicQueryError = ErrorType<unknown>;

export function useNotificationTopicsControllerGetNotificationTopic<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  topicId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetNotificationTopic<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  topicId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationTopicsControllerGetNotificationTopic<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  topicId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useNotificationTopicsControllerGetNotificationTopic<
  TData = Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  topicId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof notificationTopicsControllerGetNotificationTopic>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getNotificationTopicsControllerGetNotificationTopicQueryOptions(teamCode, topicId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const notificationTopicsControllerUpdateTopic = (
  teamCode: string,
  topicId: number,
  updateTopicRequest: UpdateTopicRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/v1/notifications/topics/${teamCode}/${topicId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateTopicRequest,
    },
    options
  );
};

export const getNotificationTopicsControllerUpdateTopicMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>,
    TError,
    { teamCode: string; topicId: number; data: UpdateTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>,
  TError,
  { teamCode: string; topicId: number; data: UpdateTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>,
    { teamCode: string; topicId: number; data: UpdateTopicRequest }
  > = (props) => {
    const { teamCode, topicId, data } = props ?? {};

    return notificationTopicsControllerUpdateTopic(teamCode, topicId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerUpdateTopicMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>
>;
export type NotificationTopicsControllerUpdateTopicMutationBody = UpdateTopicRequest;
export type NotificationTopicsControllerUpdateTopicMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerUpdateTopic = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>,
    TError,
    { teamCode: string; topicId: number; data: UpdateTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerUpdateTopic>>,
  TError,
  { teamCode: string; topicId: number; data: UpdateTopicRequest },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerUpdateTopicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationTopicsControllerDeleteTopic = (
  teamCode: string,
  topicId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotificationTopicResponseDto>(
    { url: `/v1/notifications/topics/${teamCode}/${topicId}`, method: "DELETE" },
    options
  );
};

export const getNotificationTopicsControllerDeleteTopicMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>,
    TError,
    { teamCode: string; topicId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>,
  TError,
  { teamCode: string; topicId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>,
    { teamCode: string; topicId: number }
  > = (props) => {
    const { teamCode, topicId } = props ?? {};

    return notificationTopicsControllerDeleteTopic(teamCode, topicId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationTopicsControllerDeleteTopicMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>
>;

export type NotificationTopicsControllerDeleteTopicMutationError = ErrorType<unknown>;

export const useNotificationTopicsControllerDeleteTopic = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>,
    TError,
    { teamCode: string; topicId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTopicsControllerDeleteTopic>>,
  TError,
  { teamCode: string; topicId: number },
  TContext
> => {
  const mutationOptions = getNotificationTopicsControllerDeleteTopicMutationOptions(options);

  return useMutation(mutationOptions);
};
