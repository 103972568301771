import { FeatureResponseDto } from "@/api/generated";
import { Button } from "@/components/ui/button";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowDown, ArrowUp } from "lucide-react";
import { Link } from "react-router-dom";

export const featureFlagsColumns: ColumnDef<FeatureResponseDto>[] = [
  {
    accessorKey: "code",
    header: ({ column }) => {
      const isSorted = column.getIsSorted();

      return (
        <Button
          variant="ghost"
          className="px-0 bg-transparent"
          onClick={() => column.toggleSorting(isSorted === "asc")}
        >
          Code
          {isSorted && isSorted === "asc" ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <Link className="text-primary-600" to={row.original.code}>
          {row.original.code}
        </Link>
      );
    },
    meta: {
      className: {
        cell: "text-wrap",
      },
      customWidth: "auto",
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    meta: {
      customWidth: "70%",
    },
  },
];
