import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useAuth } from "@/auth/useAuth";
import { InvitationCard } from "@/lib/invitations/invitation-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from "react-router-dom";
import { useInvitationsControllerCheckInvite } from "@/api/generated";

export const AcceptInvite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { getLoggedInUser } = useAuth();

  const verificationToken = searchParams.get("inviteCode");

  const { data: invitation, isPending, isError } = useInvitationsControllerCheckInvite(verificationToken ?? "");

  if (!verificationToken || isError) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isPending) {
    return <LoadingSpinner message="Verifying..." />;
  }

  if (
    !invitation.acceptable &&
    invitation.notAcceptableReason &&
    invitation.notAcceptableReason.code === "USER_NOT_FOUND"
  ) {
    const state = {
      emailPrefill: invitation.email,
    };

    return <Navigate to={`/auth/register?inviteCode=${verificationToken}`} state={state} replace={true} />;
  }

  const userId = getLoggedInUser();

  if (!userId || invitation.email !== userId) {
    const state = {
      emailPrefill: invitation.email,
      redirect: `/accept-invite?inviteCode=${verificationToken}`,
    };
    return <Navigate to="/auth" state={state} replace={true} />;
  }

  return (
    <>
      {!invitation.acceptable && (
        <div className="neu rounded-lg bg-white p-4">
          <div className="space-y-4">
            <div className="flex flex-col items-center gap-2">
              <h4 className="font-semibold text-lg">Oops! This invitation is not valid.</h4>
              <p className="text-sm text-gray-600">
                {invitation.notAcceptableReason
                  ? invitation.notAcceptableReason.description
                  : "Contact the team owner for a new invitation."}
              </p>
            </div>
            <div className="flex justify-center gap-2">
              <p className="text-sm text-gray-700">Back to Homepage?</p>
              <Link to="/dashboard" className="underline hover:cursor-pointer text-secondary-950">
                <p className="text-sm text-gray-700">Home</p>
              </Link>
            </div>
          </div>
        </div>
      )}

      {invitation.acceptable && (
        <Card className="w-full max-w-xl">
          <CardHeader>
            <CardTitle className="font-semibold text-base">
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 items-center">
                    <h3 className="text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">
                      You have been invited
                    </h3>
                    <div className="text-4xl lg:text-5xl">🚀</div>
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-gray-700">Join the team</h3>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-8">
              <InvitationCard invitation={invitation} />
              <div className="flex justify-center gap-2">
                <p className="text-sm text-gray-700">Back to Homepage?</p>
                <Link to="/dashboard" className="underline hover:cursor-pointer text-secondary-950">
                  <p className="text-sm text-gray-700">Home</p>
                </Link>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};
