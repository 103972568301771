import { Label } from "@/components/ui/label";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { useOutletContext } from "react-router-dom";
import { SettingsOutletContext } from "@/layouts/settings-layout";

export const Profile: React.FC = () => {
  const { user } = useOutletContext<SettingsOutletContext>();

  return (
    <div className="space-y-6 px-4">
      <div>
        <div className="flex justify-between items-center">
          <h5 className="font-semibold tracking-tight">Personal Profile</h5>
          <Badge variant="secondary" className="uppercase">
            {user.orgName}
          </Badge>
        </div>
        <small>Update your profile settings.</small>
      </div>
      <div className="flex flex-col gap-4 max-w-[600px]">
        <div>
          <div className="flex flex-col gap-4">
            <div className="">
              <Label htmlFor="name">Name</Label>
              <Input id="name" className="col-span-3" value={user.userFullName} readOnly />
            </div>
            <div>
              <Label htmlFor="email_address">Email Address</Label>
              <Input id="email_address" className="col-span-3" value={user.email} readOnly />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
