import { formatDistanceToNow } from "date-fns";
import React from "react";

interface CreatedUpdatedFormatProps {
  value: {
    createdAt?: string;
    lastModifiedAt?: string;
  };
}

export const CreatedUpdatedFormat: React.FC<CreatedUpdatedFormatProps> = ({ value: { createdAt, lastModifiedAt } }) => {
  if (createdAt === lastModifiedAt) {
    if (!createdAt) {
      return <></>;
    }

    return <div>Created {formatDistanceToNow(createdAt)} ago</div>;
  }

  if (!lastModifiedAt) {
    return <></>;
  }

  return <div>Edited {formatDistanceToNow(lastModifiedAt)} ago</div>;
};
