import { WebhookReceivedRequestInfoDto } from "@/api/generated";
import { WebhookDetails } from "@/old/api/types/response";

export type SortOption = Extract<keyof WebhookDetails, "method" | "path" | "date">;
export type GroupByOption = Extract<keyof WebhookDetails, "method" | "path" | "date">;

export class WebhookService {
  // TODO: sortOptions and groupByOptions list must be exactly the same as the ones in the WebhookDetails type
  static sortOptions: SortOption[] = ["method", "path", "date"];
  static groupByOptions: GroupByOption[] = ["method", "path", "date"];

  static sortBy<T extends keyof WebhookDetails>(
    receivedRequests: WebhookReceivedRequestInfoDto[],
    key: T,
    ascending: boolean = true
  ): WebhookReceivedRequestInfoDto[] {
    return receivedRequests.sort((a, b) => {
      let valueA = a[key] as string | number | Date;
      let valueB = b[key] as string | number | Date;

      if (key === "date") {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        return ascending ? valueA - valueB : valueB - valueA;
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return ascending ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
      }
      return 0;
    });
  }

  static groupBy<T extends keyof WebhookDetails>(
    receivedRequests: WebhookReceivedRequestInfoDto[],
    key: T
  ): Record<string, WebhookReceivedRequestInfoDto[]> {
    const grouped: Record<string, WebhookReceivedRequestInfoDto[]> = {};

    receivedRequests.forEach((request) => {
      const value = request[key];

      if (!grouped[value as string]) {
        grouped[value as string] = [];
      }
      grouped[value as string].push(request);
    });

    return grouped;
  }

  static sortAndGroup<T extends keyof WebhookDetails>(
    receivedRequests: WebhookReceivedRequestInfoDto[],
    sortKey: T,
    ascending: boolean,
    groupKey: T
  ): Record<string, WebhookReceivedRequestInfoDto[]> {
    const sorted = WebhookService.sortBy(receivedRequests, sortKey, ascending);
    const grouped = WebhookService.groupBy(sorted, groupKey);
    // Combine sorting and grouping
    for (const key in grouped) {
      if (Object.prototype.hasOwnProperty.call(grouped, key)) {
        grouped[key] = WebhookService.sortBy(grouped[key], sortKey, ascending);
      }
    }
    return grouped;
  }
}
