import {
  getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey,
  useHyperlinksHyperlinksControllerDeleteHyperlinks,
  useHyperlinksHyperlinksControllerGetHyperlinksForProject,
} from "@/api/generated";
import { EnvHeader } from "@/components/env-header";
import { LoadingSpinner } from "@/components/loading-spinner";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { AddHyperlinkDialog } from "@/lib/projects/hyperlinks/add-hyperlink-dialog";
import { HyperlinksActionCell } from "@/lib/projects/hyperlinks/hyperlinks-action-cell";
import { HyperlinkEnvCell } from "@/lib/projects/hyperlinks/hyperlinks-cells";
import { hyperlinksColumns } from "@/lib/projects/hyperlinks/hyperlinks-config";
import { HyperlinksContextProvider } from "@/lib/projects/hyperlinks/hyperlinks-context";
import { PresignHyperlinksDialog } from "@/lib/projects/hyperlinks/presign-hyperlinks-dialog";
import { Fixed__ProjectHyperlinkResponseDto } from "@/lib/projects/item/project-items-helpers";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Activity, LinkIcon } from "lucide-react";
import React from "react";
import { Link, Navigate } from "react-router-dom";

export const Hyperlinks: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode, projectEnvironments } = useCurrentProject();

  const pagination = usePagination();
  const {
    isPending,
    isFetching,
    isError,
    data: links,
    refetch,
  } = useHyperlinksHyperlinksControllerGetHyperlinksForProject(teamCode, projectCode, pagination.params);

  const environmentsColumns: ColumnDef<Fixed__ProjectHyperlinkResponseDto>[] = React.useMemo(() => {
    return projectEnvironments.map((e) => {
      return {
        header: () => <EnvHeader environment={e} />,
        accessorKey: `values.${e.code}.value`,
        cell: (args) => <HyperlinkEnvCell environment={e} {...args} />,
        minSize: 0,
        meta: {
          customWidth: "unset",
          className: {
            header: "p-0",
          },
        },
      } satisfies ColumnDef<Fixed__ProjectHyperlinkResponseDto>;
    });
  }, [projectEnvironments]);

  const columns: ColumnDef<Fixed__ProjectHyperlinkResponseDto>[] = React.useMemo(() => {
    return [
      ...hyperlinksColumns,
      ...environmentsColumns,
      {
        id: "$add-column",
        header: "Actions",
        cell: HyperlinksActionCell,
        minSize: 128,
        size: 128,
        maxSize: 128,
        meta: {
          className: {
            header: "text-center font-bold",
          },
        },
      },
    ];
  }, [environmentsColumns]);

  const queryClient = useQueryClient();

  const deleteMutation = useHyperlinksHyperlinksControllerDeleteHyperlinks({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey(teamCode, projectCode),
        });
      },
      onError: onErrorToast,
    },
    request: {
      paramsSerializer: {
        indexes: null,
      },
    },
  });

  return (
    <FeaturePage
      title="Hyperlinks"
      description="Manage your project's hyperlinks. Hyperlinks are links to external resources that can be used in your project's configuration files."
      isLoading={isPending || isFetching}
      actions={
        <div className="flex items-center gap-2">
          <PresignHyperlinksDialog teamCode={teamCode} projectCode={projectCode}>
            <Button size="icon" className="bg-secondary-200 w-9 h-9">
              <LinkIcon size={16} />
            </Button>
          </PresignHyperlinksDialog>

          <Button asChild className="no-underline bg-secondary-200">
            <Link to={`/dashboard/${teamCode}/${projectCode}/monitoring`}>
              <Activity className="mr-2 size-4" />
              System Health
            </Link>
          </Button>
        </div>
      }
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}

      {isError && <Navigate to="/404" replace={true} />}

      <HyperlinksContextProvider>
        {!isPending && !isError && links && (
          <>
            <ManagerTable
              columns={columns}
              data={links.results as Fixed__ProjectHyperlinkResponseDto[]}
              searchBy="code"
              onChange={(rows) => {
                deleteMutation.mutate({
                  teamCode,
                  projectCode,
                  params: {
                    itemCodes: rows.map((row) => row.original.code),
                  },
                });
              }}
            />

            <AddHyperlinkDialog onSuccess={() => refetch()}>
              <Button className="bg-secondary-200">Create Hyperlink</Button>
            </AddHyperlinkDialog>

            <Pagination className="pt-4" pagination={pagination} numberOfPages={links?.numberOfPages} />
          </>
        )}
      </HyperlinksContextProvider>
    </FeaturePage>
  );
};
