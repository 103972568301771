import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { format } from "date-fns";
import { AlertTriangle, Calendar, ChevronDown, ChevronUp, Fingerprint, ShieldCheck, User } from "lucide-react";
import React from "react";

type CertificateDetails = {
  issuer: string;
  fingerprint: string;
  expiryDate: string;
  pemCertificate: string;
  validationErrors?: string[];
};

interface DomainCertRunDetailsProps {
  rawData: any;
}

export const DomainCertRunDetails: React.FC<DomainCertRunDetailsProps> = ({ rawData }) => {
  const certificateDetails = rawData as CertificateDetails;
  const [isPemExpanded, setIsPemExpanded] = React.useState(false);
  const [isErrorsExpanded, setIsErrorsExpanded] = React.useState(false);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg font-semibold leading-none tracking-tight">Domain Certificate</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Issuer</h2>
          <p className="font-mono break-all flex items-center">
            <User className="inline mr-2 h-5 w-5" />
            {certificateDetails.issuer}
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Fingerprint</h2>
          <p className="font-mono break-all flex items-center">
            <Fingerprint className="inline mr-2 h-5 w-5 flex-shrink-0" />
            <span className="break-all">{certificateDetails.fingerprint}</span>
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Expiry Date</h2>
          <p className="font-mono break-all flex items-center">
            <Calendar className="inline mr-2 h-5 w-5" />
            {format(new Date(certificateDetails.expiryDate), "PPpp")}
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Status</h2>
          <Badge
            variant={certificateDetails.validationErrors?.length ? "destructive" : "default"}
            className="text-sm py-1 px-2"
          >
            {certificateDetails.validationErrors?.length ? (
              <>
                <AlertTriangle className="mr-1 h-4 w-4" />
                Invalid
              </>
            ) : (
              <>
                <ShieldCheck className="mr-1 h-4 w-4" />
                Valid
              </>
            )}
          </Badge>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-medium text-gray-500">PEM Certificate</h2>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsPemExpanded(!isPemExpanded)}
              className="text-gray-500 hover:text-gray-900 h-5"
            >
              {isPemExpanded ? (
                <>
                  <ChevronUp className="h-4 w-4 mr-1" />
                  Collapse
                </>
              ) : (
                <>
                  <ChevronDown className="h-4 w-4 mr-1" />
                  Expand
                </>
              )}
            </Button>
          </div>
          {isPemExpanded && (
            <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm">
              {certificateDetails.pemCertificate}
            </pre>
          )}
        </div>

        {certificateDetails.validationErrors && certificateDetails.validationErrors.length > 0 && (
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-medium text-gray-500">Validation Errors</h2>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsErrorsExpanded(!isErrorsExpanded)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {isErrorsExpanded ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Collapse
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    Expand
                  </>
                )}
              </Button>
            </div>
            {isErrorsExpanded && (
              <ul className="list-disc list-inside space-y-1 text-red-600">
                {certificateDetails.validationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
