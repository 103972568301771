import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { SidebarInset, SidebarTrigger } from "@/components/ui/sidebar";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { MainContentLayout } from "@/layouts/elements/main-content-layout.tsx";
import { UserDropdown } from "@/layouts/elements/user-dropdown";

export const DashboardLayoutContent: React.FC = () => {
  const breadcrumb = window.location.pathname.split("/").filter((item) => item !== "");

  const Header: React.FC = () => (
    <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-primary-300/90 border-b-[2px] border-secondary-950">
      <div className="flex justify-between px-4 w-full">
        <div className="flex items-center gap-2">
          <SidebarTrigger className="-ml-1 hover:bg-transparent size-4" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {breadcrumb.length > 1 && (
                <>
                  <BreadcrumbItem className="hidden md:block">
                    <BreadcrumbLink asChild>
                      <Button
                        asChild
                        variant="ghost"
                        size="sm"
                        className="bg-transparent no-underline hover:underline p-0"
                      >
                        <Link to={`/${breadcrumb.slice(0, breadcrumb.length - 1).join("/")}`}>
                          {breadcrumb[breadcrumb.length - 2]}
                        </Link>
                      </Button>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </>
              )}

              <BreadcrumbSeparator className="hidden md:block" />
              <BreadcrumbItem>
                <BreadcrumbPage>{breadcrumb[breadcrumb.length - 1]}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>

        <div>
          <UserDropdown />
        </div>
      </div>
    </header>
  );

  return (
    <SidebarInset className="h-dvh max-h-dvh">
      <Header />
      <MainContentLayout>
        <Outlet />
      </MainContentLayout>
    </SidebarInset>
  );
};
