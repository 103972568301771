import {
  getTeamsControllerGetTeamQueryKey,
  useTeamsControllerGetTeam,
  useTeamsControllerUpdateTeam,
} from "@/api/generated";
import { TextField, TextFieldBase } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { TeamIcon } from "@/components/project-icon";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { cn } from "@/utils/ui.util";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Controller } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { z } from "zod";

const updateTeamSchema = z.object({
  name: z.string().min(1, { message: "Team name is required" }).max(250),
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  iconUrl: z.optional(z.string().max(512, "URL can be at most 512 characters long.")),
});

export const UpdateTeam: React.FC = () => {
  const { toast } = useToast();

  const { teamCode } = useCurrentTeam();

  const { data: team, isPending, isError } = useTeamsControllerGetTeam(teamCode);

  const queryClient = useQueryClient();

  const updateTeamMutation = useTeamsControllerUpdateTeam({
    mutation: {
      onSuccess: () => {
        toast({ title: "Success", description: "Team updated successfully" });

        queryClient.invalidateQueries({
          queryKey: getTeamsControllerGetTeamQueryKey(teamCode),
        });
      },
      onError: (error) => {
        const message = (error.response?.data as { message?: string })?.message;

        if (message) {
          toast({ title: "Oops!", description: message });
        } else {
          toast(defaultError);
        }
      },
    },
  });

  const onSubmit = (values: z.infer<typeof updateTeamSchema>) => {
    updateTeamMutation.mutate({
      teamCode: teamCode,
      data: {
        name: values.name,
        code: values.code,
        iconUrl: values.iconUrl,
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <div>
          <h4 className="font-semibold text-lg">Team Info</h4>
          <p className="text-sm text-gray-600">Update your team settings here.</p>
        </div>
      </CardHeader>
      <CardContent className="grid gap-6">
        <>
          {isPending && (
            <div className="flex items-center justify-center w-full">
              <LoadingSpinner message={"Loading..."} />
            </div>
          )}
          {isError && <Navigate to="/404" replace={true} />}
          {!isPending && !isError && team && (
            <>
              <Form
                schema={updateTeamSchema}
                onSubmit={onSubmit}
                defaultValues={{ code: team.code, name: team.name, iconUrl: team.iconUrl ?? undefined }}
              >
                <div className="flex flex-col gap-4">
                  <div className="space-y-1">
                    <TextField name="name" label="Team Name" placeholder="Input team name..." />
                  </div>
                  <div className="space-y-1">
                    <TextField name="code" label="Team Code" placeholder="Input team code..." />
                  </div>

                  <Controller
                    name={"iconUrl"}
                    render={({ field }) => {
                      return (
                        <div
                          className={cn(
                            "grid items-end gap-4 grid-cols-1",
                            field.value && "grid-cols-[1fr,min-content]"
                          )}
                        >
                          <div>
                            <TextFieldBase label="Icon URL" {...field} />
                          </div>

                          {field.value && <TeamIcon url={field.value} />}
                        </div>
                      );
                    }}
                  />

                  <div className="flex justify-end mt-4">
                    <Button
                      type="submit"
                      size="sm"
                      className="bg-secondary-300"
                      disabled={updateTeamMutation.isPending}
                    >
                      {updateTeamMutation.isPending ? <LoadingSpinner message={"Updating"} /> : "Update"}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </>
      </CardContent>
    </Card>
  );
};
