import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/utils/ui.util";
import { ArrowRight, BellRing, Lock, LucideIcon, Mail, PencilRuler, Webhook } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";

export type ToolboxItem = {
  name: string;
  description: string;
  icon: LucideIcon;
  path: string;
  tags?: string[];
  superscript?: {
    text: string;
    className: string;
  };
  disabled?: boolean;
  authRequired?: boolean;
  hidden?: boolean;
};

const toolbox: ToolboxItem[] = [
  {
    name: "Notification Topics",
    description: "Create notification topics which can be used to send notifications across your systems.",
    icon: BellRing,
    path: "/notifications/topics",
    tags: ["Notifications"],
  },
  {
    name: "Tools",
    description: "Parse and inspect data from your systems.",
    icon: PencilRuler,
    path: "/tools",
    tags: ["Transformations"],
    superscript: {
      text: "Beta",
      className: "bg-secondary-950 hover:bg-secondary-950",
    },
  },
  {
    name: "Webhooks",
    description: "Monitor, test, and debug your system's webhook responses and payloads in real-time.",
    icon: Webhook,
    path: "/webhooks",
    tags: ["Testing", "Monitoring"],
  },
  {
    name: "Emails",
    description: "Generate email addresses to test your email templates, and inspect the data sent.",
    icon: Mail,
    path: "/emails",
    tags: ["Testing", "Template Validation"],

    disabled: true,
  },
];

export const TeamToolbox: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  return (
    <div className="space-y-4">
      <div>
        <h4 className="font-bold tracking-tight">Platform</h4>
        <p className="text-sm text-gray-600">Explore and manage tools within this team.</p>
      </div>
      <div className="flex flex-col gap-2 2xl:gap-6">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {toolbox.map((item, index) => {
            if (item.hidden) return null;

            return (
              <Card
                key={index}
                className={cn("w-full flex flex-col relative overflow-hidden", item.disabled && "opacity-70")}
              >
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <h4 className="font-semibold text-lg">{item.name}</h4>
                      {item.superscript && (
                        <sup>
                          <Badge className={cn("p-0 px-1", item.superscript.className)}>{item.superscript.text}</Badge>
                        </sup>
                      )}
                    </div>
                    <item.icon size={20} />
                  </div>
                </CardHeader>
                <CardContent className="flex flex-grow flex-col">
                  <p className="text-sm text-gray-600 mb-4">{item.description}</p>
                  <Button asChild size="sm" variant="neu" className="bg-secondary-300 self-start mt-auto">
                    <Link to={!item.disabled ? `/dashboard/${teamCode}${item.path}` : "#"} className="no-underline">
                      {item.authRequired && <Lock size={16} className="mr-1" />} Launch {item.name}{" "}
                      {!item.authRequired && <ArrowRight size={16} className="ml-1" />}
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
