import {
  FilesResponseDtoResultsItem,
  ListFileResponseDto,
  ListPrefixResponseDto,
  useFilesControllerGetFileDownloadLink,
} from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { formatBytes } from "@/helpers/helpers";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { cn } from "@/utils/ui.util";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Download, FolderInput } from "lucide-react";
import { Link } from "react-router-dom";

const formatFileSize = (size: number): React.ReactNode => (size > 0 ? formatBytes(size) : <span>&ndash;</span>);

// eslint-disable-next-line react-refresh/only-export-components
const FileDownloadCell: React.FC<{ fileKey: string }> = ({ fileKey }) => {
  const { teamCode } = useCurrentTeam();
  const { isLoading, isError, data: downloadData } = useFilesControllerGetFileDownloadLink(teamCode, {path: fileKey});

  const downloadUrl = downloadData?.url;

  if (!downloadUrl) {
    return (
      <div
        className={cn(
          "flex items-center gap-2 truncate",
          isError && "text-red-600",
          isLoading && "text-secondary-950/60"
        )}
      >
        {isLoading && <LoadingSpinner size={16} />}
        {!isLoading && <Download size={16} />}
        {fileKey}
      </div>
    );
  }

  return (
    <a
      className="flex items-center gap-2 truncate"
      aria-label={`Download '${fileKey}'`}
      href={downloadUrl}
      download={fileKey}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Download size={16} />
      {fileKey}
    </a>
  );
};

export const filesColumns: ColumnDef<FilesResponseDtoResultsItem>[] = [
  {
    header: "Name",
    accessorKey: "prefix",
    cell: ({ row }) => {
      const item = row.original;
      const isPrefix = item.type === "prefix";

      return (
        <div className="flex gap-2 items-center">
          {isPrefix ? (
            <>
              <FolderInput size={16} />
              <Link to="#">{(item as ListPrefixResponseDto).prefix}</Link>
            </>
          ) : (
            <FileDownloadCell fileKey={(item as ListFileResponseDto).key} />
          )}
        </div>
      );
    },
    meta: { customWidth: "auto" },
  },
  {
    header: "Type",
    accessorKey: "type",
    cell: ({ row }) => (row.original.type === "prefix" ? "Folder" : "File"),
    meta: { customWidth: "auto" },
  },
  {
    header: "Size",
    accessorKey: "size",
    cell: ({ row }) => {
      const item = row.original;
      return item.type === "prefix" ? <span>&ndash;</span> : formatFileSize((item as ListFileResponseDto).size);
    },
    meta: { customWidth: "auto" },
  },
  {
    header: "Last Modified",
    accessorKey: "lastModified",
    cell: ({ row }) => {
      const item = row.original;
      return item.type === "prefix" ? (
        <span>&ndash;</span>
      ) : (
        format((item as ListFileResponseDto).lastModified, "yyyy-MM-dd HH:mm") || <span>&ndash;</span>
      );
    },
    meta: { customWidth: "auto" },
  },
];
