import { Outlet } from "react-router-dom";

export const SecondaryLayout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-dvh bg-secondary-200">
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        <Outlet />
      </div>
    </div>
  );
};
