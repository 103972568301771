import { objectKeys } from "@/utils";
import { ENV_COLORS } from "@/utils/colors/color-definitions";
import React from "react";

const ENV_COLORS_KEYS = objectKeys(ENV_COLORS);

export const DesignRoute: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 p-6">
      <h1>Go away this is not for you!</h1>

      <EnvColors />
    </div>
  );
};

export const EnvColors: React.FC = () => {
  const [background, setBackground] = React.useState("#ffffff");

  return (
    <>
      <div className="flex items-center justify-between">
        <h3>Env Colors</h3>
        <input type="color" value={background} onChange={(e) => setBackground(e.target.value)} />
      </div>
      <div
        className="grid grid-cols-3 gap-x-2 gap-y-1"
        style={{
          backgroundColor: background,
        }}
      >
        {ENV_COLORS_KEYS.map((key) => {
          const color = ENV_COLORS[key];
          return (
            <React.Fragment key={`color-${key}`}>
              <div
                className="px-2 py-1"
                style={{
                  color: color.fg,
                  backgroundColor: color.bg,
                }}
              >
                {key}
              </div>

              <div
                className="px-2 py-1"
                style={{
                  color: color.fg,
                }}
              >
                Foreground
              </div>
              <div
                className="px-2 py-1"
                style={{
                  backgroundColor: color.bg,
                }}
              >
                Background
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
