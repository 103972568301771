import { InvitationResponseDto, useInvitationsControllerAcceptInvite } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { format } from "date-fns";
import { useSwitchTeam } from "@/lib/teams/hooks/use-switch-team";

interface InvitationCardProps {
  invitation: InvitationResponseDto;
}

export const InvitationCard: React.FC<InvitationCardProps> = ({ invitation }) => {
  const { toast } = useToast();
  const { switchTeam } = useSwitchTeam();

  const acceptInvitationMutation = useInvitationsControllerAcceptInvite();

  const onSubmit = () => {
    acceptInvitationMutation.mutate(
      {
        inviteCode: invitation.code,
      },
      {
        onSuccess: () => {
          if (!invitation.teamCode) {
            // Fix this if invitation is for organization
            toast({ title: "Success!", description: "Successfully accepted invitation." });
            return;
          }

          switchTeam(invitation.teamCode);
        },
        onError: () => {
          toast(defaultError);
        },
      }
    );
  };

  return (
    <div className="neu flex justify-between items-center bg-secondary-200 rounded-lg px-4 py-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-semibold">{invitation.teamCode}</h3>
        </div>
        <p className="text-sm text-gray-600">Expires: {format(new Date(invitation.expiresAt), "MMM dd yyyy, HH:mm")}</p>
      </div>

      <div className="flex gap-2">
        <Button
          size="sm"
          className="bg-primary-300 hover:bg-primary-400"
          disabled={acceptInvitationMutation.isPending}
          onClick={onSubmit}
        >
          {acceptInvitationMutation.isPending ? <LoadingSpinner message={"Accepting"} /> : "Accept"}
        </Button>
      </div>
    </div>
  );
};
