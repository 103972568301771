import { useProjectsControllerCreateEnvironment } from "@/api/generated";
import { ColorSelect } from "@/components/color-select";
import { FieldError } from "@/components/field-error";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { TextField } from "@/components/form/elements/text-field";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { onErrorToast } from "@/utils/api.util";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useCurrentProject } from "../context/project-context";

export const EnvironmentAddSchema = z.object({
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.optional(z.string()),
  color: z.optional(z.string()),
});

export type EnvironmentAddSchemaInputs = z.infer<typeof EnvironmentAddSchema>;

interface AddEnvironmentDialogProps {
  teamCode: string;
  projectCode: string;
  onSuccess?: () => void;
  children: React.ReactNode;
}

export const AddEnvironmentDialog: React.FC<AddEnvironmentDialogProps> = ({ children, onSuccess, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Create new environment</DialogTitle>
        <DialogDescription>
          Enter the details for the new environment. This will be used to manage your project's configurations.
        </DialogDescription>

        <AddEnvironment
          onSuccess={() => {
            setOpen(false);
            onSuccess?.();
          }}
          {...props}
        />
      </DialogContent>
    </Dialog>
  );
};

const AddEnvironment: React.FC<Omit<AddEnvironmentDialogProps, "children">> = ({
  teamCode,
  projectCode,
  onSuccess,
}) => {
  const { refetch } = useCurrentProject();

  const form = useForm<EnvironmentAddSchemaInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(EnvironmentAddSchema),
    defaultValues: {
      code: "",
      description: undefined,
      color: undefined,
    },
  });

  const editMutation = useProjectsControllerCreateEnvironment({
    mutation: {
      onSuccess: () => {
        form.reset();

        refetch();

        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const onSubmit: SubmitHandler<EnvironmentAddSchemaInputs> = (values) => {
    editMutation.mutate({
      teamCode,
      projectCode,
      data: {
        code: values.code,
        description: values.description,
        color: values.color,
        sortIndex: 1, // Fix this
      },
    });
  };

  const colorValue = form.watch("color");
  const envColor = React.useMemo(() => parseEnvColor(colorValue), [colorValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <div>
          <TextField
            label="Code"
            name="code"
            style={{
              color: envColor?.fg,
            }}
          />
        </div>

        <div>
          <TextAreaField
            label="Description"
            name="description"
            style={{
              background: envColor?.bg,
            }}
          />
        </div>

        <div className="space-y-1">
          <Label className="text-sm font-semibold mb-2">Color</Label>

          <Controller
            name="color"
            control={form.control}
            render={({ field }) => <ColorSelect value={field.value} onValueChange={(color) => field.onChange(color)} />}
          />

          <FieldError errors={form.formState.errors} name="color" />
        </div>

        <div className="flex justify-end mt-4">
          <Button
            variant="neu-flat"
            type="submit"
            disabled={!form.formState.isDirty}
            loading={editMutation.isPending}
            className="bg-tertiary-200"
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
