import {
  CreateSecretDtoEnvValues,
  EnvironmentMiniResponseDto,
  useSecretsSecretsControllerUpdateSecret,
} from "@/api/generated";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useCurrentProject } from "../context/project-context";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { EditEnvSecret, keyValueFromArray, keyValueToArray, SecretsEditorValuesSchema } from "./edit-secret-dialog";

interface EditEnvSecretDialogProps extends EditorValuesProps {
  children: React.ReactNode;
}

export const EditEnvSecretDialog: React.FC<EditEnvSecretDialogProps> = ({ item, environment, children, onSuccess }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="max-w-4xl min-h-[40vh] grid grid-rows-[min-content,1fr] gap-y-8 overflow-hidden max-h-screen"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Edit Secret "{item.code}"</DialogTitle>

        <EditorValues
          item={item}
          environment={environment}
          onSuccess={() => {
            setOpen(false);
            onSuccess?.();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export type SecretsEditorValuesInputs = z.infer<typeof SecretsEditorValuesSchema>;

interface EditorValuesProps {
  item: Fixed__ProjectSecretResponseDto;
  environment: EnvironmentMiniResponseDto;
  onSuccess?: () => void;
}

const EditorValues: React.FC<EditorValuesProps> = ({ item, environment, onSuccess }) => {
  const { projectCode } = useCurrentProject();
  const { teamCode } = useCurrentTeam();

  const updateMutation = useSecretsSecretsControllerUpdateSecret({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const form = useForm<SecretsEditorValuesInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SecretsEditorValuesSchema),
    disabled: updateMutation.isPending,
    defaultValues: {
      envValues: objectKeys(item?.envValues ?? {}).reduce(
        (p, key) => {
          p[key] = keyValueToArray(item?.envValues?.[key] ?? {});
          return p;
        },
        {} as Record<string, Array<{ key: string; value: string }>>
      ),
    },
  });

  const onSubmit: SubmitHandler<SecretsEditorValuesInputs> = (data) => {
    updateMutation.mutate({
      teamCode,
      projectCode,
      data: {
        envValues: objectKeys(data.envValues)
          .filter((envCode) => Boolean(data.envValues[envCode]) && data.envValues[envCode]?.length)
          .reduce((p, envCode) => {
            p[envCode] = keyValueFromArray(data.envValues[envCode] ?? []);

            return p;
          }, {} as CreateSecretDtoEnvValues),
      },
      itemCode: item.code,
    });
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="grid grid-rows-[min-content,1fr,min-content] grid-cols-1 gap-2 justify-between overflow-hidden max-h-full"
    >
      <p>
        for <strong className="text-secondary-600">{environment.code}</strong> environment
      </p>
      <div className="overflow-hidden">
        <EditEnvSecret
          env={environment.code}
          item={item}
          onChange={(v) => {
            form.setValue(`envValues.[${environment.code}]`, v);
          }}
        />
      </div>

      <div className="flex justify-end mt-4">
        <Button variant="neu-flat" type="submit" className="bg-tertiary-200">
          Save
        </Button>
      </div>
    </form>
  );
};
