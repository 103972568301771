import { Card, CardContent, CardHeader } from "@/components/ui/card";
import React from "react";

import { useInvitationsControllerGetAllForMe } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { InvitationCard } from "@/lib/invitations/invitation-card";

export const InvitationsList: React.FC = () => {
  const { isPending, isError, data: invitations } = useInvitationsControllerGetAllForMe();

  return (
    <Card>
      <CardHeader>
        <div>
          <h4 className="font-semibold text-lg">Team Invitations</h4>
          <p className="text-sm text-gray-600">Accept invitations to join teams.</p>
        </div>
      </CardHeader>
      <CardContent className="grid gap-4">
        <>
          {isPending && (
            <div className="flex items-center justify-center w-full">
              <LoadingSpinner message={"Loading..."} />
            </div>
          )}
          {isError && <Navigate to="/404" replace={true} />}
          {!isPending && !isError && invitations && (
            <>
              {invitations.results.length === 0 && (
                <div className="flex items-center justify-center w-full">
                  <p className="text-sm text-gray-600">No invitations found.</p>
                </div>
              )}

              {invitations.results.length > 0 &&
                invitations.results.map((invitation) => <InvitationCard invitation={invitation} />)}
            </>
          )}
        </>
      </CardContent>
    </Card>
  );
};
