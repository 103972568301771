import { ScrollArea } from "@/components/ui/scroll-area";
import { HeadersTable } from "@/components/headers-table";
import { RequestHeader } from "@/lib/webhooks/request/request-header";
import { Separator } from "@/components/ui/separator";
import { RequestHelper } from "@/utils/parsing/utils/request-helper";
import prettyBytes from "pretty-bytes";

interface RequestDetailsProps {
  requestHelper: RequestHelper;
}

export const RequestDetails: React.FC<RequestDetailsProps> = ({ requestHelper }) => {
  const requestDetailsFields = {
    method: requestHelper.getMethod(),
    path: requestHelper.getPath(),
    host: requestHelper.getSource(),
    time: requestHelper.getTime(),
    id: requestHelper.getRequestId(),
    size: prettyBytes(requestHelper.getBodyLength()),
  };

  return (
    <ScrollArea className="h-full max-w-full">
      <RequestHeader
        requestId={requestDetailsFields.id}
        method={requestDetailsFields.method}
        path={requestDetailsFields.path}
        requestHelper={requestHelper}
      />
      <Separator />
      <HeadersTable title={"Request Details"} headers={requestDetailsFields} type="custom" />
      {requestHelper.hasQueryParams() && (
        <HeadersTable title={"Query Parameters"} headers={requestHelper.getQueryParams()} type="query-params" />
      )}
    </ScrollArea>
  );
};
