/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */

export type FeatureConditionalValueDtoConditionsItemOperation =
  (typeof FeatureConditionalValueDtoConditionsItemOperation)[keyof typeof FeatureConditionalValueDtoConditionsItemOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureConditionalValueDtoConditionsItemOperation = {
  startsWith: "startsWith",
  endsWith: "endsWith",
  contains: "contains",
  equals: "equals",
  greaterThan: "greaterThan",
  lessThan: "lessThan",
  greaterThanOrEqual: "greaterThanOrEqual",
  lessThanOrEqual: "lessThanOrEqual",
} as const;
