import {
  ProjectHyperlinkMonitorResultsByHyperlinksResponseDto,
  SingleHyperlinkWithEnvironmentsMonitorDto,
  useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments,
} from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { ManagerTable } from "@/components/manager-table";
import { Button } from "@/components/ui/button";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { constructHyperlinkMonitoringColumns } from "@/lib/projects/monitoring/hyperlink-monitor/hyperlink-monitoring-columns";
import { MonitorDisplay } from "@/lib/projects/monitoring/monitor-display";
import { MonitorSelect } from "@/lib/projects/monitoring/monitor-select";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { LinkIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { Navigate, useParams } from "react-router-dom";

export const HyperlinkMonitor: React.FC = () => {
  const { hyperlinkCode } = useParams<{ hyperlinkCode: string }>();
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  if (!hyperlinkCode) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <FeaturePage
      title={hyperlinkCode}
      description={
        <>
          Health of the <span className="font-semibold">{hyperlinkCode}</span> hyperlink.
        </>
      }
      actions={
        <Button asChild className="no-underline bg-secondary-200">
          <Link to={`/dashboard/${teamCode}/${projectCode}/hyperlinks`}>
            <LinkIcon className="mr-2 size-4" />
            Edit Hyperlinks
          </Link>
        </Button>
      }
    >
      <MonitorDetails hyperlinkCode={hyperlinkCode} />
    </FeaturePage>
  );
};

export interface MonitorDetailsProps {
  hyperlinkCode: string;
}

export const MonitorDetails: React.FC<MonitorDetailsProps> = ({ hyperlinkCode }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const {
    isPending,
    isError,
    data: monitorResults,
  } = useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments(
    teamCode,
    projectCode,
    hyperlinkCode,
    {
      maxResults: 32,
    }
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && monitorResults && (
        <MonitorDetailsCore hyperlinkCode={hyperlinkCode} monitorResults={monitorResults} />
      )}
    </>
  );
};

interface MonitorDetailsCoreProps {
  hyperlinkCode: string;
  monitorResults: ProjectHyperlinkMonitorResultsByHyperlinksResponseDto;
}

const MonitorDetailsCore: React.FC<MonitorDetailsCoreProps> = ({ hyperlinkCode, monitorResults }) => {
  const currentMonitoringProfile = monitorResults.hyperlinks[hyperlinkCode].hyperlinkItemCurrentMonitoringProfile;
  const environments = Object.keys(monitorResults.hyperlinks[hyperlinkCode].environments);
  const [selectedEnvironment, setSelectedEnvironment] = React.useState<string | null>(environments[0]);

  const handleColumnSelect = (columnId: string | null) => {
    setSelectedEnvironment(columnId);
  };

  return (
    <div className="space-y-8">
      <HyperlinkMonitorOverview
        hyperlinkCode={hyperlinkCode}
        hyperlinkMonitoringProfile={currentMonitoringProfile}
        monitorResults={monitorResults.hyperlinks[hyperlinkCode]}
        selectedColumn={selectedEnvironment}
        handleColumnSelect={handleColumnSelect}
      />

      {selectedEnvironment && (
        <HyperlinkMonitorCore
          hyperlinkCode={hyperlinkCode}
          monitorResults={monitorResults.hyperlinks[hyperlinkCode]}
          selectedEnvironment={selectedEnvironment}
        />
      )}
    </div>
  );
};

interface HyperlinkMonitorOverviewProps {
  hyperlinkCode: string;
  hyperlinkMonitoringProfile: string;
  monitorResults: SingleHyperlinkWithEnvironmentsMonitorDto;
  selectedColumn: string | null;
  handleColumnSelect: (columnId: string | null) => void;
}

const HyperlinkMonitorOverview: React.FC<HyperlinkMonitorOverviewProps> = ({
  hyperlinkCode,
  hyperlinkMonitoringProfile,
  monitorResults,
  selectedColumn,
  handleColumnSelect,
}) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { columns, data } = React.useMemo(() => {
    return constructHyperlinkMonitoringColumns(hyperlinkCode, monitorResults, teamCode, projectCode);
  }, [hyperlinkCode, monitorResults, teamCode, projectCode]);

  return (
    <div>
      <p>
        Currently selected Monitoring Profile: <span className="font-semibold">{hyperlinkMonitoringProfile}</span>
      </p>
      <ManagerTable
        columns={columns}
        data={data}
        enableColumnSelection={true}
        selectedColumn={selectedColumn}
        onColumnSelect={handleColumnSelect}
      />
    </div>
  );
};

interface HyperlinkMonitorCoreProps {
  hyperlinkCode: string;
  monitorResults: SingleHyperlinkWithEnvironmentsMonitorDto;
  selectedEnvironment: string;
}

const HyperlinkMonitorCore: React.FC<HyperlinkMonitorCoreProps> = ({
  hyperlinkCode,
  monitorResults,
  selectedEnvironment,
}) => {
  const monitors = Object.keys(monitorResults.environments[selectedEnvironment].monitorEvents);
  const [selectedMonitor, setSelectedMonitor] = React.useState<string>(monitors[0]);

  const selectedMonitorEvents = monitorResults.environments[selectedEnvironment].monitorEvents[selectedMonitor];

  return (
    <>
      {selectedMonitorEvents.length > 0 && (
        <div className="space-y-4">
          <h4 className="font-bold">
            Environment: <span className="text-gray-600 font-mono">{selectedEnvironment}</span>
          </h4>
          <div className="grid xl:grid-cols-3 gap-6">
            <MonitorSelect
              monitorResults={monitorResults.environments[selectedEnvironment]}
              selectedMonitor={selectedMonitor}
              setSelectedMonitor={setSelectedMonitor}
              hyperlinkCode={hyperlinkCode}
              hyperlinkItemValueId={monitorResults.environments[selectedEnvironment].hyperlinkItemValueId}
            />

            <div className="xl:col-span-2">
              <MonitorDisplay
                selectedMonitor={selectedMonitor}
                monitorEvents={selectedMonitorEvents}
                hyperlinkCode={hyperlinkCode}
                hyperlinkItemValueId={monitorResults.environments[selectedEnvironment].hyperlinkItemValueId}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
