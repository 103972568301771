import { ColumnDef } from "@tanstack/react-table";

import { NotificationChannelResponseDto } from "@/api/generated";
import { Button } from "@/components/ui/button.tsx";
import { NotificationChannelConfirmDelete } from "@/lib/teams/notifications/channels/notification-channels-confirm-delete-dialog.tsx";
import { NotificationChannelUpsertDialog } from "@/lib/teams/notifications/channels/notifcation-channel-upsert-dialog.tsx";
import { ChannelIcon } from "@/lib/teams/notifications/channels/notification-channel-icon.tsx";
import {
  NotificationChannelSendTestMsg
} from "@/lib/teams/notifications/channels/notification-channel-send-test-msg.tsx";

export const notificationChannelsColumns: ColumnDef<NotificationChannelResponseDto>[] = [
  {
    accessorKey: "id",
    header: "ID",
    cell: ({ row }) => {
      const id = row.getValue("id") as number;
      return <span>{id}</span>;
    },
    minSize: 50,
    size: 60,
    maxSize: 90,
  },
  {
    accessorKey: "teamCode",
    header: "Team Code",
    cell: ({ row }) => {
      const teamCode = row.getValue("teamCode") as string;
      return <span className="font-mono font-semibold">{teamCode}</span>;
    },
    minSize: 90,
    size: 100,
    maxSize: 300,
  },
  {
    accessorKey: "code",
    header: "Channel Code",
    cell: ({ row }) => {
      const code = row.getValue("code") as string;
      return <span>{code} </span>;
    },
  },
  {
    accessorKey: "type",
    header: "Type",
    cell: ({ row }) => {
      const type = row.original.channelData.type;
      return type ? (
        <span className="flex gap-1 align-center">
          {type}
          <ChannelIcon type={type} />
        </span>
      ) : null;
    },
    minSize: 70,
    size: 90,
    maxSize: 150,
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const createdAt = row.getValue("createdAt") as string;
      return createdAt ? <span>{new Date(createdAt).toLocaleString()}</span> : null;
    },
  },
  {
    accessorKey: "lastModifiedAt",
    header: "Last Modified",
    cell: ({ row }) => {
      const lastModifiedAt = row.getValue("lastModifiedAt") as string;
      return lastModifiedAt ? <span>{new Date(lastModifiedAt).toLocaleString()}</span> : null;
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex items-center justify-end space-x-3">
          <NotificationChannelSendTestMsg channel={row.original} />
          <NotificationChannelUpsertDialog teamCode={row.original.teamCode} existingChannel={row.original}>
            <Button variant="neu" className=" justify-self-end">
              Edit
            </Button>
          </NotificationChannelUpsertDialog>
          <NotificationChannelConfirmDelete channel={row.original} />
        </div>
      );
    },
  },
];
