import {
  adminUpdateAccountUser,
  adminUpdateAccountUserPassword,
  createAccountUser,
  createCustomerPortalSession,
  createNotificationTopic,
  createPipelinePanel,
  deleteAccountUser,
  deletePipelinePanel,
  generateDynamicWebhook,
  generateEmail,
  generateWebhook,
  joinWaitlist,
  loginUser,
  refreshToken,
  registerMarketplaceSubscriber,
  registerUser,
  resetPassword,
  signInWithGithub,
  triggerVerification,
  updateAccountUser,
  updateAccountUserPassword,
  updatePipelineInfo,
  updatePipelinePanelSettings,
  updatePipelinePanelVisibility,
  updatePipelineVisibility,
  updateUserCredentials,
  updateWebhook,
  verifyGithubUser,
  verifyUser,
} from "@/old/api/requests";
import { createMutation } from "@/old/api/utils";

/* Webhooks */
export const useCreateWebhook = createMutation("webhooks", generateWebhook);
export const useUpdateWebhook = createMutation(["webhooks", "update"], updateWebhook);
export const useCreateDynamicWebhook = createMutation(["webhooks", "dynamic"], generateDynamicWebhook);

/* Emails */
export const useCreateEmail = createMutation("webhooks", generateEmail);

/* Auth */
export const useLoginUser = createMutation("login", loginUser);
export const useRegisterUser = createMutation("register", registerUser);
export const useRegisterMarketplaceSubscriber = createMutation("register", registerMarketplaceSubscriber);
export const useTriggerVerification = createMutation(["trigger", "verification"], triggerVerification);
export const useVerifyUser = createMutation(["verify", "user"], verifyUser);
export const useResetPassword = createMutation(["reset", "password"], resetPassword);
export const useRefreshToken = createMutation("refresh", refreshToken);
export const useSignInWithGithub = createMutation(["github", "signin"], signInWithGithub);
export const userVerifyGithubUser = createMutation(["github", "verify"], verifyGithubUser);

/* Users */
export const useCreateAccountUser = createMutation(["account", "users"], createAccountUser);
export const useUpdateUserCredentials = createMutation(["users", "credentials"], updateUserCredentials);
export const useJoinWaitlist = createMutation(["waitlist", "join"], joinWaitlist);
export const useDeleteAccountUser = createMutation(["account", "users", "delete"], deleteAccountUser);
export const useUpdateAccountUser = createMutation(["account", "users", "update"], updateAccountUser);
export const useUpdateAccountUserPassword = createMutation(
  ["account", "users", "update", "password"],
  updateAccountUserPassword
);
export const useCutomerPortalSession = createMutation(["subscription", "session"], createCustomerPortalSession);

/* admin */
export const useAdminUpdateAccountUser = createMutation(
  ["admin", "account", "users", "update"],
  adminUpdateAccountUser
);
export const useAdminUpdateAccountUserPassword = createMutation(
  ["admin", "account", "users", "update", "password"],
  adminUpdateAccountUserPassword
);

/* Pipelines */
export const useUpdatePipelineInfo = createMutation(["pipelines", "update"], updatePipelineInfo);
export const useUpdatePipelineVisibility = createMutation(
  ["pipelines", "update", "visibility"],
  updatePipelineVisibility
);

/* Pipeline Panels */
export const useCreatePipelinePanel = createMutation(["pipelines", "panel", "create"], createPipelinePanel);
export const useUpdatePipelinePanelSettings = createMutation(
  ["pipelines", "panel", "update", "settings"],
  updatePipelinePanelSettings
);
export const useUpdatePipelinePanelVisibility = createMutation(
  ["pipelines", "panel", "update", "visibility"],
  updatePipelinePanelVisibility
);
export const useDeletePipelinePanel = createMutation(["pipelines", "panel", "delete"], deletePipelinePanel);

export const useCreateNotificationTopic = createMutation(["notification-topics", "create"], createNotificationTopic);
