import React from "react";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { MonitorEventInfoDto, useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails } from "@/api/generated";
import { DomainCertRunDetails } from "@/lib/projects/monitoring/run-details/domain-cert-run-details";
import { DomainDnsRunDetails } from "@/lib/projects/monitoring/run-details/domain-dns-run-details";
import { UrlHealthBrowserRunDetails } from "@/lib/projects/monitoring/run-details/url-health-browser-run-details";
import { UrlHealthRestRunDetails } from "@/lib/projects/monitoring/run-details/url-health-rest-run-details";
import { UrlHealthScreenshotRunDetails } from "@/lib/projects/monitoring/run-details/url-health-screenshot-run-details";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { AlertTriangle, Check, ChevronDown, ChevronUp, HelpCircle, InfoIcon, XCircle } from "lucide-react";

interface RunDetailsProps {
  event: MonitorEventInfoDto;
  monitor: string;
  hyperlinkCode: string;
  hyperlinkItemValueId: number;
  monitorRunId: number;
  children: React.ReactNode;
}

export const RunDetails: React.FC<RunDetailsProps> = ({
  event,
  monitor,
  hyperlinkCode,
  hyperlinkItemValueId,
  monitorRunId,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="max-h-[90vh] w-full max-w-3xl overflow-y-auto"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Run Details</DialogTitle>
        <DialogDescription>Detailed information about the run of this monitor.</DialogDescription>
        <div className="space-y-4">
          <div className="px-2">
            <div className="flex justify-between items-end">
              {event && (
                <div className="space-y-2 text-sm">
                  <div className="flex items-center gap-2">
                    <InfoIcon className="size-5 text-gray-500" />
                    <EventDetailItem label="Status" value={event.monitorRunStatus ?? "Unknown"} />
                  </div>
                  <div className="flex items-center gap-2">
                    {event.result === "success" && <Check className="size-5 text-gray-500" />}
                    {event.result === "success-with-warning" && <AlertTriangle className="size-5 text-gray-500" />}
                    {event.result === "failure" && <XCircle className="size-5 text-gray-500" />}
                    {event.result !== "success" &&
                      event.result !== "success-with-warning" &&
                      event.result !== "failure" && <HelpCircle className="size-5 text-gray-500" />}
                    <EventDetailItem label="Result" value={event.result ?? "Unknown"} />
                  </div>
                </div>
              )}

              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {isEventDetailsOpen ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Less
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    More
                  </>
                )}
              </Button>
            </div>

            {isEventDetailsOpen && (
              <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-800">
                <EventDetails event={event} />
              </div>
            )}
          </div>

          <RunDetailsCore
            monitor={monitor}
            hyperlinkCode={hyperlinkCode}
            hyperlinkItemValueId={hyperlinkItemValueId}
            monitorRunId={monitorRunId}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface RunDetailsCoreProps {
  monitor: string;
  hyperlinkCode: string;
  hyperlinkItemValueId: number;
  monitorRunId: number;
}

const RunDetailsCore: React.FC<RunDetailsCoreProps> = ({
  monitor,
  hyperlinkCode,
  hyperlinkItemValueId,
  monitorRunId,
}) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const {
    isPending,
    isError,
    data: runDetails,
  } = useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails(teamCode, projectCode, hyperlinkCode, {
    itemValueId: hyperlinkItemValueId,
    monitorRunId: monitorRunId,
  });

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full py-8">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && runDetails && runDetails.data && (
        <>
          {monitor === "domain-cert" && <DomainCertRunDetails rawData={runDetails.data} />}
          {monitor === "domain-dns" && <DomainDnsRunDetails rawData={runDetails.data} />}
          {monitor === "url-health-browser" && <UrlHealthBrowserRunDetails rawData={runDetails.data} />}
          {monitor === "url-health-rest" && <UrlHealthRestRunDetails rawData={runDetails.data} />}
          {monitor === "url-health-screenshot" && <UrlHealthScreenshotRunDetails rawData={runDetails.data} />}
        </>
      )}
    </>
  );
};

interface EventDetailsProps {
  event: MonitorEventInfoDto;
}

const EventDetails: React.FC<EventDetailsProps> = ({ event }) => (
  <div className="space-y-2 text-sm ml-2">
    <EventDetailItem
      label="Started At"
      value={event.checkStartedAt ? format(new Date(event.checkStartedAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
    />
    <EventDetailItem
      label="Completed At"
      value={event.checkCompletedAt ? format(new Date(event.checkCompletedAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
    />
    <EventDetailItem label="Duration" value={event.checkDurationMillis ? `${event.checkDurationMillis} ms` : "N/A"} />
    <EventDetailItem label="Target" value={event.checkTarget ?? "N/A"} />
    {event.resultMessage && <EventDetailItem label="Message" value={event.resultMessage} />}
  </div>
);

const EventDetailItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="flex flex-col sm:flex-row gap-2">
    <span className="text-gray-500">{label}:</span>
    <span className="font-mono">{value}</span>
  </div>
);
