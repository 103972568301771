import { Button } from "@/components/ui/button";
import { BrandingVideosCarousel } from "@/lib/landing-page/branding-videos";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { ArrowUpRight, ExternalLinkIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { KeyFeatures } from "./components/key-features";
import { LayeredTitle } from "./components/layered-title";
import { LandingSection } from "./homes/landing-section";
import { UseCases } from "./use-cases";

export const LandingPage: React.FC = () => {
  return <LandingPageWrapper head={<LandingPageHead />} children={<LandingPageContent />} />;
};

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";
const LandingPageHead: React.FC = () => {
  return (
    <div className="flex flex-col items-center space-y-6 text-center pt-20 2xl:pt-32">
      <div className="flex flex-col gap-8 items-center">
        <LayeredTitle
          title="Opinionated Developer"
          className="text-5xl md:text-6xl/none lg:text-7xl/none xl:text-8xl/none font-black font-[system-ui] tracking-wide whitespace-nowrap"
        />

        <LayeredTitle
          title="Toolbox"
          className="text-5xl md:text-6xl/none lg:text-7xl/none xl:text-8xl/none  font-black font-[system-ui] tracking-wide whitespace-nowrap"
        />

        <p className="mx-auto text-muted-foreground md:text-xl max-w-[900px]">
          Includes tools for testing webhooks & emails validation, easy config and feature flags and visualizing CI/CD
          pipelines.
        </p>
      </div>

      <div className="flex gap-2">
        <Button asChild>
          <a href="#common-scenarios" className="no-underline">
            Common scenarios
          </a>
        </Button>
        <Button className="bg-secondary-200" asChild id="get-started-button">
          <Link to={docsURL + "/openapi"} target="_blank" className="no-underline">
            <div className="flex items-center gap-2">
              API
              <ExternalLinkIcon size={16} />
            </div>
          </Link>
        </Button>
        <Button className="bg-primary-200" asChild id="get-started-button">
          <Link to="/dashboard" className="no-underline">
            <div className="flex items-center gap-2">
              Get Started
              <ArrowUpRight size={16} />
            </div>
          </Link>
        </Button>
      </div>
    </div>
  );
};

const LandingPageContent: React.FC = () => {
  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24">
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-lg text-justify">
            We’ve put together a collection of tools to make <b>development</b>, <b>testing</b>, <b>debugging</b> and{" "}
            <b>monitoring</b> easier, so you can deliver your projects faster.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={[
            { title: "Minimal config", description: "Many functions can be done directly by using presigned urls" },
            { title: "API first", description: "All functionality available on the UI is available via the API" },
            { title: "Dev / Test / Debug / Monitor" },
          ]}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Highlighted Tools
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Our toolbox covers the essentials to <b>simplify your workflow</b>. Whether it’s <b>pipelines</b>,{" "}
          <b>webhooks</b> or <b>emails</b>, we’ve got you covered with tools that are easy to use and powerful enough to
          get the job done.
        </p>

        <BrandingVideosCarousel />
      </LandingSection>

      <LandingSection className="bg-primary-200" id="common-scenarios">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center pb-2">
          Common scenarios
        </h2>

        <UseCases />
      </LandingSection>

      <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Why Choose Us?</h2>
        <p className="text-muted-foreground md:text-lg">
          We built this toolbox to make life easier for <b>developers</b>. No extra fluff, no unnecessary
          complexity—just solid tools that work. Whether you’re working <b>solo</b> or in a team, our tools will help
          you <b>streamline your workflow</b>
          and get more done with less hassle.
          <br />
          <br />
          <span className="inline-flex">
            <a target="_blank" href={"https://discord.gg/6hXaw8sATm"}>
              <img
                alt="Discord"
                className="mb-[-4px]"
                src="https://img.shields.io/discord/1257985752000757852?style=flat&logo=discord&logoColor=%23ff9f58&label=Join%20a%20community&labelColor=090919&color=%23ff9f58&link=https%3A%2F%2Fdiscord.gg%2F6hXaw8sATm"
              />
            </a>
          </span>{" "}
          of developers and QA engineers who trust our platform for their testing needs.
        </p>
      </LandingSection>
    </div>
  );
};
