import { Input } from "@/components/ui/input";
import { cn } from "@/utils/ui.util";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { Check, Copy } from "lucide-react";
import React from "react";

export interface CopyableInputProps {
  value: string;
}

export const CopyableInput: React.FC<CopyableInputProps> = ({ value }) => {
  return (
    <CopyableValue value={value}>
      <Input className="font-mono" value={value} readOnly />
    </CopyableValue>
  );
};

export interface CopyableValueProps {
  value: string;
  className?: string;
  children: React.ReactNode;
}

export const CopyableValue: React.FC<CopyableValueProps> = ({ value, className, children }) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <div className={cn("flex flex-row items-center gap-4", className)}>
      {children}
      {!isCopied && <Copy className="hover:cursor-pointer h-4 flex-shrink-0" onClick={handleCopy} />}
      {isCopied && <Check className="h-4 text-tertiary-600 flex-shrink-0" />}
    </div>
  );
};

interface HiddenCopyableValueProps {
  value: string;
  className?: string;
}

export const HiddenCopyableValue: React.FC<HiddenCopyableValueProps> = ({ value, className }) => {
  const [isHidden, setIsHidden] = React.useState<boolean>(true);

  const hiddenValue = React.useMemo(() => "•".repeat(value.length), [value]);

  return (
    <CopyableValue value={value} className={cn("grid grid-cols-[1fr,min-content,min-content]", className)}>
      <div className={cn("mr-2 truncate", isHidden && "tracking-widest")}>{isHidden ? hiddenValue : value}</div>

      {isHidden && <EyeClosedIcon className="cursor-pointer h-4 flex-shrink-0" onClick={() => setIsHidden(false)} />}
      {!isHidden && <EyeOpenIcon className="cursor-pointer h-4flex-shrink-0" onClick={() => setIsHidden(true)} />}
    </CopyableValue>
  );
};
