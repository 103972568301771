import { CreateHyperlinkDtoMonitoringProfile, EnvironmentMiniResponseDto } from "@/api/generated";
import { FieldError } from "@/components/field-error";
import { TextFieldBase } from "@/components/form/elements/text-field";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useHyperlinksContext } from "@/lib/projects/hyperlinks/hyperlinks-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { CellContext } from "@tanstack/react-table";
import { isString } from "@wt-dev/common/utils";
import { Activity } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { useCurrentProject } from "../context/project-context";
import { Fixed__ProjectHyperlinkResponseDto } from "../item/project-items-helpers";

export const HyperlinkEnvCell: React.FC<
  CellContext<Fixed__ProjectHyperlinkResponseDto, unknown> & { environment: EnvironmentMiniResponseDto }
> = ({ row, environment }) => {
  const { editing } = useHyperlinksContext();

  const url = row.original.envValues[environment.code]?.url;
  const form = editing[row.original.id];

  const envColor = parseEnvColor(environment.color);

  const urlObj = React.useMemo(() => {
    if (!isString(url)) return null;

    try {
      return new URL(url);
    } catch (error) {
      return null;
    }
  }, [url]);

  if (form) {
    return (
      <TextFieldBase
        style={{
          color: envColor?.fg,
        }}
        variant="neu-flat"
        placeholder={`"${environment.code}" url...`}
        defaultValue={isString(url) ? url : ""}
        error={form.formState.errors?.envValues?.[environment.code]}
        {...form.register(`envValues.${environment.code}`)}
      />
    );
  }

  if (!isString(url)) return <></>;

  return (
    <HoverCard>
      <HoverCardTrigger>
        <a
          href={url}
          className="truncate"
          style={{
            color: envColor?.fg,
          }}
        >
          {url}
        </a>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex justify-between space-x-2">
          {urlObj && (
            <Avatar>
              <AvatarImage src={`${urlObj.origin}/favicon.ico`} />
              <AvatarFallback>?</AvatarFallback>
            </Avatar>
          )}

          <div className="space-y-1 w-full">
            <h4 className="text-sm font-semibold">{urlObj?.hostname}</h4>
            <p className="text-sm">Description...?</p>
          </div>
        </div>

        <div className="w-full mt-4">Monitoring... Info...</div>
      </HoverCardContent>
    </HoverCard>
  );
};

export const HyperlinkCodeCell: React.FC<CellContext<Fixed__ProjectHyperlinkResponseDto, unknown>> = ({ row }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { editing } = useHyperlinksContext();

  const form = editing[row.original.id];
  const { code, monitoringProfile } = row.original;
  const hasMonitoringEnabled = monitoringProfile !== CreateHyperlinkDtoMonitoringProfile.none;

  if (form) {
    return (
      <div>
        <TextFieldBase
          variant="neu-flat"
          placeholder="Code..."
          defaultValue={code ?? ""}
          error={form.formState.errors.code}
          {...form.register("code", { required: true })}
        />
      </div>
    );
  }

  if (!hasMonitoringEnabled) {
    return <div className="text-md font-medium text-primary-600 [word-wrap:break-word]">{code}</div>;
  }

  return (
    <Button variant="ghost" className="text-md text-primary-600 [word-wrap:break-word] bg-transparent p-0" asChild>
      <Link to={`/dashboard/${teamCode}/${projectCode}/monitoring/${code}`}>
        {code} <Activity className="ml-1 size-3" />
      </Link>
    </Button>
  );
};

export const HyperlinkDescriptionCell: React.FC<CellContext<Fixed__ProjectHyperlinkResponseDto, unknown>> = ({
  row,
}) => {
  const { editing } = useHyperlinksContext();

  const form = editing[row.original.id];
  const { description } = row.original;

  if (form) {
    return (
      <div>
        <TextFieldBase
          variant="neu-flat"
          placeholder="Description..."
          defaultValue={description ?? ""}
          error={form.formState.errors.description}
          {...form.register("description", { required: true })}
        />
      </div>
    );
  }

  return description;
};

export const HyperlinkMonitoringProfileCell: React.FC<CellContext<Fixed__ProjectHyperlinkResponseDto, unknown>> = ({
  row,
}) => {
  const { editing } = useHyperlinksContext();

  const form = editing[row.original.id];

  if (form) {
    return (
      <div>
        <Select
          {...form.register("monitoringProfile", { required: true })}
          defaultValue={row.original.monitoringProfile}
          onValueChange={(value) => {
            form.setValue("monitoringProfile", value);
          }}
        >
          <SelectTrigger className="pr-2 pl-4 rounded-md text-sm w-full h-10">
            <SelectValue placeholder="Select Monitoring Profile" />
          </SelectTrigger>
          <SelectContent>
            {objectKeys(CreateHyperlinkDtoMonitoringProfile).map((profile) => {
              return (
                <SelectItem key={`profile-${profile}`} value={CreateHyperlinkDtoMonitoringProfile[profile]}>
                  {profile}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <FieldError errors={form.formState.errors} name="monitoringProfile" />
      </div>
    );
  }

  return row.original.monitoringProfile;
};
