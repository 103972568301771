import { PreviewType } from "@uiw/react-md-editor";
import React from "react";

export interface DocumentContextProps {
  preview?: PreviewType;
  setPreview: React.Dispatch<React.SetStateAction<PreviewType | undefined>>;
}

export const DocumentContext = React.createContext<DocumentContextProps | null>(null);

export interface DocumentProviderProps {
  children: React.ReactNode;
}

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
  const [preview, setPreview] = React.useState<DocumentContextProps["preview"]>("preview");

  return <DocumentContext.Provider value={{ preview, setPreview }} children={children} />;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDocument = () => {
  const context = React.useContext(DocumentContext);
  if (!context) {
    throw new Error(`${useDocument.name} must be used within ${DocumentProvider.name}`);
  }

  return context;
};
