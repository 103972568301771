import { cn } from "@/utils/ui.util";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { LoadingSpinner } from "./loading-spinner";
import { Building2Icon, FolderDotIcon } from "lucide-react";
import React from "react";

interface ProjectIconProps {
  url?: string | null;
  className?: string;
  variant?: "neu" | "neu-flat" | "";
  optional?: boolean;
  loading?: boolean;
  fallback?: React.ReactNode;
}

export const ProjectIcon: React.FC<ProjectIconProps> = ({
  url,
  variant = "neu",
  className,
  optional,
  loading,
  fallback,
}) => {
  if (optional && !url && !loading) {
    return <></>;
  }

  return (
    <Avatar className={cn("rounded-md bg-primary-300 text-secondary-950", variant, className)}>
      {url && <AvatarImage src={url} />}
      <AvatarFallback>{loading ? <LoadingSpinner /> : (fallback ?? <FolderDotIcon />)}</AvatarFallback>
    </Avatar>
  );
};

export const TeamIcon: React.FC<ProjectIconProps> = (props) => {
  return <ProjectIcon fallback={<Building2Icon />} {...props} />;
};
