import React from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "@/components/ui/input";

interface DestructiveConfirmDialogProps {
  title: React.ReactNode;
  additionalDescription?: React.ReactNode;
  desciption?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  children: React.ReactNode;
  value: string;
}

export const DestructiveConfirmDialog: React.FC<DestructiveConfirmDialogProps> = ({
  title,
  additionalDescription,
  desciption,
  onConfirm,
  onCancel,
  children,
  value,
}) => {
  const [open, setOpen] = React.useState(false);
  const [internal, setInternal] = React.useState("");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle children={title} />

        {additionalDescription && <DialogDescription>{additionalDescription}</DialogDescription>}

        <DialogDescription>
          {desciption ?? (
            <>
              To confirm type "<b>{value}</b>" in the box below
            </>
          )}
        </DialogDescription>

        <Input
          value={internal}
          onChange={(e) => setInternal(e.target.value)}
          onKeyUp={(e) => {
            if (internal === value && e.code === "Enter") {
              onConfirm?.();
              setOpen(false);
            }
          }}
        />

        <div className="flex items-center justify-end gap-2 mt-4">
          <Button
            className="bg-secondary-100"
            onClick={() => {
              onCancel?.();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="bg-red-500 text-white disabled:bg-red-200 disabled:text-secondary-950"
            disabled={internal !== value}
            onClick={() => {
              onConfirm?.();
              setOpen(false);
            }}
          >
            Permanently Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
