import { useAuthControllerForgotPassword } from "@/api/generated";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { onErrorToast } from "@/utils/api.util";
import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

const forgotPasswordSchema = z.object({
  email: z.string().email({ message: "Must be a valid email" }),
});

interface ForgotPasswordFormProps {
  title: string;
  description: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ title, description }) => {
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const forgotPasswordMutation = useAuthControllerForgotPassword();

  const onSubmit = (values: z.infer<typeof forgotPasswordSchema>) => {
    forgotPasswordMutation.mutate(
      {
        data: {
          email: values.email,
        },
      },
      {
        onSuccess(data) {
          if (data && data.status === "success") {
            setEmailSent(true);
          }
        },
        onError: onErrorToast,
      }
    );
  };

  if (emailSent) {
    return (
      <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
        <div className="flex justify-center items-center gap-2">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <h4 className="font-semibold tracking-tight">Email Sent</h4>
          <p className="text-sm text-gray-600 text-center">
            An email has been sent to your email address with instructions on how to reset your password.
          </p>
        </div>
        <div className="flex justify-center gap-2 text-sm">
          <small>Back to Sign In?</small>
          <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
            <small>Sign In</small>
          </Link>
        </div>
      </Card>
    );
  }

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-3">
          <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
          <h3 className="font-bold font-mono">wt.dev</h3>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small className="text-center">{description}</small>
      </div>
      <Form schema={forgotPasswordSchema} onSubmit={onSubmit}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <TextField name="email" placeholder="Email" />
          </div>
          <Button
            type="submit"
            scheme="black"
            size="sm"
            disabled={forgotPasswordMutation.isPending}
            className="bg-secondary-950"
          >
            {forgotPasswordMutation.isPending ? <LoadingSpinner message={"Sending Email"} /> : "Send Email"}
          </Button>
        </div>
      </Form>
      <div className="flex justify-center gap-2 text-sm">
        <small>Back to Sign In?</small>
        <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
          <small>Sign In</small>
        </Link>
      </div>
    </Card>
  );
};
