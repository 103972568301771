import { parseError } from "@/utils/api.util";
import { cn } from "@/utils/ui.util";
import React from "react";

interface ErrorDisplayProps {
  error?: any;
  className?: string;
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error, className }) => {
  const parsed = parseError(error);

  return (
    <div className={cn("text-red-600 border-2 border-current bg-red-100 rounded-md", className)}>
      <h4 className="px-2 py-1 border-b-2 border-current">{parsed.title}</h4>
      <div className="px-2 py-1 text-base font-thin">{parsed.description}</div>
    </div>
  );
};
