import { useNotificationTopicsControllerGetTopicsPaginated } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner.tsx";
import { ManagerTable } from "@/components/manager-table.tsx";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button.tsx";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { notificationTopicsColumns } from "@/lib/teams/notifications/topics/notification-topics-columns.tsx";
import { NotificationTopicUpsertDialog } from "@/lib/teams/notifications/topics/notification-topics-create-dialog.tsx";
import { Plus } from "lucide-react";
import { Navigate, useParams } from "react-router-dom";

export const NotificationTopics: React.FC = () => {
  const { teamCode } = useParams();

  const pagination = usePagination();
  const { data, isPending, isError } = useNotificationTopicsControllerGetTopicsPaginated(
    teamCode ?? "",
    pagination.params
  );

  const topics = data?.results ?? [];

  return (
    <FeaturePage
      title="Notification Topics"
      description="Easily post messages to various channels such as Slack, Telegram, Discord, and HTTP with notification topics. This allows for streamlined communication across multiple platforms in a single action."
      isLoading={isPending}
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && topics && (
        <>
          <ManagerTable
            columns={notificationTopicsColumns}
            data={topics}
            searchBy="name"
            actions={
              <NotificationTopicUpsertDialog teamCode={teamCode as string}>
                <Button variant="neu" className="bg-secondary-300 justify-self-end">
                  Add new topic <Plus size={16} className="ml-1" />
                </Button>
              </NotificationTopicUpsertDialog>
            }
            onChange={() => {}}
          />

          <Pagination className="pt-4" pagination={pagination} numberOfPages={data?.numberOfPages} />
        </>
      )}
    </FeaturePage>
  );
};
