/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateEnvironmentDto,
  CreateProjectDto,
  EnvironmentResponseDto,
  PaginatedProjectsResponseDto,
  PreviewDeleteEnvironmentDto,
  PreviewDeleteProjectDto,
  ProjectOverviewResponseDto,
  ProjectResponseDto,
  ProjectsControllerGetProjectsParams,
  UpdateEnvironmentDto,
  UpdateProjectDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const projectsControllerGetProjects = (
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedProjectsResponseDto>(
    { url: `/v1/projects/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getProjectsControllerGetProjectsQueryKey = (
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams
) => {
  return [`/v1/projects/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getProjectsControllerGetProjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerGetProjects>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectsControllerGetProjectsQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerGetProjects>>> = ({ signal }) =>
    projectsControllerGetProjects(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsControllerGetProjects>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ProjectsControllerGetProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerGetProjects>>
>;
export type ProjectsControllerGetProjectsQueryError = ErrorType<unknown>;

export function useProjectsControllerGetProjects<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjects>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | ProjectsControllerGetProjectsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProjects<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjects>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProjects<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjects>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerGetProjects<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjects>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ProjectsControllerGetProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjects>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerGetProjectsQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const projectsControllerCreateProject = (
  teamCode: string,
  createProjectDto: CreateProjectDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectResponseDto>(
    {
      url: `/v1/projects/${teamCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createProjectDto,
      signal,
    },
    options
  );
};

export const getProjectsControllerCreateProjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerCreateProject>>,
    TError,
    { teamCode: string; data: CreateProjectDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerCreateProject>>,
  TError,
  { teamCode: string; data: CreateProjectDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerCreateProject>>,
    { teamCode: string; data: CreateProjectDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return projectsControllerCreateProject(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerCreateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerCreateProject>>
>;
export type ProjectsControllerCreateProjectMutationBody = CreateProjectDto;
export type ProjectsControllerCreateProjectMutationError = ErrorType<unknown>;

export const useProjectsControllerCreateProject = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerCreateProject>>,
    TError,
    { teamCode: string; data: CreateProjectDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerCreateProject>>,
  TError,
  { teamCode: string; data: CreateProjectDto },
  TContext
> => {
  const mutationOptions = getProjectsControllerCreateProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerGetProject = (
  teamCode: string,
  projectCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectResponseDto>(
    { url: `/v1/projects/${teamCode}/${projectCode}`, method: "GET", signal },
    options
  );
};

export const getProjectsControllerGetProjectQueryKey = (teamCode: string, projectCode: string) => {
  return [`/v1/projects/${teamCode}/${projectCode}`] as const;
};

export const getProjectsControllerGetProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerGetProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectsControllerGetProjectQueryKey(teamCode, projectCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerGetProject>>> = ({ signal }) =>
    projectsControllerGetProject(teamCode, projectCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsControllerGetProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ProjectsControllerGetProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerGetProject>>
>;
export type ProjectsControllerGetProjectQueryError = ErrorType<unknown>;

export function useProjectsControllerGetProject<
  TData = Awaited<ReturnType<typeof projectsControllerGetProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProject<
  TData = Awaited<ReturnType<typeof projectsControllerGetProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProject<
  TData = Awaited<ReturnType<typeof projectsControllerGetProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerGetProject<
  TData = Awaited<ReturnType<typeof projectsControllerGetProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerGetProjectQueryOptions(teamCode, projectCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const projectsControllerUpdateProject = (
  teamCode: string,
  projectCode: string,
  updateProjectDto: UpdateProjectDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProjectResponseDto>(
    {
      url: `/v1/projects/${teamCode}/${projectCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateProjectDto,
    },
    options
  );
};

export const getProjectsControllerUpdateProjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerUpdateProject>>,
    TError,
    { teamCode: string; projectCode: string; data: UpdateProjectDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerUpdateProject>>,
  TError,
  { teamCode: string; projectCode: string; data: UpdateProjectDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerUpdateProject>>,
    { teamCode: string; projectCode: string; data: UpdateProjectDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return projectsControllerUpdateProject(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerUpdateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerUpdateProject>>
>;
export type ProjectsControllerUpdateProjectMutationBody = UpdateProjectDto;
export type ProjectsControllerUpdateProjectMutationError = ErrorType<unknown>;

export const useProjectsControllerUpdateProject = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerUpdateProject>>,
    TError,
    { teamCode: string; projectCode: string; data: UpdateProjectDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerUpdateProject>>,
  TError,
  { teamCode: string; projectCode: string; data: UpdateProjectDto },
  TContext
> => {
  const mutationOptions = getProjectsControllerUpdateProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerDeleteProject = (
  teamCode: string,
  projectCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/v1/projects/${teamCode}/${projectCode}`, method: "DELETE" }, options);
};

export const getProjectsControllerDeleteProjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerDeleteProject>>,
    TError,
    { teamCode: string; projectCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerDeleteProject>>,
  TError,
  { teamCode: string; projectCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerDeleteProject>>,
    { teamCode: string; projectCode: string }
  > = (props) => {
    const { teamCode, projectCode } = props ?? {};

    return projectsControllerDeleteProject(teamCode, projectCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerDeleteProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerDeleteProject>>
>;

export type ProjectsControllerDeleteProjectMutationError = ErrorType<unknown>;

export const useProjectsControllerDeleteProject = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerDeleteProject>>,
    TError,
    { teamCode: string; projectCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerDeleteProject>>,
  TError,
  { teamCode: string; projectCode: string },
  TContext
> => {
  const mutationOptions = getProjectsControllerDeleteProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerGetProjectOverview = (
  teamCode: string,
  projectCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectOverviewResponseDto>(
    { url: `/v1/projects/${teamCode}/${projectCode}/overview`, method: "GET", signal },
    options
  );
};

export const getProjectsControllerGetProjectOverviewQueryKey = (teamCode: string, projectCode: string) => {
  return [`/v1/projects/${teamCode}/${projectCode}/overview`] as const;
};

export const getProjectsControllerGetProjectOverviewQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectsControllerGetProjectOverviewQueryKey(teamCode, projectCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>> = ({ signal }) =>
    projectsControllerGetProjectOverview(teamCode, projectCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ProjectsControllerGetProjectOverviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>
>;
export type ProjectsControllerGetProjectOverviewQueryError = ErrorType<unknown>;

export function useProjectsControllerGetProjectOverview<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProjectOverview<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetProjectOverview<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerGetProjectOverview<
  TData = Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetProjectOverview>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerGetProjectOverviewQueryOptions(teamCode, projectCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const projectsControllerPreviewDeleteProject = (
  teamCode: string,
  projectCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PreviewDeleteProjectDto>(
    { url: `/v1/projects/${teamCode}/${projectCode}/preview-delete`, method: "GET", signal },
    options
  );
};

export const getProjectsControllerPreviewDeleteProjectQueryKey = (teamCode: string, projectCode: string) => {
  return [`/v1/projects/${teamCode}/${projectCode}/preview-delete`] as const;
};

export const getProjectsControllerPreviewDeleteProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectsControllerPreviewDeleteProjectQueryKey(teamCode, projectCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>> = ({ signal }) =>
    projectsControllerPreviewDeleteProject(teamCode, projectCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ProjectsControllerPreviewDeleteProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>
>;
export type ProjectsControllerPreviewDeleteProjectQueryError = ErrorType<unknown>;

export function useProjectsControllerPreviewDeleteProject<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerPreviewDeleteProject<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerPreviewDeleteProject<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerPreviewDeleteProject<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteProject>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerPreviewDeleteProjectQueryOptions(teamCode, projectCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const projectsControllerGetEnvironments = (
  teamCode: string,
  projectCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<EnvironmentResponseDto[]>(
    { url: `/v1/projects/${teamCode}/${projectCode}/environments`, method: "GET", signal },
    options
  );
};

export const getProjectsControllerGetEnvironmentsQueryKey = (teamCode: string, projectCode: string) => {
  return [`/v1/projects/${teamCode}/${projectCode}/environments`] as const;
};

export const getProjectsControllerGetEnvironmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectsControllerGetEnvironmentsQueryKey(teamCode, projectCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>> = ({ signal }) =>
    projectsControllerGetEnvironments(teamCode, projectCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ProjectsControllerGetEnvironmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerGetEnvironments>>
>;
export type ProjectsControllerGetEnvironmentsQueryError = ErrorType<unknown>;

export function useProjectsControllerGetEnvironments<
  TData = Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetEnvironments<
  TData = Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerGetEnvironments<
  TData = Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerGetEnvironments<
  TData = Awaited<ReturnType<typeof projectsControllerGetEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsControllerGetEnvironments>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerGetEnvironmentsQueryOptions(teamCode, projectCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const projectsControllerCreateEnvironment = (
  teamCode: string,
  projectCode: string,
  createEnvironmentDto: CreateEnvironmentDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<EnvironmentResponseDto>(
    {
      url: `/v1/projects/${teamCode}/${projectCode}/environments`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createEnvironmentDto,
      signal,
    },
    options
  );
};

export const getProjectsControllerCreateEnvironmentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateEnvironmentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateEnvironmentDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>,
    { teamCode: string; projectCode: string; data: CreateEnvironmentDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return projectsControllerCreateEnvironment(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerCreateEnvironmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>
>;
export type ProjectsControllerCreateEnvironmentMutationBody = CreateEnvironmentDto;
export type ProjectsControllerCreateEnvironmentMutationError = ErrorType<unknown>;

export const useProjectsControllerCreateEnvironment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateEnvironmentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerCreateEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateEnvironmentDto },
  TContext
> => {
  const mutationOptions = getProjectsControllerCreateEnvironmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerUpdateEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  updateEnvironmentDto: UpdateEnvironmentDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EnvironmentResponseDto>(
    {
      url: `/v1/projects/${teamCode}/${projectCode}/${environmentCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateEnvironmentDto,
    },
    options
  );
};

export const getProjectsControllerUpdateEnvironmentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; environmentCode: string; data: UpdateEnvironmentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; environmentCode: string; data: UpdateEnvironmentDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>,
    { teamCode: string; projectCode: string; environmentCode: string; data: UpdateEnvironmentDto }
  > = (props) => {
    const { teamCode, projectCode, environmentCode, data } = props ?? {};

    return projectsControllerUpdateEnvironment(teamCode, projectCode, environmentCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerUpdateEnvironmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>
>;
export type ProjectsControllerUpdateEnvironmentMutationBody = UpdateEnvironmentDto;
export type ProjectsControllerUpdateEnvironmentMutationError = ErrorType<unknown>;

export const useProjectsControllerUpdateEnvironment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; environmentCode: string; data: UpdateEnvironmentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerUpdateEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; environmentCode: string; data: UpdateEnvironmentDto },
  TContext
> => {
  const mutationOptions = getProjectsControllerUpdateEnvironmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerDeleteEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/projects/${teamCode}/${projectCode}/${environmentCode}`, method: "DELETE" },
    options
  );
};

export const getProjectsControllerDeleteEnvironmentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; environmentCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>,
    { teamCode: string; projectCode: string; environmentCode: string }
  > = (props) => {
    const { teamCode, projectCode, environmentCode } = props ?? {};

    return projectsControllerDeleteEnvironment(teamCode, projectCode, environmentCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectsControllerDeleteEnvironmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>
>;

export type ProjectsControllerDeleteEnvironmentMutationError = ErrorType<unknown>;

export const useProjectsControllerDeleteEnvironment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>,
    TError,
    { teamCode: string; projectCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsControllerDeleteEnvironment>>,
  TError,
  { teamCode: string; projectCode: string; environmentCode: string },
  TContext
> => {
  const mutationOptions = getProjectsControllerDeleteEnvironmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectsControllerPreviewDeleteEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PreviewDeleteEnvironmentDto>(
    { url: `/v1/projects/${teamCode}/${projectCode}/${environmentCode}/preview-delete`, method: "GET", signal },
    options
  );
};

export const getProjectsControllerPreviewDeleteEnvironmentQueryKey = (
  teamCode: string,
  projectCode: string,
  environmentCode: string
) => {
  return [`/v1/projects/${teamCode}/${projectCode}/${environmentCode}/preview-delete`] as const;
};

export const getProjectsControllerPreviewDeleteEnvironmentQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectsControllerPreviewDeleteEnvironmentQueryKey(teamCode, projectCode, environmentCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>> = ({ signal }) =>
    projectsControllerPreviewDeleteEnvironment(teamCode, projectCode, environmentCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type ProjectsControllerPreviewDeleteEnvironmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>
>;
export type ProjectsControllerPreviewDeleteEnvironmentQueryError = ErrorType<unknown>;

export function useProjectsControllerPreviewDeleteEnvironment<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerPreviewDeleteEnvironment<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useProjectsControllerPreviewDeleteEnvironment<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useProjectsControllerPreviewDeleteEnvironment<
  TData = Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof projectsControllerPreviewDeleteEnvironment>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getProjectsControllerPreviewDeleteEnvironmentQueryOptions(
    teamCode,
    projectCode,
    environmentCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
