import { PresigningRequestDtoOneOfOnezero } from "@/api/generated";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import React from "react";
import { PresignedUrlBuilder } from "../presigned-url-builder";

interface PresignHyperlinksDialogProps extends ApiPresignedUrlBuilderProps {
  children: React.ReactNode;
}

export const PresignHyperlinksDialog: React.FC<PresignHyperlinksDialogProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden max-w-3xl max-h-screen grid grid-cols-1 grid-rows-[min-content,1fr]"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Presigned URL for hyperlinks</DialogTitle>

        <div className="overflow-y-auto max-h-full pb-1 -mx-5 px-5">
          <ApiPresignedUrlBuilder {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ApiPresignedUrlBuilderProps {
  teamCode: string;
  projectCode: string;
}

export const ApiPresignedUrlBuilder: React.FC<ApiPresignedUrlBuilderProps> = ({ teamCode, projectCode }) => {
  const presignedRequest = React.useMemo(
    () =>
      ({
        type: "hyperlink",
        listUrl: true,
        readUrl: false,
        teamCode,
        updateUrl: false,
        projectCode,
        itemCode: "",
      }) satisfies Omit<PresigningRequestDtoOneOfOnezero, "apiKey">,
    [projectCode, teamCode]
  );

  return <PresignedUrlBuilder className="flex flex-col gap-4 w-full" presigningRequest={presignedRequest} />;
};
