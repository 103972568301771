import { Navigate, Outlet, useParams } from "react-router-dom";
import { TeamProvider } from "@/lib/teams/context/team-context";
import { useAuth } from "@/auth/useAuth";
import { NotActiveTeam } from "@/components/not-active-team";

export const DashboardLayout: React.FC = () => {
  const { teamCode } = useParams();
  const { getLoggedInTeam } = useAuth();
  const loggedInTeam = getLoggedInTeam(); // TODO: This should be a hook

  if (!loggedInTeam) {
    return <Navigate to="/welcome" replace={true} />;
  }

  if (teamCode !== loggedInTeam) {
    if (!teamCode) {
      return <Navigate to={`/dashboard/${loggedInTeam}`} replace={true} />;
    }

    return <NotActiveTeam teamCode={teamCode} loggedInTeam={loggedInTeam} />;
  }

  return (
    <>
      <TeamProvider teamCode={teamCode}>
        <Outlet />
      </TeamProvider>
    </>
  );
};
