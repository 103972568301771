import { Card, CardContent, CardHeader } from "@/components/ui/card";
import React from "react";
import { Button } from "@/components/ui/button";
import { ArrowRightLeft, ArrowUpRight } from "lucide-react";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { Link } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ParserInitialSetup, sampleGroups } from "@/routes/tools/tools-samples-inputs";

export const ToolsPreview: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const [selectedGroup, setSelectedGroup] = React.useState<keyof typeof sampleGroups>("authenticationAndSecurity");
  const groupKeys = Object.keys(sampleGroups) as Array<keyof typeof sampleGroups>;

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div className="w-full">
            <h4 className="font-semibold text-lg">Quick Tools</h4>
            <p className="text-sm text-gray-600">Parse and inspect data from your systems.</p>
          </div>

          <div>
            <Select value={selectedGroup} onValueChange={(value: keyof typeof sampleGroups) => setSelectedGroup(value)}>
              <SelectTrigger>
                <SelectValue placeholder="Select a sample group">{sampleGroups[selectedGroup].title}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                {groupKeys.map((groupKey) => (
                  <SelectItem key={groupKey} value={groupKey}>
                    {sampleGroups[groupKey].title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardHeader>
      <CardContent className="grid gap-6">
        {Object.entries(sampleGroups[selectedGroup].samples).map(([sampleKey, sample]) => (
          <ToolsPreviewItem key={sampleKey} sampleKey={sampleKey} sample={sample} />
        ))}

        <Button asChild size="sm" className="bg-secondary-300 gap-1 items-center no-underline">
          <Link to={`/dashboard/${teamCode}/tools`}>
            Open Tools
            <ArrowUpRight className="size-4" />
          </Link>
        </Button>
      </CardContent>
    </Card>
  );
};

interface ToolsPreviewItemProps {
  sampleKey: string;
  sample: ParserInitialSetup;
}

const ToolsPreviewItem: React.FC<ToolsPreviewItemProps> = ({ sampleKey, sample }) => {
  const { teamCode } = useCurrentTeam();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
            {sample.icon ?? <ArrowRightLeft size={16} />}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <p className="font-semibold">{sample.name}</p>
            </div>
            <p className="text-sm text-gray-600">{sample.description && sample.description}</p>
          </div>
        </div>

        <div className="flex gap-2">
          <Button asChild size="sm" className="bg-secondary-300 gap-1 items-center no-underline">
            <Link to={`/dashboard/${teamCode}/tools/${sampleKey}`}>
              Open
              <ArrowUpRight className="size-4" />
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
};
