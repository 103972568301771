import {NotificationChannelResponseDto, useNotificationTopicsControllerPostTestMessageToChannel} from "@/api/generated";
import React from "react";
import {Button} from "@/components/ui/button.tsx";
import {toast} from "@/components/ui/use-toast.ts";
import {onErrorToast} from "@/utils/api.util.ts";

interface NotificationChannelSendTestMsgProps {
  channel: NotificationChannelResponseDto;
}

export const NotificationChannelSendTestMsg: React.FC<NotificationChannelSendTestMsgProps> = ({ channel }: NotificationChannelSendTestMsgProps) => {
  const sendTestMsgMutation = useNotificationTopicsControllerPostTestMessageToChannel({
    mutation: {
      onSuccess: () => {
        toast({
          title: "Success",
          description: "Test notification message queued",
        });
      },
      onError: onErrorToast
    }
  });

  const handleSendMsg = async () => {
    sendTestMsgMutation.mutate(
      {
        teamCode: channel.teamCode,
        channelCode: channel.code,
      }
    );
  };

  let hasDataForTest: boolean;
  switch (channel.channelData.type) {
    case "slack":
      // slack config: either webhook or (token + channel) must be defined
      hasDataForTest =
        (!!channel.channelData.config.webhookUrl && !channel.channelData.config.slackToken)
        ||
        (!!channel.channelData.config.slackToken && !!channel.channelData.config.channel && !channel.channelData.config.webhookUrl);
      break;
    case "discord":
      hasDataForTest = !!channel.channelData.config.webhookUrl;
      break;
    case "telegram":
      hasDataForTest = !!channel.channelData.config.botToken && !!channel.channelData.config.chatId;
      break;
    case "email":
      hasDataForTest = !!channel.channelData.config.emails;
      break;
    default:
      hasDataForTest = false;
  }

  return (
    <Button
      type="button"
      variant="neu"
      className="justify-between bg-green-500"
      disabled={!hasDataForTest}
      loading={sendTestMsgMutation.isPending}
      onClick={handleSendMsg}
    >
      Test
    </Button>
  );
};
