import { useExceptionsInboxControllerReturnSubmitExample } from "@/api/generated";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { PartialPresigningRequest, PresignedUrlBuilder } from "@/lib/projects/presigned-url-builder";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import React from "react";

export const ExceptionInboxPresignedRoute: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { data: exampleData } = useExceptionsInboxControllerReturnSubmitExample<string>({
    query: { staleTime: Infinity },
  });

  const presignedRequest = React.useMemo(
    () =>
      ({
        teamCode,
        projectCode,
        type: "exceptions-inbox",
      }) satisfies PartialPresigningRequest,
    [projectCode, teamCode]
  );

  return (
    <div className="w-full flex justify-center pt-2">
      <div className="max-w-3xl w-full">
        <h4 className="font-semibold tracking-tight mb-2">Create Presigned URL</h4>

        <PresignedUrlBuilder
          className="flex flex-col gap-4 w-full"
          presigningRequest={presignedRequest}
          createTestData={({ presignedURLs }) => {
            if (!presignedURLs.create) return null;

            return {
              config: {
                method: "POST",
                url: presignedURLs.create.url,
              },
              exampleData: JSON.stringify(exampleData),
            };
          }}
        />
      </div>
    </div>
  );
};
