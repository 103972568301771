import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { DefaultValues, FormProvider, SubmitHandler, useForm, UseFormReset } from "react-hook-form";
import { z, ZodEffects } from "zod";

type ZodSchemaType = z.AnyZodObject | ZodEffects<any, any>;

type FormProps<SchemaType extends ZodSchemaType> = {
  schema: SchemaType;
  onSubmit: (values: z.infer<SchemaType>, reset: UseFormReset<z.infer<SchemaType>>) => void | Promise<void>;
  defaultValues?: DefaultValues<z.infer<SchemaType>>;
  children: React.ReactNode;
};

export const Form = <SchemaType extends ZodSchemaType>({
  schema,
  onSubmit,
  defaultValues,
  children,
}: FormProps<SchemaType>) => {
  const methods = useForm<z.infer<SchemaType>>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const handleFormSubmit: SubmitHandler<z.infer<typeof schema>> = async (values) => {
    try {
      await onSubmit(values, reset);
    } catch (error) {
      console.error("Error during form submission", error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>{children}</form>
    </FormProvider>
  );
};
