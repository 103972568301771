import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { onErrorToast } from "@/utils/api.util";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAuthControllerResetPassword } from "@/api/generated";
import { Form } from "@/components/form/form";
import { TextField } from "@/components/form/elements/text-field";
import { Link } from "react-router-dom";

const ResetPasswordSchema = z
  .object({
    newPassword: z.string().min(6, "PPassword must be at least 6 characters long"),
    repeatPassword: z.string().min(6, "Password must be at least 6 characters long"),
  })
  .refine((data) => data.newPassword === data.repeatPassword, {
    message: "Passwords do not match",
    path: ["repeatPassword"], // Set the error on repeatPassword field
  });

interface ResetPasswordFormProps {
  title: string;
  description: string;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ title, description }) => {
  const [passwordUpdated, setPasswordUpdated] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const forgotPasswordMutation = useAuthControllerResetPassword();

  const onSubmit = (values: z.infer<typeof ResetPasswordSchema>) => {
    forgotPasswordMutation.mutate(
      {
        data: {
          token: token ?? "",
          newPassword: values.newPassword,
        },
      },
      {
        onSuccess(data) {
          if (data && data.status === "success") {
            setPasswordUpdated(true);
          }
        },
        onError: onErrorToast,
      }
    );
  };

  if (passwordUpdated) {
    return (
      <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
        <div className="flex justify-center items-center gap-2">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <h4 className="font-semibold tracking-tight">Password Updated</h4>
          <p className="text-sm text-gray-600 text-center">
            Your password has been updated successfully. You can now sign in with your new password.
          </p>
        </div>
        <div className="flex justify-center gap-2 text-sm">
          <small>Back to Sign In?</small>
          <Link to="/auth" className="underline hover:cursor-pointer text-secondary-950">
            <small>Sign In</small>
          </Link>
        </div>
      </Card>
    );
  }

  return (
    <Card className="flex flex-col gap-8 p-6 lg:p-10 w-full max-w-[28rem] rounded-2xl bg-secondary-200">
      <div className="flex justify-center items-center gap-2">
        <div className="flex justify-center items-center gap-4">
          <div className="flex items-center gap-3">
            <img src={"/logo-circle-hollow-dark.svg"} alt={"Email Testing Service"} className="w-8" />
            <h3 className="font-bold font-mono">wt.dev</h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h5 className="font-semibold">{title}</h5>
        <small className="text-center">{description}</small>
      </div>
      <Form schema={ResetPasswordSchema} onSubmit={onSubmit}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <TextField name="newPassword" placeholder="Password" type="password" />
            <TextField name="repeatPassword" placeholder="Repeat Password" type="password" />
          </div>
          <Button
            type="submit"
            scheme="black"
            size="sm"
            disabled={forgotPasswordMutation.isPending}
            className="bg-secondary-950"
          >
            {forgotPasswordMutation.isPending ? <LoadingSpinner message={"Updating Password"} /> : "Update Password"}
          </Button>
        </div>
      </Form>
      <div className="flex justify-center gap-2 text-sm">
        <small>Didn't receive the code?</small>
        <Link to="/auth/forgot-password" className="underline hover:cursor-pointer text-secondary-950">
          <small>Forgot password</small>
        </Link>
      </div>
    </Card>
  );
};
