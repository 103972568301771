import { useNavigate } from "react-router-dom";
import { useAuth } from "@/auth/useAuth.tsx";
import { useAuthControllerSwitchTeam } from "@/api/generated";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";

export const useSwitchTeam = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { switchLoggedInTeam } = useAuth();

  const switchTeamMutation = useAuthControllerSwitchTeam();

  const switchTeam = (targetTeamCode: string) => {
    switchTeamMutation.mutate(
      {
        teamCode: targetTeamCode,
      },
      {
        onSuccess(data) {
          if (data && data.accessToken) {
            switchLoggedInTeam(data.accessToken);
            navigate(`/dashboard/${targetTeamCode}`);
          }
        },
        onError: (error) => {
          const message = (error.response?.data as { message?: string })?.message;

          if (message) {
            toast({ title: "Oops!", description: message });
          } else {
            toast(defaultError);
          }
        },
      }
    );
  };

  return { switchTeam, mutation: switchTeamMutation };
};
