import { Button } from "@/components/ui/button";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { ArrowUpRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { BoomBox } from "../components/boom-box";
import { KeyFeatures } from "../components/key-features";
import { LayeredTitle } from "../components/layered-title";
import { LandingSection } from "./landing-section";

export const ExceptionInboxHome: React.FC = () => {
  return <LandingPageWrapper head={<ExceptionInboxHomeHead />} children={<ExceptionInboxHomeContent />} />;
};

export const ExceptionInboxHomeHead: React.FC = () => {
  // const [loaded, setLoaded] = React.useState(false);

  return (
    <div className="container px-4 sm:px-8 pt-8 2xl:pt-16">
      <div className="flex flex-col gap-8 md:flex-row items-center">
        <div className="flex flex-col gap-6 w-full">
          <div className="space-y-4">
            <div className="flex mb-12">
              <div className="relative">
                <LayeredTitle
                  title="Exception Inbox"
                  className="text-6xl lg:text-8xl/none font-black font-[system-ui] tracking-wide"
                />

                <svg
                  className="absolute rotate-[24deg] w-24 -top-[2rem] -right-[3.5rem] lg:w-36 lg:-top-[3rem] lg:-right-[5rem]"
                  viewBox="0 0 436.75089 436.65052"
                  version="1.1"
                >
                  <path
                    d="m 299.77555,251.67572 136.5,-33.4 -137.6,-33.6 73.8,-121.500405 -120.8,73.400405 -33.3,-136.10039608 -33.2,136.10039608 -120.800202,-73.400405 73.700202,121.500405 -137.60019682,33.6 136.50019682,33.4 -72.600202,119.7 120.300202,-73.1 33.7,137.9 33.7,-137.9 120.4,73.1 z"
                    className="fill-tertiary-400"
                    stroke="#000"
                    strokeWidth={6}
                  />
                  <text
                    className="font-[900] text-[130px] font-[system-ui]"
                    fill="#fff"
                    stroke="#000"
                    strokeWidth={6}
                    x="50%"
                    y="53%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    NEW
                  </text>
                </svg>
              </div>
            </div>
            <p className="mx-auto text-muted-foreground md:text-xl">
              Track, organize, and resolve important exceptions with ease. Whether it's rare workflows, DLQ messages, or
              manual interventions, the Exception Inbox ensures nothing slips through the cracks.
            </p>
          </div>

          <div className="flex gap-2">
            <Button asChild id="get-started-button">
              <Link to="/dashboard" className="no-underline">
                <div className="flex items-center gap-1">
                  Get Started
                  <ArrowUpRight size={20} />
                </div>
              </Link>
            </Button>
          </div>
        </div>

        {/* <div className={cn("relative block w-full overflow-hidden", loaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/06.webm"}
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            loop={true}
            onReady={() => setLoaded(true)}
          />
        </div> */}
      </div>
    </div>
  );
};

export const ExceptionInboxHomeContent: React.FC = () => {
  // const [quickPreviewLoaded, setQuickPreviewLoaded] = React.useState(false);

  return (
    <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
      <LandingSection className="bg-white flex flex-col gap-y-8 lg:flex-row lg:gap-24">
        <div>
          <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6">Overview</h2>
          <div className="text-muted-foreground md:text-xl text-justify">
            The Exception Inbox is your go-to tool for managing high-priority exceptions. It’s built for handling rare
            workflows, unshipped notifications, or DLQ messages that require manual resolution. By centralizing these
            exceptions in one place, with the ability to assign categories, add notes, and track statuses, you’ll ensure
            smooth resolution workflows without overloading your system.
          </div>
        </div>

        <KeyFeatures
          className="whitespace-nowrap row-span-2 self-center"
          features={[
            { title: "Exception Categorization" },
            { title: "Real-Time Notifications" },
            { title: "Status Tracking" },
            { title: "Notes and Collaboration" },
            { title: "Payload Inspection" },
          ]}
        />
      </LandingSection>

      <LandingSection className="bg-secondary-200">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">
          Why you should use our Exception Inbox Tool?
        </h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Managing critical exceptions from diverse sources can be chaotic. The Exception Inbox simplifies this process
          by offering a centralized space for tracking and resolving issues, designed for <b>high-value</b>,{" "}
          <b>low-volume events</b>.
        </p>

        <div className="grid grid-cols-2 gap-6">
          <BoomBox
            title="Handle Rare Workflows"
            boom="Perfect for workflows that occur infrequently but need immediate attention."
            description={
              <>
                Automate the collection of rare workflows (e.g., once-a-month issues) by sending the details to the
                Exception Inbox, where notifications can forward the issue to a Slack channel for manual resolution.
              </>
            }
          />
          <BoomBox
            title="Resolve Unshipped Notifications"
            boom="Easily track and resolve notifications from shippers."
            description={
              <>
                Send unshipped notification data to the Exception Inbox with tags like unshipped and the shipper’s name.
                This data triggers a Slack notification, and the issue is resolved manually and marked as resolved in
                the inbox.
              </>
            }
          />
          <BoomBox
            title="Manage DLQ Messages"
            boom="Summarize DLQ messages from low-volume systems."
            description={
              <>
                For dead-letter queues (DLQs) with infrequent messages, configure the Exception Inbox to send summary
                emails on a set schedule (e.g., hourly or daily), ensuring your team stays updated without constant
                monitoring.
              </>
            }
          />
          <BoomBox
            title="Focus on High-Value Data"
            boom="Designed for 100s or low 1000s of important entries, not bulk logs."
            description={
              <>
                Avoid overwhelming the Exception Inbox with system logs. This tool is optimized for managing high-value,
                actionable events, ensuring focus stays on critical issues rather than noisy, low-priority data.
              </>
            }
          />
          <BoomBox
            title="Collaborate Effectively"
            boom="Provide context and resolve exceptions faster with shared notes."
            description={
              <>
                Add notes to exceptions, enabling your team to document insights, progress, or next steps. This makes
                collaboration smoother and ensures nothing is missed during resolution.
              </>
            }
          />
          <BoomBox
            title="Categorize and Prioritize"
            boom="Use categories and tags for targeted exception management."
            description={
              <>
                Organize exceptions by categories such as manual, DLQ, or unshipped. Combined with priority tagging,
                this helps teams focus on what matters most, without getting lost in less urgent issues.
              </>
            }
          />
        </div>
      </LandingSection>

      {/* TODO: */}
      {/* <LandingSection className="bg-white">
        <h2 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl/none mb-6 text-center">Quick Preview</h2>
        <p className="text-muted-foreground md:text-lg text-center mb-12">
          Take a look at how the Exception Inbox simplifies error tracking and resolution, helping you debug smarter and
          faster.
        </p>

        <div className={cn("overflow-hidden", quickPreviewLoaded && "neu rounded-lg")}>
          <ReactPlayer
            url={"/branding/05.webm"}
            width="100%"
            height="100%"
            controls={true}
            muted={true}
            onReady={() => setQuickPreviewLoaded(true)}
          />
        </div>
      </LandingSection> */}
    </div>
  );
};
