import { LayersIcon, LucideIcon, SlidersHorizontalIcon } from "lucide-react";

export interface SettingConfig {
  icon: LucideIcon;
  title: string;
  description: string;
  path: string;
  disabled?: boolean;
}

export const settings = [
  {
    title: "General",
    description: "General project settings.",
    path: "/general",
    icon: SlidersHorizontalIcon,
  },
  {
    title: "Environments",
    description: "Manage environments.",
    path: "/environments",
    icon: LayersIcon,
  },
] satisfies SettingConfig[];
