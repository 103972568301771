import React from "react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/utils/ui.util";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Search } from "lucide-react";
import classes from "./manager-table.module.scss";

interface ManagerTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  searchBy?: string;
  actions?: React.ReactNode;
  enableColumnSelection?: boolean;
  selectedColumn?: string | null;
  onColumnSelect?: (columnId: string | null) => void;
  onChange?: (rows: Row<TData>[]) => void;
  serverPagination?: PaginationState & { onPaginationChange: OnChangeFn<PaginationState>; totalPages: number };
  additionalRows?: React.ReactNode;
  className?: string;
  paginationButtonsClassName?: string;
}

export function ManagerTable<TData, TValue>({
  columns,
  data,
  searchBy,
  actions,
  enableColumnSelection = false,
  selectedColumn,
  onColumnSelect,
  onChange,
  serverPagination,
  additionalRows,
  className,
  paginationButtonsClassName,
}: ManagerTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = React.useState({});

  const handleColumnSelect = (columnId: string | null, columnIndex: number) => {
    if (columnIndex === 0) return;

    if (onColumnSelect) {
      onColumnSelect(columnId);
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: Boolean(serverPagination),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: serverPagination?.onPaginationChange,
    pageCount: serverPagination?.totalPages,
    state: {
      sorting,
      columnFilters,
      rowSelection,
      ...(serverPagination ? { pagination: serverPagination } : {}),
    },
  });

  const selectedRowsModel = table.getFilteredSelectedRowModel();

  return (
    <div className="flex flex-col gap-4 rounded-">
      {(searchBy || actions) && (
        <div className="grid grid-cols-[1fr,min-content,1fr] items-center gap-2">
          {searchBy ? (
            <>
              <Input
                placeholder={`Search by ${searchBy}`}
                value={(table.getColumn(searchBy)?.getFilterValue() as string) ?? ""}
                onChange={(event) => table.getColumn(searchBy)?.setFilterValue(event.target.value)}
                className="w-full"
              />

              <Button variant="neu" className="bg-primary-300">
                Search <Search size={16} className="ml-1" />
              </Button>
            </>
          ) : (
            <div className="col-span-2" />
          )}

          {actions}
        </div>
      )}

      {!!selectedRowsModel.rows.length && (
        <div className="neu-flat rounded-md px-2 py-2 bg-secondary-200 flex-1 text-sm grid grid-cols-[min-content,1fr,min-content] items-center gap-2">
          <Checkbox checked={true} onClick={() => setRowSelection({})} />
          <span>
            {selectedRowsModel.rows.length} / {table.getFilteredRowModel().rows.length} selected
          </span>

          <Button
            variant="neu-flat"
            size="sm"
            className="bg-red-400"
            onClick={() => {
              onChange && onChange(selectedRowsModel.flatRows);
              setRowSelection({});
            }}
          >
            Delete
          </Button>
        </div>
      )}

      <div className="neu rounded-md">
        <Table className={cn("table-fixed", classes["manager-table"], className)}>
          <TableHeader className="border-b-2 border-b-secondary-950">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  const meta = header.column.columnDef.meta as any;
                  const className = meta?.className?.header;
                  const customWidth = meta?.customWidth;

                  const isSelected = selectedColumn === header.id;
                  const isFirstColumn = index === 0;

                  return (
                    <TableHead
                      key={header.id}
                      className={clsx(
                        className,
                        "truncate",
                        enableColumnSelection && !isFirstColumn && "cursor-pointer hover:!bg-tertiary-300/50",
                        enableColumnSelection && isSelected && "!bg-tertiary-400/50"
                      )}
                      style={{ width: customWidth ?? header.getSize() }}
                      onClick={() => enableColumnSelection && handleColumnSelect(header.id, index)}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell, index) => {
                    const meta = cell.column.columnDef.meta as any;
                    const className = meta?.className?.cell;

                    const isSelected = selectedColumn === cell.column.id;
                    const isFirstColumn = index === 0;

                    return (
                      <TableCell
                        key={cell.id}
                        className={clsx(
                          className,
                          "truncate",
                          enableColumnSelection && isSelected && !isFirstColumn && "!bg-tertiary-200/40"
                        )}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}

            {additionalRows}
          </TableBody>
        </Table>
      </div>

      {serverPagination && (
        <div className="flex items-center justify-end space-x-2">
          <Button size="sm" className={cn(paginationButtonsClassName)} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            Previous
          </Button>
          <Button size="sm" className={cn(paginationButtonsClassName)} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            Next
          </Button>
        </div>
      )}
    </div>
  );
}
