import { useHttpRequesterControllerSendRequest } from "@/api/generated";
import { CodeBlock } from "@/components/code-block";
import { TextField } from "@/components/form/elements/text-field";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { VerticalTable } from "@/components/vertical-table";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { HttpRequesterEvent } from "@/lib/websockets/websocket-events";
import { SocketProvider } from "@/lib/websockets/websocket-provider";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { wtHttpRequestSchema } from "@wt-dev/common/schemas";
import { ServiceType } from "@wt-dev/common/ws";
import { Trash } from "lucide-react";
import React from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

const requestSchema = wtHttpRequestSchema.extend({
  headers: z.array(z.object({ key: z.string(), value: z.string() })), // Updated headers
});

type Request = z.infer<typeof requestSchema>;

const requestMethods = requestSchema.shape.method.options;
type RequestMethod = (typeof requestMethods)[number];

export const HttpRequester: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const [events, setEvents] = React.useState<HttpRequesterEvent[] | null>(null);

  const handleNewEvent = React.useCallback((event: HttpRequesterEvent) => {
    console.log(event);
    setEvents((prev) => {
      if (!prev) {
        return [event];
      }

      return [...prev, event];
    });
  }, []);

  return (
    <FeaturePage title="HTTP Requester" description="Make http requests">
      <SocketProvider<HttpRequesterEvent>
        config={{
          service: ServiceType.HTTP_REQUESTER,
          teamCode,
          projectCode,
          handleEvent: handleNewEvent,
        }}
      >
        <HttpRequesterForm />

        {!!events?.length && (
          <>
            <Separator className="h-[2px]" />

            <div className="flex flex-col gap-y-4">
              <Label>Events</Label>

              <div className="space-y-2 neu rounded-md p-4 bg-tertiary-100">
                {events?.map((event, idx) => {
                  // TODO: HttpRequesterWsPayload broken???
                  const response = (
                    event.data as unknown as {
                      response: {
                        status: number;
                        statusText: string;
                        body?: string | null;
                        headers?: Record<string, string | number>;
                      };
                    }
                  ).response;

                  return (
                    <div key={idx} className="space-y-2 bg-white rounded-md neu-flat p-4">
                      <VerticalTable
                        data={[
                          {
                            header: "Status",
                            value: `${response.status} ${response.statusText ?? ""}`,
                          },
                          {
                            header: "Headers",
                            value: (
                              <div className="grid grid-cols-[min-content,1fr]">
                                {objectKeys(response.headers ?? {}).map((key) => {
                                  return (
                                    <React.Fragment key={`header-${key}`}>
                                      <div className="font-bold">{key}</div>
                                      <div>{response.headers?.[key]}</div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            ),
                          },
                          {
                            header: "Body",
                            value: response.body,
                          },
                        ]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </SocketProvider>
    </FeaturePage>
  );
};

export const HttpRequesterForm: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const form = useForm<Request>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(requestSchema),
    defaultValues: {
      method: "GET",
      url: "",
      headers: [{}],
    },
  });

  const sendRequestMutation = useHttpRequesterControllerSendRequest({
    mutation: {
      onSuccess: () => {
        console.log("SUCCESS");
      },
      onError: onErrorToast,
    },
  });

  const onSubmit: SubmitHandler<Request> = async (values) => {
    const headers = values.headers.reduce<Record<string, string>>((acc, header) => {
      if (header.key) {
        acc[header.key] = header.value;
      }
      return acc;
    }, {});

    sendRequestMutation.mutate({
      teamCode,
      projectCode,
      data: {
        method: values.method,
        url: values.url,
        headers: headers,
      },
    });
  };

  const headersArray = useFieldArray({
    control: form.control,
    name: "headers",
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
        <div className="grid grid-cols-[min-content,1fr,min-content] items-start gap-2">
          <Select
            defaultValue={form.getValues("method")}
            onValueChange={(method: RequestMethod) => {
              form.setValue("method", method);
            }}
          >
            <SelectTrigger type="button" className="pr-2 pl-4 rounded-md text-sm w-fit h-10">
              <SelectValue placeholder="Select Method" />
            </SelectTrigger>
            <SelectContent>
              {requestMethods.map((method) => {
                return (
                  <SelectItem key={`method-${method}`} value={method}>
                    {method}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>

          <div>
            <TextField variant="neu-flat" name="url" />
          </div>

          <Button type="submit" className="bg-tertiary-200">
            Send
          </Button>
        </div>

        <div>
          <Label>Headers</Label>

          <div className="grid grid-cols-[1fr,3fr,min-content] neu-flat gap-x-2 gap-y-2 p-4 rounded-md">
            <h4 className="text-base font-bold">Key</h4>
            <h4 className="text-base font-bold">Value</h4>
            <div></div>

            {headersArray.fields.map((_, i) => {
              return (
                <React.Fragment key={`field-${i}`}>
                  <TextField variant="neu-flat" name={`headers.[${i}].key`} />
                  <TextField variant="neu-flat" name={`headers.[${i}].value`} />
                  <Button
                    type="button"
                    size="icon"
                    className="bg-red-200"
                    onClick={() => {
                      headersArray.remove(i);
                    }}
                  >
                    <Trash size={16} />
                  </Button>
                </React.Fragment>
              );
            })}

            <Button
              type="button"
              className="bg-secondary-200 mt-2"
              onClick={() => headersArray.append({ key: "", value: "" })}
            >
              Add
            </Button>
          </div>
        </div>

        <div>
          <Label>Body</Label>

          <BodyEditor value={form.getValues("body")} setValue={(value) => form.setValue("body", value)} />
        </div>
      </form>
    </FormProvider>
  );
};

interface BodyEditorProps {
  value?: Request["body"];
  setValue: (value?: Request["body"]) => void;
}

export const BodyEditor: React.FC<BodyEditorProps> = ({ value, setValue }) => {
  const [language, setLanguage] = React.useState<string>("json");

  return (
    <div className="neu-flat rounded-md overflow-hidden">
      <Tabs value={language} onValueChange={(v) => setLanguage(v as typeof language)}>
        <TabsList className="w-fit bg-transparent gap-2 h-fit p-2">
          <TabsTrigger
            value="json"
            type="button"
            className="text-sm data-[state=active]:bg-tertiary-200 hover:bg-tertiary-100 bg-white w-full"
          >
            JSON
          </TabsTrigger>

          <TabsTrigger
            value="txt"
            type="button"
            className="text-sm data-[state=active]:bg-tertiary-200 hover:bg-tertiary-100 bg-white w-full"
          >
            Raw
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <CodeBlock
        code={(value as string) ?? ""}
        language={language}
        readonly={false}
        theme="light"
        className="border-t-2 border-t-secondary-950 rounded-none"
        handleCodeChange={(v) => {
          setValue({ type: language, value: v ?? "" });
        }}
      />
    </div>
  );
};
