export const ChannelIcon = ({ type, className = "w-4 h-4 inline-block" }: { type: string; className?: string }) => {
  const getIconSrc = (type: string) => {
    switch (type) {
      case "slack":
        return "/logo-slack.svg";
      case "discord":
        return "/logo-discord.svg";
      case "telegram":
        return "/logo-telegram.svg";
      case "email":
        return "/logo-email.svg";
      default:
        return "";
    }
  };

  return (
    <img src={getIconSrc(type)} alt={`${type.charAt(0).toUpperCase() + type.slice(1)} icon`} className={className} />
  );
};
