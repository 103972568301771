import { ApiKeyResponseDto, useApiKeysControllerUpdateApiKey } from "@/api/generated";
import { ToggleButton } from "@/components/toggle-button";
import { Button } from "@/components/ui/button";
import { ConfirmDialog } from "@/lib/dialog/confirm-dialog";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { Row } from "@tanstack/react-table";
import { Edit, Trash } from "lucide-react";
import React from "react";
import { EditApiKeyDialog } from "./edit-api-key-dialog";

export const ApiKeysStatusCell: React.FC<{ row: Row<ApiKeyResponseDto>; refetch: () => void }> = ({ row, refetch }) => {
  const { teamCode } = useCurrentTeam();

  const updateMutation = useApiKeysControllerUpdateApiKey({
    mutation: {
      onSuccess: () => refetch(),
    },
  });

  const enabled = React.useMemo(() => {
    return !(updateMutation.data?.disabled ?? row.original.disabled);
  }, [row.original.disabled, updateMutation.data?.disabled]);

  return (
    <ToggleButton
      enabled={enabled}
      loading={updateMutation.isPending}
      onClick={() =>
        updateMutation.mutate({
          teamCode,
          data: { disabled: enabled },
          apiKeyId: row.original.id,
        })
      }
    />
  );
};

export const ApiKeysActionCell: React.FC<{ row: Row<ApiKeyResponseDto>; refetch: () => void }> = ({ row, refetch }) => {
  const { teamCode } = useCurrentTeam();

  return (
    <div className="flex items-center gap-2 justify-end">
      <EditApiKeyDialog teamCode={teamCode} apiKey={row.original} onSuccess={() => refetch()}>
        <Button size="icon" className="bg-primary-200 w-8 h-8">
          <Edit size={16} />
        </Button>
      </EditApiKeyDialog>

      <ConfirmDialog
        title="Delete Confirmation"
        desciption={`Are you sure you want to delete "${row.original.key.substring(0, 12)}...${row.original.key.slice(-3)}" API key?`}
      >
        <Button size="icon" className="bg-red-200 w-8 h-8" disabled>
          <Trash size={16} />
        </Button>
      </ConfirmDialog>
    </div>
  );
};
