/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ActionStatusResponseDto,
  AuthControllerVerifyParams,
  ForgotPasswordDto,
  LoginResponseDto,
  LoginUserDto,
  ResetPasswordDto,
  SignUpRequestDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get JWT from username/password
 */
export const authControllerLogin = (
  loginUserDto: LoginUserDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<LoginResponseDto>(
    {
      url: `/v1/auth/login`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: loginUserDto,
      signal,
    },
    options
  );
};

export const getAuthControllerLoginMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogin>>,
    TError,
    { data: LoginUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authControllerLogin>>, TError, { data: LoginUserDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerLogin>>, { data: LoginUserDto }> = (
    props
  ) => {
    const { data } = props ?? {};

    return authControllerLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerLogin>>>;
export type AuthControllerLoginMutationBody = LoginUserDto;
export type AuthControllerLoginMutationError = ErrorType<unknown>;

/**
 * @summary Get JWT from username/password
 */
export const useAuthControllerLogin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogin>>,
    TError,
    { data: LoginUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authControllerLogin>>, TError, { data: LoginUserDto }, TContext> => {
  const mutationOptions = getAuthControllerLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Registers new user
 */
export const authControllerRegister = (
  signUpRequestDto: SignUpRequestDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<LoginResponseDto>(
    {
      url: `/v1/auth/register`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: signUpRequestDto,
      signal,
    },
    options
  );
};

export const getAuthControllerRegisterMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerRegister>>,
    TError,
    { data: SignUpRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerRegister>>,
  TError,
  { data: SignUpRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerRegister>>, { data: SignUpRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {};

    return authControllerRegister(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerRegister>>>;
export type AuthControllerRegisterMutationBody = SignUpRequestDto;
export type AuthControllerRegisterMutationError = ErrorType<unknown>;

/**
 * @summary Registers new user
 */
export const useAuthControllerRegister = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerRegister>>,
    TError,
    { data: SignUpRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerRegister>>,
  TError,
  { data: SignUpRequestDto },
  TContext
> => {
  const mutationOptions = getAuthControllerRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Refreshes JWT token using refresh token
 */
export const authControllerRefreshTokens = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<LoginResponseDto>({ url: `/v1/auth/refresh`, method: "POST", signal }, options);
};

export const getAuthControllerRefreshTokensMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerRefreshTokens>>, void> = () => {
    return authControllerRefreshTokens(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerRefreshTokensMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerRefreshTokens>>
>;

export type AuthControllerRefreshTokensMutationError = ErrorType<unknown>;

/**
 * @summary Refreshes JWT token using refresh token
 */
export const useAuthControllerRefreshTokens = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authControllerRefreshTokens>>, TError, void, TContext> => {
  const mutationOptions = getAuthControllerRefreshTokensMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Switches team
 */
export const authControllerSwitchTeam = (
  teamCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<LoginResponseDto>({ url: `/v1/auth/team/${teamCode}`, method: "POST", signal }, options);
};

export const getAuthControllerSwitchTeamMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSwitchTeam>>,
    TError,
    { teamCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerSwitchTeam>>,
  TError,
  { teamCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerSwitchTeam>>, { teamCode: string }> = (
    props
  ) => {
    const { teamCode } = props ?? {};

    return authControllerSwitchTeam(teamCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerSwitchTeamMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerSwitchTeam>>>;

export type AuthControllerSwitchTeamMutationError = ErrorType<unknown>;

/**
 * @summary Switches team
 */
export const useAuthControllerSwitchTeam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerSwitchTeam>>,
    TError,
    { teamCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authControllerSwitchTeam>>, TError, { teamCode: string }, TContext> => {
  const mutationOptions = getAuthControllerSwitchTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Verify user
 */
export const authControllerVerify = (
  params: AuthControllerVerifyParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ActionStatusResponseDto>({ url: `/v1/auth/verify`, method: "GET", params, signal }, options);
};

export const getAuthControllerVerifyQueryKey = (params: AuthControllerVerifyParams) => {
  return [`/v1/auth/verify`, ...(params ? [params] : [])] as const;
};

export const getAuthControllerVerifyQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerVerify>>,
  TError = ErrorType<unknown>,
>(
  params: AuthControllerVerifyParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthControllerVerifyQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerVerify>>> = ({ signal }) =>
    authControllerVerify(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerVerify>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthControllerVerifyQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerVerify>>>;
export type AuthControllerVerifyQueryError = ErrorType<unknown>;

export function useAuthControllerVerify<
  TData = Awaited<ReturnType<typeof authControllerVerify>>,
  TError = ErrorType<unknown>,
>(
  params: AuthControllerVerifyParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>, "initialData">;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerVerify<
  TData = Awaited<ReturnType<typeof authControllerVerify>>,
  TError = ErrorType<unknown>,
>(
  params: AuthControllerVerifyParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>, "initialData">;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerVerify<
  TData = Awaited<ReturnType<typeof authControllerVerify>>,
  TError = ErrorType<unknown>,
>(
  params: AuthControllerVerifyParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Verify user
 */

export function useAuthControllerVerify<
  TData = Awaited<ReturnType<typeof authControllerVerify>>,
  TError = ErrorType<unknown>,
>(
  params: AuthControllerVerifyParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerVerify>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthControllerVerifyQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Send forgot password email
 */
export const authControllerForgotPassword = (
  forgotPasswordDto: ForgotPasswordDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ActionStatusResponseDto>(
    {
      url: `/v1/auth/forgot-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: forgotPasswordDto,
      signal,
    },
    options
  );
};

export const getAuthControllerForgotPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerForgotPassword>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    { data: ForgotPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerForgotPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerForgotPassword>>
>;
export type AuthControllerForgotPasswordMutationBody = ForgotPasswordDto;
export type AuthControllerForgotPasswordMutationError = ErrorType<unknown>;

/**
 * @summary Send forgot password email
 */
export const useAuthControllerForgotPassword = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerForgotPassword>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthControllerForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Reset password
 */
export const authControllerResetPassword = (
  resetPasswordDto: ResetPasswordDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ActionStatusResponseDto>(
    {
      url: `/v1/auth/reset-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: resetPasswordDto,
      signal,
    },
    options
  );
};

export const getAuthControllerResetPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerResetPassword>>,
    TError,
    { data: ResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerResetPassword>>,
  TError,
  { data: ResetPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerResetPassword>>,
    { data: ResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerResetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerResetPassword>>
>;
export type AuthControllerResetPasswordMutationBody = ResetPasswordDto;
export type AuthControllerResetPasswordMutationError = ErrorType<unknown>;

/**
 * @summary Reset password
 */
export const useAuthControllerResetPassword = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerResetPassword>>,
    TError,
    { data: ResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerResetPassword>>,
  TError,
  { data: ResetPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthControllerResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authControllerSignInWithGithub = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/v1/auth/sign-in-with-github`, method: "GET", signal }, options);
};

export const getAuthControllerSignInWithGithubQueryKey = () => {
  return [`/v1/auth/sign-in-with-github`] as const;
};

export const getAuthControllerSignInWithGithubQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthControllerSignInWithGithubQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerSignInWithGithub>>> = ({ signal }) =>
    authControllerSignInWithGithub(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthControllerSignInWithGithubQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSignInWithGithub>>
>;
export type AuthControllerSignInWithGithubQueryError = ErrorType<unknown>;

export function useAuthControllerSignInWithGithub<
  TData = Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerSignInWithGithub<
  TData = Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerSignInWithGithub<
  TData = Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAuthControllerSignInWithGithub<
  TData = Awaited<ReturnType<typeof authControllerSignInWithGithub>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerSignInWithGithub>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthControllerSignInWithGithubQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const authControllerAuthCallback = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<void>({ url: `/v1/auth/authentication-callback`, method: "GET", signal }, options);
};

export const getAuthControllerAuthCallbackQueryKey = () => {
  return [`/v1/auth/authentication-callback`] as const;
};

export const getAuthControllerAuthCallbackQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerAuthCallback>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthControllerAuthCallbackQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerAuthCallback>>> = ({ signal }) =>
    authControllerAuthCallback(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerAuthCallback>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthControllerAuthCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerAuthCallback>>>;
export type AuthControllerAuthCallbackQueryError = ErrorType<unknown>;

export function useAuthControllerAuthCallback<
  TData = Awaited<ReturnType<typeof authControllerAuthCallback>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerAuthCallback<
  TData = Awaited<ReturnType<typeof authControllerAuthCallback>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthControllerAuthCallback<
  TData = Awaited<ReturnType<typeof authControllerAuthCallback>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAuthControllerAuthCallback<
  TData = Awaited<ReturnType<typeof authControllerAuthCallback>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerAuthCallback>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthControllerAuthCallbackQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
