import { useExceptionsInboxControllerReturnSubmitExample } from "@/api/generated";
import { CodeBlock } from "@/components/code-block";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import React from "react";

interface ValidSchemaDialogProps {
  children: React.ReactNode;
}

export const ValidSchemaDialog: React.FC<ValidSchemaDialogProps> = ({ children }) => {
  const { data, isLoading, isError } = useExceptionsInboxControllerReturnSubmitExample({
    query: { staleTime: Infinity },
  });

  const stringData = React.useMemo(() => {
    try {
      return JSON.stringify(data, null, 2);
    } catch (error) {
      return "Error...";
    }
  }, [data]);

  return (
    <Dialog>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="max-w-4xl max-h-screen overflow-y-auto"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Exception Inbox Schema</DialogTitle>

        <CodeBlock
          code={isLoading ? "Loading..." : isError ? "Error..." : stringData}
          language="json"
          readonly
          theme="light"
          className="neu-flat rounded-md"
        />
      </DialogContent>
    </Dialog>
  );
};
