import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import jwtService from "./JWTService";
import { ApiMutation } from "@/old/api";

type AuthStatus = "loading" | "authenticated" | "public" | "redirect-to-auth";

interface AuthContextType {
  status: AuthStatus;
  setPublicAccess: () => void;
  login: (token: string) => void;
  logout: () => void;
  switchLoggedInTeam: (token: string) => void;
  getLoggedInTeam: () => string | undefined;
  getLoggedInUser: () => string | undefined;
  getAccessToken: () => string | null;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [status, setStatus] = React.useState<AuthStatus>("loading");
  const queryClient = useQueryClient();

  const { mutate: refreshToken } = ApiMutation.useRefreshToken({
    onSuccess: ({ accessToken }) => {
      login(accessToken);
    },
    onError: () => {
      logout();
    },
  });

  React.useEffect(() => {
    const calculateRefreshTime = (token: string): number => {
      const tokenExpiration = jwtService.getTokenExpiration(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const refreshThreshold = 300; // Refresh 5 minutes before expiration

      return tokenExpiration - currentTime - refreshThreshold;
    };

    const scheduleTokenRefresh = (refreshTimeInSeconds: number) => {
      return setTimeout(() => refreshToken(), refreshTimeInSeconds < 0 ? 0 : refreshTimeInSeconds * 1000);
    };

    const handleAuth = () => {
      const token = jwtService.getToken();

      if (!token) {
        setStatus("public");
        return;
      }

      if (jwtService.isTokenValid(token)) {
        setStatus("authenticated");
      }

      const refreshTime = calculateRefreshTime(token);
      const timer = scheduleTokenRefresh(refreshTime);

      return () => clearTimeout(timer);
    };

    // TODO we now added team switching, which causes a new access token to be received for the 'target' team.
    //  However: right now this also generates a new refreshToken, and both tokens' expiry changes.
    //  This will mess up the auth-refresh because the timer above will be inaccurate.

    return handleAuth();
  }, [refreshToken]);

  const setPublicAccess = () => {
    setStatus("public");
  };

  const login = (token: string) => {
    jwtService.saveToken(token);
    queryClient.invalidateQueries();
    setStatus("authenticated");
  };

  const logout = () => {
    jwtService.removeToken();
    queryClient.invalidateQueries();

    if (window.location.pathname === "/" || window.location.pathname.startsWith("/home/")) {
      setStatus("public");
      return;
    }

    setStatus("redirect-to-auth");
  };

  const switchLoggedInTeam = (token: string) => {
    jwtService.saveToken(token);
    setStatus("authenticated");
    // TODO: save tokens per-team; when switching, first check if we have a stored token for this team (that is still valid)
  };

  const getLoggedInTeam = () => {
    const token = jwtService.getToken();
    if (!token || !jwtService.isTokenValid(token)) {
      return undefined;
    }

    const decoded = jwtService.decodeToken(token);
    return decoded?.teamCode;
  };

  const getLoggedInUser = () => {
    const token = jwtService.getToken();
    if (!token || !jwtService.isTokenValid(token)) {
      return undefined;
    }

    const decoded = jwtService.decodeToken(token);
    return decoded?.userId;
  };

  const getAccessToken = () => {
    return jwtService.getToken();
  };

  return (
    <AuthContext.Provider
      value={{
        status,
        setPublicAccess,
        login,
        logout,
        switchLoggedInTeam,
        getLoggedInTeam,
        getLoggedInUser,
        getAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
