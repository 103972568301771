import clsx from "clsx";

interface LandingSectionProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties | undefined;
  id?: string;
}

export const LandingSection: React.FC<LandingSectionProps> = ({ children, className, style, id }) => {
  return (
    <section
      id={id}
      className={clsx("px-8 md:px-16 lg:px-24 neu rounded-md py-12", className)}
      style={style}
      children={children}
    />
  );
};
