import React from "react";
import { CopyableInput } from "@/components/copyable-input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TooltipBadge } from "@/components/tooltip-badge";
import { WebhookStatusCard } from "@/lib/webhooks/help/webhook-status-card";
import { Lock } from "lucide-react";
import { Navigate } from "react-router-dom";
import { useOutletContext, useParams } from "react-router-dom";
import {
  EmailAddressResponseDto,
  PaginatedReceivedEmailsResponseDto,
  ReceivedEmailInfoResponseDto,
  useEmailsControllerGetReceivedEmails,
} from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { LoadingSpinner } from "@/components/loading-spinner";
import { constructEmailAddress } from "./email-address-builder";
import { RecentEmails } from "@/lib/projects/emails/sidebar/recent-emails";
import { Link } from "react-router-dom";
import { EmailDisplay } from "@/lib/projects/emails/email/email-display";
import { SocketProvider } from "@/lib/websockets/websocket-provider";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { EmailsEvent } from "@/lib/websockets/websocket-events";
import { ServiceType } from "@wt-dev/common/ws";

export interface EmailAddressStatus {
  isEmailAddressEnabled: boolean;
  maxItems: number;
}

interface EmailsPageOutletContext {
  email: EmailAddressResponseDto;
}

export const EmailsPage: React.FC = () => {
  const { email } = useOutletContext<EmailsPageOutletContext>();
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { emailCode } = useParams<{ emailCode: string }>();

  const emailAddressStatus = React.useMemo<EmailAddressStatus>(() => {
    const isEmailAddressEnabled = email.enabled;
    const maxItems = email.maxItems;

    return { isEmailAddressEnabled, maxItems };
  }, [email]);

  const {
    data: received,
    isPending,
    isError,
    refetch,
  } = useEmailsControllerGetReceivedEmails(teamCode, projectCode, email.emailAddressCode);

  const handleNewEvent = React.useCallback(
    (event: EmailsEvent) => {
      if (event.data.emailAddressCode === email.emailAddressCode) {
        console.log(event.data.message);
        refetch();
      }
    },
    [refetch, email]
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <SocketProvider<EmailsEvent>
          config={{
            service: ServiceType.EMAILS,
            teamCode: teamCode,
            projectCode: projectCode,
            handleEvent: handleNewEvent,
          }}
        >
          <EmailPageCore
            emailAddressCode={email.emailAddressCode}
            emailCode={emailCode}
            received={received}
            emailAddressStatus={emailAddressStatus}
          />
        </SocketProvider>
      )}
    </>
  );
};

interface EmailPageCoreProps {
  emailAddressCode: string;
  emailCode?: string;
  received: PaginatedReceivedEmailsResponseDto;
  emailAddressStatus: EmailAddressStatus;
}

export const EmailPageCore: React.FC<EmailPageCoreProps> = ({
  emailAddressCode,
  emailCode,
  received,
  emailAddressStatus,
}) => {
  const emailAddress = constructEmailAddress(emailAddressCode);

  const selected: ReceivedEmailInfoResponseDto | undefined = React.useMemo(
    () => received.results.find((item) => item.emailCode === emailCode),
    [received, emailCode]
  );

  const sidebar = (
    <Sidebar
      emailAddressCode={emailAddressCode}
      emailAddress={emailAddress}
      received={received}
      emailAddressStatus={emailAddressStatus}
      selected={selected}
    />
  );

  return (
    <div className="flex flex-col md:flex-row w-full gap-6">
      {/* Sidebar */}
      <div className="flex flex-col md:w-4/12 2xl:w-3/12">
        <div className="neu rounded-lg bg-secondary-200 h-[calc(100dvh-120px)]">{sidebar}</div>
      </div>

      {/* Content */}
      <div className="flex flex-col md:w-8/12 2xl:w-9/12">
        {(received.results.length === 0 || !selected) && (
          <>
            <div className="flex flex-col p-4">
              <WebhookStatusCard
                message={received.results.length === 0 ? "No emails received yet." : "Select an email to view."}
                description={
                  <span>
                    Use your email client, web email client or email sending service to send emails to{" "}
                    <Link to={`mailto:${emailAddress}`} className="text-secondary-950">
                      {emailAddress}
                    </Link>
                  </span>
                }
              />
            </div>
          </>
        )}
        {received.results.length > 0 && selected && (
          <div className="neu rounded-lg">
            <EmailDisplay emailAddressCode={emailAddressCode} emailInfo={selected} />
          </div>
        )}
      </div>
    </div>
  );
};

interface SidebarProps {
  emailAddressCode: string;
  emailAddress: string;
  received: PaginatedReceivedEmailsResponseDto;
  emailAddressStatus: EmailAddressStatus;
  selected: ReceivedEmailInfoResponseDto | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({
  emailAddressCode,
  emailAddress,
  received,
  emailAddressStatus,
  selected,
}) => {
  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-col gap-4 py-6 px-4 border-b-2 border-secondary-950">
          <div className="flex gap-2 items-start justify-between">
            <div className="flex items-center">
              <h4 className="font-semibold tracking-tight">Email Address</h4>
            </div>
            <div className="flex gap-1">
              <TooltipBadge
                title="private"
                message="Only you can access emails sent to this address."
                icon={<Lock className="w-3 h-3" />}
              />
              <TooltipBadge
                title={`Limit: ${emailAddressStatus.maxItems}`}
                message="Maximum number of emails that can be received."
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-600">Send an email here:</p>
              <CopyableInput value={emailAddress} />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[calc(100%-186px)]">
        <ScrollArea className="h-full w-full">
          <div className="py-4">
            <div className="flex items-center mb-2 px-4">
              <h5 className="font-semibold tracking-tight">Inbox</h5>
            </div>
            <div className="flex flex-col gap-4 px-4">
              {received.count === 0 && (
                <div className="mt-4 m-auto">
                  <p className="text-sm text-gray-600">Received emails will appear here.</p>
                </div>
              )}
              {received.count > 0 && (
                <RecentEmails emailAddressCode={emailAddressCode} received={received.results} selected={selected} />
              )}
              {!emailAddressStatus.isEmailAddressEnabled && (
                <WebhookStatusCard message={"Email address is disabled."} />
              )}
            </div>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
