import React, { useState } from "react";
import { ChevronRight } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils/ui.util";
import { Label } from "@/components/ui/label";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ProjectResponseDto, useProjectsControllerGetProjects } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { TextField } from "@/components/form/elements/text-field";

// TODO: Fetch services and permissions from the server
const services = [
  "api-keys",
  "documents",
  "exceptions-inbox",
  "features",
  "files",
  "invitations",
  "hyperlinks",
  "secrets",
  "notifications",
  "projects",
  "teams",
  "users",
  "webhooks",
];

const permissions = ["read", "create", "list", "update", "delete"];

type PermissionTreeNode = {
  level: number;
  value: string;
  selected: boolean;
  children: PermissionTreeNode[] | null;
};

const updateNodeRecursively = (node: PermissionTreeNode, newSelectedState: boolean): PermissionTreeNode => {
  return {
    ...node,
    selected: newSelectedState,
    children: node.children?.map((child) => updateNodeRecursively(child, newSelectedState)) || null,
  };
};

interface PermissionTreeComponentProps {
  node: PermissionTreeNode;
  onToggle: (node: PermissionTreeNode) => void;
}

const PermissionTreeComponent: React.FC<PermissionTreeComponentProps> = ({ node, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => setIsOpen(!isOpen);

  const handleCheckboxToggle = () => {
    onToggle(node);
  };

  return (
    <div className={`space-y-2 pl-[20px]`}>
      <div className="flex items-center space-x-2">
        {node.children && (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => toggleSection()}
            className="transition-transform p-0"
            type="button"
          >
            <ChevronRight className={cn("h-4 w-4 transition-transform", isOpen ? "rotate-90" : "rotate-0")} />
          </Button>
        )}

        <Checkbox
          id={`select-${node.value}`}
          checked={node.selected}
          onCheckedChange={handleCheckboxToggle}
          className={cn(!node.children && "ml-10")}
        />

        <Label htmlFor={`select-${node.value}`} className="text-sm font-medium leading-none">
          {node.value}
        </Label>
      </div>

      {node.children && isOpen && (
        <>
          {node.children.map((childNode, index) => (
            <PermissionTreeComponent key={`${childNode.value}-${index}`} node={childNode} onToggle={onToggle} />
          ))}
        </>
      )}
    </div>
  );
};

export const ApiKeyPermissionsBuilder: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const { isPending, isError, data: projects } = useProjectsControllerGetProjects(teamCode);

  if (isPending) {
    return (
      <div className="flex items-center justify-center w-full h-[400px]">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (isError) {
    return <Navigate to="/404" replace={true} />;
  }

  return <PermissionTree projects={projects.results} />;
};

interface PermissionTreeProps {
  projects: ProjectResponseDto[];
}

export const PermissionTree: React.FC<PermissionTreeProps> = ({ projects }) => {
  const { teamCode } = useCurrentTeam();

  const [tree, setTree] = useState<PermissionTreeNode>({
    level: 3,
    value: teamCode,
    selected: true,
    children: projects.map((project) => ({
      level: 4,
      value: project.code,
      selected: true,
      children:
        project.environments?.map((environment) => ({
          level: 5,
          value: environment.code ?? "*",
          selected: true,
          children: services.map((service) => ({
            level: 6,
            value: service,
            selected: true,
            children: permissions.map((permission) => ({
              level: 7,
              value: permission,
              selected: true,
              children: null,
            })),
          })),
        })) || [],
    })),
  });

  const toggleNode = (nodeToToggle: PermissionTreeNode) => {
    const updateNode = (node: PermissionTreeNode): PermissionTreeNode => {
      if (node === nodeToToggle) {
        return updateNodeRecursively(node, !node.selected);
      }

      if (node.children) {
        const updatedChildren = node.children.map((child) => updateNode(child));

        return {
          ...node,
          children: updatedChildren,
          selected: updatedChildren.some((child) => child.selected),
        };
      }

      return node;
    };

    const bubbleUpSelection = (node: PermissionTreeNode): PermissionTreeNode => {
      if (!node.children) {
        return node;
      }

      const updatedChildren = node.children.map(bubbleUpSelection);
      const isAnyChildSelected = updatedChildren.some((child) => child.selected);

      return {
        ...node,
        selected: isAnyChildSelected || node.selected,
        children: updatedChildren,
      };
    };

    const newTree = updateNode(tree);
    setTree(bubbleUpSelection(newTree));
  };

  return (
    <div className="space-y-2">
      <p className="text-sm font-semibold">Permissions</p>
      <ScrollArea className="h-[40vh]">
        <PermissionTreeComponent node={tree} onToggle={toggleNode} />
      </ScrollArea>

      <DisplayPermissions permissionTree={tree} />
    </div>
  );
};

interface DisplayPermissionsProps {
  permissionTree: PermissionTreeNode;
}

const DisplayPermissions: React.FC<DisplayPermissionsProps> = ({ permissionTree }) => {
  const { teamCode } = useCurrentTeam();

  const areAllChildrenTokensIdentical = (map: Map<string, string[]>): boolean => {
    if (!map || map.size === 0) {
      throw new Error("Nothing to compare");
    }

    const values = Array.from(map.values());
    const reference = [...values[0]].sort();

    // compare the others to the first
    for (const tokens of values) {
      if (tokens.length !== reference.length) return false;

      const sortedArr = [...tokens].sort();
      for (let i = 0; i < reference.length; i++) {
        if (sortedArr[i] !== reference[i]) return false;
      }
    }

    return true;
  };

  const constructPermissions = React.useCallback(
    (
      currentLevel: number,
      currentLevelNodes: PermissionTreeNode[]
    ): {
      allSelected: boolean;
      tokens: string[];
    } => {
      const selected = currentLevelNodes.filter((node) => node.selected);
      if (selected.length === 0) {
        return { allSelected: false, tokens: [] };
      }
      const allSelectedCurrentLevel = selected.length === currentLevelNodes.length;

      if (currentLevel === 7) {
        // leaf nodes
        if (allSelectedCurrentLevel) {
          console.log(`Level ${currentLevel} returning tokens: ${["*"]}`);
          return { allSelected: true, tokens: ["*"] };
        }
        console.log(`Level ${currentLevel} returning tokens: ${selected.map((child) => child.value)}`);
        return { allSelected: true, tokens: selected.map((child) => child.value) };
      }

      let allBelowSelected: boolean = true;
      const childrenResultsMap = new Map<string, string[]>();

      for (const node of selected) {
        const nodeResult = constructPermissions(currentLevel + 1, node.children!);
        allBelowSelected = allBelowSelected && nodeResult.allSelected;
        childrenResultsMap.set(node.value, nodeResult.tokens);
      }
      // if all current nodes have the same children, and all are selected, we can wildcard
      if (allSelectedCurrentLevel) {
        if (areAllChildrenTokensIdentical(childrenResultsMap)) {
          const firstKey = Array.from(childrenResultsMap.keys())[0];
          if (!firstKey) throw new Error("Map is empty");

          return {
            allSelected: allBelowSelected && allSelectedCurrentLevel,
            tokens: childrenResultsMap.get(firstKey)?.map((t) => "*:" + t) ?? [],
          };
        }
      }
      const constructedTokens: string[] = [];
      for (const key of childrenResultsMap.keys()) {
        constructedTokens.push(...(childrenResultsMap.get(key)?.map((t) => key + ":" + t) ?? []));
      }
      const currentLevelResult = {
        allSelected: allBelowSelected && allSelectedCurrentLevel,
        tokens: constructedTokens,
      };
      console.log(`Level ${currentLevel} current tokens: ${currentLevelResult.tokens}`);
      return currentLevelResult;
    },
    []
  );

  const generatedPermissions = React.useMemo(() => {
    const prefix = `wrn:default:${teamCode}`;
    const result = constructPermissions(4, permissionTree.children!);
    return Array.from(new Set(result.tokens.map((t) => prefix + ":" + t))).sort();
  }, [permissionTree, constructPermissions, teamCode]);

  return (
    <div className="space-y-2">
      <p className="text-sm font-semibold">Generated Permissions:</p>
      <div className="bg-gray-100 p-2 rounded-lg h-[20vh]">
        {generatedPermissions && generatedPermissions.length > 0 ? (
          <ScrollArea className="h-full">
            {generatedPermissions.map((row, index) => (
              <div key={index} className="font-mono text-xs">
                {row}
              </div>
            ))}
          </ScrollArea>
        ) : (
          <div className="font-mono text-xs">None selected</div>
        )}
      </div>

      <div className="hidden">
        <TextField name="permissions" placeholder="Permissions" value={generatedPermissions.join("\n")} />
      </div>
    </div>
  );
};
