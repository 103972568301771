import { ChevronRight, FolderDot, Plus } from "lucide-react";

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Link, Navigate } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useProjectsControllerGetProjects } from "@/api/generated";
import { AddProjectDialog } from "@/lib/projects/add-project-dialog";

export const SidebarProjectsGroup: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const { isPending, isError, data: projects } = useProjectsControllerGetProjects(teamCode);

  const { open } = useSidebar();

  return (
    <SidebarGroup>
      <SidebarGroupLabel className="flex justify-between pr-0">
        Projects
        <div>
          <AddProjectDialog teamCode={teamCode}>
            <SidebarMenuButton>
              <Plus className="size-4" />
            </SidebarMenuButton>
          </AddProjectDialog>
        </div>
      </SidebarGroupLabel>
      <SidebarMenu>
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && projects && (
          <>
            {projects.results.map((project) => (
              <SidebarMenuItem>
                <SidebarMenuButton asChild tooltip={project.code}>
                  <Link to={`/dashboard/${teamCode}/${project.code}`} className="border-2 border-transparent no-underline text-secondary-950">
                    <span className="flex items-center">
                      {!open && <FolderDot className="mr-2 h-4 w-4" />}
                      <span className="truncate">{project.code}</span>
                    </span>
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </>
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
};
