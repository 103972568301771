import { ProjectResponseDto, useProjectsControllerGetProjects } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { ProjectIcon } from "@/components/project-icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { SidebarMenuButton, useSidebar } from "@/components/ui/sidebar";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { cn } from "@/utils/ui.util";
import { ChevronsUpDown } from "lucide-react";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const ProjectSelectDropdown: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { isPending, isError, data: projects } = useProjectsControllerGetProjects(teamCode);

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && projects && <ProjectSelectDropdownContent projects={projects.results} />}
    </>
  );
};

interface ProjectSelectDropdownContentProps {
  projects: ProjectResponseDto[];
}

export const ProjectSelectDropdownContent: React.FC<ProjectSelectDropdownContentProps> = ({ projects }) => {
  const navigate = useNavigate();
  const { teamCode } = useCurrentTeam();
  const { projectCode, project } = useCurrentProject();
  const { open } = useSidebar();

  const onSwitchProject = (targetProjectCode: string) => {
    navigate(`/dashboard/${teamCode}/${targetProjectCode}`);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton
          size="lg"
          className="pl-1 pr-2 data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground border-[2px] border-transparent data-[state=open]:neu-flat"
        >
          <ProjectIcon
            url={project.iconUrl}
            variant="neu-flat"
            className={cn("size-9 mr-2", !open && "size-7 border-[1px]")}
          />

          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-semibold">{projectCode}</span>
            <span className="truncate text-xs">Project</span>
          </div>
          <ChevronsUpDown className="ml-auto" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        align="start"
        side="bottom"
        sideOffset={4}
      >
        <DropdownMenuLabel className="text-xs text-muted-foreground">Projects</DropdownMenuLabel>
        <ScrollArea>
          <div className="max-h-[50dvh]">
            {projects.map((project, index) => (
              <DropdownMenuItem
                key={project.code}
                onClick={() => {
                  onSwitchProject(project.code);
                }}
                className="gap-2 p-2"
              >
                {project.code}
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
          </div>
        </ScrollArea>

        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
