import { LoadingSpinner } from "@/components/loading-spinner.tsx";
import { ManagerTable } from "@/components/manager-table.tsx";
import { Pagination, usePagination } from "@/components/pagination";
import { Navigate, useParams } from "react-router-dom";

import { useNotificationTopicsControllerGetChannelsPaginated } from "@/api/generated";
import { Button } from "@/components/ui/button.tsx";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { NotificationChannelUpsertDialog } from "@/lib/teams/notifications/channels/notifcation-channel-upsert-dialog.tsx";
import { notificationChannelsColumns } from "@/lib/teams/notifications/channels/notification-channels-columns.tsx";
import { Plus } from "lucide-react";

export const NotificationChannels: React.FC = () => {
  const { teamCode } = useParams();

  const pagination = usePagination();
  const { data, isPending, isError } = useNotificationTopicsControllerGetChannelsPaginated(
    teamCode ?? "",
    pagination.params
  );

  const channels = data?.results ?? [];

  return (
    <FeaturePage
      title="Notification Channels"
      description="Configure and manage notification channels for different platforms like Slack, Telegram, Discord, and HTTP endpoints. These channels serve as destinations for your notification topics."
      isLoading={isPending}
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && channels && (
        <>
          <ManagerTable
            columns={notificationChannelsColumns}
            data={channels}
            actions={
              <NotificationChannelUpsertDialog teamCode={teamCode as string}>
                <Button variant="neu" className="bg-secondary-300 justify-self-end">
                  Add new channel <Plus size={16} className="ml-1" />
                </Button>
              </NotificationChannelUpsertDialog>
            }
            searchBy="code"
            onChange={() => {}}
          />

          <Pagination className="pt-4" pagination={pagination} numberOfPages={data?.numberOfPages} />
        </>
      )}
    </FeaturePage>
  );
};
