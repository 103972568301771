/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreatePresignedSecretUrlDto,
  CreatePresignedSecretUrlResponseDto,
  CreateSecretDto,
  GetApiKeysForSigningSecretUrlResponseDto,
  PaginatedSecretsResponseDto,
  ProjectSecretResponseDto,
  SecretsSecretsControllerDeleteSecretsParams,
  SecretsSecretsControllerGetJsonSecretValueParams,
  SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  SecretsSecretsControllerGetSecretsForProjectParams,
  SecretsSecretsControllerGetSecretsForTeamParams,
  UpdateSecretDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create a new Secret item
 */
export const secretsSecretsControllerCreateSecret = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  createSecretDto: CreateSecretDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectSecretResponseDto>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createSecretDto,
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerCreateSecretMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateSecretDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: CreateSecretDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateSecretDto }
  > = (props) => {
    const { teamCode, projectCode, itemCode, data } = props ?? {};

    return secretsSecretsControllerCreateSecret(teamCode, projectCode, itemCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerCreateSecretMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>
>;
export type SecretsSecretsControllerCreateSecretMutationBody = CreateSecretDto;
export type SecretsSecretsControllerCreateSecretMutationError = ErrorType<unknown>;

/**
 * @summary Create a new Secret item
 */
export const useSecretsSecretsControllerCreateSecret = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: CreateSecretDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerCreateSecret>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: CreateSecretDto },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerCreateSecretMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update a Secret
 */
export const secretsSecretsControllerUpdateSecret = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  updateSecretDto: UpdateSecretDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProjectSecretResponseDto>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateSecretDto,
    },
    options
  );
};

export const getSecretsSecretsControllerUpdateSecretMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateSecretDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: UpdateSecretDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateSecretDto }
  > = (props) => {
    const { teamCode, projectCode, itemCode, data } = props ?? {};

    return secretsSecretsControllerUpdateSecret(teamCode, projectCode, itemCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerUpdateSecretMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>
>;
export type SecretsSecretsControllerUpdateSecretMutationBody = UpdateSecretDto;
export type SecretsSecretsControllerUpdateSecretMutationError = ErrorType<unknown>;

/**
 * @summary Update a Secret
 */
export const useSecretsSecretsControllerUpdateSecret = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; data: UpdateSecretDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecret>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; data: UpdateSecretDto },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerUpdateSecretMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a Secret by code
 */
export const secretsSecretsControllerGetSecret = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectSecretResponseDto>(
    { url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`, method: "GET", signal },
    options
  );
};

export const getSecretsSecretsControllerGetSecretQueryKey = (
  teamCode: string,
  projectCode: string,
  itemCode: string
) => {
  return [`/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}`] as const;
};

export const getSecretsSecretsControllerGetSecretQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSecretsSecretsControllerGetSecretQueryKey(teamCode, projectCode, itemCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>> = ({ signal }) =>
    secretsSecretsControllerGetSecret(teamCode, projectCode, itemCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode && itemCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SecretsSecretsControllerGetSecretQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>
>;
export type SecretsSecretsControllerGetSecretQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetSecret<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecret<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecret<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a Secret by code
 */

export function useSecretsSecretsControllerGetSecret<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecret>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetSecretQueryOptions(teamCode, projectCode, itemCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update raw JSON Secret
 */
export const secretsSecretsControllerUpdateJsonSecretValue = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/json`,
      method: "PUT",
    },
    options
  );
};

export const getSecretsSecretsControllerUpdateJsonSecretValueMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; environmentCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string }
  > = (props) => {
    const { teamCode, projectCode, itemCode, environmentCode } = props ?? {};

    return secretsSecretsControllerUpdateJsonSecretValue(
      teamCode,
      projectCode,
      itemCode,
      environmentCode,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerUpdateJsonSecretValueMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>
>;

export type SecretsSecretsControllerUpdateJsonSecretValueMutationError = ErrorType<unknown>;

/**
 * @summary Update raw JSON Secret
 */
export const useSecretsSecretsControllerUpdateJsonSecretValue = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateJsonSecretValue>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; environmentCode: string },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerUpdateJsonSecretValueMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a Secret's value, optionally a specific key from a JSON value
 */
export const secretsSecretsControllerGetJsonSecretValue = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/json`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerGetJsonSecretValueQueryKey = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams
) => {
  return [
    `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/json`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSecretsSecretsControllerGetJsonSecretValueQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSecretsSecretsControllerGetJsonSecretValueQueryKey(teamCode, projectCode, itemCode, environmentCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>> = ({ signal }) =>
    secretsSecretsControllerGetJsonSecretValue(
      teamCode,
      projectCode,
      itemCode,
      environmentCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && itemCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type SecretsSecretsControllerGetJsonSecretValueQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>
>;
export type SecretsSecretsControllerGetJsonSecretValueQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetJsonSecretValue<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetJsonSecretValue<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetJsonSecretValue<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a Secret's value, optionally a specific key from a JSON value
 */

export function useSecretsSecretsControllerGetJsonSecretValue<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  params: SecretsSecretsControllerGetJsonSecretValueParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetJsonSecretValue>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetJsonSecretValueQueryOptions(
    teamCode,
    projectCode,
    itemCode,
    environmentCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get api keys that give access to a secret
 */
export const secretsSecretsControllerGetValidApiKeys = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetApiKeysForSigningSecretUrlResponseDto>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/presigning-api-keys`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerGetValidApiKeysQueryKey = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string
) => {
  return [
    `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/presigning-api-keys`,
  ] as const;
};

export const getSecretsSecretsControllerGetValidApiKeysQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSecretsSecretsControllerGetValidApiKeysQueryKey(teamCode, projectCode, itemCode, environmentCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>> = ({ signal }) =>
    secretsSecretsControllerGetValidApiKeys(teamCode, projectCode, itemCode, environmentCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && itemCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type SecretsSecretsControllerGetValidApiKeysQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>
>;
export type SecretsSecretsControllerGetValidApiKeysQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetValidApiKeys<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetValidApiKeys<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetValidApiKeys<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get api keys that give access to a secret
 */

export function useSecretsSecretsControllerGetValidApiKeys<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetValidApiKeys>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetValidApiKeysQueryOptions(
    teamCode,
    projectCode,
    itemCode,
    environmentCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete one or more Secrets
 */
export const secretsSecretsControllerDeleteSecrets = (
  teamCode: string,
  projectCode: string,
  params: SecretsSecretsControllerDeleteSecretsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}`, method: "DELETE", params },
    options
  );
};

export const getSecretsSecretsControllerDeleteSecretsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>,
    TError,
    { teamCode: string; projectCode: string; params: SecretsSecretsControllerDeleteSecretsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>,
  TError,
  { teamCode: string; projectCode: string; params: SecretsSecretsControllerDeleteSecretsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>,
    { teamCode: string; projectCode: string; params: SecretsSecretsControllerDeleteSecretsParams }
  > = (props) => {
    const { teamCode, projectCode, params } = props ?? {};

    return secretsSecretsControllerDeleteSecrets(teamCode, projectCode, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerDeleteSecretsMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>
>;

export type SecretsSecretsControllerDeleteSecretsMutationError = ErrorType<unknown>;

/**
 * @summary Delete one or more Secrets
 */
export const useSecretsSecretsControllerDeleteSecrets = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>,
    TError,
    { teamCode: string; projectCode: string; params: SecretsSecretsControllerDeleteSecretsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerDeleteSecrets>>,
  TError,
  { teamCode: string; projectCode: string; params: SecretsSecretsControllerDeleteSecretsParams },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerDeleteSecretsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a pre-signed URL for operations on a Secret value
 */
export const secretsSecretsControllerGetSecretPresignedUrl = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  createPresignedSecretUrlDto: CreatePresignedSecretUrlDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CreatePresignedSecretUrlResponseDto>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/presigned`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createPresignedSecretUrlDto,
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerGetSecretPresignedUrlMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: CreatePresignedSecretUrlDto;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    itemCode: string;
    environmentCode: string;
    data: CreatePresignedSecretUrlDto;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: CreatePresignedSecretUrlDto;
    }
  > = (props) => {
    const { teamCode, projectCode, itemCode, environmentCode, data } = props ?? {};

    return secretsSecretsControllerGetSecretPresignedUrl(
      teamCode,
      projectCode,
      itemCode,
      environmentCode,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerGetSecretPresignedUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>
>;
export type SecretsSecretsControllerGetSecretPresignedUrlMutationBody = CreatePresignedSecretUrlDto;
export type SecretsSecretsControllerGetSecretPresignedUrlMutationError = ErrorType<unknown>;

/**
 * @summary Get a pre-signed URL for operations on a Secret value
 */
export const useSecretsSecretsControllerGetSecretPresignedUrl = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      itemCode: string;
      environmentCode: string;
      data: CreatePresignedSecretUrlDto;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretPresignedUrl>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    itemCode: string;
    environmentCode: string;
    data: CreatePresignedSecretUrlDto;
  },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerGetSecretPresignedUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a paginated list of Secrets by Project and Environment
 */
export const secretsSecretsControllerGetSecretsForProjectAndEnvironment = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSecretsResponseDto>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/environment/${environmentCode}/items`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryKey = (
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams
) => {
  return [
    `/v1/secrets/teams/${teamCode}/projects/${projectCode}/environment/${environmentCode}/items`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryKey(
      teamCode,
      projectCode,
      environmentCode,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>
  > = ({ signal }) =>
    secretsSecretsControllerGetSecretsForProjectAndEnvironment(
      teamCode,
      projectCode,
      environmentCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>
>;
export type SecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetSecretsForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params: undefined | SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Secrets by Project and Environment
 */

export function useSecretsSecretsControllerGetSecretsForProjectAndEnvironment<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  environmentCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectAndEnvironmentParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProjectAndEnvironment>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetSecretsForProjectAndEnvironmentQueryOptions(
    teamCode,
    projectCode,
    environmentCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a paginated list of Secrets by Project
 */
export const secretsSecretsControllerGetSecretsForProject = (
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSecretsResponseDto>(
    { url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items`, method: "GET", params, signal },
    options
  );
};

export const getSecretsSecretsControllerGetSecretsForProjectQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams
) => {
  return [`/v1/secrets/teams/${teamCode}/projects/${projectCode}/items`, ...(params ? [params] : [])] as const;
};

export const getSecretsSecretsControllerGetSecretsForProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSecretsSecretsControllerGetSecretsForProjectQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>> = ({
    signal,
  }) => secretsSecretsControllerGetSecretsForProject(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SecretsSecretsControllerGetSecretsForProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>
>;
export type SecretsSecretsControllerGetSecretsForProjectQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetSecretsForProject<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | SecretsSecretsControllerGetSecretsForProjectParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForProject<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForProject<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Secrets by Project
 */

export function useSecretsSecretsControllerGetSecretsForProject<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: SecretsSecretsControllerGetSecretsForProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetSecretsForProjectQueryOptions(
    teamCode,
    projectCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a paginated list of Secrets by Team
 */
export const secretsSecretsControllerGetSecretsForTeam = (
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSecretsResponseDto>(
    { url: `/v1/secrets/teams/${teamCode}/items`, method: "GET", params, signal },
    options
  );
};

export const getSecretsSecretsControllerGetSecretsForTeamQueryKey = (
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams
) => {
  return [`/v1/secrets/teams/${teamCode}/items`, ...(params ? [params] : [])] as const;
};

export const getSecretsSecretsControllerGetSecretsForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSecretsSecretsControllerGetSecretsForTeamQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>> = ({ signal }) =>
    secretsSecretsControllerGetSecretsForTeam(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SecretsSecretsControllerGetSecretsForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>
>;
export type SecretsSecretsControllerGetSecretsForTeamQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetSecretsForTeam<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | SecretsSecretsControllerGetSecretsForTeamParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForTeam<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretsForTeam<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a paginated list of Secrets by Team
 */

export function useSecretsSecretsControllerGetSecretsForTeam<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: SecretsSecretsControllerGetSecretsForTeamParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretsForTeam>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetSecretsForTeamQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a Secret value formatted as an environment variable
 */
export const secretsSecretsControllerGetSecretAsEnv = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<string>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/dotenv`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSecretsSecretsControllerGetSecretAsEnvQueryKey = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string
) => {
  return [
    `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/dotenv`,
  ] as const;
};

export const getSecretsSecretsControllerGetSecretAsEnvQueryOptions = <
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSecretsSecretsControllerGetSecretAsEnvQueryKey(teamCode, projectCode, itemCode, environmentCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>> = ({ signal }) =>
    secretsSecretsControllerGetSecretAsEnv(teamCode, projectCode, itemCode, environmentCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && itemCode && environmentCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type SecretsSecretsControllerGetSecretAsEnvQueryResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>
>;
export type SecretsSecretsControllerGetSecretAsEnvQueryError = ErrorType<unknown>;

export function useSecretsSecretsControllerGetSecretAsEnv<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretAsEnv<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSecretsSecretsControllerGetSecretAsEnv<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a Secret value formatted as an environment variable
 */

export function useSecretsSecretsControllerGetSecretAsEnv<
  TData = Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof secretsSecretsControllerGetSecretAsEnv>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSecretsSecretsControllerGetSecretAsEnvQueryOptions(
    teamCode,
    projectCode,
    itemCode,
    environmentCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a Secret value with a dotenv-formatted request
 */
export const secretsSecretsControllerUpdateSecretAsDotenv = (
  teamCode: string,
  projectCode: string,
  itemCode: string,
  environmentCode: string,
  secretsSecretsControllerUpdateSecretAsDotenvBody: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/v1/secrets/teams/${teamCode}/projects/${projectCode}/items/${itemCode}/environments/${environmentCode}/dotenv`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: secretsSecretsControllerUpdateSecretAsDotenvBody,
    },
    options
  );
};

export const getSecretsSecretsControllerUpdateSecretAsDotenvMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; environmentCode: string; data: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string; data: string }
  > = (props) => {
    const { teamCode, projectCode, itemCode, environmentCode, data } = props ?? {};

    return secretsSecretsControllerUpdateSecretAsDotenv(
      teamCode,
      projectCode,
      itemCode,
      environmentCode,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SecretsSecretsControllerUpdateSecretAsDotenvMutationResult = NonNullable<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>
>;
export type SecretsSecretsControllerUpdateSecretAsDotenvMutationBody = string;
export type SecretsSecretsControllerUpdateSecretAsDotenvMutationError = ErrorType<unknown>;

/**
 * @summary Update a Secret value with a dotenv-formatted request
 */
export const useSecretsSecretsControllerUpdateSecretAsDotenv = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>,
    TError,
    { teamCode: string; projectCode: string; itemCode: string; environmentCode: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof secretsSecretsControllerUpdateSecretAsDotenv>>,
  TError,
  { teamCode: string; projectCode: string; itemCode: string; environmentCode: string; data: string },
  TContext
> => {
  const mutationOptions = getSecretsSecretsControllerUpdateSecretAsDotenvMutationOptions(options);

  return useMutation(mutationOptions);
};
