import { filesControllerInitiateUploadForTeamAndProject } from "@/api/generated";
import { createTask } from "@lilbunnyrabbit/task-manager";

export const CHUNK_SIZE = 5 * 1024 * 1024;

export interface FixedInitiateUploadResponseDto {
  uploadId: string;
  parts: Array<{
    url: string;
    partNumber: number;
  }>;
}

export type PartData = FixedInitiateUploadResponseDto["parts"][number];

// TODO: Update types when fixed
export default createTask<{ file: File; teamCode: string; projectCode: string }, FixedInitiateUploadResponseDto>({
  name: "Prepare file upload",

  parse() {
    switch (this.status) {
      default:
      case "idle": {
        return {
          status: `Prepare "${this.data.file.name}" for upload.`,
        };
      }
      case "in-progress": {
        return {
          status: `Preparing "${this.data.file.name}"...`,
        };
      }
      case "error": {
        return {
          status: `Failed to prepare "${this.data.file.name}"...`,
        };
      }
      case "success": {
        if (this.result.isPresent()) {
          return {
            status: `"${this.data.file.name}" prepared!`,
            result: JSON.stringify(this.result.get(), null, 2),
          };
        }

        return {
          status: `"${this.data.file.name}" prepared!`,
        };
      }
    }
  },

  async execute({ file, teamCode, projectCode }) {
    return (await filesControllerInitiateUploadForTeamAndProject(teamCode, projectCode, {
      filename: file.name,
      fileSize: file.size,
      contentType: file.type,
      // TODO: Specify path
      path: "",
    })) as unknown as FixedInitiateUploadResponseDto;
  },
});
