import React from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock, ChevronDown, ChevronUp, CheckCircle, XCircle, AlertTriangle } from "lucide-react";

type HyperlinkMonitorHttpCheckResult = {
  status: number;
  statusText: string;
  durationInMs: number;
  headers?: Record<string, string | number> | undefined;
  body?: string | undefined;
};

interface UrlHealthRestRunDetailsProps {
  rawData: any;
}

export const UrlHealthRestRunDetails: React.FC<UrlHealthRestRunDetailsProps> = ({ rawData }) => {
  const data = rawData as HyperlinkMonitorHttpCheckResult;
  const [isHeadersExpanded, setIsHeadersExpanded] = React.useState(false);
  const [isBodyExpanded, setIsBodyExpanded] = React.useState(false);

  const getStatusBadge = (status: number) => {
    if (status >= 200 && status < 300) {
      return (
        <Badge className="bg-green-500 hover:bg-green-600">
          <CheckCircle className="mr-1 h-4 w-4" />
          Success
        </Badge>
      );
    } else if (status >= 300 && status < 400) {
      return (
        <Badge className="bg-blue-500 hover:bg-blue-600">
          <AlertTriangle className="mr-1 h-4 w-4" />
          Redirection
        </Badge>
      );
    } else {
      return (
        <Badge className="bg-red-500 hover:bg-red-600">
          <XCircle className="mr-1 h-4 w-4" />
          Error
        </Badge>
      );
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg font-semibold leading-none tracking-tight">URL Health (REST)</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Status</h2>
          <div className="flex items-center space-x-2">
            {getStatusBadge(data.status)}
            <span>
              {data.status} {data.statusText}
            </span>
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="text-sm font-medium text-gray-500">Duration</h2>
          <p>
            <Clock className="inline mr-2 h-5 w-5" />
            {data.durationInMs} ms
          </p>
        </div>
        {data.headers && (
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-medium text-gray-500">Headers</h2>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsHeadersExpanded(!isHeadersExpanded)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {isHeadersExpanded ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Collapse
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    Expand
                  </>
                )}
              </Button>
            </div>
            {isHeadersExpanded && (
              <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm">
                {JSON.stringify(data.headers, null, 2)}
              </pre>
            )}
          </div>
        )}
        {data.body && (
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-medium text-gray-500">Body</h2>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsBodyExpanded(!isBodyExpanded)}
                className="text-gray-500 hover:text-gray-900 h-5"
              >
                {isBodyExpanded ? (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    Collapse
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    Expand
                  </>
                )}
              </Button>
            </div>
            {isBodyExpanded && <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm">{data.body}</pre>}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
