import {
  getTeamsControllerListTeamsQueryKey,
  useAuthControllerSwitchTeam,
  useTeamsControllerCreateTeam,
} from "@/api/generated";
import { useAuth } from "@/auth/useAuth";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const createTeamSchema = z.object({
  name: z.string().min(1, { message: "Team name is required" }).max(250),
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
});

export const CreateTeamForm: React.FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const createTeamMutation = useTeamsControllerCreateTeam();
  const queryClient = useQueryClient();

  const { switchLoggedInTeam } = useAuth();

  const switchTeamMutation = useAuthControllerSwitchTeam();

  const onSubmit = async (values: z.infer<typeof createTeamSchema>) => {
    try {
      await createTeamMutation.mutateAsync({
        data: {
          name: values.name,
          code: values.code,
        },
      });

      const data = await switchTeamMutation.mutateAsync({
        teamCode: values.code,
      });

      if (data && data.accessToken) {
        await queryClient.refetchQueries({ queryKey: getTeamsControllerListTeamsQueryKey() });
        switchLoggedInTeam(data.accessToken);
        navigate(`/dashboard/${values.code}`);
      }
    } catch (error) {
      toast(defaultError);
    }
  };

  return (
    <Form schema={createTeamSchema} onSubmit={onSubmit}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <TextField name="name" placeholder="Team Name" />
          <TextField name="code" placeholder="Code" />
        </div>
        <Button
          type="submit"
          scheme="black"
          size="sm"
          disabled={createTeamMutation.isPending || switchTeamMutation.isPending}
          className="bg-secondary-950"
        >
          {createTeamMutation.isPending || switchTeamMutation.isPending ? (
            <LoadingSpinner message={"Creating Team"} />
          ) : (
            "Create Team"
          )}
        </Button>
      </div>
    </Form>
  );
};
