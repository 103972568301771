import { Button } from "@/components/ui/button";
import { cn } from "@/utils/ui.util";

interface QuickOptionProps {
  name?: string;
  description?: string | React.ReactNode;
  icon: React.ReactNode;
  handleOnClick?: () => void;
  disabled?: boolean;
  className?: string;
}

export const QuickOption: React.FC<QuickOptionProps> = ({
  name,
  description,
  icon,
  handleOnClick,
  disabled,
  className,
}) => {
  return (
    <Button
      type="button"
      className={cn("bg-secondary-200 min-h-36 w-64", className)}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <div className="flex flex-col items-center gap-4">
        <div>{icon}</div>
        <div>
          <h6 className="font-semibold text-wrap">{name}</h6>
          {description && <p className="text-sm text-gray-600 text-wrap line-clamp-2">{description}</p>}
        </div>
      </div>
    </Button>
  );
};
