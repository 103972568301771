import { TeamMembers } from "@/lib/teams/dashboard/team-members";
import { UpdateTeam } from "@/lib/teams/settings/update-team";
import React from "react";

export const TeamSettings: React.FC = () => {
  return (
    <>
      <h3 className="font-bold tracking-tight">Team Settings</h3>

      <div className="grid xl:grid-cols-5 gap-6">
        <div className="xl:col-span-3">
          <UpdateTeam />
        </div>
        <div className="xl:col-span-2">
          <TeamMembers />
        </div>
      </div>
    </>
  );
};
