/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation } from "@tanstack/react-query";
import type { MutationFunction, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import type { HttpRequesterControllerSendRequestParams, SendHttpRequestDto } from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const httpRequesterControllerSendRequest = (
  teamCode: string,
  projectCode: string,
  sendHttpRequestDto: SendHttpRequestDto,
  params?: HttpRequesterControllerSendRequestParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    {
      url: `/v1/http-requester/${teamCode}/${projectCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendHttpRequestDto,
      params,
      signal,
    },
    options
  );
};

export const getHttpRequesterControllerSendRequestMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      data: SendHttpRequestDto;
      params?: HttpRequesterControllerSendRequestParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    data: SendHttpRequestDto;
    params?: HttpRequesterControllerSendRequestParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>,
    {
      teamCode: string;
      projectCode: string;
      data: SendHttpRequestDto;
      params?: HttpRequesterControllerSendRequestParams;
    }
  > = (props) => {
    const { teamCode, projectCode, data, params } = props ?? {};

    return httpRequesterControllerSendRequest(teamCode, projectCode, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HttpRequesterControllerSendRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>
>;
export type HttpRequesterControllerSendRequestMutationBody = SendHttpRequestDto;
export type HttpRequesterControllerSendRequestMutationError = ErrorType<unknown>;

export const useHttpRequesterControllerSendRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>,
    TError,
    {
      teamCode: string;
      projectCode: string;
      data: SendHttpRequestDto;
      params?: HttpRequesterControllerSendRequestParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof httpRequesterControllerSendRequest>>,
  TError,
  {
    teamCode: string;
    projectCode: string;
    data: SendHttpRequestDto;
    params?: HttpRequesterControllerSendRequestParams;
  },
  TContext
> => {
  const mutationOptions = getHttpRequesterControllerSendRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
