import { Button } from "@/components/ui/button";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowDown, ArrowUp } from "lucide-react";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { SecretCodeCell } from "./secrets-cells";

export const secretsColumns: ColumnDef<Fixed__ProjectSecretResponseDto>[] = [
  {
    accessorKey: "code",
    header: ({ column }) => {
      const isSorted = column.getIsSorted();

      return (
        <Button variant="ghost" className="px-0 bg-transparent" onClick={() => column.toggleSorting(isSorted === "asc")}>
          Code
          {isSorted && isSorted === "asc" ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: SecretCodeCell,
    meta: {
      className: {
        cell: "text-wrap",
      },
      customWidth: "auto",
    },
  },
];
