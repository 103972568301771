import { cn } from "@/utils/ui.util";
import { FieldErrors, FieldValues, get } from "react-hook-form";

export function FieldError<T extends FieldValues = FieldValues>({
  errors,
  name,
  className,
}: {
  errors: FieldErrors<T>;
  name: string;
  className?: string;
}) {
  const fieldError = get(errors, name);

  if (!fieldError) return undefined;

  return <span className={cn("text-sm text-red-600 font-medium", className)}>{fieldError?.message as string}</span>;
}
