import React from "react";
import { z } from "zod";
import { useInvitationsControllerCreateInvite } from "@/api/generated";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { defaultError } from "@/lib/auth/utils/default-error-message";
import { useCurrentTeam } from "@/lib/teams/context/team-context";

const loginSchema = z.object({
  teamCode: z.string().optional(),
  recipientEmail: z.string().email(),
});

interface SendInvitationDialogProps {
  children: React.ReactNode;
}

export const SendInvitationDialog: React.FC<SendInvitationDialogProps> = ({ children }) => {
  const { teamCode } = useCurrentTeam();
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const createInvitationMutation = useInvitationsControllerCreateInvite();

  const onSubmit = async (values: z.infer<typeof loginSchema>) => {
    try {
      const data = await createInvitationMutation.mutateAsync({
        data: {
          teamCode: teamCode,
          recipientEmail: values.recipientEmail,
          expiresInSeconds: 60 * 60 * 24 * 7, // 7 days
          redirectUrl: `${window.location.origin}/accept-invite`,
        },
      });

      if (data) {
        setOpen(false);
        toast({
          title: "Invite sent successfully",
          description: `An invitation has been sent to ${values.recipientEmail}.`,
        });
      }
    } catch (error) {
      toast(defaultError);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Send invitation</DialogTitle>
        <DialogDescription>
          Send an invitation to join this team to a user by entering their email address.
        </DialogDescription>

        <Form schema={loginSchema} onSubmit={onSubmit}>
          <div className="flex flex-col gap-6">
            <div className="space-y-1">
              <TextField name="recipientEmail" label="Recipient email" />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                variant="neu-flat"
                size="sm"
                className="bg-secondary-300"
                disabled={createInvitationMutation.isPending}
              >
                {createInvitationMutation.isPending ? (
                  <LoadingSpinner message={"Sending invitation"} />
                ) : (
                  "Send invitation"
                )}
              </Button>
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
