import { Input, InputProps, inputVariants } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { VariantProps } from "class-variance-authority";
import React from "react";
import { get, useFormContext } from "react-hook-form";

interface TextFieldProps {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  type?: string;
  defaultValue?: string | number | readonly string[] | undefined;
  value?: string | number | readonly string[] | undefined;
  disabled?: boolean;
  variant?: VariantProps<typeof inputVariants>["variant"];
  style?: React.CSSProperties;
}

export const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  defaultValue,
  value,
  disabled,
  variant,
  style,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  React.useEffect(() => {
    if (value !== undefined) {
      setValue(name, value);
    }
  }, [value, name, setValue]);

  const fieldError = get(errors, name);

  return (
    <>
      {label && (
        <Label htmlFor={name} className="text-sm font-semibold">
          {label}
        </Label>
      )}
      <Input
        defaultValue={defaultValue}
        value={value}
        id={name}
        type={type}
        {...register(name)}
        placeholder={placeholder}
        disabled={disabled}
        variant={variant}
        style={style}
      />
      {fieldError && <span className="text-sm text-red-600 font-medium">{fieldError?.message as string}</span>}
    </>
  );
};

export interface TextFieldBaseProps extends InputProps {
  label?: React.ReactNode;
  error?: any;
  description?: React.ReactNode;
}

export const TextFieldBase = React.forwardRef<HTMLInputElement, TextFieldBaseProps>(
  ({ label, error, description, ...props }, ref) => {
    return (
      <>
        {label && (
          <Label htmlFor={props.name} className="text-sm font-semibold">
            {label}
          </Label>
        )}
        <Input ref={ref} {...props} />

        {!error && description && (
          <span className="text-sm text-secondary-950/60 font-light text-justify">{description}</span>
        )}

        {error && <span className="text-sm text-red-600 font-medium text-justify">{error?.message}</span>}
      </>
    );
  }
);
Input.displayName = "TextFieldBase";

export const FieldError: React.FC<TextFieldProps> = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, name);

  return fieldError && <span className="text-sm text-red-600 font-medium">{fieldError?.message as string}</span>;
};
