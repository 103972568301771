import { MonitorEventsForSingleHyperlinkAndEnvironmentDto } from "@/api/generated";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/utils/ui.util";
import React from "react";
import { DisplayMonitorEvents } from "@/lib/projects/monitoring/elements/display-monitor-events";
import { Badge } from "@/components/ui/badge";
import { MonitoringJobs } from "@/lib/projects/monitoring/utils";

interface MonitorSelectProps {
  monitorResults: MonitorEventsForSingleHyperlinkAndEnvironmentDto;
  selectedMonitor: string;
  setSelectedMonitor: (monitor: string) => void;
  hyperlinkCode: string;
  hyperlinkItemValueId: number;
}

export const MonitorSelect: React.FC<MonitorSelectProps> = ({
  monitorResults,
  selectedMonitor,
  setSelectedMonitor,
  hyperlinkCode,
  hyperlinkItemValueId,
}) => {
  const monitorEvents = Object.keys(monitorResults.monitorEvents);

  return (
    <Card>
      <CardContent className="p-4 space-y-4">
        {monitorEvents.map((monitor) => {
          const monitorKey = Object.values(MonitoringJobs).find((job) => job.key === monitor)?.key;
          const monitorLabel = Object.values(MonitoringJobs).find((job) => job.key === monitor)?.label;

          if (!monitorKey || !monitorLabel) {
            return null;
          }

          const monitorEventInfo = monitorResults.monitorEvents[monitor];

          if (monitorEventInfo.length === 0) {
            return null;
          }

          const monitorStatus = Math.floor(
            (monitorEventInfo.reduce((acc: number, event) => {
              if (event.result === "success") {
                return acc + 1;
              }

              if (event.result === "success-with-warning") {
                return acc + 0.5;
              }

              return acc;
            }, 0) /
              monitorEventInfo.length) *
              100
          );

          return (
            <Button
              variant="ghost"
              key={monitor}
              className={cn(
                "flex items-center justify-between gap-4 w-full h-full hover:bg-secondary-200",
                selectedMonitor === monitor && "bg-secondary-200"
              )}
              onClick={() => setSelectedMonitor(monitor)}
            >
              <div className="flex items-center gap-2">
                <Badge
                  variant="secondary"
                  className={cn(
                    monitorStatus >= 90 && "bg-green-500",
                    monitorStatus >= 70 && monitorStatus < 90 && "bg-yellow-500",
                    monitorStatus < 70 && "bg-red-500"
                  )}
                >
                  {monitorStatus}%
                </Badge>
                <span className="text-sm text-foreground break-all line-clamp-1 text-wrap">{monitorLabel}</span>
              </div>

              <DisplayMonitorEvents
                monitor={monitorKey}
                hyperlinkCode={hyperlinkCode}
                hyperlinkItemValueId={hyperlinkItemValueId}
                monitorEvents={monitorEventInfo}
                limit={6}
                variant="hover"
              />
            </Button>
          );
        })}
      </CardContent>
    </Card>
  );
};
