import { useSecretsSecretsControllerGetSecretsForProject } from "@/api/generated";
import { EnvHeader } from "@/components/env-header";
import { LoadingSpinner } from "@/components/loading-spinner";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { Fixed__ProjectSecretResponseDto } from "@/lib/projects/item/project-items-helpers";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { EditSecretDialog } from "@/lib/projects/secrets/edit-secret-dialog";
import { SecretActionCell } from "@/lib/projects/secrets/secrets-action-cell";
import { SecretEnvCell } from "@/lib/projects/secrets/secrets-cells";
import { secretsColumns } from "@/lib/projects/secrets/secrets-config";
import { SecretsContextProvider } from "@/lib/projects/secrets/secrets-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { Navigate } from "react-router-dom";

export const Secrets: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode, projectEnvironments } = useCurrentProject();

  const pagination = usePagination();
  const {
    isPending,
    isFetching,
    isError,
    data: secrets,
    refetch,
  } = useSecretsSecretsControllerGetSecretsForProject(teamCode, projectCode, pagination.params);

  const environmentsColumns: ColumnDef<Fixed__ProjectSecretResponseDto>[] = React.useMemo(() => {
    return projectEnvironments.map((e) => {
      return {
        header: () => <EnvHeader environment={e} />,
        accessorKey: `values.${e.code}.value`,
        cell: (args) => <SecretEnvCell environment={e} refetch={refetch} {...args} />,
        minSize: 0,
        meta: {
          customWidth: "unset",
          className: {
            header: "p-0",
          },
        },
      } satisfies ColumnDef<Fixed__ProjectSecretResponseDto>;
    });
  }, [projectEnvironments, refetch]);

  const columns: ColumnDef<Fixed__ProjectSecretResponseDto>[] = React.useMemo(() => {
    return [
      ...secretsColumns,
      ...environmentsColumns,
      {
        id: "$add-column",
        header: "Actions",
        cell: SecretActionCell,
        meta: {
          className: {
            header: "text-center font-bold",
          },
        },
        minSize: 128,
        size: 128,
        maxSize: 128,
      },
    ];
  }, [environmentsColumns]);

  return (
    <FeaturePage
      title="Secrets"
      description="Manage your project's secrets. Secrets are encrypted values that can be used in your project's configuration files."
      isLoading={isPending || isFetching}
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}

      {isError && <Navigate to="/404" replace={true} />}

      <SecretsContextProvider>
        {!isPending && !isError && secrets && (
          <>
            <ManagerTable
              columns={columns}
              data={secrets.results as unknown as Fixed__ProjectSecretResponseDto[]}
              searchBy="code"
            />

            <EditSecretDialog onSuccess={() => refetch()}>
              <Button className="bg-secondary-200">Create Secret</Button>
            </EditSecretDialog>

            <Pagination className="pt-4" pagination={pagination} numberOfPages={secrets?.numberOfPages} />
          </>
        )}
      </SecretsContextProvider>
    </FeaturePage>
  );
};
