import { useProjectsControllerGetProjects, useTeamsControllerGetTeam } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { TeamIcon } from "@/components/project-icon";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
  useSidebar,
} from "@/components/ui/sidebar";
import { DashboardLayoutContent } from "@/layouts/dashboard/elements/dashboard-layout-content";
import { SidebarFeaturesGroup } from "@/layouts/dashboard/sidebar-elements/sidebar-features-group";
import { ProjectProvider } from "@/lib/projects/context/project-context";
import { ProjectSelectDropdown } from "@/lib/projects/project-select-dropdown";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { SendInvitationDialog } from "@/lib/teams/invitations/send-invitation-dialog";
import { cn } from "@/utils/ui.util";
import { ChevronRight, UserPlus } from "lucide-react";
import { Link, Navigate, useParams } from "react-router-dom";

export const ProjectDashboardLayout: React.FC = () => {
  const { projectCode } = useParams();
  const { teamCode } = useCurrentTeam();

  const { isPending, isError, data: projects, refetch } = useProjectsControllerGetProjects(teamCode);

  if (isPending) {
    return (
      <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
        <LoadingSpinner message="Loading..." />
      </div>
    );
  }

  if (isError) {
    return <Navigate to="/404" replace />;
  }

  if (!projects || projects.results.length === 0) {
    return <Navigate to={`/dashboard/${teamCode}`} replace={true} />;
  }

  if (!projectCode) {
    return <Navigate to={`/dashboard/${teamCode}/${projects.results[0].code}`} replace={true} />;
  }

  const currentProject = projects.results.find((project) => project.code === projectCode);

  if (!currentProject) {
    return <Navigate to={`/dashboard/${teamCode}/${projects.results[0].code}`} replace={true} />;
  }

  return (
    <>
      <ProjectProvider
        project={currentProject}
        projectCode={currentProject.code}
        projectEnvironments={currentProject.environments ?? []}
        refetch={refetch}
      >
        <SidebarProvider>
          <ProjectLayoutSidebar />
          <DashboardLayoutContent />
        </SidebarProvider>
      </ProjectProvider>
    </>
  );
};

const ProjectLayoutSidebar: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { data: team, isLoading } = useTeamsControllerGetTeam(teamCode);
  const { open } = useSidebar();

  return (
    <Sidebar collapsible="icon" className="z-10">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <Link
                to={`/dashboard/${teamCode}`}
                className="pl-1 pr-2 no-underline text-secondary-950 border-[2px] border-transparent"
              >
                <TeamIcon
                  url={team?.iconUrl}
                  loading={isLoading}
                  variant="neu-flat"
                  className={cn("size-9 mr-2", !open && "size-7 border-[1px]")}
                />
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{team?.name ?? teamCode}</span>
                  <span className="truncate text-xs">Team</span>
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarSeparator />

      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <ProjectSelectDropdown />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarContent className="gap-0">
        <SidebarFeaturesGroup />
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SendInvitationDialog>
              <SidebarMenuButton className="h-12 border-2 border-transparent">
                <UserPlus />
                <span>Invite Members</span>
                <ChevronRight className="ml-auto" />
              </SidebarMenuButton>
            </SendInvitationDialog>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};
