import { DocumentResponseDto, useDocumentsControllerCreateDocument } from "@/api/generated";
import { TextField } from "@/components/form/elements/text-field";
import { Form } from "@/components/form/form";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { generateFilename } from "@/lib/projects/documents/document-utils";
import { onErrorToast } from "@/utils/api.util";
import { Plus } from "lucide-react";
import React from "react";
import { z } from "zod";

const createDocumentSchema = z.object({
  title: z.string().min(1, "Title is required").max(128, "Max 128 characters"),
});

interface AddDocumentDialogProps {
  teamCode: string;
  projectCode: string;
  onSuccess?: (document: DocumentResponseDto) => void;
  children: React.ReactNode;
}

export const AddDocumentDialog: React.FC<AddDocumentDialogProps> = ({ teamCode, projectCode, children, onSuccess }) => {
  const [open, setOpen] = React.useState(false);

  const createMutation = useDocumentsControllerCreateDocument({
    mutation: {
      onSuccess: (document) => {
        setOpen(false);
        createMutation.reset();

        onSuccess?.(document);
      },
      onError: onErrorToast,
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Create new document</DialogTitle>
        <DialogDescription>
          Create a new document in this project. Documents are markdown files that can be used to document your project.
        </DialogDescription>
        <Form
          schema={createDocumentSchema}
          onSubmit={(data, reset) => {
            createMutation.mutate(
              {
                teamCode,
                projectCode,
                data: {
                  title: data.title,
                  mimeType: "text/markdown",
                  content: `# ${data.title}`,
                  filename: generateFilename(data.title),
                },
              },
              {
                onSuccess: () => reset({ title: "" }),
              }
            );
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="space-y-1">
              <TextField name="title" label="Title" placeholder="Input title..." />
            </div>

            <div className="flex justify-end mt-4">
              <Button type="submit" size="sm" variant="neu-flat" className="bg-secondary-300" disabled={false}>
                {createMutation.isPending ? (
                  <LoadingSpinner message={"Creating"} />
                ) : (
                  <>
                    Create Document <Plus size={16} className="ml-1" />
                  </>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
