import {
  FeatureConditionalValueDto,
  FeatureConditionalValueDtoConditionsItem,
  FeatureConditionalValueDtoConditionsItemOperation,
  FeatureEnvironmentValueDto,
  FeatureFlagDtoEnvValuesItem,
  FeatureFlagDtoEnvValuesItemConditionalValuesItem,
  FeatureFlagDtoEnvValuesItemConditionalValuesItemConditionsItem,
  FeatureFlagDtoEnvValuesItemConditionalValuesItemConditionsItemOperation,
  FeatureValueDto,
  useFeaturesControllerCreateOrUpdateFeature,
  useFeaturesControllerDeleteFeature,
  useFeaturesControllerGetFeature,
} from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { AddEnvironmentDialog } from "@/lib/projects/environments/add-environment-dialog";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { cn } from "@/utils/ui.util";
import { SelectTrigger } from "@radix-ui/react-select";
import { formatDistanceToNow as unsafeFormatDistanceToNow } from "date-fns";
import { ArrowRight, Calendar, History, Plus, Settings2, X } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

const formatDistanceToNow = (...args: Parameters<typeof unsafeFormatDistanceToNow>) => {
  if (!args[0]) return "unknown";

  return unsafeFormatDistanceToNow(...args);
};

function EnvironmentDtoToEnvDto(value: FeatureEnvironmentValueDto): FeatureFlagDtoEnvValuesItem {
  return {
    conditionalValues: value.conditionalValues,
    defaultValue: value.defaultValue,
    disabled: value.disabled,
    envCode: value.environmentCode,
    scheduledFrom: value.scheduledFrom && new Date(value.scheduledFrom).toISOString(),
    scheduledTo: value.scheduledTo && new Date(value.scheduledTo).toISOString(),
  };
}

export const FeatureFlag: React.FC = () => {
  const { code: featureCode } = useParams();

  const { teamCode } = useCurrentTeam();
  const { projectCode, projectEnvironments } = useCurrentProject();
  const navigate = useNavigate();

  const {
    data: featureFlag,
    refetch,
    isFetching,
  } = useFeaturesControllerGetFeature(teamCode, projectCode, featureCode!);

  const updateMutation = useFeaturesControllerCreateOrUpdateFeature({
    mutation: {
      onSuccess: () => refetch(),
    },
  });

  const deleteMutation = useFeaturesControllerDeleteFeature({
    mutation: {
      onSuccess: () => navigate("../"),
    },
  });

  const missingEnvironments = React.useMemo(() => {
    if (!featureFlag?.values) return projectEnvironments;

    return projectEnvironments.filter((env) => {
      const index = featureFlag.values.findIndex(({ environmentCode }) => env.code === environmentCode);

      return index === -1;
    });
  }, [projectEnvironments, featureFlag?.values]);

  return (
    <FeaturePage
      title={`Feature Flag | ${featureCode}`}
      description={featureFlag?.description}
      isLoading={updateMutation.isPending || isFetching}
      actions={
        featureFlag && (
          <div className="text-sm text-gray-600 text-right">
            <div>Created {formatDistanceToNow(featureFlag.createdAt)} ago</div>
            <div>Last updated {formatDistanceToNow(featureFlag.lastModifiedAt)} ago</div>
          </div>
        )
      }
    >
      <div className="flex gap-4">
        <Button
          className="bg-primary-200"
          disabled={featureFlag?.values.every((v) => v.disabled) || !featureFlag}
          onClick={() => {
            if (!featureFlag) return;

            updateMutation.mutate({
              teamCode,
              projectCode,
              featureCode: featureCode!,
              data: {
                code: featureCode!,
                envValues: featureFlag.values.map((value) => EnvironmentDtoToEnvDto({ ...value, disabled: true })),
              },
            });
          }}
        >
          {updateMutation.isPending && <LoadingSpinner className="mr-2" size={16} />}
          Disable
        </Button>
        <Button
          className="bg-red-200"
          disabled={!featureFlag}
          onClick={() =>
            deleteMutation.mutate({
              teamCode,
              projectCode,
              featureCode: featureCode!,
            })
          }
        >
          {deleteMutation.isPending && <LoadingSpinner className="mr-2" size={16} />}
          Delete
        </Button>
      </div>

      <div className="flex justify-between items-center">
        <h5 className="font-bold tracking-tight">Environment Values</h5>
        <AddEnvironmentDialog teamCode={teamCode} projectCode={projectCode} onSuccess={() => refetch()}>
          <Button className="bg-secondary-100">
            <Plus size={16} className="mr-2" /> Add Environment
          </Button>
        </AddEnvironmentDialog>
      </div>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(500px,1fr))] gap-4">
        {featureFlag?.values.map((value, i) => {
          return (
            <div key={`feature-flag-value-${i}`} className="neu rounded-md relative p-4 h-fit">
              <FeatureFlagValue
                value={value}
                onChange={(newValue) => {
                  updateMutation.mutate({
                    teamCode,
                    projectCode,
                    featureCode: featureFlag.code,
                    data: {
                      code: featureFlag.code,
                      envValues: [
                        {
                          ...EnvironmentDtoToEnvDto(value),
                          ...newValue,
                        },
                      ],
                    },
                  });
                }}
              />
            </div>
          );
        })}

        {missingEnvironments.map((environment) => {
          return (
            <Button
              key={`missing-environment-${environment.code}`}
              className="neu rounded-md relative p-4 flex flex-col items-center justify-center h-full bg-primary-50"
              onClick={() => {
                updateMutation.mutate({
                  teamCode,
                  projectCode,
                  featureCode: featureCode!,
                  data: {
                    code: featureCode!,
                    envValues: [
                      {
                        disabled: false,
                        envCode: environment.code,
                      },
                    ],
                  },
                });
              }}
            >
              <div className="font-semibold">
                Add{" "}
                <span
                  style={{
                    color: parseEnvColor(environment.color)?.fg,
                  }}
                >
                  {environment.code}
                </span>{" "}
                environment
              </div>
              <div className="text-sm text-secondary-950/60">
                <div>{environment.description}</div>
              </div>
            </Button>
          );
        })}
      </div>
    </FeaturePage>
  );
};

interface FeatureFlagValueProps {
  value: FeatureEnvironmentValueDto;
  onChange: (value: Partial<FeatureFlagDtoEnvValuesItem>) => void;
}

const FeatureFlagValue: React.FC<FeatureFlagValueProps> = ({ value, onChange: onChangeExternal }) => {
  const { projectEnvironments } = useCurrentProject();
  const onChange = useDebouncedCallback((value: Partial<FeatureFlagDtoEnvValuesItem>) => onChangeExternal(value), 500);

  const environment = React.useMemo(
    () => projectEnvironments.find((env) => env.code === value.environmentCode),
    [value, projectEnvironments]
  );

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <span
            className="text-xl font-semibold"
            style={{
              color: parseEnvColor(environment?.color)?.fg,
            }}
          >
            {value.environmentCode}
          </span>

          <div className="text-sm text-secondary-950/60">
            <div>Last updated {formatDistanceToNow(value.lastModifiedAt)} ago</div>
          </div>
        </div>

        <Switch checked={!value.disabled} onCheckedChange={(enabled) => onChangeExternal({ disabled: !enabled })} />
      </div>

      <Separator className="my-4" />

      <div className={cn(value.disabled && "opacity-50")}>
        <div className="flex justify-between items-center">
          <div>
            <h5>
              <History className="inline-block mr-1 h-4" /> Default Value
            </h5>
            <p className="text-sm text-gray-600">Value when no conditions match</p>
          </div>

          <div className="py-4">
            <FeatureFlagValueEdit
              featureValue={value.defaultValue}
              onChange={(defaultValue) => onChange({ defaultValue })}
            />
          </div>
        </div>

        <Separator className="my-4" />

        <div className="flex justify-between items-center mb-4">
          <div>
            <h5>
              <Settings2 className="inline-block mr-1 h-4" /> Conditional Values
            </h5>
            <p className="text-sm text-gray-600">Values with conditions</p>
          </div>

          <Button
            className="bg-tertiary-100"
            size="sm"
            onClick={() => {
              onChange({
                conditionalValues: [
                  ...(value?.conditionalValues ?? []),
                  {
                    conditions: [],
                    value: {
                      type: "bool",
                      value: false,
                    },
                  },
                ],
              });
            }}
          >
            <Plus size={16} className="mr-2" /> Add
          </Button>
        </div>

        {value.conditionalValues?.map((conditionalValue, i) => {
          return (
            <FeatureFlagConditional
              key={`conditional-value-${i}`}
              conditionalValue={conditionalValue}
              onChange={(newValue) => {
                onChange({
                  conditionalValues: value?.conditionalValues?.map((v, j) => {
                    if (i === j) {
                      return newValue;
                    }

                    return v;
                  }),
                });
              }}
              onDelete={() => {
                onChange({
                  conditionalValues: value?.conditionalValues?.filter((_, j) => i !== j),
                });
              }}
            />
          );
        })}

        {(value.scheduledFrom || value.scheduledTo) && (
          <>
            <Separator className="my-4" />

            <div className="neu rounded-md p-4">
              <div className="mb-4">
                <Calendar className="inline-block mr-1 h-4" /> Scheduled
              </div>
              <div className="flex text-sm items-center gap-2">
                {value.scheduledFrom && <Badge>{new Date(value.scheduledFrom).toLocaleString()}</Badge>}
                {value.scheduledFrom && value.scheduledTo && <ArrowRight size={14} />}
                {value.scheduledTo && <Badge>{new Date(value.scheduledTo).toLocaleString()}</Badge>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface FeatureFlagValueEditProps {
  featureValue?: FeatureValueDto | null;
  onChange?: (value: FeatureValueDto) => void;
}

const defaultValues: Record<FeatureValueDto["type"], FeatureValueDto> = {
  bool: {
    type: "bool",
    value: false,
  },
  percentage: {
    type: "percentage",
    value: 1,
  },
  json: {
    type: "json",
    value: "",
  },
};

const FeatureFlagValueEdit: React.FC<FeatureFlagValueEditProps> = ({ featureValue, onChange }) => {
  const inputRender = React.useMemo(() => {
    if (!featureValue) return <></>;

    if (featureValue.type === "bool") {
      return <Switch checked={featureValue.value} onCheckedChange={(value) => onChange?.({ type: "bool", value })} />;
    }

    if (featureValue.type === "percentage") {
      return (
        <Input
          variant="neu-flat"
          type="number"
          min={0}
          max={100}
          defaultValue={featureValue.value * 100}
          onChange={(e) => onChange?.({ type: "percentage", value: (e.target.valueAsNumber ?? 0) / 100 })}
        />
      );
    }

    return <div className="text-primary-700">Unsupported value type "{featureValue.type}"</div>;
  }, [featureValue, onChange]);

  return (
    <div className="flex gap-2 items-center">
      <Select
        defaultValue={featureValue?.type}
        onValueChange={(value) => {
          if (value === featureValue?.type) return;

          onChange?.(defaultValues[value as FeatureValueDto["type"]]);
        }}
      >
        <SelectTrigger className="mx-2 bg-secondary-50 rounded-md text-secondary-800 text-sm">
          <SelectValue placeholder="Select Type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="bool">Boolean</SelectItem>
          <SelectItem value="percentage">Percentage</SelectItem>
          <SelectItem value="json">JSON</SelectItem>
        </SelectContent>
      </Select>
      {inputRender}
    </div>
  );
};

interface FeatureFlagConditionalProps {
  conditionalValue: FeatureConditionalValueDto;
  onChange?: (conditionalValue: FeatureFlagDtoEnvValuesItemConditionalValuesItem) => void;
  onDelete?: () => void;
}

const FeatureFlagConditional: React.FC<FeatureFlagConditionalProps> = ({
  conditionalValue,
  onChange: onChangeExternal,
  onDelete,
}) => {
  const onChange = useDebouncedCallback(
    (value: FeatureFlagDtoEnvValuesItemConditionalValuesItem) => onChangeExternal?.(value),
    50
  );

  return (
    <div className="neu rounded-md p-4">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-base font-medium">Conditions</h5>

        <Button variant="neu-flat" size="icon" className="bg-red-200 h-6 w-6 aspect-square" onClick={onDelete}>
          <X size={16} />
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {conditionalValue.conditions.map((condition, i) => {
          return (
            <FeatureConditionalValueDtoConditionsItemEdit
              key={`condition-${i}`}
              condition={condition}
              onChange={(newCondition) => {
                onChange({
                  ...conditionalValue,
                  conditions: conditionalValue.conditions.map((v, j) => {
                    if (i === j) {
                      return newCondition;
                    }

                    return v;
                  }),
                });
              }}
              onDelete={() => {
                onChange({
                  ...conditionalValue,
                  conditions: conditionalValue.conditions.filter((_, j) => j !== i),
                });
              }}
            />
          );
        })}
      </div>

      <Button
        className={cn("bg-tertiary-100 mt-2", conditionalValue.conditions.length && "ml-8")}
        size="sm"
        onClick={() => {
          onChange?.({
            ...conditionalValue,
            conditions: [
              ...conditionalValue.conditions,
              {
                field: "",
                operation: "startsWith",
                value: "",
              },
            ],
          });
        }}
      >
        <Plus size={16} className="mr-2" /> Add Condition
      </Button>

      <Separator className="my-4" />

      <h5 className="mb-2 text-base font-medium">Value</h5>

      <FeatureFlagValueEdit
        featureValue={conditionalValue.value}
        onChange={(value) => {
          onChange?.({
            ...conditionalValue,
            value,
          });
        }}
      />
    </div>
  );
};

interface FeatureConditionalValueDtoConditionsItemEditProps {
  condition: FeatureConditionalValueDtoConditionsItem;
  onChange: (condition: FeatureFlagDtoEnvValuesItemConditionalValuesItemConditionsItem) => void;
  onDelete?: () => void;
}

const FeatureConditionalValueDtoConditionsItemEdit: React.FC<FeatureConditionalValueDtoConditionsItemEditProps> = ({
  condition,
  onChange: onChangeExternal,
  onDelete,
}) => {
  const onChange = useDebouncedCallback(
    (value: FeatureFlagDtoEnvValuesItemConditionalValuesItemConditionsItem) => onChangeExternal(value),
    50
  );

  return (
    <div className="flex items-center">
      <Button variant="neu-flat" size="icon" className="bg-red-200 mr-2 h-6 w-6 aspect-square" onClick={onDelete}>
        <X size={16} />
      </Button>

      <Input
        variant="neu-flat"
        placeholder="Field"
        defaultValue={condition.field}
        onChange={(e) => {
          onChange({
            ...condition,
            field: e.target.value,
          });
        }}
      />
      <Select
        defaultValue={condition.operation}
        onValueChange={(value) => {
          onChange({
            ...condition,
            operation: value as FeatureFlagDtoEnvValuesItemConditionalValuesItemConditionsItemOperation,
          });
        }}
      >
        <SelectTrigger className="px-2">
          <SelectValue placeholder="Operation" />
        </SelectTrigger>
        <SelectContent>
          {objectKeys(FeatureConditionalValueDtoConditionsItemOperation).map((key) => {
            return (
              <SelectItem key={`select-item-${key}`} value={FeatureConditionalValueDtoConditionsItemOperation[key]}>
                {key}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>

      <Input
        variant="neu-flat"
        placeholder="Value"
        defaultValue={condition.value}
        onChange={(e) => {
          onChange({
            ...condition,
            value: e.target.value,
          });
        }}
      />
    </div>
  );
};
