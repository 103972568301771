import {
  CreateNotificationChannelRequest,
  getNotificationTopicsControllerGetChannelsPaginatedQueryKey,
  NotificationChannelResponseDto,
  useNotificationTopicsControllerCreateChannel,
  useNotificationTopicsControllerUpdateChannel,
} from "@/api/generated";
import { SelectField } from "@/components/form/elements/select-field.tsx";
import { TextField } from "@/components/form/elements/text-field.tsx";
import { Form } from "@/components/form/form.tsx";
import { LoadingSpinner } from "@/components/loading-spinner.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Label } from "@/components/ui/label.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { ToastAction } from "@/components/ui/toast.tsx";
import { onErrorToastExtended } from "@/utils/api.util";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

const createNotificationChannelSchema = z.object({
  code: z.string().min(1, "Channel code is required"),
  type: z.enum(["slack", "discord", "telegram", "email"]),
  config: z.object({
    webhookUrl: z.string().url().optional(),
    slackToken: z.string().optional(),
    channel: z.string().optional(),
    botToken: z.string().optional(),
    chatId: z.string().optional(),
    subjectPrefix: z.string().optional(),
    emails: z.string().optional(),
  }),
});

interface NotificationChannelUpsertDialogProps {
  teamCode: string;
  existingChannel?: NotificationChannelResponseDto;
  children: React.ReactNode;
}

export const NotificationChannelUpsertDialog: React.FC<NotificationChannelUpsertDialogProps> = ({
  teamCode,
  existingChannel,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const updateMutation = useNotificationTopicsControllerUpdateChannel({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: getNotificationTopicsControllerGetChannelsPaginatedQueryKey(teamCode),
        });
      },

      onError: (error) => {
        onErrorToastExtended(error, { action: <ToastAction altText="Try again">Try again</ToastAction> });
      },
    },
  });

  const createMutation = useNotificationTopicsControllerCreateChannel({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: getNotificationTopicsControllerGetChannelsPaginatedQueryKey(teamCode),
        });
      },
      onError: (error) => {
        onErrorToastExtended(error, { action: <ToastAction altText="Try again">Try again</ToastAction> });
      },
    },
  });

  const onSubmit = (values: z.infer<typeof createNotificationChannelSchema>) => {
    const channelData = {
      code: values.code,
      channel: {
        type: values.type,
        config: values.config,
        code: values.code,
      },
    } as CreateNotificationChannelRequest;

    if (existingChannel) {
      updateMutation.mutate({
        teamCode: teamCode,
        channelCode: existingChannel.code,
        data: channelData,
      });
    } else {
      createMutation.mutate({
        teamCode: teamCode,
        data: channelData,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="max-h-[90vh] overflow-y-auto w-[500px]">
        <DialogTitle>{existingChannel ? "Update" : "Create"} Notification Channel</DialogTitle>
        <DialogDescription>
          Configure a notification channel for your selected platform. Each channel can be used by multiple notification
          topics.
        </DialogDescription>
        <Form
          schema={createNotificationChannelSchema}
          defaultValues={{
            code: existingChannel?.code,
            type: (existingChannel?.channelData?.type as "slack" | "discord" | "telegram" | "email") || "slack",
            config: existingChannel?.channelData?.config || {},
          }}
          onSubmit={onSubmit}
        >
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="space-y-1">
                <TextField name="code" label="Channel Code" placeholder="Enter a unique channel code..." />
              </div>

              <div className="space-y-1">
                <ChannelSelect />
              </div>
            </div>

            <div className={"p-4 neu rounded"}>
              <ChannelConfigurationSection />
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <Button
              type="submit"
              className="bg-secondary-300"
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              {createMutation.isPending || updateMutation.isPending ? (
                <LoadingSpinner message={existingChannel ? "Updating" : "Creating"} />
              ) : (
                <>{existingChannel ? "Update Channel" : "Create Channel"}</>
              )}
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const ChannelSelect = () => {
  const { unregister } = useFormContext();
  return (
    <SelectField
      name="type"
      label="Channel Type"
      onValueChange={(_) => {
        unregister("config", {
          keepDirty: false,
          keepTouched: false,
          keepIsValid: false,
        });
      }}
      options={[
        {
          label: "Slack",
          value: "slack",
          icon: <img src="/logo-slack.svg" alt="Slack" className="w-4 h-4 inline-block mr-2" />,
        },
        {
          label: "Discord",
          value: "discord",
          icon: <img src="/logo-discord.svg" alt="Discord" className="w-4 h-4 inline-block mr-2" />,
        },
        {
          label: "Telegram",
          value: "telegram",
          icon: <img src="/logo-telegram.svg" alt="Telegram" className="w-4 h-4 inline-block mr-2" />,
        },
        {
          label: "Email",
          value: "email",
          icon: <img src="/logo-email.svg" alt="Email" className="w-4 h-4 inline-block mr-2" />,
        },
      ]}
    />
  );
};
const ChannelConfigurationSection: React.FC = () => {
  const { watch } = useFormContext();
  const channelType = watch("type");

  switch (channelType) {
    case "slack":
      return <SlackConfigurationForm />;
    case "discord":
      return <DiscordConfigurationForm />;
    case "telegram":
      return <TelegramConfigurationForm />;
    case "email":
      return <EmailConfigurationForm />;
    default:
      return null;
  }
};

const SlackConfigurationForm: React.FC = () => {
  const [slackConfigOption, setSlackConfigOption] = useState("webhook");
  const { unregister } = useFormContext();

  return (
    <div className="space-y-4">
      <p className="text-sm font-semibold">Slack Configuration</p>
      <RadioGroup
        value={slackConfigOption}
        onValueChange={(value) => {
          unregister("config", {
            keepDirty: false,
            keepTouched: false,
            keepIsValid: false,
          });

          setSlackConfigOption(value);
        }}
        className="flex gap-4"
      >
        <RadioGroupItem id="webhook" value="webhook" />
        <Label htmlFor="webhook">Webhook URL</Label>

        <RadioGroupItem id="api-key" value="apiKey" />
        <Label htmlFor="api-key">API Key</Label>
      </RadioGroup>

      <div className="min-h-[80px]">
        {slackConfigOption === "webhook" ? (
          <div className="space-y-1">
            <TextField name="config.webhookUrl" label="Webhook URL" placeholder="Webhook URL" />
          </div>
        ) : (
          <div className="space-y-2">
            <div className="space-y-1">
              <TextField name="config.slackToken" label="API Key" placeholder="API Key" />
            </div>
            <div className="space-y-1">
              <TextField name="config.channel" label="Channel" placeholder="Channel" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DiscordConfigurationForm: React.FC = () => (
  <div className="min-h-[164px] space-y-4">
    <p className="text-sm font-semibold">Discord Configuration</p>
    <div className="space-y-1">
      <TextField name="config.webhookUrl" label="Webhook URL" placeholder="Webhook URL" />
    </div>
  </div>
);

const TelegramConfigurationForm: React.FC = () => (
  <div className="min-h-[164px] space-y-4">
    <p className="text-sm font-semibold">Telegram Configuration</p>
    <div className="space-y-2">
      <div className="space-y-1">
        <TextField name="config.botToken" label="Bot Token" placeholder="Bot Token" />
      </div>
      <div className="space-y-1">
        <TextField name="config.chatId" label="Chat ID" placeholder="Chat ID" />
      </div>
    </div>
  </div>
);

const EmailConfigurationForm: React.FC = () => (
  <div className="min-h-[164px] space-y-4">
    <p className="text-sm font-semibold">Email Configuration</p>
    <div className="space-y-2">
      <div className="space-y-1">
        <TextField name="config.subjectPrefix" label="Subject prefix" placeholder="Subject prefix" />
      </div>
      <div className="space-y-1">
        <TextField name="config.emails" label="Recipients" placeholder="Comma-delimited list of email addresses" />
      </div>
    </div>
  </div>
);
