/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { CreateTeamDto, TeamMemberResponseDto, TeamResponseDto, UpdateTeamDto } from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const teamsControllerListTeams = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<TeamResponseDto[]>({ url: `/v1/teams`, method: "GET", signal }, options);
};

export const getTeamsControllerListTeamsQueryKey = () => {
  return [`/v1/teams`] as const;
};

export const getTeamsControllerListTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsControllerListTeams>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsControllerListTeamsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsControllerListTeams>>> = ({ signal }) =>
    teamsControllerListTeams(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsControllerListTeams>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type TeamsControllerListTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof teamsControllerListTeams>>>;
export type TeamsControllerListTeamsQueryError = ErrorType<unknown>;

export function useTeamsControllerListTeams<
  TData = Awaited<ReturnType<typeof teamsControllerListTeams>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>, "initialData">;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerListTeams<
  TData = Awaited<ReturnType<typeof teamsControllerListTeams>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerListTeams<
  TData = Awaited<ReturnType<typeof teamsControllerListTeams>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useTeamsControllerListTeams<
  TData = Awaited<ReturnType<typeof teamsControllerListTeams>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerListTeams>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getTeamsControllerListTeamsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const teamsControllerCreateTeam = (
  createTeamDto: CreateTeamDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TeamResponseDto>(
    { url: `/v1/teams`, method: "POST", headers: { "Content-Type": "application/json" }, data: createTeamDto, signal },
    options
  );
};

export const getTeamsControllerCreateTeamMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerCreateTeam>>,
    TError,
    { data: CreateTeamDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsControllerCreateTeam>>,
  TError,
  { data: CreateTeamDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof teamsControllerCreateTeam>>, { data: CreateTeamDto }> = (
    props
  ) => {
    const { data } = props ?? {};

    return teamsControllerCreateTeam(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsControllerCreateTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsControllerCreateTeam>>
>;
export type TeamsControllerCreateTeamMutationBody = CreateTeamDto;
export type TeamsControllerCreateTeamMutationError = ErrorType<unknown>;

export const useTeamsControllerCreateTeam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerCreateTeam>>,
    TError,
    { data: CreateTeamDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof teamsControllerCreateTeam>>,
  TError,
  { data: CreateTeamDto },
  TContext
> => {
  const mutationOptions = getTeamsControllerCreateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamsControllerGetTeam = (
  teamCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TeamResponseDto>({ url: `/v1/teams/${teamCode}`, method: "GET", signal }, options);
};

export const getTeamsControllerGetTeamQueryKey = (teamCode: string) => {
  return [`/v1/teams/${teamCode}`] as const;
};

export const getTeamsControllerGetTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsControllerGetTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsControllerGetTeamQueryKey(teamCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsControllerGetTeam>>> = ({ signal }) =>
    teamsControllerGetTeam(teamCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsControllerGetTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type TeamsControllerGetTeamQueryResult = NonNullable<Awaited<ReturnType<typeof teamsControllerGetTeam>>>;
export type TeamsControllerGetTeamQueryError = ErrorType<unknown>;

export function useTeamsControllerGetTeam<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>, "initialData">;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerGetTeam<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerGetTeam<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useTeamsControllerGetTeam<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getTeamsControllerGetTeamQueryOptions(teamCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const teamsControllerUpdateTeam = (
  teamCode: string,
  updateTeamDto: UpdateTeamDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TeamResponseDto>(
    {
      url: `/v1/teams/${teamCode}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateTeamDto,
    },
    options
  );
};

export const getTeamsControllerUpdateTeamMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerUpdateTeam>>,
    TError,
    { teamCode: string; data: UpdateTeamDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsControllerUpdateTeam>>,
  TError,
  { teamCode: string; data: UpdateTeamDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsControllerUpdateTeam>>,
    { teamCode: string; data: UpdateTeamDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return teamsControllerUpdateTeam(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsControllerUpdateTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsControllerUpdateTeam>>
>;
export type TeamsControllerUpdateTeamMutationBody = UpdateTeamDto;
export type TeamsControllerUpdateTeamMutationError = ErrorType<unknown>;

export const useTeamsControllerUpdateTeam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerUpdateTeam>>,
    TError,
    { teamCode: string; data: UpdateTeamDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof teamsControllerUpdateTeam>>,
  TError,
  { teamCode: string; data: UpdateTeamDto },
  TContext
> => {
  const mutationOptions = getTeamsControllerUpdateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamsControllerGetTeamMembers = (
  teamCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TeamMemberResponseDto[]>(
    { url: `/v1/teams/${teamCode}/users`, method: "GET", signal },
    options
  );
};

export const getTeamsControllerGetTeamMembersQueryKey = (teamCode: string) => {
  return [`/v1/teams/${teamCode}/users`] as const;
};

export const getTeamsControllerGetTeamMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsControllerGetTeamMembersQueryKey(teamCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>> = ({ signal }) =>
    teamsControllerGetTeamMembers(teamCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type TeamsControllerGetTeamMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>
>;
export type TeamsControllerGetTeamMembersQueryError = ErrorType<unknown>;

export function useTeamsControllerGetTeamMembers<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerGetTeamMembers<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTeamsControllerGetTeamMembers<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useTeamsControllerGetTeamMembers<
  TData = Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsControllerGetTeamMembers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getTeamsControllerGetTeamMembersQueryOptions(teamCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const teamsControllerRemoveUserFromTeam = (
  teamCode: string,
  userId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/v1/teams/${teamCode}/users/${userId}`, method: "DELETE" }, options);
};

export const getTeamsControllerRemoveUserFromTeamMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>,
    TError,
    { teamCode: string; userId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>,
  TError,
  { teamCode: string; userId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>,
    { teamCode: string; userId: number }
  > = (props) => {
    const { teamCode, userId } = props ?? {};

    return teamsControllerRemoveUserFromTeam(teamCode, userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsControllerRemoveUserFromTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>
>;

export type TeamsControllerRemoveUserFromTeamMutationError = ErrorType<unknown>;

export const useTeamsControllerRemoveUserFromTeam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>,
    TError,
    { teamCode: string; userId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof teamsControllerRemoveUserFromTeam>>,
  TError,
  { teamCode: string; userId: number },
  TContext
> => {
  const mutationOptions = getTeamsControllerRemoveUserFromTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
