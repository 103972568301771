import { Input } from "@/components/ui/input";
import { CheckCircleIcon } from "lucide-react";
import React from "react";
import { useDebounce } from "use-debounce";

const data = [
  {
    section: "Secrets and configs",
    cases: [
      {
        want: "get `REACT_APP_` secrets into GitHub Actions",
        solution: "Use Secrets presigned GET link",
      },
      {
        want: "Quickly test different configs in my docker app",
        solution: "Use secrets presigned GET in docker entrypoint script and load secrets into ENV variables",
      },
      {
        want: "keep up-to-date api-key/secret from test env",
        solution: "Use presigned secrets PUT link to update api-key/secret from tests where api-key is created",
      },
      {
        want: "store test credentials for Braintree for the whole team",
        solution: "Store them in secrets `test` environment",
      },
    ],
  },
  {
    section: "Exceptions inbox",
    cases: [
      {
        want: "Handle rare workflows (e.g. 1/month) that requires manual intervention but makes no sense to programmatically handle",
        solution:
          "Send info to exception inbox presigned url with notification set up to forward issue to slack channel for manual resolution",
      },
      {
        want: "handle unshipped notification from shipper",
        solution:
          "Send data to exceptions inbox with category `unshipped` and shipper name. Notification is sent to Slack, issue is manually resolved and marked in Exceptions inbox",
      },
      {
        want: "do something with DLQ messages from low volume systems",
        solution: "Send them to Exceptions inbox configured to send summary email every hour/day",
      },
      {
        want: "send logs to Exceptions Inbox",
        solution:
          "Do not send them to Exceptions inbox, system is designed for 100s and low 1000s of entries of highly important events",
      },
    ],
  },
  {
    section: "Monitoring",
    cases: [
      {
        want: "know when DNS changes are applied and not be surprised by another team DNS changes",
        solution: "Enable DNS monitoring",
      },
      {
        want: "make sure my internal site is up",
        solution: "Enable HTTP or browser monitoring with agent (coming soon)",
      },
      {
        want: "make sure my certificates are up-to-date and expiration won't surprise me",
        solution: "Enable certificate monitoring",
      },
      {
        want: "know when my content changes are visible on our external site",
        solution: "Enable browser monitoring",
      },
      {
        want: "see the status of all GitHub actions that our team depends on",
        solution: "Use GitHub pipelines",
      },
      {
        want: "monitor competitor's site for changes",
        solution: "Use browser screenshot change monitoring",
      },
    ],
  },
  {
    section: "Files",
    cases: [
      {
        want: "to share test PDF files with the team",
        solution: "Store them in files",
      },
      {
        want: "latest image generated by genAI to be available for testing/review",
        solution: "Use presigned or api-key secret API to upload it to files",
      },
      {
        want: "to upload large test files in our Playwright tests",
        solution: "Use presigned GET, use api-key/secret to download test file(s) from files section",
      },
    ],
  },
  {
    section: "Documents",
    cases: [
      {
        want: "list of prompts to be available to the whole team",
        solution: "Store them in documents",
      },
      {
        want: "list of AI prompts to be up-to-date",
        solution: "Use presigned URLs to PUT latest prompts in documents from your integration tests",
      },
      {
        want: "to quickly share design of API with the team and I like using GitHub gists",
        solution: "Use documents",
      },
      {
        want: "to share our internal Open API Spec 3.0",
        solution: "Upload to documents (or files) via the API. (coming in v3)",
      },
    ],
  },
  {
    section: "JWT",
    cases: [
      {
        want: "test my service that does not yet issue JWTs",
        solution: "Use tools with `generate-jwt` command",
      },
      {
        want: "extend my test JWTs and I have secret/private key",
        solution: "Use tools with `generate-jwt` command",
      },
      {
        want: "debug my JWT",
        solution: "Paste JWT into tools",
      },
      {
        want: "trying to figure out why my JWT signature does not match",
        solution: "Use Tools smart compare to detect issues with spaces in keys, encoding & wrong secrets",
      },
    ],
  },
  {
    section: "Http requests",
    cases: [
      {
        want: "Send HTTP requests to our microservices and share them with the team",
        solution: "Use Http Requester",
      },
      {
        want: "test my public HTTP endpoint from public internet",
        solution: "Use HTTP requester",
      },
      {
        want: "test my internal HTTP endpoint",
        solution: "Use HTTP requester with agent (coming soon)",
      },
      {
        want: "test my HTTP endpoint that uses async workflow and hooks",
        solution: "Send request with Http Requester and create & provide Webhook endpoint",
      },
    ],
  },
  {
    section: "Webhooks",
    cases: [
      {
        want: "see if my app actually sends HTTP request",
        solution: "Use webhooks",
      },
      {
        want: "debug my HTTP sending retry logic",
        solution: "Use sequential webhook and configure response codes and timeouts",
      },
      {
        want: "integrate with 3rd party service that makes it hard to send webhooks",
        solution: "Use webhook and then use CLI with replay option",
      },
      {
        want: "debug my webhook content",
        solution: "Send it to Webhooks and examine it in tools",
      },
    ],
  },
  {
    section: "Emails",
    cases: [
      {
        want: "test signup with email in Puppeteer tests",
        solution:
          "Create email address with wt.dev API and use it to signup. Check received email with code via the API",
      },
      {
        want: "check if my email MIME works",
        solution: "Use emails and tools to examine payload",
      },
      {
        want: "check SPF & DMARC",
        solution: "Create email address and send email to it and check it in emails section.",
      },
      {
        want: "create multiple free sign-up accounts with a service that provides trial",
        solution: "Use emails",
      },
      {
        want: "extract ZIP from email attachment",
        solution: "Use tools `extract-email-mime`",
      },
    ],
  },
  {
    section: "Testing",
    cases: [
      {
        want: "Periodically run Puppeteer tests",
        solution: "Use custom browser tests (Coming in v3)",
      },
      {
        want: "Generate Jest tests based on my Open API specs, webhook and HTTP requester data",
        solution: "Use our AI test generator (Coming in v4)",
      },
    ],
  },
];

export const UseCases: React.FC = () => {
  const [search, setSearch] = React.useState<string>("");
  const [debouncedSearch] = useDebounce(search, 150);

  const filteredData = React.useMemo(() => {
    if (!debouncedSearch) return data;

    const regex = new RegExp(debouncedSearch, "i");

    const out: typeof data = [];

    for (const section of data) {
      const items: (typeof data)[number]["cases"] = [];

      for (const item of section.cases) {
        if (regex.test(item.want) || regex.test(item.solution)) {
          items.push(item);
        }
      }

      if (items.length) {
        out.push({ section: section.section, cases: items });
      }
    }

    return out;
  }, [debouncedSearch]);

  return (
    <div className="space-y-8">
      <Input
        placeholder="Find a scenario"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full max-w-3xl"
      />

      {filteredData.map(({ section, cases }) => {
        return (
          <div key={`section-${section}`}>
            <h3 className="font-bold mb-4">{section}</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {cases.map((item) => (
                <div
                  key={`case-${section}-${item.want}`}
                  className="neu rounded-md px-4 py-2 flex flex-col gap-4 justify-between bg-white"
                >
                  <div className="font-bold">{item.want}</div>
                  <div>
                    <CheckCircleIcon size={18} className="text-tertiary-500 inline-block mr-2" />
                    <span className="text-sm text-justify">{item.solution}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {!filteredData.length && <div>No use cases found...</div>}
    </div>
  );
};
