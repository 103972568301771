import {
  getSecretsSecretsControllerGetSecretsForProjectQueryKey,
  useSecretsSecretsControllerDeleteSecrets,
} from "@/api/generated";
import { Button } from "@/components/ui/button";
import { ConfirmDialog } from "@/lib/dialog/confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import { useQueryClient } from "@tanstack/react-query";
import { CellContext, Row } from "@tanstack/react-table";
import { Edit, LinkIcon, Trash } from "lucide-react";
import React from "react";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { EditSecretDialog } from "./edit-secret-dialog";
import { SecretsPresignDialog } from "./secrets-presign-dialog";

export const SecretActionCell: React.FC<CellContext<Fixed__ProjectSecretResponseDto, unknown>> = ({ row }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const queryClient = useQueryClient();

  const invalidateQuery = React.useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: getSecretsSecretsControllerGetSecretsForProjectQueryKey(teamCode, projectCode),
    });
  }, [projectCode, queryClient, teamCode]);

  return (
    <div className="flex items-center gap-2 justify-end">
      <EditSecretDialog item={row.original} onSuccess={invalidateQuery}>
        <Button size="icon" className="bg-primary-200 w-8 h-8">
          <Edit size={16} />
        </Button>
      </EditSecretDialog>

      <SecretsPresignDialog item={row.original} teamCode={teamCode}>
        <Button size="icon" className="bg-secondary-200 w-8 h-8">
          <LinkIcon size={16} />
        </Button>
      </SecretsPresignDialog>

      <SecretDelete {...{ invalidateQuery, teamCode, projectCode, row }} />
    </div>
  );
};

interface SecretDeleteProps {
  invalidateQuery: () => Promise<void>;
  teamCode: string;
  projectCode: string;
  row: Row<Fixed__ProjectSecretResponseDto>;
}

const SecretDelete: React.FC<SecretDeleteProps> = ({ invalidateQuery, teamCode, projectCode, row }) => {
  const deleteMutation = useSecretsSecretsControllerDeleteSecrets({
    mutation: {
      onSuccess: () => {
        invalidateQuery();
      },
      onError: onErrorToast,
    },
    request: {
      paramsSerializer: {
        indexes: null,
      },
    },
  });

  return (
    <ConfirmDialog
      title="Delete Confirmation"
      desciption={`Are you sure you want to delete "${row.original.code}" secret?`}
      onConfirm={() => {
        deleteMutation.mutate({
          teamCode,
          projectCode,
          params: {
            itemCodes: [row.original.code],
          },
        });
      }}
    >
      <Button size="icon" className="bg-red-200 w-8 h-8">
        <Trash size={16} />
      </Button>
    </ConfirmDialog>
  );
};
