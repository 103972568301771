import { LogOut, Settings } from "lucide-react";

import { useUsersControllerGetLoggedInUser } from "@/api/generated";
import { useAuth } from "@/auth/useAuth";
import { AuthAvatar } from "@/components/auth-avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { Link, useNavigate } from "react-router-dom";

export const UserDropdown: React.FC = () => {
  const { data: user } = useUsersControllerGetLoggedInUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    navigate("/auth");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="bg-transparent">
          <AuthAvatar className="w-8 h-8" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {user && (
          <>
            <DropdownMenuLabel className="flex flex-col gap-1">
              <div className="flex justify-between">{user.userFullName}</div>
              <div className="font-light text-secondary-950/90">{user.email}</div>
            </DropdownMenuLabel>

            <Separator className="h-[2px]" />
          </>
        )}

        <DropdownMenuItem asChild>
          <Link to="/settings" className="no-underline text-secondary-950">
            <Settings size={16} className="mr-2" />
            <span>Settings</span>
          </Link>
        </DropdownMenuItem>

        <Separator className="h-[2px]" />

        <DropdownMenuItem onClick={handleLogOut}>
          <LogOut size={16} className="mr-2" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
