import { Label } from "@/components/ui/label";
import { Textarea, textareaVariants } from "@/components/ui/textarea";
import { VariantProps } from "class-variance-authority";
import React from "react";
import { get, useFormContext } from "react-hook-form";

interface TextAreaFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string | number | readonly string[] | undefined;
  disabled?: boolean;
  variant?: VariantProps<typeof textareaVariants>["variant"];
  style?: React.CSSProperties;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  name,
  label,
  placeholder,
  className,
  defaultValue,
  disabled,
  variant,
  style,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, name);

  return (
    <div>
      {label && (
        <Label htmlFor={name} className="text-sm font-semibold">
          {label}
        </Label>
      )}
      <Textarea
        className={className}
        id={name}
        {...register(name)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        variant={variant}
        style={style}
      />
      {fieldError && <span className="text-sm text-red-600 font-medium">{fieldError?.message as string}</span>}
    </div>
  );
};
