import { FileUploaderField } from "@/components/form/elements/form-uploader-field";
import { Form } from "@/components/form/form";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { TaskManagerRender } from "@/lib/tasks/task-manager-render";
import createFileUploadTasks from "@/lib/tasks/upload-file";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { TaskManager } from "@lilbunnyrabbit/task-manager";
import React from "react";
import { z } from "zod";
import { useCurrentProject } from "../context/project-context";

const uploadFileSchema = z.object({
  files: z.array(z.any()).nonempty("At least one file is required"),
});

interface AddProjectDialogProps {
  children: React.ReactNode;
  onSuccess?: () => void;
}

export const UploadFilesDialog: React.FC<AddProjectDialogProps> = ({ children, onSuccess }) => {
  const [manager, setManager] = React.useState<TaskManager>();

  const [open, setOpenState] = React.useState(false);

  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const setOpen = React.useCallback(
    (open: boolean) => {
      if (!open) {
        if (manager?.isStatus("in-progress")) {
          return;
        }

        setManager(undefined);
      }

      setOpenState(open);
    },
    [manager]
  );

  const onSubmit = (values: z.infer<typeof uploadFileSchema>) => {
    console.log(values.files);

    const taskManager = new TaskManager();

    taskManager.on("success", () => {
      onSuccess?.();
    });

    taskManager.addTasks(
      createFileUploadTasks({
        files: values.files,
        teamCode,
        projectCode,
      })
    );

    taskManager.start();

    setManager(taskManager);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Upload Files</DialogTitle>
        {!manager && (
          <>
            <DialogDescription>Select the files you want to upload to the project.</DialogDescription>
            <Form schema={uploadFileSchema} onSubmit={onSubmit}>
              <FileUploaderField name="files" label="Upload Files" maxFileCount={4} maxSize={4 * 1024 * 1024} />
              <div className="flex justify-end mt-4">
                <Button type="submit" size="sm" className="bg-secondary-300">
                  Submit
                </Button>
              </div>
            </Form>
          </>
        )}

        {manager && <TaskManagerRender taskManager={manager} />}
      </DialogContent>
    </Dialog>
  );
};
