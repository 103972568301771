import React from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const HyperlinksEditorSchema = z.object({
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.optional(z.string()),
  envValues: z.record(
    z.union([z.string().url().min(3, { message: "Url must be at least 3 characters long" }), z.literal("")])
  ),
  monitoringProfile: z.string().min(1),
});

export type HyperlinksEditorInputs = z.infer<typeof HyperlinksEditorSchema>;

export interface HyperlinksContextProps {
  editing: Record<number, UseFormReturn<HyperlinksEditorInputs> | null>;

  setEdit: (id: number, form: UseFormReturn<HyperlinksEditorInputs> | null) => void;
}

export const HyperlinksContext = React.createContext<HyperlinksContextProps | null>(null);

export interface HyperlinksContextProviderProps {
  children: React.ReactNode;
}

export const HyperlinksContextProvider: React.FC<HyperlinksContextProviderProps> = ({ children }) => {
  const [editing, setEditing] = React.useState<HyperlinksContextProps["editing"]>({});

  const setEdit: HyperlinksContextProps["setEdit"] = React.useCallback(
    (id, form) => {
      setEditing((editing) => {
        return {
          ...editing,
          [id]: form,
        };
      });
    },
    [setEditing]
  );

  return <HyperlinksContext.Provider value={{ editing, setEdit }} children={children} />;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHyperlinksContext = () => {
  const context = React.useContext(HyperlinksContext);
  if (!context) {
    throw new Error(`${useHyperlinksContext.name} must be used within ${HyperlinksContextProvider.name}`);
  }

  return context;
};
