import { useProjectsControllerDeleteProject, useProjectsControllerPreviewDeleteProject } from "@/api/generated";
import { LoadingCount } from "@/components/loading-count";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { DestructiveConfirmDialog } from "@/lib/dialog/destructive-confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { EditProjectForm } from "@/lib/projects/settings/edit-project-form";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import {
  AtSignIcon,
  ContainerIcon,
  FileTextIcon,
  FlagIcon,
  LinkIcon,
  ShieldCheckIcon,
  WebhookIcon,
} from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ProjectGeneralSettings: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode, project, refetch } = useCurrentProject();
  const navigate = useNavigate();

  const deleteMutation = useProjectsControllerDeleteProject({
    mutation: {
      onSuccess: () => {
        toast({
          title: `Project "${projectCode}" deleted.`,
        });
        navigate("/dashboard");
      },
      onError: onErrorToast,
    },
  });

  return (
    <FeaturePage
      title="Project General Settings"
      description="Manage project environments."
      actions={
        <DestructiveConfirmDialog
          value={`#${projectCode}`}
          title={`Delete "${projectCode}" project`}
          additionalDescription={<ProjectPreviewDelete teamCode={teamCode} projectCode={projectCode} />}
          onConfirm={() => deleteMutation.mutate({ teamCode, projectCode })}
        >
          <Button loading={deleteMutation.isPending} size="sm" className="bg-red-200">
            Delete Project
          </Button>
        </DestructiveConfirmDialog>
      }
      isLoading={deleteMutation.isPending}
    >
      <div className="max-w-3xl">
        <EditProjectForm teamCode={teamCode} projectCode={projectCode} project={project} onSuccess={() => refetch()} />
      </div>
    </FeaturePage>
  );
};

interface ProjectPreviewDeleteProps {
  teamCode: string;
  projectCode: string;
}

const ProjectPreviewDelete: React.FC<ProjectPreviewDeleteProps> = ({ teamCode, projectCode }) => {
  const { data } = useProjectsControllerPreviewDeleteProject(teamCode, projectCode);

  return (
    <div>
      <div>The following action will delete:</div>
      <ul className="list-disc list-inside ml-2">
        <li>
          <FileTextIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numDocuments} item="Document" />
        </li>

        <li>
          <AtSignIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numEmailAddresses} item="Email" />
        </li>

        <li>
          <ContainerIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numEnvironments} item="Environment" />
        </li>

        <li>
          <FlagIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numFeatureFlags} item="Feature Flag" />
        </li>

        <li>
          <LinkIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numHyperlinks} item="Hyperlink" />
        </li>

        <li>
          <ShieldCheckIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numSecrets} item="Secret" />
        </li>

        <li>
          <WebhookIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numWebhooks} item="Webhook" />
        </li>
      </ul>
    </div>
  );
};
