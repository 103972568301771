import { Card, CardContent, CardHeader } from "@/components/ui/card";
import React from "react";
import { Button } from "@/components/ui/button";
import { NotificationTopicResponseDto, useNotificationTopicsControllerGetTopicsPaginated } from "@/api/generated";
import { ArrowUpRight, SendIcon } from "lucide-react";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Navigate } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { NotificationTopicPostToTopicDialog } from "../notifications/topics/notificaiton-topics-post-to-topic";
import { Link } from "react-router-dom";

export const NotificationTopicsPreview: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const {
    data: topics,
    isPending,
    isError,
  } = useNotificationTopicsControllerGetTopicsPaginated(teamCode, {
    page: 0,
    pageSize: 3,
  });

  return (
    <Card>
      <CardHeader>
        <div>
          <h4 className="font-semibold text-lg">Notification Topics</h4>
          <p className="text-sm text-gray-600">
            Create notification topics which can be used to send notifications across your systems.
          </p>
        </div>
      </CardHeader>
      <CardContent className="grid gap-6">
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && topics && (
          <>
            {topics.results.map((topic) => (
              <TopicPreview key={topic.id} topic={topic} />
            ))}
          </>
        )}

        <Button asChild size="sm" className="bg-secondary-300 gap-1 items-center no-underline">
          <Link to={`/dashboard/${teamCode}/notifications/topics`}>
            {topics && topics.results.length > 0 ? <span>View All Topics</span> : <span>Create Your First Topic</span>}
            <ArrowUpRight className="size-4" />
          </Link>
        </Button>
      </CardContent>
    </Card>
  );
};

interface TopicPreviewProps {
  topic: NotificationTopicResponseDto;
}

const TopicPreview: React.FC<TopicPreviewProps> = ({ topic }) => {
  const channelCodes = topic.channels.map((channel) => channel.code);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
          <SendIcon className="size-5" />
        </div>
        <div>
          <div className="flex items-center gap-2">
            <p className="font-semibold hover:underline hover:cursor-pointer">{topic.name}</p>
          </div>
          <p className="text-sm text-gray-600">
            {channelCodes.length > 0 ? <div>Sending to channels: {channelCodes.join(", ")}</div> : "No channels"}
          </p>
        </div>
      </div>

      <div className="flex gap-2">
        <NotificationTopicPostToTopicDialog topic={topic} />
      </div>
    </div>
  );
};
