import { useEmailsControllerGetEmailAddressByCode } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { Navigate, Outlet, useParams } from "react-router-dom";

export const Emails: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const { emailAddressCode } = useParams<{ emailAddressCode: string }>();

  const {
    isPending,
    isError,
    data: email,
  } = useEmailsControllerGetEmailAddressByCode(teamCode, projectCode, emailAddressCode ?? "");

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && (
        <Outlet
          context={{
            email: email,
          }}
        />
      )}
    </>
  );
};
