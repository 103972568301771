import {CellContext} from "@tanstack/react-table";
import {HyperlinkMonitorRow} from "@/lib/projects/monitoring/hyperlink-monitor/hyperlink-monitoring-columns.tsx";
import {
  getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryKey,
  useHyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns
} from "@/api/generated";
import {toast} from "@/components/ui/use-toast.ts";
import {onErrorToast} from "@/utils/api.util.ts";
import {Button} from "@/components/ui/button.tsx";
import {DisplayMonitorEvents} from "@/lib/projects/monitoring/elements/display-monitor-events.tsx";
import React from "react";
import {useQueryClient} from "@tanstack/react-query";

interface HyperlinkMonitoringEnvironmentEventsCellProps extends CellContext<HyperlinkMonitorRow, unknown> {
  envCode: string;
}

export const HyperlinkMonitoringEnvironmentEventsCell: React.FC<HyperlinkMonitoringEnvironmentEventsCellProps> = ({ envCode, row }) => {
  const data = row.original.data[envCode];
  const queryClient = useQueryClient();

  const invalidateQuery = React.useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryKey(data.teamCode, data.projectCode, data.hyperlink.code, { maxResults: 32 }),
    });
  }, [data.hyperlink.code, data.projectCode, data.teamCode, queryClient]);

  return (
    <div className="flex items-center justify-between w-full">
      <DisplayMonitorEvents
        monitor={row.original.monitoringJobKey}
        hyperlinkCode={data.hyperlink.code}
        hyperlinkItemValueId={data.hyperlink.hyperlinkItemValueId}
        monitorEvents={data.monitorEvents}
        limit={6}
        description={true}
      />
      <ButtonEnqueueMonitorRun
        invalidateQuery={invalidateQuery}
        teamCode={data.teamCode}
        projectCode={data.projectCode}
        hyperlinkCode={data.hyperlink.code}
        envCode={envCode}
        jobType={row.original.monitoringJobKey}
      />
    </div>
  );
}

interface EnqueueMonitorRunProps {
  invalidateQuery: () => Promise<void>;
  teamCode: string;
  projectCode: string;
  hyperlinkCode: string;
  envCode: string;
  jobType: string;
}

const ButtonEnqueueMonitorRun: React.FC<EnqueueMonitorRunProps> = ({ invalidateQuery, teamCode, projectCode, hyperlinkCode, envCode, jobType }) => {
  const enqueueMonitorRunMutation = useHyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns({
    mutation: {
      onSuccess: () => {
        invalidateQuery();
        toast({
          title: "Success",
          description: "Run enqueued successfully",
        });
      },
      onError: onErrorToast
    }
  });

  const handleEnqueueRun = () => {
    enqueueMonitorRunMutation.mutate(
      {
        teamCode,
        projectCode,
        hyperlinkCode,
        envCode,
        jobType,
      }
    );
  };

  return (
    <Button
      variant="neu"
      className="bg-tertiary-200"
      onClick={handleEnqueueRun}
      disabled={enqueueMonitorRunMutation.isPending}
    >
      Enqueue Run
    </Button>
  );
};