import {
  CreateHyperlinkDtoEnvValues,
  CreateHyperlinkDtoMonitoringProfile,
  useHyperlinksHyperlinksControllerCreateHyperlink,
} from "@/api/generated";
import { DividerText } from "@/components/divider-text";
import { FieldError } from "@/components/field-error";
import { TextFieldBase } from "@/components/form/elements/text-field";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useAutoResize } from "@/hooks/use-auto-resize";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { cn } from "@/utils/ui.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { isFunction } from "@wt-dev/common/utils";
import { ExpandIcon, LayersIcon, Plus, ShrinkIcon, Trash } from "lucide-react";
import React from "react";
import { FieldErrors, FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { useCurrentProject } from "../context/project-context";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { monitoringProfileInfo } from "./hyperlinks-config";

interface AddHyperlinkDialogProps {
  children: React.ReactNode;
  onSuccess?: () => void;
}

export const AddHyperlinkDialog: React.FC<AddHyperlinkDialogProps> = ({ children, onSuccess }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="max-w-4xl min-h-[40vh] max-h-screen overflow-hidden grid grid-rows-[min-content,1fr] gap-y-8"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Create Hyperlink</DialogTitle>

        {open && (
          <Editor
            onSuccess={() => {
              onSuccess?.();
              setOpen(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface EditorProps {
  onSuccess?: () => void;
}

const Editor: React.FC<EditorProps> = ({ onSuccess }) => {
  const [otherValues, setOtherValues] = React.useState<HyperlinkEditorCodeInputs>();

  if (otherValues) {
    return <EditorValues otherValues={otherValues} onSuccess={onSuccess} />;
  }

  return <EditorCode onSubmit={(data) => setOtherValues(data)} />;
};

export const HyperlinkEditorCodeSchema = z.object({
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.optional(z.string()),
  monitoringProfile: z.string().min(1),
});

export type HyperlinkEditorCodeInputs = z.infer<typeof HyperlinkEditorCodeSchema>;

interface EditorCodeProps {
  onSubmit: SubmitHandler<HyperlinkEditorCodeInputs>;
}

const EditorCode: React.FC<EditorCodeProps> = ({ onSubmit }) => {
  const form = useForm<HyperlinkEditorCodeInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(HyperlinkEditorCodeSchema),
    defaultValues: {
      code: "",
      description: undefined,
      monitoringProfile: "none",
    },
  });

  const monitoringProfile = form.watch("monitoringProfile");

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2 justify-between">
      <div className="mb-4">
        <div className="mb-2">
          <TextFieldBase
            label="Code"
            description="Code is used to access this secret via API"
            error={form.formState.errors.code}
            {...form.register("code", { required: true })}
          />
        </div>

        <div className="mb-4">
          <TextFieldBase
            label="Description"
            error={form.formState.errors.description}
            {...form.register("description", { required: false })}
          />
        </div>

        <div>
          <Label className="text-sm font-semibold">Monitoring Profile</Label>

          <Select
            {...form.register("monitoringProfile", { required: true })}
            defaultValue={CreateHyperlinkDtoMonitoringProfile.none}
            onValueChange={(value) => {
              form.setValue("monitoringProfile", value);
            }}
          >
            <SelectTrigger className="neu pr-2 pl-4 rounded-md text-sm w-full h-10">
              {!monitoringProfile ? "Select Monitoring Profile" : monitoringProfile}
            </SelectTrigger>
            <SelectContent>
              {objectKeys(CreateHyperlinkDtoMonitoringProfile).map((profile) => {
                return (
                  <SelectItem key={`profile-${profile}`} value={CreateHyperlinkDtoMonitoringProfile[profile]}>
                    <div>{profile}</div>
                    {monitoringProfileInfo[profile] && (
                      <div className="text-xs text-secondary-950/60">{monitoringProfileInfo[profile].description}</div>
                    )}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>

          <FieldError errors={form.formState.errors} name="monitoringProfile" />
        </div>
      </div>

      <div className="flex justify-end">
        <Button variant="neu-flat" type="submit" className="bg-tertiary-200">
          Next
        </Button>
      </div>
    </form>
  );
};

export const HyperlinkEditorValuesSchema = z.object({
  defaultUrl: z.optional(z.string()),
  envValues: z
    .array(
      z.object({
        code: z.string(),
        url: z.union([z.optional(z.string().url()), z.literal("")]),
        color: z.optional(z.string()),
      })
    )
    .refine(
      (envValues) =>
        envValues.every((env, index) => (index === 0 || env.url?.trim() ? env.url && env.url.trim().length > 0 : true)),
      {
        message: "At least one environment is required",
      }
    ),
});

// eslint-disable-next-line react-refresh/only-export-components
export function keyValueToArray(values: Record<string, string>): Array<{ key: string; value: string }> {
  return objectKeys(values).map((key) => ({ key, value: String(values[key]) }));
}

export type HyperlinkEditorValuesInputs = z.infer<typeof HyperlinkEditorValuesSchema>;

interface EditorValuesProps {
  otherValues: HyperlinkEditorCodeInputs;
  onSuccess?: () => void;
}

const EditorValues: React.FC<EditorValuesProps> = ({ otherValues, onSuccess }) => {
  const { projectCode, projectEnvironments } = useCurrentProject();
  const { teamCode } = useCurrentTeam();

  const createHyperlinksMutation = useHyperlinksHyperlinksControllerCreateHyperlink({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const form = useForm<HyperlinkEditorValuesInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(HyperlinkEditorValuesSchema),
    disabled: createHyperlinksMutation.isPending,
    defaultValues: {
      envValues: projectEnvironments.map((env) => ({ code: env.code, color: env.color ?? undefined, url: "" })),
    },
  });

  const onSubmit: SubmitHandler<HyperlinkEditorValuesInputs> = (data) => {
    createHyperlinksMutation.mutate(
      {
        teamCode,
        projectCode,
        data: {
          envValues: data.envValues
            .filter(({ url }) => url)
            .reduce((p, c) => {
              p[c.code] = { url: c.url! };
              return p;
            }, {} as CreateHyperlinkDtoEnvValues),
          monitoringProfile: otherValues.monitoringProfile as CreateHyperlinkDtoMonitoringProfile,
          description: otherValues.description,
        },
        itemCode: otherValues.code,
      },
      {
        onSuccess: () => {
          form.reset();
        },
      }
    );
  };

  const { fields, replace, update } = useFieldArray({
    control: form.control,
    name: "envValues",
  });

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="grid grid-rows-[min-content,min-content,1fr,min-content] grid-cols-1 gap-2 justify-between"
    >
      <div>
        <TextFieldBase
          label="Default URL"
          value={form.watch("defaultUrl")}
          onChange={(e) => {
            form.setValue("defaultUrl", e.target.value);

            replace(fields.map((field) => ({ ...field, url: e.target.value })));
          }}
          error={form.formState.errors.defaultUrl}
        />
      </div>

      <DividerText className="my-4 text-secondary-950/60">or</DividerText>

      <div className="space-y-2">
        {fields.map((field, i) => {
          const color = parseEnvColor(field.color);

          return (
            <div>
              <TextFieldBase
                value={field.url}
                onChange={(e) => {
                  update(i, { ...field, url: e.target.value });
                }}
                error={form.formState.errors.envValues?.[i]?.url}
                label={
                  <span
                    style={{
                      color: color?.fg,
                    }}
                    className="font-medium"
                  >
                    <LayersIcon className="inline-block size-4 mr-2" /> {field.code} URL
                  </span>
                }
                style={{
                  background: color?.bg,
                }}
              />
            </div>
          );
        })}

        <div>
          <FieldError errors={form.formState.errors} name="envValues" />
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <Button variant="neu-flat" type="submit" className="bg-tertiary-200">
          Save
        </Button>
      </div>
    </form>
  );
};

export function EditEnvSecret<T extends FieldValues = FieldValues>({
  env,
  item,
  onChange,
  errors,
  name,
}: {
  item?: Fixed__ProjectSecretResponseDto;
  env: string;
  onChange: (
    v: {
      key: string;
      value: string;
    }[]
  ) => void;
  errors?: FieldErrors<T>;
  name?: (index: number) => string;
}) {
  const [value, setValue] = React.useState(() => keyValueToArray(item?.envValues?.[env] ?? {}));

  const handleSetValue = React.useCallback(
    (newValues: React.SetStateAction<typeof value>) => {
      setValue((values) => {
        const result = isFunction(newValues) ? newValues(values) : newValues;

        onChange(result);

        return result;
      });
    },
    [onChange]
  );

  const editPair = React.useCallback(
    (index: number, value: Partial<{ key: string; value: string }>) => {
      handleSetValue((oldValues) => {
        return oldValues?.map((v, i) => {
          if (i === index) {
            return {
              key: value.key ?? v.key ?? "",
              value: value.value ?? v.value ?? "",
            };
          }

          return v;
        });
      });
    },
    [handleSetValue]
  );

  return (
    <div className="neu-flat rounded-md pr-4 overflow-y-auto max-h-full">
      <div className="grid grid-cols-[2fr,3fr,min-content,min-content] items-start gap-x-2 gap-y-2 max-h-full overflow-y-auto pr-2 py-4 pl-4 ">
        {!!value?.length && (
          <>
            <Label className="font-bold">Key</Label>
            <Label className="font-bold col-span-3">Value</Label>
          </>
        )}

        {value?.map(({ key, value }, i) => {
          return (
            <KeyValueInput
              key={`key-value-${i}`}
              keyValue={key}
              value={value}
              onEdit={(v) => editPair(i, v)}
              name={name?.(i) ?? ""}
              onDelete={() => setValue((v) => v.filter((_, j) => i !== j))}
              errors={errors}
            />
          );
        })}

        <Button
          variant="neu-flat"
          className="bg-tertiary-200"
          type="button"
          onClick={() => setValue((v) => [...(v ?? []), { key: "", value: "" }])}
        >
          <Plus size={16} className="mr-2" /> Add Pair
        </Button>
      </div>
    </div>
  );
}

interface KeyValueInputProps {
  keyValue: string;
  value: string;
  name: string;
  onEdit: (value: Partial<{ key: string; value: string }>) => void;
  onDelete: () => void;
  errors?: FieldErrors<any>;
}

const KeyValueInput: React.FC<KeyValueInputProps> = ({ keyValue: key, value, name, onEdit, onDelete, errors }) => {
  const [expand, setExpand] = React.useState(false);

  const ref = React.useRef<HTMLTextAreaElement>(null);
  useAutoResize(ref, [expand]);

  const valueInput = (
    <Textarea
      ref={ref}
      variant="neu-flat"
      placeholder="Value"
      value={value}
      onChange={(e) => onEdit({ value: e.target.value })}
      rows={expand ? 3 : 1}
      className={cn(
        "bg-secondary-100 font-mono",
        !expand && "min-h-10 resize-none max-h-10",
        expand && "overflow-hidden resize-none"
      )}
    />
  );

  return (
    <>
      <div className={expand ? "col-span-2" : ""}>
        <Input
          variant="neu-flat"
          placeholder="Key"
          value={key}
          className="bg-primary-100 font-mono"
          onChange={(e) => onEdit({ key: e.target.value })}
        />
        {errors && name && <FieldError errors={errors} name={name + ".key"} />}
      </div>
      {!expand && (
        <div>
          {valueInput}
          {errors && name && <FieldError errors={errors} name={name + ".value"} />}
        </div>
      )}
      <Button
        type="button"
        size="icon"
        className={expand ? "bg-white" : "bg-white"}
        onClick={() => setExpand((e) => !e)}
      >
        {expand ? <ShrinkIcon size={16} /> : <ExpandIcon size={16} />}
      </Button>
      <Button type="button" size="icon" className="bg-red-200" onClick={onDelete}>
        <Trash size={16} />
      </Button>

      {expand && (
        <div className="col-span-4">
          {valueInput}
          {errors && name && <FieldError errors={errors} name={name + ".value"} />}
        </div>
      )}

      {errors && name && <FieldError errors={errors} name={name} className="col-span-4" />}
    </>
  );
};
