import * as React from "react";

import { useMergeRefs } from "@/hooks/useMergeRefs";
import { useFileDrop } from "@/utils/file.util";
import { cn } from "@/utils/ui.util";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";

export const textareaVariants = cva(
  "flex min-h-[80px] w-full rounded-md bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-secondary-950/40 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        neu: "neu",
        "neu-flat": "neu-flat",
        ghost: "ghost",
      },
    },
    defaultVariants: {
      variant: "neu",
    },
  }
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, variant, ...props }, ref) => {
  return <textarea className={cn(textareaVariants({ variant, className }))} ref={ref} {...props} />;
});
Textarea.displayName = "Textarea";

export interface TextareaDropProps extends TextareaProps {
  onFilesDrop?: (files: File[]) => void;
}

const TextareaDrop = React.forwardRef<HTMLTextAreaElement, TextareaDropProps>(
  ({ onFilesDrop, className, ...props }, ref) => {
    const dropRef = React.useRef<HTMLTextAreaElement>(null);
    const { activeDrag } = useFileDrop(dropRef, onFilesDrop ?? (() => {}));

    const mergedRef = useMergeRefs(ref, dropRef);
    return (
      <div className={clsx("relative", className)}>
        <Textarea ref={mergedRef} className={clsx("w-full h-full", activeDrag && "bg-secondary-200")} {...props} />

        {activeDrag && (
          <div className="absolute top-2 left-3 right-3 bottom-2 pointer-events-none bg-secondary-200 flex items-center justify-center">
            Drop files here
          </div>
        )}
      </div>
    );
  }
);
TextareaDrop.displayName = "TextareaDrop";

export { Textarea, TextareaDrop };
