import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AddProjectDialog } from "@/lib/projects/add-project-dialog";
import { Plus } from "lucide-react";
import { useProjectsControllerGetProjects } from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ProjectIcon } from "@/components/project-icon";

export const TeamProjectList: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const { isPending, isError, data: projects } = useProjectsControllerGetProjects(teamCode);

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <div>
          <h4 className="font-bold tracking-tight">Projects</h4>
          <p className="text-sm text-gray-600">View and manage projects within this team.</p>
        </div>

        <AddProjectDialog teamCode={teamCode}>
          <Button size="sm" variant="neu" className="bg-secondary-300 justify-self-end">
            Create Project <Plus size={16} className="ml-1" />
          </Button>
        </AddProjectDialog>
      </div>

      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && projects && (
        <>
          {projects.results.length > 0 && (
            <div className="grid grid-cols-2 gap-6">
              {projects.results.map((project) => (
                <div
                  key={project.code}
                  className="flex items-center justify-between gap-4 p-4 bg-secondary-200 neu rounded-md"
                >
                  <div className="flex gap-4 items-center self-start">
                    <ProjectIcon url={project.iconUrl} optional className="self-start" />

                    <div>
                      <h6 className="font-semibold">{project.code}</h6>
                      <p className="text-sm text-gray-600 text-justify">{project.description}</p>
                    </div>
                  </div>
                  <div>
                    <Button size="sm" variant="neu" scheme="white-black" asChild className="no-underline">
                      <Link to={`/dashboard/${teamCode}/${project.code}`}>View</Link>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {projects.results.length === 0 && (
            <div className="flex justify-center">
              <p className="text-gray-600">No projects found. Create your first project to get started.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
