import React, { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/form/form";
import { TextField } from "@/components/form/elements/text-field";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { Label } from "@/components/ui/label";
import { LoadingSpinner } from "@/components/loading-spinner";
import {
  getNotificationTopicsControllerGetTopicsPaginatedQueryKey,
  NotificationTopicResponseDto,
  useNotificationTopicsControllerCreateTopic,
  useNotificationTopicsControllerGetChannelsPaginated,
  useNotificationTopicsControllerUpdateChannel,
  useNotificationTopicsControllerUpdateTopic,
} from "@/api/generated";
import { useFormContext } from "react-hook-form";
import { MultiSelect } from "@/components/multiselect";
import { ChannelIcon } from "@/lib/teams/notifications/channels/notification-channel-icon.tsx";

const createNotificationTopicSchema = z.object({
  topicName: z.string().min(1, "Topic name is required"),
  description: z.string().optional(),
  channelCodes: z.array(z.string()),
});

type FormData = z.infer<typeof createNotificationTopicSchema>;

interface NotificationTopicCreateDialogProps {
  teamCode: string;
  existingTopic?: NotificationTopicResponseDto;
  children: React.ReactNode;
}

interface ChannelCodeSelectProps {
  channelOptions: Array<{
    label: string;
    value: string;
    icon?: () => JSX.Element;
  }>;
}

const ChannelCodeSelect: React.FC<ChannelCodeSelectProps> = ({ channelOptions }) => {
  const { setValue, watch } = useFormContext<FormData>();
  const channelCodes = watch("channelCodes") || [];

  const handleValueChange = (values: string[]) => {
    setValue("channelCodes", values, { shouldValidate: true });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Label>Channel Codes</Label>
      <MultiSelect
        options={channelOptions}
        value={channelCodes}
        defaultValue={channelCodes}
        onValueChange={handleValueChange}
        placeholder="Select channels..."
        modalPopover={true}
      />
    </div>
  );
};

const NotificationTopicForm: React.FC<{
  onSubmit: (values: FormData) => void;
  channelOptions: Array<{ label: string; value: string; icon?: () => JSX.Element }>;
  existingTopic?: NotificationTopicResponseDto;
  isPending: boolean;
}> = ({ onSubmit, channelOptions, existingTopic, isPending }) => {
  return (
    <Form
      schema={createNotificationTopicSchema}
      defaultValues={{
        topicName: existingTopic?.name || "",
        description: existingTopic?.description || "",
        channelCodes: existingTopic?.channels?.map((c) => c.code) || [],
      }}
      onSubmit={onSubmit}
    >
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="space-y-1">
            <TextField name="topicName" label="Topic Name" placeholder="Input topic name..." />
          </div>
          <div className="space-y-1">
            <TextAreaField name="description" label="Description" placeholder="Input description..." />
          </div>
          <div className="space-y-1">
            <ChannelCodeSelect channelOptions={channelOptions} />
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit" className="bg-secondary-300" disabled={isPending}>
            {isPending ? (
              <LoadingSpinner message={"Creating"} />
            ) : (
              <>{existingTopic ? "Update Topic" : "Create Topic"}</>
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export const NotificationTopicUpsertDialog: React.FC<NotificationTopicCreateDialogProps> = ({
  teamCode,
  existingTopic,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data: channelData, isLoading: isLoadingChannels } = useNotificationTopicsControllerGetChannelsPaginated(
    teamCode ?? "",
    {
      page: 0,
      pageSize: 100,
    }
  );

  const allChannels = channelData?.results ?? [];

  const channelOptions = allChannels?.map((channel) => ({
    label: channel.code,
    value: channel.code,
    icon: () => <ChannelIcon type={channel?.channelData?.type} className="w-4 h-4 inline-block mr-2" />,
  }));

  useNotificationTopicsControllerUpdateChannel();
  const updateMutation = useNotificationTopicsControllerUpdateTopic({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: getNotificationTopicsControllerGetTopicsPaginatedQueryKey(teamCode),
        });
      },
    },
  });

  const createMutation = useNotificationTopicsControllerCreateTopic({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: getNotificationTopicsControllerGetTopicsPaginatedQueryKey(teamCode),
        });
      },
    },
  });

  const onSubmit = (values: FormData) => {
    if (existingTopic) {
      updateMutation.mutate({
        teamCode: teamCode,
        topicId: existingTopic?.id,
        data: {
          topicName: values.topicName,
          description: values.description,
          channelCodes: values.channelCodes,
        },
      });
    } else {
      createMutation.mutate({
        teamCode: teamCode,
        data: {
          topicName: values.topicName,
          description: values.description,
          channelCodes: values.channelCodes,
        },
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen} modal>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="max-h-[90vh] overflow-y-auto"
        onPointerDownOutside={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogTitle>{existingTopic ? "Update" : "Create"} Notification Topic</DialogTitle>
        <DialogDescription>
          {existingTopic ? (<>Edit the details of the notification topic.</>
          ) : (<>Enter the details for the new notification topic you want to add. Provide a name, description, and channels.</>
          )}
        </DialogDescription>
        {isLoadingChannels ? (
          <LoadingSpinner message="Loading channels..." />
        ) : (
          <NotificationTopicForm
            onSubmit={onSubmit}
            channelOptions={channelOptions}
            existingTopic={existingTopic}
            isPending={createMutation.isPending || updateMutation.isPending}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
