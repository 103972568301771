/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { UserResponseDto } from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get all users belonging to the org
 */
export const usersControllerFindAll = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<void>({ url: `/v1/users`, method: "GET", signal }, options);
};

export const getUsersControllerFindAllQueryKey = () => {
  return [`/v1/users`] as const;
};

export const getUsersControllerFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof usersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersControllerFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerFindAll>>> = ({ signal }) =>
    usersControllerFindAll(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerFindAll>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerFindAll>>>;
export type UsersControllerFindAllQueryError = ErrorType<unknown>;

export function useUsersControllerFindAll<
  TData = Awaited<ReturnType<typeof usersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>, "initialData">;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerFindAll<
  TData = Awaited<ReturnType<typeof usersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>, "initialData">;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerFindAll<
  TData = Awaited<ReturnType<typeof usersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all users belonging to the org
 */

export function useUsersControllerFindAll<
  TData = Awaited<ReturnType<typeof usersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindAll>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersControllerFindAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get current user's info
 */
export const usersControllerGetLoggedInUser = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserResponseDto>({ url: `/v1/users/me`, method: "GET", signal }, options);
};

export const getUsersControllerGetLoggedInUserQueryKey = () => {
  return [`/v1/users/me`] as const;
};

export const getUsersControllerGetLoggedInUserQueryOptions = <
  TData = Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersControllerGetLoggedInUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>> = ({ signal }) =>
    usersControllerGetLoggedInUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersControllerGetLoggedInUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>
>;
export type UsersControllerGetLoggedInUserQueryError = ErrorType<unknown>;

export function useUsersControllerGetLoggedInUser<
  TData = Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerGetLoggedInUser<
  TData = Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>,
      "initialData"
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerGetLoggedInUser<
  TData = Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get current user's info
 */

export function useUsersControllerGetLoggedInUser<
  TData = Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetLoggedInUser>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersControllerGetLoggedInUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user by id
 */
export const usersControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/v1/users/${id}`, method: "GET", signal }, options);
};

export const getUsersControllerFindOneQueryKey = (id: number) => {
  return [`/v1/users/${id}`] as const;
};

export const getUsersControllerFindOneQueryOptions = <
  TData = Awaited<ReturnType<typeof usersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerFindOne>>> = ({ signal }) =>
    usersControllerFindOne(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersControllerFindOne>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerFindOne>>>;
export type UsersControllerFindOneQueryError = ErrorType<unknown>;

export function useUsersControllerFindOne<
  TData = Awaited<ReturnType<typeof usersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>, "initialData">;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerFindOne<
  TData = Awaited<ReturnType<typeof usersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersControllerFindOne<
  TData = Awaited<ReturnType<typeof usersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get user by id
 */

export function useUsersControllerFindOne<
  TData = Awaited<ReturnType<typeof usersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerFindOne>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersControllerFindOneQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
