/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */

export type UpdateExceptionEventStatusDtoNewStatus =
  (typeof UpdateExceptionEventStatusDtoNewStatus)[keyof typeof UpdateExceptionEventStatusDtoNewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateExceptionEventStatusDtoNewStatus = {
  received: "received",
  acknowledged: "acknowledged",
  investigating: "investigating",
  resolved: "resolved",
} as const;
