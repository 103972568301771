import { useTeamsControllerListTeams } from "@/api/generated";
import { useSwitchTeam } from "@/lib/teams/hooks/use-switch-team";
import React from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "./loading-spinner";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";

interface NotActiveTeamProps {
  teamCode?: string;
  loggedInTeam?: string;
}

export const NotActiveTeam: React.FC<NotActiveTeamProps> = ({ teamCode, loggedInTeam }) => {
  const { switchTeam, mutation } = useSwitchTeam();
  const { isLoading, isError, data: teams } = useTeamsControllerListTeams();

  const hasTeam = React.useMemo(
    () => !!teamCode && !!teams && teams?.findIndex((t) => t.code === teamCode) > -1,
    [teamCode, teams]
  );

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-secondary-200">
      <Card className="w-full max-w-md shadow-lg border-0">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-semibold tracking-tight">
            {hasTeam || isLoading ? "Switch Team?" : "Team Not Found"}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <LoadingSpinner message="Loading teams..." />
          ) : isError ? (
            <div className="space-y-4">
              <p className="text-red-600">Failed to load teams... Please try again later.</p>

              <div>
                <Link to={`/dashboard/${loggedInTeam}`}>
                  <Button className="bg-secondary-200">Back</Button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              {hasTeam ? (
                <div className="space-y-4">
                  <p>
                    You're about to switch from <b>{loggedInTeam}</b> to <b>{teamCode}</b> team. Are you sure you want
                    to continue?
                  </p>

                  <div className="grid grid-cols-[min-content,1fr] gap-x-4">
                    <Link to={`/dashboard/${loggedInTeam}`}>
                      <Button className="bg-secondary-200">Back</Button>
                    </Link>
                    <Button
                      className="bg-tertiary-200"
                      onClick={() => switchTeam(teamCode!)}
                      disabled={mutation.isPending}
                    >
                      {mutation.isPending ? <LoadingSpinner message="Switching team..." /> : "Switch"}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  <p>
                    You don't have access to <b>{teamCode}</b> team. Please contact team administrator if you believe
                    this is a mistake.
                  </p>

                  <div>
                    <Link to={`/dashboard/${loggedInTeam}`}>
                      <Button className="bg-secondary-200">Back</Button>
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
