/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateWebhookDto,
  PaginatedWebhookReceivedRequestsDto,
  PaginatedWebhooksResponseDto,
  StatusMessageDto,
  WebhookReceivedRequestFullDto,
  WebhookResponseDto,
  WebhooksControllerGetWebhookReceivedRequestsParams,
  WebhooksControllerGetWebhooksParams,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const webhooksControllerCreateWebhook = (
  teamCode: string,
  projectCode: string,
  createWebhookDto: CreateWebhookDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<WebhookResponseDto>(
    {
      url: `/v1/webhooks/teams/${teamCode}/projects/${projectCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createWebhookDto,
      signal,
    },
    options
  );
};

export const getWebhooksControllerCreateWebhookMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateWebhookDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateWebhookDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>,
    { teamCode: string; projectCode: string; data: CreateWebhookDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return webhooksControllerCreateWebhook(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerCreateWebhookMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>
>;
export type WebhooksControllerCreateWebhookMutationBody = CreateWebhookDto;
export type WebhooksControllerCreateWebhookMutationError = ErrorType<unknown>;

export const useWebhooksControllerCreateWebhook = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>,
    TError,
    { teamCode: string; projectCode: string; data: CreateWebhookDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerCreateWebhook>>,
  TError,
  { teamCode: string; projectCode: string; data: CreateWebhookDto },
  TContext
> => {
  const mutationOptions = getWebhooksControllerCreateWebhookMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webhooksControllerGetWebhooks = (
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedWebhooksResponseDto>(
    { url: `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints`, method: "GET", params, signal },
    options
  );
};

export const getWebhooksControllerGetWebhooksQueryKey = (
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams
) => {
  return [`/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints`, ...(params ? [params] : [])] as const;
};

export const getWebhooksControllerGetWebhooksQueryOptions = <
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebhooksControllerGetWebhooksQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>> = ({ signal }) =>
    webhooksControllerGetWebhooks(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type WebhooksControllerGetWebhooksQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>
>;
export type WebhooksControllerGetWebhooksQueryError = ErrorType<unknown>;

export function useWebhooksControllerGetWebhooks<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: undefined | WebhooksControllerGetWebhooksParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhooks<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhooks<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebhooksControllerGetWebhooks<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params?: WebhooksControllerGetWebhooksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhooks>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebhooksControllerGetWebhooksQueryOptions(teamCode, projectCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webhooksControllerGetWebhookByCode = (
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<WebhookResponseDto>(
    { url: `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}`, method: "GET", signal },
    options
  );
};

export const getWebhooksControllerGetWebhookByCodeQueryKey = (
  teamCode: string,
  projectCode: string,
  endpointCode: string
) => {
  return [`/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}`] as const;
};

export const getWebhooksControllerGetWebhookByCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getWebhooksControllerGetWebhookByCodeQueryKey(teamCode, projectCode, endpointCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>> = ({ signal }) =>
    webhooksControllerGetWebhookByCode(teamCode, projectCode, endpointCode, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && endpointCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type WebhooksControllerGetWebhookByCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>
>;
export type WebhooksControllerGetWebhookByCodeQueryError = ErrorType<unknown>;

export function useWebhooksControllerGetWebhookByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebhooksControllerGetWebhookByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookByCode>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebhooksControllerGetWebhookByCodeQueryOptions(teamCode, projectCode, endpointCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webhooksControllerGetWebhookReceivedRequests = (
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedWebhookReceivedRequestsDto>(
    {
      url: `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}/received`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getWebhooksControllerGetWebhookReceivedRequestsQueryKey = (
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams
) => {
  return [
    `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}/received`,
    ...(params ? [params] : []),
  ] as const;
};

export const getWebhooksControllerGetWebhookReceivedRequestsQueryOptions = <
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWebhooksControllerGetWebhookReceivedRequestsQueryKey(teamCode, projectCode, endpointCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>> = ({
    signal,
  }) =>
    webhooksControllerGetWebhookReceivedRequests(teamCode, projectCode, endpointCode, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && endpointCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type WebhooksControllerGetWebhookReceivedRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>
>;
export type WebhooksControllerGetWebhookReceivedRequestsQueryError = ErrorType<unknown>;

export function useWebhooksControllerGetWebhookReceivedRequests<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params: undefined | WebhooksControllerGetWebhookReceivedRequestsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookReceivedRequests<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookReceivedRequests<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebhooksControllerGetWebhookReceivedRequests<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  params?: WebhooksControllerGetWebhookReceivedRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequests>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebhooksControllerGetWebhookReceivedRequestsQueryOptions(
    teamCode,
    projectCode,
    endpointCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webhooksControllerGetWebhookReceivedRequestByCode = (
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<WebhookReceivedRequestFullDto>(
    {
      url: `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}/received/${requestCode}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getWebhooksControllerGetWebhookReceivedRequestByCodeQueryKey = (
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string
) => {
  return [
    `/v1/webhooks/teams/${teamCode}/projects/${projectCode}/endpoints/${endpointCode}/received/${requestCode}`,
  ] as const;
};

export const getWebhooksControllerGetWebhookReceivedRequestByCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWebhooksControllerGetWebhookReceivedRequestByCodeQueryKey(teamCode, projectCode, endpointCode, requestCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>> = ({
    signal,
  }) =>
    webhooksControllerGetWebhookReceivedRequestByCode(
      teamCode,
      projectCode,
      endpointCode,
      requestCode,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && endpointCode && requestCode),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type WebhooksControllerGetWebhookReceivedRequestByCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>
>;
export type WebhooksControllerGetWebhookReceivedRequestByCodeQueryError = ErrorType<unknown>;

export function useWebhooksControllerGetWebhookReceivedRequestByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookReceivedRequestByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerGetWebhookReceivedRequestByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebhooksControllerGetWebhookReceivedRequestByCode<
  TData = Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  endpointCode: string,
  requestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerGetWebhookReceivedRequestByCode>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebhooksControllerGetWebhookReceivedRequestByCodeQueryOptions(
    teamCode,
    projectCode,
    endpointCode,
    requestCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webhooksControllerListenWebhookEventGet = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<StatusMessageDto>(
    { url: `/v1/webhooks/listen/${endpointCode}`, method: "GET", signal },
    options
  );
};

export const getWebhooksControllerListenWebhookEventGetQueryKey = (endpointCode: string) => {
  return [`/v1/webhooks/listen/${endpointCode}`] as const;
};

export const getWebhooksControllerListenWebhookEventGetQueryOptions = <
  TData = Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
  TError = ErrorType<unknown>,
>(
  endpointCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebhooksControllerListenWebhookEventGetQueryKey(endpointCode);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>> = ({ signal }) =>
    webhooksControllerListenWebhookEventGet(endpointCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!endpointCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type WebhooksControllerListenWebhookEventGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>
>;
export type WebhooksControllerListenWebhookEventGetQueryError = ErrorType<unknown>;

export function useWebhooksControllerListenWebhookEventGet<
  TData = Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
  TError = ErrorType<unknown>,
>(
  endpointCode: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerListenWebhookEventGet<
  TData = Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
  TError = ErrorType<unknown>,
>(
  endpointCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebhooksControllerListenWebhookEventGet<
  TData = Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
  TError = ErrorType<unknown>,
>(
  endpointCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebhooksControllerListenWebhookEventGet<
  TData = Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>,
  TError = ErrorType<unknown>,
>(
  endpointCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof webhooksControllerListenWebhookEventGet>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebhooksControllerListenWebhookEventGetQueryOptions(endpointCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webhooksControllerListenWebhookEventPost = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<StatusMessageDto>(
    { url: `/v1/webhooks/listen/${endpointCode}`, method: "POST", signal },
    options
  );
};

export const getWebhooksControllerListenWebhookEventPostMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>,
    { endpointCode: string }
  > = (props) => {
    const { endpointCode } = props ?? {};

    return webhooksControllerListenWebhookEventPost(endpointCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerListenWebhookEventPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>
>;

export type WebhooksControllerListenWebhookEventPostMutationError = ErrorType<unknown>;

export const useWebhooksControllerListenWebhookEventPost = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPost>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const mutationOptions = getWebhooksControllerListenWebhookEventPostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webhooksControllerListenWebhookEventPut = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<StatusMessageDto>({ url: `/v1/webhooks/listen/${endpointCode}`, method: "PUT" }, options);
};

export const getWebhooksControllerListenWebhookEventPutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>,
    { endpointCode: string }
  > = (props) => {
    const { endpointCode } = props ?? {};

    return webhooksControllerListenWebhookEventPut(endpointCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerListenWebhookEventPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>
>;

export type WebhooksControllerListenWebhookEventPutMutationError = ErrorType<unknown>;

export const useWebhooksControllerListenWebhookEventPut = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPut>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const mutationOptions = getWebhooksControllerListenWebhookEventPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webhooksControllerListenWebhookEventDelete = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<StatusMessageDto>({ url: `/v1/webhooks/listen/${endpointCode}`, method: "DELETE" }, options);
};

export const getWebhooksControllerListenWebhookEventDeleteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>,
    { endpointCode: string }
  > = (props) => {
    const { endpointCode } = props ?? {};

    return webhooksControllerListenWebhookEventDelete(endpointCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerListenWebhookEventDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>
>;

export type WebhooksControllerListenWebhookEventDeleteMutationError = ErrorType<unknown>;

export const useWebhooksControllerListenWebhookEventDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventDelete>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const mutationOptions = getWebhooksControllerListenWebhookEventDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webhooksControllerListenWebhookEventPatch = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<StatusMessageDto>({ url: `/v1/webhooks/listen/${endpointCode}`, method: "PATCH" }, options);
};

export const getWebhooksControllerListenWebhookEventPatchMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>,
    { endpointCode: string }
  > = (props) => {
    const { endpointCode } = props ?? {};

    return webhooksControllerListenWebhookEventPatch(endpointCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerListenWebhookEventPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>
>;

export type WebhooksControllerListenWebhookEventPatchMutationError = ErrorType<unknown>;

export const useWebhooksControllerListenWebhookEventPatch = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventPatch>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const mutationOptions = getWebhooksControllerListenWebhookEventPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webhooksControllerListenWebhookEventHead = (
  endpointCode: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<StatusMessageDto>(
    { url: `/v1/webhooks/listen/${endpointCode}`, method: "HEAD", signal },
    options
  );
};

export const getWebhooksControllerListenWebhookEventHeadMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>,
    { endpointCode: string }
  > = (props) => {
    const { endpointCode } = props ?? {};

    return webhooksControllerListenWebhookEventHead(endpointCode, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhooksControllerListenWebhookEventHeadMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>
>;

export type WebhooksControllerListenWebhookEventHeadMutationError = ErrorType<unknown>;

export const useWebhooksControllerListenWebhookEventHead = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>,
    TError,
    { endpointCode: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof webhooksControllerListenWebhookEventHead>>,
  TError,
  { endpointCode: string },
  TContext
> => {
  const mutationOptions = getWebhooksControllerListenWebhookEventHeadMutationOptions(options);

  return useMutation(mutationOptions);
};
