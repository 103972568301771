import { Label } from "@/components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import React from "react";
import { get, useFormContext } from "react-hook-form";

interface SelectFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: { value: string; label: string; icon?: React.ReactNode }[];
  onValueChange?: (value: string) => void;
}

export const SelectField: React.FC<SelectFieldProps> = ({ name, label, placeholder, options, onValueChange }) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const selectedValue = watch(name);
  const fieldError = get(errors, name);

  const handleValueChange = (value: string) => {
    setValue(name, value);
    onValueChange?.(value);
  };

  return (
    <>
      {label && (
        <Label htmlFor={name} className="text-sm font-semibold">
          {label}
        </Label>
      )}
      <Select value={selectedValue} onValueChange={handleValueChange}>
        <SelectTrigger className="text-sm py-1">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="bg-gray-100 text-sm">
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.icon && option.icon}
              <span>{option.label}</span>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {fieldError && <span className="text-sm text-red-600 font-medium">{fieldError?.message as string}</span>}
    </>
  );
};
