import { ColumnDef } from "@tanstack/react-table";

import { EmailAddressResponseDto } from "@/api/generated";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useCurrentProject } from "@/lib/projects/context/project-context";

export const emailsColumns: ColumnDef<EmailAddressResponseDto>[] = [
  {
    accessorKey: "emailAddressCode",
    header: "ID",
    cell: ({ row }) => {
      const emailAddressCode = row.getValue("emailAddressCode") as string;
      return <EmailAddressCodeLink emailAddressCode={emailAddressCode} />;
    },
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const createdAt = row.getValue("createdAt") as string;

      return <span>{formatDistanceToNow(createdAt)} ago</span>;
    },
  },
  {
    accessorKey: "maxItems",
    header: "Max Items",
    cell: ({ row }) => {
      const maxItems = row.getValue("maxItems") as number;
      return <span>{maxItems}</span>;
    },
  },
  {
    accessorKey: "enabled",
    header: "Enabled",
    cell: ({ row }) => {
      const enabled = row.getValue("enabled") as boolean;
      return <span>{enabled ? "Yes" : "No"}</span>;
    },
  },
];

interface EndpointCodeLinkProps {
  emailAddressCode: string;
}

// eslint-disable-next-line react-refresh/only-export-components
const EmailAddressCodeLink: React.FC<EndpointCodeLinkProps> = ({ emailAddressCode }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  return (
    <Link to={`/dashboard/${teamCode}/${projectCode}/emails/${emailAddressCode}`} className="font-mono font-semibold">
      {emailAddressCode}
    </Link>
  );
};
