import { DocumentContext } from "@/lib/projects/documents/document-context";
import { cn } from "@/utils/ui.util";
import MDEditor, { EditorContext, getExtraCommands } from "@uiw/react-md-editor";
import mermaid from "mermaid";
import React from "react";
import { getCodeString } from "rehype-rewrite";
import classes from "./md-editor.module.scss";

type JsxRuntimeComponents = import("hast-util-to-jsx-runtime").Components;

const randomid = () => parseInt(String(Math.random() * 1e15), 10).toString(36);

const Code: JsxRuntimeComponents["code"] = ({ children = [], className, ...props }) => {
  const demoid = React.useRef(`dome${randomid()}`);
  const [container, setContainer] = React.useState<HTMLElement | null>(null);
  const isMermaid = className && /^language-mermaid/.test(className.toLocaleLowerCase());
  // TODO: Fix types??
  const code = children ? getCodeString(props.node?.children as any) : (children as any)?.[0] || "";

  React.useEffect(() => {
    if (container && isMermaid && demoid.current && code) {
      mermaid
        .render(demoid.current, code)
        .then(({ svg, bindFunctions }) => {
          container.innerHTML = svg;
          if (bindFunctions) {
            bindFunctions(container);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [container, isMermaid, code, demoid]);

  const refElement = React.useCallback((node: HTMLElement) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  if (isMermaid) {
    return (
      <React.Fragment>
        <code id={demoid.current} style={{ display: "none" }} />
        <code className={className} ref={refElement} data-name="mermaid" />
      </React.Fragment>
    );
  }
  return <code className={className}>{children}</code>;
};

const ContextHack = () => {
  const { preview } = React.useContext(EditorContext);
  const documentContext = React.useContext(DocumentContext);

  React.useEffect(() => {
    documentContext?.setPreview(preview);
  }, [documentContext, preview]);

  return <></>;
};

const contextHackButton = {
  name: "Context Hack",
  keyCommand: "disable",
  value: "disable",
  icon: <ContextHack />,
};

export const MarkdownEditor: React.FC<React.ComponentProps<typeof MDEditor>> = ({ className, ...props }) => {
  return (
    <MDEditor
      data-color-mode="light"
      previewOptions={{
        components: {
          code: Code,
        },
      }}
      className={cn(classes["md-editor"], className)}
      extraCommands={[contextHackButton, ...getExtraCommands()]}
      {...props}
    />
  );
};
