import { AvatarProps } from "@radix-ui/react-avatar";
import clsx from "clsx";
import React from "react";
import { LoadingSpinner } from "./loading-spinner";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { useUsersControllerGetLoggedInUser } from "@/api/generated";

export const AuthAvatar = React.forwardRef<
  HTMLSpanElement,
  Omit<AvatarProps & React.RefAttributes<HTMLSpanElement>, "ref" | "children"> & React.RefAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => {
  const { data: user, isPending } = useUsersControllerGetLoggedInUser();

  const chars = React.useMemo(() => {
    if (!user) return "?";

    return user.userFullName.charAt(0) ?? "?";
  }, [user]);

  return (
    <div className="relative">
      <Avatar ref={ref} className={clsx("relative bg-white border-2 border-secondary-950", className)} {...props}>
        <AvatarFallback className="uppercase">{isPending ? <LoadingSpinner size={14} /> : chars}</AvatarFallback>
      </Avatar>
    </div>
  );
});
