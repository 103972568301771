import { Menubar } from "@/components/ui/menubar";
import { AuthToggleMore } from "@/layouts/elements/auth-toggle-more";

interface AuthMenuProps {
  showPublicAccess?: boolean;
}

export const AuthMenu: React.FC<AuthMenuProps> = ({ showPublicAccess = true }) => {
  return (
    <div className="sticky top-0 w-full z-50">
      <Menubar className="flex justify-end border-none rounded-none shadow-none py-6 px-2 lg:px-4">
        <div className="flex gap-1">
          {/* <ModeToggle /> */}
          {showPublicAccess && <AuthToggleMore />}
        </div>
      </Menubar>
    </div>
  );
};
