import { useAuth } from "@/auth/useAuth";
import React from "react";
import io, { Socket } from "socket.io-client";
import { ServiceType } from "@wt-dev/common/ws";

const websocketURL = import.meta.env.VITE_WEBSOCKET_URL ?? "http://localhost:3000/ws";

interface SocketContextType {
  socket: Socket | null;
  isConnected: boolean;
}

const SocketContext = React.createContext<SocketContextType>({
  socket: null,
  isConnected: false,
});

interface SocketContextProps<TEvent> {
  config: {
    service: ServiceType;
    teamCode: string;
    projectCode?: string;
    handleEvent?: (event: TEvent) => void;
  };
}

export const SocketProvider = <TEvent,>({ children, config }: React.PropsWithChildren<SocketContextProps<TEvent>>) => {
  const { getAccessToken } = useAuth();
  const socketRef = React.useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = React.useState(false);

  const memoizedConfig = React.useMemo(
    () => ({
      service: config.service,
      teamCode: config.teamCode,
      projectCode: config.projectCode,
      handleEvent: config.handleEvent,
    }),
    [config.service, config.teamCode, config.projectCode, config.handleEvent]
  );

  React.useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      return;
    }

    const queryParameters: { service: string; teamCode: string; projectCode?: string } = {
      service: memoizedConfig.service,
      teamCode: memoizedConfig.teamCode,
    };

    if (memoizedConfig.projectCode) {
      queryParameters.projectCode = memoizedConfig.projectCode;
    }

    const socket = io(websocketURL, {
      query: queryParameters,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    socketRef.current = socket;

    socket.on("connect", () => {
      setIsConnected(true);
      console.log(`Socket connected for ${memoizedConfig.service}`);
    });

    socket.on(`${memoizedConfig.service}-event`, (event: TEvent) => {
      if (memoizedConfig.handleEvent) {
        memoizedConfig.handleEvent(event);
      }
    });

    socket.on("connect_error", (error) => {
      console.error(`Socket connection error for ${memoizedConfig.service}:`, error);
      setIsConnected(false);
    });

    return () => {
      socket.disconnect();
      socketRef.current = null;
    };
  }, [memoizedConfig, getAccessToken]);

  return <SocketContext.Provider value={{ socket: socketRef.current, isConnected }}>{children}</SocketContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useSocket = () => {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error(`${useSocket.name} must be used within ${SocketProvider.name}`);
  }

  return context;
};
