import { ProjectResponseDto, useProjectsControllerUpdateProject } from "@/api/generated";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { TextField } from "@/components/form/elements/text-field";
import { ProjectIcon } from "@/components/project-icon";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { onErrorToast } from "@/utils/api.util";
import { cn } from "@/utils/ui.util";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

export const ProjectEditSchema = z.object({
  description: z.optional(z.string().max(512, "Description can be at most 512 characters long.")),
  iconUrl: z.optional(z.string().max(512, "URL can be at most 512 characters long.")),
});

export type ProjectEditSchemaInputs = z.infer<typeof ProjectEditSchema>;

interface EditProjectFormProps {
  teamCode: string;
  projectCode: string;
  project: ProjectResponseDto;
  onSuccess?: (data: ProjectResponseDto) => void;
}

export const EditProjectForm: React.FC<EditProjectFormProps> = ({ teamCode, projectCode, project, onSuccess }) => {
  const updateMutation = useProjectsControllerUpdateProject({
    mutation: {
      onSuccess,
      onError: onErrorToast,
    },
  });

  const form = useForm<ProjectEditSchemaInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(ProjectEditSchema),
    disabled: !project,
    defaultValues: {
      description: project?.description ?? undefined,
      iconUrl: project?.iconUrl ?? undefined,
    },
  });

  const onSubmit: SubmitHandler<ProjectEditSchemaInputs> = (values) => {
    updateMutation.mutate({
      teamCode,
      projectCode,
      data: values,
    });
  };

  const iconUrl = form.watch("iconUrl");

  return (
    <Card>
      <CardHeader>
        <div>
          <h4 className="font-semibold text-lg">Project Info</h4>
          <p className="text-sm text-gray-600">Update your project settings here.</p>
        </div>
      </CardHeader>
      <CardContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
            <div>
              <TextAreaField label="Description" name="description" />
            </div>
            <div className={cn("grid items-end gap-4 grid-cols-1", iconUrl && "grid-cols-[1fr,min-content]")}>
              <div>
                <TextField label="Icon URL" name="iconUrl" />
              </div>

              {iconUrl && <ProjectIcon url={iconUrl} />}
            </div>

            <div className="flex justify-end mt-4">
              <Button
                variant="neu-flat"
                type="submit"
                disabled={!form.formState.isDirty}
                loading={updateMutation.isPending}
                className="bg-tertiary-200"
              >
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};
