import { PresigningRequestDtoOneOfOnezero } from "@/api/generated";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import React from "react";
import { Fixed__ProjectHyperlinkResponseDto } from "../item/project-items-helpers";
import { PresignedUrlBuilder } from "../presigned-url-builder";

interface PresignHyperlinkDialogProps extends ApiPresignedUrlBuilderProps {
  children: React.ReactNode;
}

export const PresignHyperlinkDialog: React.FC<PresignHyperlinkDialogProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden max-w-3xl max-h-screen grid grid-cols-1 grid-rows-[min-content,1fr]"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Presigned URL for "{props.item.code}"</DialogTitle>

        <div className="overflow-y-auto max-h-full pb-1 -mx-5 px-5">
          <ApiPresignedUrlBuilder {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ApiPresignedUrlBuilderProps {
  item: Fixed__ProjectHyperlinkResponseDto;
  teamCode: string;
}

export const ApiPresignedUrlBuilder: React.FC<ApiPresignedUrlBuilderProps> = ({ item, teamCode }) => {
  const presignedRequest = React.useMemo(
    () =>
      ({
        teamCode,
        projectCode: item.projectCode,
        type: "hyperlink",
        readUrl: true,
        listUrl: false,
        updateUrl: true,
        itemCode: item.code,
      }) satisfies Omit<PresigningRequestDtoOneOfOnezero, "apiKey">,
    [item.code, item.projectCode, teamCode]
  );

  return (
    <PresignedUrlBuilder
      className="flex flex-col gap-4 w-full"
      presigningRequest={presignedRequest}
      createTestData={({ presignedURLs, environment }) => {
        if (!presignedURLs.get || !environment) return null;

        return {
          config: {
            method: "GET",
            url: presignedURLs.get.url,
          },
        };
      }}
      validate={({ environment }) => !!environment}
    />
  );
};
