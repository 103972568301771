export const generateFilename = (title: string, extension?: string): string => {
  const sanitizedTitle = title
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "-") // Replace any non-alphanumeric, non-dash characters with a dash
    .replace(/^-+|-+$/g, "") // Remove leading and trailing dashes
    .replace(/-+/g, "-"); // Remove consecutive dashes

  const validFilename = sanitizedTitle.substring(0, 64);

  return extension ? `${validFilename}.${extension}` : validFilename;
};
