import { PresigningRequestDtoOneOfSix } from "@/api/generated";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import React from "react";
import { PresignedUrlBuilder } from "../presigned-url-builder";

interface PresignDocumentsDialogProps extends ApiPresignedUrlBuilderProps {
  children: React.ReactNode;
}

export const PresignDocumentsDialog: React.FC<PresignDocumentsDialogProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden max-w-3xl max-h-screen grid grid-cols-1 grid-rows-[min-content,1fr]"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Presigned URL for documents</DialogTitle>

        <div className="overflow-y-auto max-h-full pb-1 -mx-5 px-5">
          <ApiPresignedUrlBuilder {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ApiPresignedUrlBuilderProps {
  teamCode: string;
  projectCode: string;
}

export const ApiPresignedUrlBuilder: React.FC<ApiPresignedUrlBuilderProps> = ({ teamCode, projectCode }) => {
  const presignedRequest = React.useMemo(
    () =>
      ({
        type: "document",
        createUrl: true,
        documentId: "",
        projectCode,
        readUrl: false,
        teamCode,
        updateUrl: false,
      }) satisfies Omit<PresigningRequestDtoOneOfSix, "apiKey">,
    [projectCode, teamCode]
  );

  return (
    <PresignedUrlBuilder
      className="flex flex-col gap-4 w-full"
      presigningRequest={presignedRequest}
      omit={{
        environment: true,
      }}
      createTestData={({ presignedURLs }) => {
        if (!presignedURLs.create) return null;

        return {
          config: {
            method: "POST",
            url: presignedURLs.create.url,
          },
          exampleData: JSON.stringify({
            title: "Example Document",
            mimeType: "text/markdown",
            content: "# Hello!",
            filename: `example-document-${Date.now()}`,
          }),
        };
      }}
    />
  );
};
