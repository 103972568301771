import { CodeBlock } from "@/components/code-block";
import { EmptyState, MultipartState, RequestState, StatePopover, StringState } from "@/utils/parsing";
import { State } from "@/utils/parsing/state";

interface RequestBodyProps {
  webhookRequest: RequestState;
}

export const RequestBody: React.FC<RequestBodyProps> = ({ webhookRequest }) => {
  const requestHelper = webhookRequest.getRequest();

  const extension = requestHelper.getContentType();
  const body = requestHelper.hasBody() !== "none" ? webhookRequest.getBodyAsString() : "";

  let state: State;
  if (requestHelper.isMultipart()) {
    state = new MultipartState(
      (requestHelper.getBodyAsString() as any) ?? "",
      requestHelper.getContentTypeHeaderValue()
    );
  } else if (requestHelper.getBodyLength() > 0) {
    state = new StringState((requestHelper.getBodyAsString() as any) ?? "");
  } else state = new EmptyState("No Request Body");

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex items-center">
        <p className="text-sm font-semibold">Request Body</p>
        {state && <StatePopover input={{ type: "state", state }} />}
      </div>
      <div className="flex flex-col gap-2">
        {webhookRequest && <CodeBlock code={body ?? ""} language={extension ?? "text"} prettyPrint minHeight={300} />}
      </div>
    </div>
  );
};
