import { ApiKeyResponseDto } from "@/api/generated";
import { Button } from "@/components/ui/button";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowDown, ArrowUp } from "lucide-react";

export const apiKeysColumns: ColumnDef<ApiKeyResponseDto>[] = [
  {
    accessorKey: "key",
    header: ({ column }) => {
      const isSorted = column.getIsSorted();

      return (
        <Button
          variant="ghost"
          className="px-0 bg-transparent"
          onClick={() => column.toggleSorting(isSorted === "asc")}
        >
          Key
          {isSorted && isSorted === "asc" ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: ({ row }) => {
      return <span className="break-all text-wrap">{row.original.key}</span>;
    },
    meta: {
      customWidth: "auto",
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    meta: {
      customWidth: "auto",
    },
  },
  {
    accessorKey: "permissions",
    header: "Permissions",
    cell: ({ row }) => {
      return <pre>{row.original.permissions}</pre>;
    },
    meta: {
      customWidth: "auto",
    },
  },
];
