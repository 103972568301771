import { PresigningRequestDtoOneOfFour, useSecretsSecretsControllerGetSecretAsEnv } from "@/api/generated";
import { CodeBlock } from "@/components/code-block";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import React from "react";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { PresignedUrlBuilder } from "../presigned-url-builder";

interface SecretsPresignDialogProps extends ApiPresignedUrlBuilderProps {
  children: React.ReactNode;
}

export const SecretsPresignDialog: React.FC<SecretsPresignDialogProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden max-w-3xl max-h-screen grid grid-cols-1 grid-rows-[min-content,1fr]"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Presigned URL for "{props.item.code}"</DialogTitle>

        <div className="overflow-y-auto max-h-full pb-1 -mx-5 px-5">
          <ApiPresignedUrlBuilder {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ApiPresignedUrlBuilderProps {
  item: Fixed__ProjectSecretResponseDto;
  teamCode: string;
}

export const ApiPresignedUrlBuilder: React.FC<ApiPresignedUrlBuilderProps> = ({ item, teamCode }) => {
  const [showExport, setShowExport] = React.useState(false);

  const presignedRequest = React.useMemo(
    () =>
      ({
        teamCode,
        projectCode: item.projectCode,
        type: "secret",
        readUrl: true,
        updateUrl: true,
        itemCode: item.code,
      }) satisfies Omit<PresigningRequestDtoOneOfFour, "apiKey">,
    [item.code, item.projectCode, teamCode]
  );

  return (
    <>
      <PresignedUrlBuilder
        className="flex flex-col gap-4 w-full"
        presigningRequest={presignedRequest}
        createTestData={({ presignedURLs, environment }) => {
          if (!presignedURLs.get || !environment) return null;

          return {
            config: {
              method: "GET",
              url: presignedURLs.get.url,
            },
          };
        }}
        validate={({ environment }) => !!environment}
      >
        {({ environment }) => (
          <div className="space-y-2">
            {showExport && environment ? (
              <DotEnvExport
                teamCode={teamCode}
                projectCode={item.projectCode}
                itemCode={item.code}
                environmentCode={environment}
              />
            ) : (
              <Button
                variant="neu-flat"
                type="submit"
                className="bg-secondary-200"
                disabled={!environment}
                onClick={() => setShowExport(true)}
              >
                Export
              </Button>
            )}
          </div>
        )}
      </PresignedUrlBuilder>
    </>
  );
};

interface DotEnvExportProps {
  teamCode: string;
  projectCode: string;
  itemCode: string;
  environmentCode: string;
}

const DotEnvExport: React.FC<DotEnvExportProps> = ({ teamCode, projectCode, itemCode, environmentCode }) => {
  const { data, isError, isLoading } = useSecretsSecretsControllerGetSecretAsEnv(
    teamCode,
    projectCode,
    itemCode,
    environmentCode
  );

  return (
    <>
      <Label className="text-base mb-2">Dotenv Export</Label>
      <CodeBlock code={data ?? ""} readonly placeholder={isError ? "Error!" : isLoading ? "Loading..." : "No data."} />
    </>
  );
};
