import { useFeaturesControllerCreateOrUpdateFeature } from "@/api/generated";
import { TextFieldBase } from "@/components/form/elements/text-field";
import { Button } from "@/components/ui/button";
import { TableCell, TableRow } from "@/components/ui/table";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

export const FeatureFlagsEditorSchema = z.object({
  code: z
    .string()
    .min(1, { message: "Code must be at least 1 character long" })
    .max(64, { message: "Code can be at most 64 characters long" })
    .regex(/^[a-zA-Z\d][a-zA-Z\d-]*$/, {
      message:
        "Code can only contain alphanumeric characters with dashes, and must start with an alphanumeric character",
    }),
  description: z.optional(z.string()),
});

interface AddFeatureFlagRowProps {
  refetch?: () => void;
}

export const AddFeatureFlagRow: React.FC<AddFeatureFlagRowProps> = ({ refetch }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const createHyperlinksMutation = useFeaturesControllerCreateOrUpdateFeature({
    mutation: {
      onSuccess: () => {
        refetch?.();
      },
      onError: onErrorToast,
    },
  });

  const { register, handleSubmit, formState, reset } = useForm<z.infer<typeof FeatureFlagsEditorSchema>>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(FeatureFlagsEditorSchema),
    disabled: createHyperlinksMutation.isPending,
  });

  const onSubmit: SubmitHandler<z.infer<typeof FeatureFlagsEditorSchema>> = (data) => {
    createHyperlinksMutation.mutate(
      {
        teamCode,
        projectCode,
        data: {
          code: data.code,
          description: data.description,
        },
        featureCode: data.code,
      },
      {
        onSuccess: () => {
          reset(
            {
              code: "",
              description: "",
            },
            {
              keepDirtyValues: false,
              keepErrors: false,
              keepDirty: false,
              keepValues: false,
              keepDefaultValues: false,
              keepIsSubmitted: false,
              keepIsSubmitSuccessful: false,
              keepTouched: false,
              keepIsValidating: false,
              keepIsValid: false,
              keepSubmitCount: false,
            }
          );
        },
      }
    );
  };

  return (
    <TableRow className="!border-t-2 !border-t-secondary-950 !bg-secondary-50">
      <TableCell className="align-baseline">
        <TextFieldBase
          variant="neu-flat"
          placeholder="Code..."
          error={formState.errors.code}
          {...register("code", { required: true })}
        />
      </TableCell>

      <TableCell className="align-baseline flex gap-2">
        <TextFieldBase
          variant="neu-flat"
          placeholder="Description"
          error={formState.errors.description}
          {...register("description", { required: false })}
        />

        <Button
          variant="neu"
          className="bg-tertiary-200 w-fit"
          onClick={handleSubmit(onSubmit)}
          disabled={createHyperlinksMutation.isPending}
        >
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
};
