import React from "react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const githubRedirectUrl = import.meta.env.VITE_API_URL + "/v1/auth/sign-in-with-github";

export const SignInWithGithub: React.FC = () => {
  return (
    <Button variant="neu" size="sm" className="bg-primary-300 no-underline" asChild>
      <Link to={githubRedirectUrl} className="flex items-center gap-2">
        <>
          <img src={"/github-svgrepo-com.svg"} alt={"GitHub"} className="w-6" />
          Sign in with Github
        </>
      </Link>
    </Button>
  );
};
