import React from "react";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { Button } from "@/components/ui/button";
import { useEmailsControllerCreateEmailAddress, useEmailsControllerGetEmailAddresses } from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { Navigate, useNavigate } from "react-router-dom";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Plus } from "lucide-react";
import { onErrorToast } from "@/utils/api.util";
import { emailsColumns } from "@/lib/projects/emails/dashboard/emails-columns";
import { useCurrentProject } from "@/lib/projects/context/project-context";

export const EmailsDashboard: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const navigate = useNavigate();

  const createEmailAddressMutation = useEmailsControllerCreateEmailAddress({
    mutation: {
      onSuccess: (data) => {
        navigate(`/dashboard/${teamCode}/${projectCode}/emails/${data.emailAddressCode}`);
      },
      onError: onErrorToast,
    },
  });

  const handleCreateEmailAddress = () => {
    createEmailAddressMutation.mutate({
      teamCode,
      projectCode,
      data: {
        enabled: true,
        emailAddressCode: Math.random().toString(36).substring(2, 9),
        maxItems: 10,
      },
    });
  };

  const pagination = usePagination();

  const {
    data: emails,
    isPending,
    isError,
  } = useEmailsControllerGetEmailAddresses(teamCode, projectCode, pagination.params);

  return (
    <FeaturePage
      title="Emails"
      description="Create temporary email addresses to test your application's email sending functionality."
      isLoading={isPending}
      actions={
        <Button
          className="bg-secondary-200"
          onClick={handleCreateEmailAddress}
          disabled={createEmailAddressMutation.isPending}
        >
          {createEmailAddressMutation.isPending ? (
            "Creating Email Address..."
          ) : (
            <div className="flex items-center">
              Create Email Address
              <Plus size={16} className="ml-1" />
            </div>
          )}
        </Button>
      }
    >
      <>
        {isPending && (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner message={"Loading..."} />
          </div>
        )}
        {isError && <Navigate to="/404" replace={true} />}
        {!isPending && !isError && (
          <>
            <ManagerTable columns={emailsColumns} data={emails.results ?? []} />

            <Pagination className="pt-4" pagination={pagination} numberOfPages={emails?.numberOfPages} />
          </>
        )}
      </>
    </FeaturePage>
  );
};
