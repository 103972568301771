import React from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { LoadingSpinner } from "@/components/loading-spinner";
import {
  NotificationsConfigForm,
  notificationsConfigSchema
} from "@/lib/projects/settings/notifications-config.tsx";
import {
  useNotificationTopicsControllerGetTopicsPaginated,
  useProjectsControllerUpdateEnvironment,
  EnvironmentMiniResponseDto,
  UpdateEnvironmentDtoNotificationsConfig
} from "@/api/generated";
import { useCurrentProject } from "../context/project-context";
import { onErrorToast } from "@/utils/api.util";
import {z} from "zod";
import {SubmitHandler} from "react-hook-form";

interface EditEnvironmentNotificationsProps {
  teamCode: string;
  projectCode: string;
  environment: EnvironmentMiniResponseDto;
  onSuccess?: () => void;
  children: React.ReactNode;
}

export const EnvironmentNotificationsEditSchema = z.object({
  notificationsConfig: notificationsConfigSchema.optional()
});

export type EnvironmentNotificationsEditInput = z.infer<typeof EnvironmentNotificationsEditSchema>;
// export type EnvironmentNotificationsEditInput = z.infer<typeof notificationsConfigSchema>;

export const EditEnvironmentNotificationsConfig: React.FC<EditEnvironmentNotificationsProps> = ({
  teamCode,
  projectCode,
  environment,
  onSuccess,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const { refetch } = useCurrentProject();

  const { data: topicsData, isLoading: isLoadingTopics } = useNotificationTopicsControllerGetTopicsPaginated(
    teamCode,
    {
      page: 0,
      pageSize: 100,
    }
  );

  const updateMutation = useProjectsControllerUpdateEnvironment({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        refetch();
        onSuccess?.();
      },
      onError: onErrorToast,
    },
  });

  const handleUpdateNotifications: SubmitHandler<EnvironmentNotificationsEditInput> = (values) => {
    console.log(`handleUpdateNotifications; values=${JSON.stringify(values)}`);
    updateMutation.mutate({
      teamCode,
      projectCode,
      environmentCode: environment.code,
      data: {
        notificationsConfig: values.notificationsConfig as UpdateEnvironmentDtoNotificationsConfig
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent>
        <DialogTitle>Edit Environment Notifications Config</DialogTitle>
        <DialogDescription>
          Configure notification settings for the {environment.code} environment.
        </DialogDescription>

        {isLoadingTopics ? (
          <LoadingSpinner message="Loading notification topics..." />
        ) : topicsData?.results ? (
          <NotificationsConfigForm
            onSubmit={handleUpdateNotifications}
            currentConfig={
              environment.notificationsConfig
                ? {notificationsConfig: environment.notificationsConfig}
                : {notificationsConfig: {exceptionsInbox: undefined}}
            }
            availableTopics={topicsData.results}
            isPending={updateMutation.isPending}
          />
        ) : (
          <div className="text-sm text-gray-500">No notification topics available</div>
        )}
      </DialogContent>
    </Dialog>
  );
};