import { cn } from "@/utils/ui.util";
import React from "react";

interface DividerTextProps {
  children?: React.ReactNode;
  className?: string;
}

export const DividerText: React.FC<DividerTextProps> = ({ children, className }) => {
  return (
    <div className={cn("grid grid-cols-[1fr,min-content,1fr] items-center h-fit gap-3", className)}>
      <div className="h-px bg-current" />
      <div>{children}</div>
      <div className="h-px bg-current" />
    </div>
  );
};
