import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { SettingsCard } from "@/lib/projects/settings/settings-card";
import { settings } from "@/lib/projects/settings/settings-config";
import React from "react";
import { useDebounce } from "use-debounce";

export const ProjectSettings: React.FC = () => {
  const [search, setSearch] = React.useState<string>("");
  const [debouncedSearch] = useDebounce(search, 150);

  const filteredSettings = React.useMemo(() => {
    if (!debouncedSearch) return settings;

    const regex = new RegExp(debouncedSearch, "i");

    return settings.filter((setting) => regex.test(setting.title) || regex.test(setting.description));
  }, [debouncedSearch]);

  return (
    <FeaturePage title="Project Settings" description="Manage project environments, settings and other features.">
      <Input
        placeholder="Find a setting"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full max-w-3xl"
      />

      <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
        {filteredSettings.map((setting) => (
          <SettingsCard key={`setting-${setting.path}`} setting={setting} />
        ))}

        {filteredSettings.length !== settings.length && (
          <Button
            type="button"
            variant="neu"
            className="min-h-[96px] opacity-60 hover:opacity-100 h-full rounded-md flex items-center justify-center bg-secondary-950/10 hover:bg-secondary-200"
            onClick={() => setSearch("")}
          >
            + {settings.length - filteredSettings.length} other
          </Button>
        )}
      </div>
    </FeaturePage>
  );
};
