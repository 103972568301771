import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { CreateTeamForm } from "@/lib/teams/common/create-team-form";
import { InvitationsList } from "@/lib/teams/invitations/invitations-list";
import { FileText, Settings } from "lucide-react";
import { Link } from "react-router-dom";

const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";

export const Welcome: React.FC = () => {
  return (
    <div className="flex p-2 md:p-10 justify-center">
      <div className="w-full max-w-6xl">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col space-y-2">
            <h3 className="font-bold tracking-tight">Get Started</h3>
            <p className="text-sm text-gray-600">Create or join a team to get started with your projects.</p>
          </div>

          <div className="flex gap-2">
            <Button size="sm" className="bg-secondary-300" asChild>
              <Link to={docsURL} target="_blank" className="no-underline text-secondary-950">
                <FileText className="size-4 mr-2" />
                Documentation
              </Link>
            </Button>
            <Button size="sm" className="bg-secondary-300" asChild>
              <Link to="/settings" className="no-underline text-secondary-950">
                <Settings className="size-4 mr-2" />
                Settings
              </Link>
            </Button>
          </div>

          <div className="flex gap-6">
            <div className="w-full">
              <Card>
                <CardHeader>
                  <div>
                    <h4 className="font-semibold text-lg">Create Team</h4>
                    <p className="text-sm text-gray-600">Create a new team to get started with your projects.</p>
                  </div>
                </CardHeader>
                <CardContent>
                  <CreateTeamForm />
                </CardContent>
              </Card>
            </div>

            <div className="w-full">
              <InvitationsList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
