import React from "react";
import { Link } from "react-router-dom";
import { BellRing, ChevronRight, Command, Hash, Home, KeyRound, Settings2 } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { useCurrentTeam } from "@/lib/teams/context/team-context.tsx";
import { ChatBubbleIcon } from "@radix-ui/react-icons";

const navMain = [
  {
    title: "Notifications",
    icon: BellRing,
    items: [
      {
        title: "Topics",
        url: "/notifications/topics",
        icon: ChatBubbleIcon,
      },
      {
        title: "Channels",
        url: "/notifications/channels",
        icon: Hash,
      },
    ],
  },
  {
    title: "Tools",
    url: "/tools",
    icon: Command,
    disabled: false,
  },
  {
    title: "API Keys",
    url: "/api-keys",
    icon: KeyRound,
  },
  {
    title: "Settings",
    url: "/settings",
    icon: Settings2,
  },
];

export const SidebarPlatformGroup: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const renderMenuItem = (item: (typeof navMain)[0]) => {
    // If the item has subitems, render a collapsible menu
    if (item.items) {
      return (
        <Collapsible key={item.title} asChild defaultOpen={true} className="group/collapsible">
          <SidebarMenuItem>
            <CollapsibleTrigger asChild>
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton tooltip={item.title} className="border-2 border-transparent"> 
                  <div className="flex items-center">
                    {item.icon && <item.icon className="mr-2 h-4 w-4" />}
                    {item.title}
                  </div>

                  <ChevronRight className="ml-auto h-4 w-4 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                </SidebarMenuButton>
              </SidebarMenuItem>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <SidebarMenuSub>
                {item.items.map((subItem) => (
                  <SidebarMenuSubItem key={subItem.title}>
                    <SidebarMenuSubButton asChild>
                      <Link to={`/dashboard/${teamCode}${subItem.url}`} className="border-2 border-transparent no-underline text-secondary-950">
                        <div className="flex items-center">
                          {subItem.icon && <subItem.icon className="mr-2 h-4 w-4" />}
                          <span>{subItem.title}</span>
                        </div>
                      </Link>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                ))}
              </SidebarMenuSub>
            </CollapsibleContent>
          </SidebarMenuItem>
        </Collapsible>
      );
    }
    return (
      <SidebarMenuItem key={item.title}>
        <SidebarMenuButton asChild disabled={item.disabled}>
          <Link
            to={`/dashboard/${teamCode}${item.url}`}
            className="border-2 border-transparent no-underline text-secondary-950 flex items-center px-2 py-1.5"
            aria-disabled={item.disabled}
          >
            <div className="flex items-center">
              {item.icon && <item.icon className="mr-2 h-4 w-4" />}
              <span className="truncate">{item.title}</span>
            </div>
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  };

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {/* Home item */}
        <SidebarMenuItem>
          <SidebarMenuButton asChild>
            <Link
              to={`/dashboard/${teamCode}`}
              className="border-2 border-transparent no-underline text-secondary-950 flex items-center px-2 py-1.5"
            >
              <div className="flex items-center">
                <Home className="mr-2 h-4 w-4" />
                <span>Home</span>
              </div>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>

        {navMain.map(renderMenuItem)}
      </SidebarMenu>
    </SidebarGroup>
  );
};

export default SidebarPlatformGroup;
