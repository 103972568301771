import { LoadingSpinner } from "@/components/loading-spinner";
import { cn } from "@/utils/ui.util";
import React from "react";

interface FeaturePageProps {
  title: React.ReactNode;
  description: React.ReactNode;
  isLoading?: boolean;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const FeaturePage: React.FC<FeaturePageProps> = ({ title, description, isLoading, actions, children }) => {
  return (
    <div className="grid grid-rows-[min-content,1fr] gap-6 relative w-full flex-1">
      <div className="flex justify-between">
        <div>
          <h4 className="font-bold tracking-tight">{title}</h4>
          <p className="text-sm text-gray-600">{description}</p>
        </div>

        {actions}
      </div>

      <div className={cn("space-y-4", isLoading && "opacity-50")}>{children}</div>

      {/* TODO: Temporary generic loading overlay */}
      {isLoading && (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
          <LoadingSpinner size={48} />
        </div>
      )}
    </div>
  );
};
