import { EnvironmentMiniResponseDto, ProjectResponseDto } from "@/api/generated";
import React from "react";

export interface ProjectContextType {
  project: ProjectResponseDto;
  projectCode: string;
  projectEnvironments: EnvironmentMiniResponseDto[];
  refetch: () => void;
}

export const ProjectContext = React.createContext<ProjectContextType | null>(null);

export interface ProjectContextProps {
  project: ProjectResponseDto;
  projectCode: string;
  projectEnvironments: EnvironmentMiniResponseDto[];
  children: React.ReactNode;
  refetch: () => void;
}

export const ProjectProvider: React.FC<ProjectContextProps> = ({
  project,
  projectCode,
  projectEnvironments,
  children,
  refetch,
}) => {
  return (
    <ProjectContext.Provider
      value={{
        project,
        projectCode,
        projectEnvironments,
        refetch,
      }}
      children={children}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentProject = () => {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error(`${useCurrentProject.name} must be used within ${ProjectProvider.name}`);
  }

  return context;
};
