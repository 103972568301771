import { ChevronRight } from "lucide-react";

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
} from "@/components/ui/sidebar";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { coreFeatures, Feature, features } from "@/lib/projects/project-features-config";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { NavLink } from "react-router-dom";

export const SidebarFeaturesGroup: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  return (
    <>
      <SidebarGroup>
        <SidebarMenu className="mb-2">
          {coreFeatures.map((feature, i) => (
            <FeatureComponent
              key={`core-feature-${i}`}
              feature={feature}
              teamCode={teamCode}
              projectCode={projectCode}
            />
          ))}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarSeparator />

      <SidebarGroup>
        <SidebarGroupLabel>Features</SidebarGroupLabel>

        <SidebarMenu>
          {features.map((feature, i) => (
            <FeatureComponent key={`feature-${i}`} feature={feature} teamCode={teamCode} projectCode={projectCode} />
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </>
  );
};

interface FeatureComponentProps {
  feature: Feature;
  teamCode: string;
  projectCode: string;
  end?: boolean;
}

const FeatureComponent: React.FC<FeatureComponentProps> = ({ feature, teamCode, projectCode }) => {
  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild tooltip={feature.title}>
        <NavLink
          to={`/dashboard/${teamCode}/${projectCode}${feature.path}`}
          className={`border-2 border-transparent no-underline text-secondary-950 [&[aria-current="page"]]:bg-secondary-200 [&[aria-current="page"]]:border-secondary-200`}
          end={feature.end}
        >
          {feature.icon && <feature.icon />}
          <span>{feature.title}</span>
          <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
        </NavLink>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};
