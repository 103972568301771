import React from "react";
import { Accordion, AccordionContent, AccordionItem } from "./ui/accordion";

interface SingleAccordionProps {
  open?: boolean;
  setOpen: (open: boolean) => void;
  classNames?: Partial<Record<"root" | "item" | "content", string>>;
  children?: React.ReactNode;
}

export const SingleAccordion: React.FC<SingleAccordionProps> = ({ open, setOpen, classNames, children }) => {
  return (
    <Accordion
      type="single"
      value={open ? "single" : undefined}
      onValueChange={(v) => setOpen(v === "single" ? true : false)}
      collapsible
      className={classNames?.root}
    >
      <AccordionItem value="single" className={classNames?.item}>
        <AccordionContent className={classNames?.root}>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
