import { useProjectsControllerGetProjectOverview } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner.tsx";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { Feature, features } from "@/lib/projects/project-features-config";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { Flag, LinkIcon, Lock } from "lucide-react";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

interface FeatureCardProps extends Feature {}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description, path }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  return (
    <Button
      asChild
      className="flex-col items-start rounded-lg p-4 w-full h-full bg-tertiary-100 hover:bg-secondary-200"
    >
      <Link
        to={`/dashboard/${teamCode}/${projectCode}${path}`}
        className="no-underline grid grid-cols-[1fr,min-content] grid-rows-[min-content,1fr] w-full min-h-[96px] gap-y-2 gap-x-2 items-center"
      >
        <h3 className="font-semibold text-sm">{title}</h3>
        <Icon className="size-5" />
        <p className="text-xs text-muted-foreground col-span-2 text-wrap self-start">{description}</p>
      </Link>
    </Button>
  );
};

export const ProjectIndex = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const [search, setSearch] = React.useState<string>("");
  const [debouncedSearch] = useDebounce(search, 150);

  const { isPending, isError, data: projectOverview } = useProjectsControllerGetProjectOverview(teamCode, projectCode);

  const filteredFeatures = React.useMemo(() => {
    if (!debouncedSearch) return features;

    const regex = new RegExp(debouncedSearch, "i");

    return features.filter((feature) => regex.test(feature.title) || regex.test(feature.description));
  }, [debouncedSearch]);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-bold tracking-tight">Project Dashboard</h3>
        <p className="text-sm text-gray-600">
          View and manage project environments, secrets, hyperlinks, and feature flags.
        </p>
      </div>

      <Input
        placeholder="Find a setting"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full max-w-3xl"
      />

      <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
        {filteredFeatures.map((feature) => (
          <FeatureCard
            key={feature.title}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            path={feature.path}
          />
        ))}

        {filteredFeatures.length !== features.length && (
          <Button
            type="button"
            variant="neu"
            className="min-h-[96px] opacity-60 hover:opacity-100 h-full rounded-md flex items-center justify-center bg-secondary-950/10 hover:bg-secondary-200"
            onClick={() => setSearch("")}
          >
            + {features.length - filteredFeatures.length} other
          </Button>
        )}
      </div>

      <Card>
        <CardHeader>
          <div>
            <h5 className="font-semibold tracking-tight">Environments</h5>
          </div>
        </CardHeader>
        <CardContent>
          <>
            {isPending && (
              <div className="flex items-center justify-center w-full">
                <LoadingSpinner message={"Loading..."} />
              </div>
            )}
            {isError && <Navigate to="/404" replace={true} />}
            {!isPending && !isError && projectOverview?.environments?.length > 0 && (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Environment</TableHead>
                    <TableHead>Description</TableHead>
                    <TableHead>
                      <div className="flex items-center gap-2">
                        <Lock className="size-4" />
                        Secrets
                      </div>
                    </TableHead>
                    <TableHead>
                      <div className="flex items-center gap-2">
                        <LinkIcon className="size-4" />
                        Hyperlinks
                      </div>
                    </TableHead>
                    <TableHead>
                      <div className="flex items-center gap-2">
                        <Flag className="size-4" />
                        Features
                      </div>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {projectOverview.environments.map((env) => {
                    return (
                      <TableRow key={env.code} className="group hover:bg-muted/50">
                        <TableCell>
                          <div
                            className="font-medium"
                            style={{
                              color: parseEnvColor(env.color)?.fg,
                            }}
                          >
                            {env.code}
                          </div>
                        </TableCell>
                        <TableCell className="text-muted-foreground">{env.description || "No description"}</TableCell>
                        <TableCell>
                          <Link to={`/dashboard/${teamCode}/${projectCode}/secrets`} className="no-underline">
                            <EnvironmentStatus value={env.numSecretValues} total={env.numProjectSecretItems} />
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/${teamCode}/${projectCode}/hyperlinks`} className="no-underline">
                            <EnvironmentStatus value={env.numHyperlinkValues} total={env.numProjectHyperlinkItems} />
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/${teamCode}/${projectCode}/feature-flags`} className="no-underline">
                            <EnvironmentStatus value={env.numFeatureFlags} total={env.numFeatureFlags} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export interface EnvironmentStatusProps {
  value: number;
  total: number;
}

export const EnvironmentStatus: React.FC<EnvironmentStatusProps> = ({ value, total }) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <span className="text-sm">
          {value} / {total}
        </span>
      </div>
    </div>
  );
};
