import React from "react";
import { useFormContext, get } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { FileUploader } from "@/components/file-uploader";

interface FileUploaderFieldProps {
  name: string;
  label?: string;
  maxFileCount?: number;
  maxSize?: number;
}

export const FileUploaderField: React.FC<FileUploaderFieldProps> = ({
  name,
  label,
  maxFileCount = 1,
  maxSize = 4 * 1024 * 1024, // 4MB default
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, name);

  return (
    <div className="mb-4">
      {label && (
        <Label htmlFor={name} className="text-sm font-semibold">
          {label}
        </Label>
      )}
      <FileUploader
        maxFileCount={maxFileCount}
        maxSize={maxSize}
        onValueChange={(files) => setValue(name, files)}
        {...register(name)}
      />
      {fieldError && <span className="text-sm text-red-600 font-medium">{fieldError?.message as string}</span>}
    </div>
  );
};
