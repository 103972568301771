import { FilesResponseDtoResultsItem, useFilesControllerGetFilesForTeamAndProject } from "@/api/generated";
import { ManagerTable } from "@/components/manager-table";
import { Button } from "@/components/ui/button";
import { useContinuation } from "@/hooks/use-continuation";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { PresignFilesDialog } from "@/lib/projects/files/presign-files-dialog";
import { UploadFilesDialog } from "@/lib/projects/files/upload-files-dialog";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ColumnDef } from "@tanstack/react-table";
import { LinkIcon } from "lucide-react";
import React from "react";
import { filesColumns } from "@/lib/projects/files/files-config";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select.tsx";

export const Files: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const continuation = useContinuation();
  const [pageSize, setPageSize] = React.useState<number>(10);


  const {
    data: files,
    refetch,
    isFetching,
  } = useFilesControllerGetFilesForTeamAndProject(
    teamCode,
    projectCode,
    {
      "max-keys": pageSize,
      ...(continuation.token && { "continuation-token": continuation.token }),
      "path": "/",
    },
  );

  React.useEffect(() => {
    refetch();
  }, [continuation.token, pageSize, refetch]);

  const columns = React.useMemo(() => {
    return [...filesColumns] satisfies ColumnDef<FilesResponseDtoResultsItem, unknown>[];
  }, []);

  return (
    <FeaturePage
      title="Files"
      description="Manage your project's files. Files are uploaded to your project and can be used in your project's code."
      actions={
        <div className="flex items-center gap-2">
          <PresignFilesDialog teamCode={teamCode}>
            <Button size="icon" className="bg-secondary-200 w-9 h-9">
              <LinkIcon size={16} />
            </Button>
          </PresignFilesDialog>

          <UploadFilesDialog onSuccess={refetch}>
            <Button size="sm" className="bg-secondary-300">
              Upload Files
            </Button>
          </UploadFilesDialog>
        </div>
      }
      isLoading={isFetching}
    >
      <ManagerTable
        columns={columns}
        data={files?.results ?? []}
        searchBy="prefix"
        actions={
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => {
              setPageSize(Number(value));
              continuation.reset();
            }}
          >
            <SelectTrigger className="w-[80px] justify-self-end">
              <SelectValue placeholder="Select page size" />
            </SelectTrigger>
            <SelectContent>
              {[10, 15, 20, 25, 50, 100, 200, 500, 1000].map((size) => (
                <SelectItem key={size} value={size.toString()}>
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        }
      />

      <div className="flex items-center justify-end gap-2">
        <div className="flex items-center justify-end space-x-2">
          <Button
            size="sm"
            className="bg-tertiary-200"
            disabled={!continuation.hasPrevious()}
            onClick={() => continuation.previous()}
          >
            Previous
          </Button>
          <Button
            size="sm"
            className="bg-tertiary-200"
            disabled={!(continuation.hasNext() || files?.nextContinuationToken)}
            onClick={() => continuation.next(files?.nextContinuationToken)}
          >
            Next
          </Button>
        </div>
      </div>
    </FeaturePage>
  );
};
