import { useFeaturesControllerGetFeaturesForTeamAndProject } from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { AddFeatureFlagRow } from "@/lib/projects/feature-flags/add-feature-flag-row";
import { featureFlagsColumns } from "@/lib/projects/feature-flags/feature-flags-config";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import React from "react";
import { Navigate } from "react-router-dom";

export const FeatureFlags: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const pagination = usePagination();
  const {
    isPending,
    isFetching,
    isError,
    data: featureFlags,
    refetch,
  } = useFeaturesControllerGetFeaturesForTeamAndProject(teamCode, projectCode, pagination.params);

  return (
    <FeaturePage
      title="Feature Flags"
      description="Manage your project's feature flags. Feature flags are toggles that can be used in your project's configuration files to enable or disable certain features."
      isLoading={isPending || isFetching}
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}

      {isError && <Navigate to="/404" replace={true} />}

      {!isPending && !isError && featureFlags && (
        <>
          <ManagerTable
            columns={featureFlagsColumns}
            data={featureFlags.results}
            searchBy="code"
            additionalRows={<AddFeatureFlagRow refetch={refetch} />}
          />

          <Pagination className="pt-4" pagination={pagination} numberOfPages={featureFlags?.numberOfPages} />
        </>
      )}
    </FeaturePage>
  );
};
