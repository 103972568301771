import {
  ExceptionEventResponseDto,
  UpdateExceptionEventStatusDto,
  UpdateExceptionEventStatusDtoNewStatus,
  useExceptionsInboxControllerChangeExceptionEventStatusWithNote,
  useExceptionsInboxControllerGetExceptionEventById,
} from "@/api/generated";
import { CodeBlock } from "@/components/code-block";
import { ErrorDisplay } from "@/components/error-display";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { exceptionEventStatusclasses } from "@/lib/projects/exception-inbox/exception-status-badge";
import { ValidSchemaDialog } from "@/lib/projects/exception-inbox/valid-schema-dialog";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { cn } from "@/utils/ui.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { LayersIcon, PlusIcon } from "lucide-react";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { z } from "zod";
import { ParserInitialSetup } from "../tools/tools-samples-inputs";

export const ExceptionInboxEventRoute: React.FC = () => {
  const { eventId } = useParams<"eventId">();

  const context = useOutletContext<{ refetch?: () => void }>();

  const {
    data: event,
    refetch,
    isLoading,
    error,
    isFetching,
  } = useExceptionsInboxControllerGetExceptionEventById(Number.parseInt(eventId ?? "0"), {
    query: {
      enabled: !!eventId,
      retry: () => false,
    },
  });

  if (error) {
    return (
      <div className="h-full flex flex-col items-center justify-center gap-4">
        <ErrorDisplay error={error} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-full flex flex-col items-center justify-center gap-4">
        <LoadingSpinner size={48} className="text-secondary-950/40" />
        <h3 className="text-secondary-950/40">Loading event #{eventId}</h3>
      </div>
    );
  }

  if (event) {
    return (
      <div className="relative">
        <ExceptionIndboxEvent
          key={`event-${event.id}`}
          event={event}
          refetch={() => {
            refetch();
            context?.refetch?.();
          }}
        />

        {isFetching && (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
            <LoadingSpinner size={48} />
          </div>
        )}
      </div>
    );
  }

  return <div className="w-full h-full flex items-center justify-center">Select an event to view details</div>;
};

export const EventEditSchema = z.object({
  noteToAppend: z.optional(z.nullable(z.string().min(1, { message: "Note must be at least 1 character long" }))),
});

export type EventEditSchemaInputs = z.infer<typeof EventEditSchema>;

interface ExceptionIndboxEventProps {
  event: ExceptionEventResponseDto;
  refetch?: () => void;
}

const ExceptionIndboxEvent: React.FC<ExceptionIndboxEventProps> = ({ event, refetch }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const navigate = useNavigate();

  function navigateToTools(): void {
    const state: ParserInitialSetup = {
      input: { type: "string", value: (event as any).payload },
      initialSnake: [{ transformationId: "autodetect" }],
      name: `Event #${event.id}`,
    };

    navigate(`/dashboard/${teamCode}/tools`, { state: state });
  }

  const form = useForm<EventEditSchemaInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(EventEditSchema),
    defaultValues: {
      noteToAppend: "",
    },
  });

  const statusMutation = useExceptionsInboxControllerChangeExceptionEventStatusWithNote({
    mutation: {
      onError: onErrorToast,
      onSuccess: () => {
        refetch?.();
      },
    },
  });

  const onSubmit: SubmitHandler<EventEditSchemaInputs> = (values) => {
    const data: UpdateExceptionEventStatusDto = {
      newStatus: event.status,
    };

    if (values.noteToAppend) {
      data.noteToAppend = values.noteToAppend ?? "";
    }

    statusMutation.mutate({
      teamCode,
      projectCode,
      exceptionEventId: event.id,
      data,
    });

    form.reset();
  };

  const notesParsed = React.useMemo(() => {
    return event.notes?.split("\n");
  }, [event.notes]);

  return (
    <div className="grid grid-cols-[1fr,min-content] gap-x-8">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="py-2 flex flex-col gap-4">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="font-semibold tracking-tight">Event #{event.id} Details</h4>
              <div className="flex items-center gap-1 mt-2 uppercase">
                {event.categories.map((category) => {
                  return (
                    <Badge key={`category-${category}`} variant="secondary">
                      {category}
                    </Badge>
                  );
                })}
              </div>
            </div>

            {event.invalidFormat && (
              <ValidSchemaDialog>
                <Button size="sm" className="bg-red-200">
                  Valid Example
                </Button>
              </ValidSchemaDialog>
            )}
          </div>

          <div>
            <Label className="font-bold">Notes</Label>
            <div className="neu rounded-md p-2 font-sans">
              <ul className="list-disc list-inside ml-1 text-sm">
                {notesParsed?.map((note, i) => {
                  return <li key={`note-${i}`}>{note}</li>;
                })}
              </ul>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-end mb-2">
              <Label className="font-bold">Payload</Label>
              <Button size="xs" onClick={navigateToTools} className="bg-tertiary-100 px-2">
                Explore in Tools
              </Button>
            </div>

            <CodeBlock
              code={(event as any).payload}
              language="json"
              readonly
              theme="light"
              className="neu rounded-md"
              prettyPrint
            />
          </div>

          <div>
            <Label className="font-bold">Add Note</Label>
            <div className="grid grid-cols-[1fr,min-content] items-start gap-2 mt-2">
              <TextAreaField name="noteToAppend" className="min-h-10 h-10" />

              <Button
                type="submit"
                disabled={!form.formState.isDirty}
                loading={statusMutation.isPending}
                className="bg-tertiary-100"
              >
                <PlusIcon size={16} className="mr-2" /> Add
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>

      <div className="whitespace-nowrap py-2 space-y-4 w-[16rem]">
        <Select
          value={event.status}
          onValueChange={(v) => {
            statusMutation.mutate({
              teamCode,
              projectCode,
              exceptionEventId: event.id,
              data: {
                newStatus: v as UpdateExceptionEventStatusDtoNewStatus,
              },
            });
          }}
        >
          <SelectTrigger
            className={cn(
              "neu w-full py-1 pr-1 pl-2 bg-tertiarty-300 font-bold text-xs uppercase bg-secondary-200 min-h-9",
              exceptionEventStatusclasses[event.status]
            )}
          >
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {objectKeys(UpdateExceptionEventStatusDtoNewStatus).map((status) => {
              return (
                <SelectItem key={`event-status-${status}`} value={status} className="uppercase">
                  {status}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        {event.createdAt && (
          <InfoBox label="Created">
            <div>{new Date(event.createdAt).toLocaleString()}</div>
          </InfoBox>
        )}

        {event.lastModifiedAt && (
          <InfoBox label="Updated">
            <div>{new Date(event.lastModifiedAt).toLocaleString()}</div>
          </InfoBox>
        )}

        {event.teamCode &&
          (event.projectCode ? (
            <InfoBox label="Team / Project">
              <div>
                <Link to={`/dashboard/${event.teamCode}`}>{event.teamCode}</Link>
                {" / "}
                <Link to={`/dashboard/${event.teamCode}/${event.projectCode}`}>{event.projectCode}</Link>
              </div>
            </InfoBox>
          ) : (
            <InfoBox label="Team">
              <div>
                <Link to={`/dashboard/${event.teamCode}`}>{event.teamCode}</Link>
              </div>
            </InfoBox>
          ))}

        {event.environmentCode && (
          <InfoBox label="Environment">
            <LayersIcon className="inline-block size-4 mr-2" />
            {event.environmentCode}
          </InfoBox>
        )}

        {event.sourceIp && (
          <InfoBox label="Source">
            <div>{event.source}</div>
            <div>IP: {event.sourceIp}</div>
          </InfoBox>
        )}
      </div>
    </div>
  );
};

interface InfoBoxProps {
  label: React.ReactNode;
  children?: React.ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ label, children }) => {
  return (
    <div className="neu rounded-md px-3 py-2 bg-secondary-100 text-sm overflow-x-hidden">
      <Label className="font-bold">{label}</Label>
      <div className="overflow-x-hidden text-wrap">{children}</div>
    </div>
  );
};
