import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from "react-router-dom";
import { useAuthControllerVerify } from "@/api/generated";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { cn } from "@/utils/ui.util";

export const VerifyUserEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { data, isPending, isError } = useAuthControllerVerify({ token: token ?? "" });

  if (!token || isError) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isPending) {
    return <LoadingSpinner message="Verifying..." />;
  }

  const getAlertContent = () => {
    if (!data) return null;

    const isSuccess = data.status === "success";

    return (
      <Card className={cn("mb-6 p-4", isSuccess ? "bg-tertiary-200/80" : "bg-red-200/80")}>
        <div className="flex gap-4 items-center">
          {isSuccess ? <CheckCircle2 className="size-6" /> : <AlertCircle className="size-6" />}
          <div>
            <h4 className="font-semibold tracking-tight">{isSuccess ? "Success!" : "Verification Failed"}</h4>
            <p className="text-sm text-gray-600">{data.message}</p>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Card className="w-full max-w-xl">
        <CardHeader>
          <CardTitle className="font-semibold text-base">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4 items-center">
                  <h3 className="text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">
                    {data?.status === "success" ? "Welcome!" : "Oops!"}
                  </h3>
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-700">
                {data?.status === "success" ? "Your email has been verified." : "Something went wrong."}
              </h3>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-8">
            {getAlertContent()}
            <div className="flex justify-center gap-2">
              <p className="text-sm text-gray-700">
                {data?.status === "success" ? "Ready to get started?" : "Need to try again?"}
              </p>
              <Link
                to={data?.status === "success" ? "/dashboard" : "/auth"}
                className="underline hover:cursor-pointer text-gray-600"
              >
                <p className="text-sm text-gray-700">{data?.status === "success" ? "Go to Dashboard" : "Login"}</p>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
