import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowDown, ArrowUp } from "lucide-react";
import { Fixed__ProjectHyperlinkResponseDto } from "../item/project-items-helpers";
import { HyperlinkCodeCell, HyperlinkDescriptionCell, HyperlinkMonitoringProfileCell } from "./hyperlinks-cells";
import { CreateHyperlinkDtoMonitoringProfile } from "@/api/generated";

export const monitoringProfileInfo: Record<CreateHyperlinkDtoMonitoringProfile, { description: string }> = {
  "public-api": {
    description: "TODO: hyperlinks-config",
  },
  "public-http-get-only": {
    description: "TODO: hyperlinks-config",
  },
  "public-website": {
    description: "TODO: hyperlinks-config",
  },
  "host-only": {
    description: "TODO: hyperlinks-config",
  },
  none: {
    description: "TODO: hyperlinks-config",
  },
};

export const hyperlinksColumns: ColumnDef<Fixed__ProjectHyperlinkResponseDto>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
    minSize: 32,
    size: 32,
    maxSize: 32,
  },
  {
    accessorKey: "code",
    header: ({ column }) => {
      const isSorted = column.getIsSorted();

      return (
        <Button
          variant="ghost"
          className="px-0 bg-transparent"
          onClick={() => column.toggleSorting(isSorted === "asc")}
        >
          Code
          {isSorted && isSorted === "asc" ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: HyperlinkCodeCell,
    meta: {
      className: {
        cell: "text-wrap",
      },
      customWidth: "auto",
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: HyperlinkDescriptionCell,
    meta: {
      className: {
        cell: "text-wrap",
      },
      customWidth: "auto",
    },
  },
  {
    accessorKey: "monitoringProfile",
    header: "Monitoring Profile",
    cell: HyperlinkMonitoringProfileCell,
    meta: {
      className: {
        cell: "text-wrap",
      },
      customWidth: "auto",
    },
  },
];
