import {
  EnvironmentMiniResponseDto,
  useProjectsControllerDeleteEnvironment, useProjectsControllerGetProject,
  useProjectsControllerPreviewDeleteEnvironment,
} from "@/api/generated";
import { LoadingCount } from "@/components/loading-count";
import { ManagerTable } from "@/components/manager-table";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { DestructiveConfirmDialog } from "@/lib/dialog/destructive-confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { AddEnvironmentDialog } from "@/lib/projects/environments/add-environment-dialog";
import { EditEnvironmentDialog } from "@/lib/projects/environments/edit-environment-dialog";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import { parseEnvColor } from "@/utils/colors/color-definitions";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Edit, FileClockIcon, FileIcon, Flag, MonitorCheckIcon, Plus, Trash, MessageSquareCodeIcon } from "lucide-react";
import React from "react";
import {
  EditEnvironmentNotificationsConfig
} from "@/lib/projects/environments/edit-environment-notifications-config-dialog.tsx";

export const EnvironmentActionCell: React.FC<
  CellContext<EnvironmentMiniResponseDto, unknown> & {
    onRefetch?: () => void;
  }
> = ({ row, onRefetch }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const deleteMutation = useProjectsControllerDeleteEnvironment({
    mutation: {
      onSuccess: (_, variables) => {
        toast({
          title: `Environment "${variables.environmentCode}" deleted.`,
        });

        onRefetch?.();
      },
      onError: onErrorToast,
    },
  });

  return (
    <div className="flex items-center gap-2 justify-end">
      <EditEnvironmentDialog
        teamCode={teamCode}
        projectCode={projectCode}
        environment={row.original}
        onSuccess={onRefetch}
      >
        <Button size="icon" className="bg-primary-200 w-8 h-8">
          <Edit size={16} />
        </Button>
      </EditEnvironmentDialog>

      <EditEnvironmentNotificationsConfig
        teamCode={teamCode}
        projectCode={projectCode}
        environment={row.original}
        onSuccess={onRefetch}
      >
        <Button size="icon" className="bg-primary-200 w-8 h-8">
          <MessageSquareCodeIcon size={16} />
        </Button>
      </EditEnvironmentNotificationsConfig>

        <DestructiveConfirmDialog
        value={`#${row.original.code}`}
        title={`Delete "${row.original.code}" environment`}
        additionalDescription={
          <EnvironmentPreviewDelete teamCode={teamCode} projectCode={projectCode} environmentCode={row.original.code} />
        }
        onConfirm={() => deleteMutation.mutate({ teamCode, projectCode, environmentCode: row.original.code })}
      >
        <Button size="icon" className="bg-red-200 w-8 h-8" loading={deleteMutation.isPending}>
          {!deleteMutation.isPending && <Trash size={16} />}
        </Button>
      </DestructiveConfirmDialog>
    </div>
  );
};

export const ProjectEnvironmentsSettings: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { isLoading, data: projectData, refetch } = useProjectsControllerGetProject(teamCode, projectCode);

  const columns = React.useMemo(() => {
    return [
      {
        header: "Environment",
        cell: ({ row }) => {
          return (
            <div
              className="font-medium"
              style={{
                color: parseEnvColor(row.original.color)?.fg,
              }}
            >
              {row.original.code}
            </div>
          );
        },
        meta: {
          customWidth: "auto",
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        meta: {
          customWidth: "auto",
        },
      },
      {
        id: "$actions",
        header: () => (
          <div className="flex justify-end">
            <AddEnvironmentDialog teamCode={teamCode} projectCode={projectCode} onSuccess={() => refetch()}>
              <Button size="icon" className="bg-tertiary-100 w-8 h-8">
                <Plus size={16} />
              </Button>
            </AddEnvironmentDialog>
          </div>
        ),
        cell: (props) => <EnvironmentActionCell onRefetch={() => refetch()} {...props} />,
        minSize: 120,
        size: 120,
        maxSize: 120,
      },
    ] satisfies ColumnDef<EnvironmentMiniResponseDto>[];
  }, [projectCode, refetch, teamCode]);

  return (
    <FeaturePage title="Project Environments Settings" description="Manage project environments." isLoading={isLoading}>
      <ManagerTable columns={columns} data={projectData?.environments ?? []} />
    </FeaturePage>
  );
};

interface EnvironmentPreviewDeleteProps {
  teamCode: string;
  projectCode: string;
  environmentCode: string;
}

const EnvironmentPreviewDelete: React.FC<EnvironmentPreviewDeleteProps> = ({
  teamCode,
  projectCode,
  environmentCode,
}) => {
  const { data } = useProjectsControllerPreviewDeleteEnvironment<{
    numFeatureFlagValues: number;
    numProjectItemValues: number;
    numHyperlinkMonitoringJobs: number;
    numHyperlinkMonitoringHistoryEvents: number;
  }>(teamCode, projectCode, environmentCode);

  return (
    <div>
      <div>The following action will delete:</div>
      <ul className="list-disc list-inside ml-2">
        <li>
          <Flag className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numFeatureFlagValues} item="Feature" />
        </li>

        <li>
          <FileClockIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numHyperlinkMonitoringHistoryEvents} item="History Event" />
        </li>

        <li>
          <FileIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numProjectItemValues} item="Item" />
        </li>

        <li>
          <MonitorCheckIcon className="size-4 inline-block mr-2" />
          <LoadingCount count={data?.numHyperlinkMonitoringJobs} item="Monitoring Job" />
        </li>
      </ul>
    </div>
  );
};
