import {
  SingleEnvironmentWithHyperlinksMonitorDto,
  useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst,
  useProjectsControllerGetEnvironments,
} from "@/api/generated";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import React from "react";
import { Navigate } from "react-router-dom";
import { ManagerTable } from "@/components/manager-table";
import { monitoringColumns } from "@/lib/projects/monitoring/dashboard/monitoring-columns";
import { transformToMonitoringColumnsInput } from "@/lib/projects/monitoring/utils";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Asterisk, LayersIcon, LinkIcon } from "lucide-react";
import { cn } from "@/utils/ui.util";

export const Monitoring: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const [selectedEnvironment, setSelectedEnvironment] = React.useState<string>("*");

  const {
    isPending,
    isFetching,
    isError,
    data: environments,
  } = useProjectsControllerGetEnvironments(teamCode, projectCode);

  if (isError) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <FeaturePage
      title="Monitoring"
      description="Monitor the health of your system."
      isLoading={isPending || isFetching}
      actions={
        <Button asChild className="no-underline bg-secondary-200">
          <Link to={`/dashboard/${teamCode}/${projectCode}/hyperlinks`}>
            <LinkIcon className="mr-2 size-4" />
            Edit Hyperlinks
          </Link>
        </Button>
      }
    >
      {environments && (
        <div className="space-y-6">
          <div className="relative">
            <div className="flex gap-4 items-center">
              <Button
                variant={selectedEnvironment === "*" ? "neu" : "neu"}
                size="sm"
                className={cn(selectedEnvironment === "*" && "bg-tertiary-400/50")}
                onClick={() => setSelectedEnvironment("*")}
              >
                <Asterisk className="size-4 mr-2" />
                All Environments
              </Button>
              <span className="font-mono font-bold">/</span>
              <div className="flex gap-2">
                {environments.map((env) => (
                  <Button
                    key={env.code}
                    size="sm"
                    variant={selectedEnvironment === env.code ? "neu" : "neu"}
                    className={cn("font-mono", selectedEnvironment === env.code && "bg-tertiary-400/50")}
                    onClick={() => setSelectedEnvironment(env.code)}
                  >
                    <LayersIcon className="size-4 mr-2" />
                    {env.code}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <MonitoringCore environment={selectedEnvironment} />
        </div>
      )}
    </FeaturePage>
  );
};

interface MonitoringCoreProps {
  environment: string;
}

const MonitoringCore: React.FC<MonitoringCoreProps> = ({ environment }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const {
    isPending,
    isError,
    data: monitorResults,
  } = useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst(
    teamCode,
    projectCode,
    environment,
    "*",
    {
      maxResults: 6,
    }
  );

  return (
    <>
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && monitorResults && (
        <>
          {Object.keys(monitorResults.environments).length === 0 && (
            <div className="flex items-center justify-center w-full">
              <p className="text-sm text-gray-600">No monitoring data available.</p>
            </div>
          )}

          {Object.keys(monitorResults.environments).map((envKey) => {
            const monitorResultsEnvironemnts = monitorResults.environments;

            const envData = monitorResultsEnvironemnts[envKey];
            return <MonitoringDashboard key={envKey} envKey={envKey} envData={envData} />;
          })}
        </>
      )}
    </>
  );
};

export interface MonitoringDashboardProps {
  envKey: string;
  envData: SingleEnvironmentWithHyperlinksMonitorDto;
}

const MonitoringDashboard: React.FC<MonitoringDashboardProps> = ({ envKey, envData }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const monitoringData = React.useMemo(() => {
    return transformToMonitoringColumnsInput(envData, teamCode, projectCode);
  }, [envData, teamCode, projectCode]);

  return (
    <div className="space-y-2">
      <h4 className="font-bold">
        Environment: <span className="text-gray-600 font-mono">{envKey}</span>
      </h4>
      <ManagerTable columns={monitoringColumns} data={monitoringData} />
    </div>
  );
};
