import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, type CarouselApi } from "@/components/ui/carousel";
import { cn } from "@/utils/ui.util";
import clsx from "clsx";
import Autoplay from "embla-carousel-autoplay";
import { ArrowUpRight, GitBranch, InboxIcon, Mail, Webhook } from "lucide-react";
import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const brandingVideos = {
  version: "1.0",
  data: [
    {
      id: "pipelines",
      title: "Pipelines",
      video: "/branding/05.webm",
      icon: <GitBranch size={20} />,
      details: {
        title: "Pipelines",
        description: (
          <>
            Manage your <b>CI/CD pipelines</b> and monitor deployments in real-time, all from a single place.
          </>
        ),
      },
      more: "/home/pipelines",
    },
    {
      id: "webhooks",
      title: "Webhooks",
      video: "/branding/01.webm",
      icon: <Webhook size={20} />,
      details: {
        title: "Webhooks",
        description: (
          <>
            Quickly test and debug <b>webhooks</b>. View incoming data and simulate different scenarios easily.
          </>
        ),
      },
      more: "/home/webhooks",
    },
    {
      id: "emails",
      title: "Emails",
      video: "/branding/03.webm",
      icon: <Mail size={20} />,
      details: {
        title: "Emails",
        description: (
          <>
            Spin up temporary <b>emails</b> to test sign-ups or notifications and check the full content for errors or
            issues.
          </>
        ),
      },
      more: "/home/emails",
    },
    {
      id: "exception-inbox",
      title: "Exception Inbox",
      video: "",
      icon: <InboxIcon size={20} />,
      details: {
        title: "Exception Inbox",
        description: <>Track, organize, and resolve errors in one place.</>,
      },
      more: "/home/exception-inbox",
    },
    // {
    //   id: "sequential-webhooks",
    //   title: "Sequential Webhooks",
    //   video: "/branding/04.webm",
    //   icon: <ListEnd size={20} />,
    //   details: {
    //     title: "Sequential Webhooks",
    //     description: "Predefine response order with specified response codes, response body, content types and delay.",
    //   },
    // },
  ],
};

export const BrandingVideosCarousel: React.FC = () => {
  const [api, setApi] = React.useState<CarouselApi>();
  const plugin = React.useRef(Autoplay({ delay: 5000, stopOnInteraction: true }));
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleStepClick = (index: number) => {
    setActiveIndex(index);
    api?.scrollTo(index);
    plugin.current.stop();
  };

  React.useEffect(() => {
    if (!api) {
      return;
    }

    const handleSlideChange = () => {
      setActiveIndex(api.selectedScrollSnap());
    };

    api.on("select", handleSlideChange);

    return () => {
      api.off("select", handleSlideChange);
    };
  }, [api]);

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="md:w-8/12 rounded-xl overflow-hidden">
        <Carousel setApi={setApi} plugins={[plugin.current]}>
          <CarouselContent>
            {brandingVideos.data.map((video, index) => {
              return (
                <CarouselItem key={index}>
                  <div className="neu-flat rounded-xl overflow-hidden">
                    <ReactPlayer
                      url={video.video}
                      width="100%"
                      height="100%"
                      controls={true}
                      muted={true}
                      onPlay={() => {
                        umami.track("Video played");
                      }}
                    />
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
        </Carousel>
      </div>
      <div className="md:w-4/12">
        <div className="grid grid-cols-1 gap-4">
          {brandingVideos.data.map((video, index) => {
            return (
              <DescriptionSteps
                key={index}
                title={video.details.title}
                description={video.details.description}
                selected={activeIndex === index}
                icon={video.icon}
                onClick={() => handleStepClick(index)}
                more={video.more}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface DescriptionStepsProps {
  title: string;
  description: React.ReactNode;
  selected: boolean;
  icon: React.ReactNode;
  onClick: () => void;
  more?: string;
}

const DescriptionSteps: React.FC<DescriptionStepsProps> = ({ title, description, selected, icon, onClick, more }) => {
  return (
    <div
      className={cn(
        "border-2 border-transparent flex flex-col gap-8 p-5 rounded-2xl cursor-pointer hover:neu-flat bg-white",
        selected && "neu-flat bg-primary-200 hover:bg-primary-200"
      )}
      onClick={onClick}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-3 items-center">
          {icon}
          <h5 className="font-semibold">{title}</h5>

          {more && (
            <Button variant="neu-flat" size="xs" className="px-2 ml-auto" asChild>
              <Link to={more} className={clsx("no-underline w-fit bg-secondary-200", selected && "bg-white")}>
                <div className="flex items-center gap-1">
                  More
                  <ArrowUpRight size={16} />
                </div>
              </Link>
            </Button>
          )}
        </div>
        <div className="line-clamp-3 text-wrap">
          <small>{description}</small>
        </div>
      </div>
    </div>
  );
};
