import React from "react";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { HeadersTable } from "@/components/headers-table";
import { Separator } from "@/components/ui/separator";
import { LoadingSpinner } from "@/components/loading-spinner";
import { RequestBody } from "@/lib/webhooks/request/request-body";
import { RequestDetails } from "@/lib/webhooks/request/request-details";
import { RequestState } from "@/utils/parsing";
import { useWebhooksControllerGetWebhookReceivedRequestByCode } from "@/api/generated";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { requestToApiGatewayEvent } from "@/lib/webhooks/utils/utils";
import { useCurrentProject } from "@/lib/projects/context/project-context";

interface RequestDisplayProps {
  endpointCode: string;
  requestCode: string;
}

export const RequestDisplay: React.FC<RequestDisplayProps> = ({ endpointCode, requestCode }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const [request, setRequest] = React.useState<RequestState>();

  const { isPending, isError, data } = useWebhooksControllerGetWebhookReceivedRequestByCode(
    teamCode,
    projectCode,
    endpointCode,
    requestCode
  );

  React.useEffect(() => {
    if (data && data.request) {
      const apiGatewayProxyEvent = requestToApiGatewayEvent(data.request);
      setRequest(new RequestState(apiGatewayProxyEvent));
    }
  }, [data]);

  return (
    <div>
      {isPending && (
        <div className="p-8 text-center text-sm font-medium">
          <LoadingSpinner message="Loading..." />
        </div>
      )}
      {isError && <p className="p-8 text-center text-sm font-medium">Error loading message...</p>}
      {!isPending && !isError && request && (
        <div className="neu rounded-lg">
          <ContentPanel webhookRequest={request} />
          <Separator />
          <RequestBody webhookRequest={request} />
        </div>
      )}
    </div>
  );
};

interface ContentPanelProps {
  webhookRequest: RequestState;
}

const ContentPanel: React.FC<ContentPanelProps> = ({ webhookRequest }) => {
  const requestHelper = webhookRequest.getRequest();

  return (
    <>
      <div className="sm:hidden">
        <div className="flex flex-col gap-2">
          <RequestDetails requestHelper={requestHelper} />
          <Separator />
          <HeadersTable title={"Request Headers"} headers={requestHelper.getHeaders()} type="http-headers" />
        </div>
      </div>
      <div className="hidden sm:block">
        <ResizablePanelGroup direction="horizontal" className="max-h-[500px]">
          <ResizablePanel defaultSize={50} minSize={25}>
            <RequestDetails requestHelper={requestHelper} />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={50} minSize={25}>
            <HeadersTable title={"Request Headers"} headers={requestHelper.getHeaders()} type="http-headers" />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </>
  );
};
