import { HoverInfo } from "@/components/hover-info";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils/ui.util";
import { ParsedTask, Task, TaskManager, TaskManagerStatus, TaskStatus } from "@lilbunnyrabbit/task-manager";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import clsx from "clsx";
import { ExpandIcon, MessageCircleWarning, MessageCircleX } from "lucide-react";
import React from "react";

const managerStatusClass: Record<TaskManagerStatus | TaskStatus, string> = {
  idle: "bg-secondary-950/20",
  "in-progress": "bg-secondary-200",
  fail: "bg-red-200",
  success: "bg-tertiary-200",
  stopped: "bg-primary-200",
  error: "bg-red-200",
};

interface TaskManagerRenderProps {
  title?: React.ReactNode;
  taskManager: TaskManager;
}

export const TaskManagerRender: React.FC<TaskManagerRenderProps> = ({ title, taskManager }) => {
  const [, setHasError] = React.useState(false);
  const counterState = React.useState(0);

  React.useEffect(() => {
    function onChange(this: TaskManager) {
      counterState[1]((c) => c + 1);
    }

    taskManager.on("change", onChange);
    taskManager.on("fail", () => setHasError(true));

    return () => {
      taskManager.off("change", onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskManager]);

  return (
    <div
      className={cn(
        "neu rounded-md w-full h-fit grid grid-cols-1 grid-rows-[min-content,1fr] overflow-hidden",
        managerStatusClass[taskManager.status]
      )}
    >
      <div className="flex gap-x-4 gap-y-2 justify-between items-center p-4 flex-wrap">
        <h3 className="font-bold">{title}</h3>

        <div className="flex gap-2 items-center flex-wrap">
          <Button
            size="sm"
            className="bg-tertiary-200"
            disabled={!taskManager.isStatus("idle", "stopped", "fail")}
            onClick={() => taskManager.start(taskManager.isStatus("fail"))}
          >
            {taskManager.isStatus("idle") ? "Start" : "Continue"} {taskManager.isStatus("fail") && "(force)"}
          </Button>
          <Button
            size="sm"
            className="bg-red-200"
            disabled={!taskManager.isStatus("in-progress")}
            onClick={() => taskManager.stop()}
          >
            Stop
          </Button>
          <Button
            size="sm"
            className="bg-primary-200"
            disabled={taskManager.isStatus("in-progress", "idle")}
            onClick={() => taskManager.reset()}
          >
            Reset
          </Button>
          <Button
            size="sm"
            className="bg-secondary-200"
            disabled={!taskManager.queue.length}
            onClick={() => taskManager.clearQueue()}
          >
            Clear Queue
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-2 border-b-2 border-b-secondary-950 px-4 pb-4">
        {taskManager.tasks.map((task) => (
          <TaskDisplay key={`task-${task.id}`} task={task} />
        ))}

        {taskManager.queue.map((task) => (
          <TaskDisplay key={`task-${task.id}`} task={task} />
        ))}
      </div>

      <TaskProgress value={taskManager.progress * 100} status={taskManager.status} />
    </div>
  );
};

interface TaskDisplayProps {
  task: Task;
}

const TaskDisplay: React.FC<TaskDisplayProps> = ({ task }) => {
  const [parsed, setParsed] = React.useState<ParsedTask>(() => task.parse());

  React.useEffect(() => {
    function onChange(this: Task) {
      const parsed = this.parse();
      setParsed(parsed);
    }

    task.on("change", onChange);

    return () => {
      task.off("change", onChange);
    };
  }, [task]);

  return (
    <div className={clsx(task.status === "idle" && "opacity-40")}>
      <ParsedDisplay parsed={parsed} task={task} />
    </div>
  );
};
interface ParsedDisplayProps {
  parsed: ParsedTask;
  task: Task;
}

const ParsedDisplay: React.FC<ParsedDisplayProps> = ({ parsed, task }) => {
  const [showResult, setShowResult] = React.useState(false);

  return (
    <div className="neu rounded-md bg-white overflow-hidden">
      <div
        className={clsx(
          "font-bold py-1 px-2 flex justify-between items-center text-sm",
          task.status !== "idle" && "border-b-2 border-b-secondary-950"
        )}
      >
        <div className="truncate">{parsed.status}</div>

        <div className="flex items-center gap-2">
          {!!parsed.warnings?.length && (
            <HoverInfo
              content={
                <ul className="list-disc list-inside">
                  {(parsed.warnings ?? []).map((warning, i) => (
                    <li key={`warning-${i}`} className="text-primary-500" children={warning} />
                  ))}
                </ul>
              }
            >
              <div className="flex items-start gap-1 text-primary-500 text-sm">
                {parsed.warnings?.length} <MessageCircleWarning size={18} />
              </div>
            </HoverInfo>
          )}

          {!!parsed.errors?.length && (
            <HoverInfo
              content={
                <ul className="list-disc list-inside">
                  {(parsed.errors ?? []).map((error, i) => (
                    <li key={`error-${i}`} className="text-red-500" children={error} />
                  ))}
                </ul>
              }
            >
              <div className="flex items-start gap-1 text-red-500 text-sm">
                {parsed.errors?.length} <MessageCircleX size={18} />
              </div>
            </HoverInfo>
          )}

          <Button
            variant="ghost"
            size="icon"
            className="w-fit !bg-transparent p-0 h-fit ml-4"
            disabled={!parsed.result}
            onClick={() => setShowResult((s) => !s)}
          >
            <ExpandIcon size={16} />
          </Button>
        </div>
      </div>

      {showResult && parsed.result && (
        <div className="overflow-auto border-b-2 border-b-secondary-950">
          <pre className="font-monospaced text-secondary-950 px-2 py-1">{parsed.result}</pre>
        </div>
      )}

      {task.status !== "idle" && <TaskProgress value={task.progress * 100} status={task.status} />}
    </div>
  );
};

const rootClass: Record<TaskManagerStatus | TaskStatus, string> = {
  idle: "bg-secondary-950/10",
  "in-progress": "bg-secondary-100",
  fail: "bg-red-100",
  success: "bg-tertiary-100",
  stopped: "bg-primary-100",
  error: "bg-red-100",
};

const indicatorClass: Record<TaskManagerStatus | TaskStatus, string> = {
  idle: "bg-secondary-950/60",
  "in-progress": "bg-secondary-600",
  fail: "bg-red-600",
  success: "bg-tertiary-600",
  stopped: "bg-primary-600",
  error: "bg-red-600",
};

interface TaskProgressProps {
  value: number;
  status: TaskStatus | TaskManagerStatus;
  className?: string;
}

const TaskProgress: React.FC<TaskProgressProps> = ({ value, status, className }) => {
  return (
    <ProgressPrimitive.Root className={cn("relative h-2 w-full overflow-hidden", rootClass[status], className)}>
      <ProgressPrimitive.Indicator
        className={cn(
          "h-full w-full flex-1 transition-all",
          indicatorClass[status],
          value < 100 && "border-r-2 border-r-secondary-950"
        )}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  );
};
