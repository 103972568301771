import clsx from "clsx";

interface LoadingSpinnerProps {
  message?: string;
  size?: number;
  className?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message, size, className }) => {
  return (
    <div className={clsx(className, "flex flex-row gap-2 justify-center items-center")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ?? 24}
        height={size ?? 24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={"animate-spin"}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
      {message && <p className="text-sm font-medium">{message}</p>}
    </div>
  );
};
