import React from "react";
import { Button, ButtonProps } from "./ui/button";
import { cn } from "@/utils/ui.util";

interface ToggleButtonProps extends ButtonProps {
  enabled: boolean;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ enabled, className, ...props }) => {
  return (
    <Button
      data-enabled={enabled}
      className={cn(
        "w-full h-8 relative [&:hover>span:first-of-type]:hidden [&:hover>span:last-of-type]:flex",
        enabled && "bg-tertiary-200 hover:bg-tertiary-100",
        !enabled && "bg-secondary-950/20 hover:bg-secondary-950/10",
        className
      )}
      {...props}
    >
      {!props.loading && (
        <>
          <span>{enabled ? "Enabled" : "Disabled"}</span>
          <span className="hidden">{enabled ? "Disable?" : "Enable?"}</span>
        </>
      )}
    </Button>
  );
};
