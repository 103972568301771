import { ApiKeyResponseDto, useApiKeysControllerGetApiKeys } from "@/api/generated";
import { ManagerTable } from "@/components/manager-table";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { AddApiKeyDialog } from "@/lib/teams/api-keys/add-api-key-dialog";
import { ApiKeysActionCell, ApiKeysStatusCell } from "@/lib/teams/api-keys/api-key-cells";
import { apiKeysColumns } from "@/lib/teams/api-keys/api-keys-config";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { ColumnDef } from "@tanstack/react-table";
import { Plus } from "lucide-react";
import React from "react";

export const ApiKeys: React.FC = () => {
  const { teamCode } = useCurrentTeam();

  const pagination = usePagination();
  const {
    isPending,
    isFetching,
    data: apiKeys,
    refetch,
  } = useApiKeysControllerGetApiKeys(teamCode, {
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });

  const columns: ColumnDef<ApiKeyResponseDto>[] = React.useMemo(() => {
    return [
      {
        accessorKey: "disabled",
        header: "Status",
        cell: ({ row }) => <ApiKeysStatusCell row={row} refetch={refetch} />,
        minSize: 100,
        size: 100,
        maxSize: 100,
      },
      ...apiKeysColumns,
      {
        id: "$actions",
        header: "Actions",
        cell: ({ row }) => <ApiKeysActionCell row={row} refetch={refetch} />,
        meta: {
          className: {
            header: "text-center font-bold",
          },
        },
        minSize: 90,
        size: 90,
        maxSize: 90,
      },
    ];
  }, [refetch]);

  return (
    <FeaturePage
      title="API Keys"
      description="Manage your project's API keys."
      isLoading={isPending || isFetching}
      actions={
        <AddApiKeyDialog teamCode={teamCode} onSuccess={refetch}>
          <Button className="bg-secondary-200">
            Create API Key <Plus size={16} className="ml-1" />
          </Button>
        </AddApiKeyDialog>
      }
    >
      <ManagerTable columns={columns} data={apiKeys?.results ?? []} />

      <Pagination className="pt-4" pagination={pagination} numberOfPages={apiKeys?.numberOfPages} />
    </FeaturePage>
  );
};
