import { LoadingSpinner } from "@/components/loading-spinner";
import { Toaster } from "@/components/ui/toaster";
import { SecretsProvider } from "@/lib/tools/secrets-manager/secrets-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode } from "react";
import { createBrowserRouter, Navigate, RouterProvider, useLocation } from "react-router-dom";

import { AuthProvider } from "@/auth/auth-provider";
import { useAuth } from "@/auth/useAuth";

import { useUser } from "@/old/api/queries";

import { AuthLayout } from "@/layouts/auth-layout";
import { DashboardLayout } from "@/layouts/dashboard-layout";
import { ProjectDashboardLayout } from "@/layouts/dashboard/project-dashboard-layout";
import { TeamDashboardLayout } from "@/layouts/dashboard/team-dashboard-layout";
import { SecondaryLayout } from "@/layouts/secondary-layout";
import { SettingsLayout } from "@/layouts/settings-layout";
import { WelcomePageLayout } from "@/layouts/welcome-page-layout";

import { LandingPageLayout } from "@/layouts/landing-page-layout";
import { PageNotFound } from "@/routes/404";
import { AcceptInvite } from "@/routes/accept-invite";
import { ForgotPassword } from "@/routes/auth/forgot-password";
import { GithubVerify } from "@/routes/auth/github-verify";
import { Login } from "@/routes/auth/login";
import { Register } from "@/routes/auth/register";
import { ResetPassword } from "@/routes/auth/reset-password";
import { VerifyUser } from "@/routes/auth/verify-user";
import { About } from "@/routes/landing/about";
import { CookiePolicy } from "@/routes/landing/cookie-policy";

import { PipelinesHome } from "@/routes/landing/homes/pipelines-home";

import { LandingPage as NewLandingPage } from "@/routes/landing/landing-page";
import { PrivacyPolicy } from "@/routes/landing/privacy-policy";
import { TermsAndConditions } from "@/routes/landing/terms-and-conditions";

import { Files } from "@/routes/projects/files";

import { PasswordAndCredentials } from "@/routes/settings/password-and-credentials";
import { PaymentsAndSubscriptions } from "@/routes/settings/payments-and-subscriptions.tsx";
import { Profile } from "@/routes/settings/profile";

import { EmailsHome } from "@/routes/landing/homes/emails-home";
import { WebhooksHome } from "@/routes/landing/homes/webhooks-home";
import { Document } from "@/routes/projects/document";
import { Documents } from "@/routes/projects/documents";
import { Emails } from "@/routes/projects/emails/emails";
import { EmailsDashboard } from "@/routes/projects/emails/emails-dashboard";
import { EmailsPage } from "@/routes/projects/emails/emails-page";
import { ExceptionInbox } from "@/routes/projects/exception-inbox";
import { FeatureFlag } from "@/routes/projects/feature-flag";
import { FeatureFlags } from "@/routes/projects/feature-flags";
import { HttpRequester } from "@/routes/projects/http-requester";
import { Hyperlinks } from "@/routes/projects/hyperlinks";
import { HyperlinkMonitor } from "@/routes/projects/monitoring/hyperlink-monitor";
import { Monitoring } from "@/routes/projects/monitoring/monitoring";
import { ProjectIndex } from "@/routes/projects/project-index";
import { Secrets } from "@/routes/projects/secrets";
import { ApiKeys } from "@/routes/teams/api-keys";
import { CreateTeam } from "@/routes/teams/create-team";
import { NotificationChannels } from "@/routes/teams/notification-channels";
import { NotificationTopics } from "@/routes/teams/notification-topics";
import { TeamIndex } from "@/routes/teams/team-index";
import { TeamSettings } from "@/routes/teams/team-settings";
import { AutodetectParserRoute } from "@/routes/tools/autodetect-parser";
import { ToolsRoute } from "@/routes/tools/tools";
import { VerifyUserEmail } from "@/routes/verify-user-email";
import { Webhooks } from "@/routes/webhooks/webhooks";
import { WebhooksDashboard } from "@/routes/webhooks/webhooks-dashboard";
import { WebhookPage } from "@/routes/webhooks/webhooks-page";
import { Welcome } from "@/routes/welcome";
import { DesignRoute } from "./routes/design";
import { ExceptionInboxHome } from "./routes/landing/homes/exception-inbox-home";
import { ExceptionInboxEventRoute } from "./routes/projects/exception-inbox-event";
import { ExceptionInboxPresignedRoute } from "./routes/projects/exception-inbox-presigned";
import { ProjectEnvironmentsSettings } from "./routes/projects/settings/project-environments-settings";
import { ProjectGeneralSettings } from "./routes/projects/settings/project-general-settings";
import { ProjectSettings } from "./routes/projects/settings/project-settings";
import { PricingPage } from "./routes/pricing-page";

const ENVIRONMENT = `${import.meta.env.VITE_ENVIRONMENT}`;

const queryClient = new QueryClient();

interface RouteProps {
  element: ReactNode;
}

export const DashboardRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();
  const location = useLocation();

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (status === "redirect-to-auth") {
    console.log(`Dashboard: status === "redirect-to-auth"`, location);

    return <Navigate to="/auth" replace={true} />;
  }

  return element;
};

export const ProtectedRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();
  const location = useLocation();

  if (status === "public") {
    return (
      <Navigate
        to={{
          pathname: "/auth",
          search: `redirect=${location.pathname}${location.hash}`,
        }}
        replace={true}
      />
    );
  }

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full h-dvh bg-secondary-200">
        <LoadingSpinner message={"Loading..."} />
      </div>
    );
  }

  if (status === "redirect-to-auth") {
    return (
      <Navigate
        to={{
          pathname: "/auth",
          search: `redirect=${location.pathname}${location.hash}`,
        }}
        replace={true}
      />
    );
  }

  return element;
};

export const AdminProtectedRoute = ({ element }: RouteProps) => {
  const { status } = useAuth();
  const { isPending, isError, data } = useUser();
  const location = useLocation();

  if (status === "public" || isError) {
    return (
      <Navigate
        to={{
          pathname: "/auth",
          search: `redirect=${location.pathname}${location.hash}`,
        }}
        replace={true}
      />
    );
  }

  if (status == "loading" || isPending) {
    return null;
  }

  if (data.role !== "admin") {
    return <Navigate to="/404" replace={true} />;
  }

  return element;
};

const router = createBrowserRouter([
  {
    path: "/design",
    element: <DesignRoute />,
  },
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <LandingPageLayout />,
    children: [
      {
        index: true,
        element: <NewLandingPage />,
      },
      {
        path: "/home",
        children: [
          {
            index: true,
            element: <Navigate to="/" replace />,
          },
          {
            path: "pipelines",
            element: <PipelinesHome />,
          },
          {
            path: "webhooks",
            element: <WebhooksHome />,
          },
          {
            path: "emails",
            element: <EmailsHome />,
          },
          {
            path: "exception-inbox",
            element: <ExceptionInboxHome />,
          },
        ],
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "cookie-policy",
        element: <CookiePolicy />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "welcome",
        element: <ProtectedRoute element={<WelcomePageLayout />} />,
        children: [
          {
            index: true,
            element: <Welcome />,
          },
        ],
      },
      {
        path: "dashboard",
        element: <ProtectedRoute element={<DashboardLayout />} />,
        children: [
          {
            path: ":teamCode",
            element: <TeamDashboardLayout />,
            children: [
              {
                index: true,
                element: <TeamIndex />,
              },
              {
                path: "notifications",
                children: [
                  {
                    path: "topics",
                    element: <NotificationTopics />,
                  },
                  {
                    path: "channels",
                    element: <NotificationChannels />,
                  },
                ],
              },
              {
                path: "tools",
                element: <ToolsRoute />,
                children: [
                  {
                    path: ":sample?",
                    element: <AutodetectParserRoute />,
                  },
                ],
              },
              {
                path: "settings",
                element: <TeamSettings />,
              },
              {
                path: "api-keys",
                element: <ApiKeys />,
              },
            ],
          },
          {
            path: ":teamCode/:projectCode",
            element: <ProjectDashboardLayout />,
            children: [
              {
                index: true,
                element: <ProjectIndex />,
              },
              {
                path: "settings",
                children: [
                  {
                    index: true,
                    element: <ProjectSettings />,
                  },
                  {
                    path: "general",
                    element: <ProjectGeneralSettings />,
                  },
                  {
                    path: "environments",
                    element: <ProjectEnvironmentsSettings />,
                  },
                ],
              },
              {
                path: "secrets",
                element: <Secrets />,
              },
              {
                path: "hyperlinks",
                element: <Hyperlinks />,
              },
              {
                path: "feature-flags",
                children: [
                  {
                    index: true,
                    element: <FeatureFlags />,
                  },
                  {
                    path: ":code",
                    element: <FeatureFlag />,
                  },
                ],
              },
              {
                path: "files",
                element: <Files />,
              },
              {
                path: "monitoring",
                children: [
                  {
                    index: true,
                    element: <Monitoring />,
                  },
                  {
                    path: ":hyperlinkCode",
                    element: <HyperlinkMonitor />,
                  },
                ],
              },
              {
                path: "exception-inbox",
                element: <ExceptionInbox />,
                children: [
                  {
                    path: "presigned-url",
                    element: <ExceptionInboxPresignedRoute />,
                  },
                  {
                    path: ":eventId?",
                    element: <ExceptionInboxEventRoute />,
                  },
                ],
              },
              {
                path: "documents",
                children: [
                  {
                    index: true,
                    element: <Documents />,
                  },
                  {
                    path: ":documentId/:filename?",
                    element: <Document />,
                  },
                ],
              },
              {
                path: "http-requester",
                element: <HttpRequester />,
              },
              {
                path: "webhooks",
                children: [
                  {
                    index: true,
                    element: <WebhooksDashboard />,
                  },
                  {
                    path: ":endpointCode",
                    element: <Webhooks />,
                    children: [
                      {
                        path: ":requestCode?",
                        element: <WebhookPage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "emails",
                children: [
                  {
                    index: true,
                    element: <EmailsDashboard />,
                  },
                  {
                    path: ":emailAddressCode",
                    element: <Emails />,
                    children: [
                      {
                        path: ":emailCode?",
                        element: <EmailsPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <ProtectedRoute element={<SettingsLayout />} />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: "credentials",
            element: <PasswordAndCredentials />,
          },
          {
            path: "payments",
            element: <PaymentsAndSubscriptions />,
          },
        ],
      },
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "verify",
            element: <VerifyUser />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
          {
            path: "github/verify",
            element: <GithubVerify />,
          },
        ],
      },
      {
        path: "create-team",
        element: <ProtectedRoute element={<SecondaryLayout />} />,
        children: [
          {
            index: true,
            element: <CreateTeam />,
          },
        ],
      },
      {
        path: "accept-invite",
        element: <SecondaryLayout />,
        children: [
          {
            index: true,
            element: <AcceptInvite />,
          },
        ],
      },
      {
        path: "verify-user",
        element: <SecondaryLayout />,
        children: [
          {
            index: true,
            element: <VerifyUserEmail />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SecretsProvider>
          <RouterProvider router={router} />
          <Toaster />
          {ENVIRONMENT !== "prod" && <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />}
        </SecretsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
