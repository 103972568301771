import { isNumber } from "@wt-dev/common/utils";
import React from "react";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { objectKeys } from "@/utils";

const types = {
  days: "Days",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  millisecond: "Milliseconds",
} as const;

const toMilliseconds = (value: number | undefined | null, type: keyof typeof types): number | undefined => {
  if (!isNumber(value)) {
    return undefined;
  }

  switch (type) {
    case "days":
      return value * 24 * 60 * 60 * 1000;
    case "hours":
      return value * 60 * 60 * 1000;
    case "minutes":
      return value * 60 * 1000;
    case "seconds":
      return value * 1000;
    case "millisecond":
    default:
      return value;
  }
};

interface MillisecondsInputProps {
  defaultValue?: number;
  onChange?: (value?: number) => void;
}

export const MillisecondsInput: React.FC<MillisecondsInputProps> = ({ defaultValue, onChange }) => {
  const [type, setType] = React.useState<keyof typeof types>("hours");

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(toMilliseconds(e.target.valueAsNumber, type));
      }
    },
    [onChange, type]
  );

  return (
    <>
      <Input ref={inputRef} type="number" variant="neu-flat" defaultValue={defaultValue} onChange={handleChange} />

      <Select
        value={type}
        onValueChange={(value) => {
          const newType = value as typeof type;
          if (onChange) {
            onChange(toMilliseconds(inputRef.current?.valueAsNumber, type));
          }

          setType(newType);
        }}
      >
        <SelectTrigger className="pr-2 pl-4 rounded-md text-sm w-fit place-self-end h-10">
          <SelectValue placeholder="Select Type" />
        </SelectTrigger>
        <SelectContent>
          {objectKeys(types).map((type) => {
            return (
              <SelectItem key={`type-${type}`} value={type}>
                {type}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </>
  );
};
