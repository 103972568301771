import {
  Activity,
  AlertCircleIcon,
  AtSign,
  File,
  FileText,
  Flag,
  HomeIcon,
  LinkIcon,
  LucideIcon,
  PlaneIcon,
  SettingsIcon,
  ShieldCheck,
  Webhook,
} from "lucide-react";

export interface Feature {
  icon: LucideIcon;
  title: string;
  description: string;
  path: string;
  end?: boolean;
}

export const coreFeatures: Feature[] = [
  {
    title: "Home",
    description: "Project Page",
    icon: HomeIcon,
    path: "/",
    end: true,
  },
  {
    title: "Settings",
    description: "Project Settings",
    icon: SettingsIcon,
    path: "/settings",
  },
];

export const features: Feature[] = [
  {
    title: "Secrets",
    description: "Manage sensitive configuration values",
    icon: ShieldCheck,
    path: `/secrets`,
  },
  {
    title: "Hyperlinks",
    description: "Configure external resource links",
    icon: LinkIcon,
    path: `/hyperlinks`,
  },
  {
    title: "Feature Flags",
    description: "Toggle features across environments",
    icon: Flag,
    path: `/feature-flags`,
  },
  {
    title: "Files",
    description: "Upload and manage files",
    icon: File,
    path: `/files`,
  },
  {
    title: "Monitoring",
    description: "Monitor your application",
    icon: Activity,
    path: `/monitoring`,
  },
  {
    title: "Exception Inbox",
    description: "Catch your errors before they catch you!",
    icon: AlertCircleIcon,
    path: `/exception-inbox`,
  },
  {
    title: "Documents",
    description: "Manage project documents",
    icon: FileText,
    path: `/documents`,
  },
  {
    title: "HTTP Requester",
    description: "Make http requests",
    icon: PlaneIcon,
    path: `/http-requester`,
  },
  {
    title: "Webhooks",
    description: "Test your service webhook integrations",
    path: "/webhooks",
    icon: Webhook,
  },
  {
    title: "Emails",
    description: "Temporary email addresses for testing",
    icon: AtSign,
    path: `/emails`,
  },
];
