import { Outlet } from "react-router-dom";
import { UserDropdown } from "@/layouts/elements/user-dropdown";
import { Link } from "react-router-dom";

export const WelcomePageLayout: React.FC = () => {
  return (
    <div className="min-h-dvh bg-secondary-200">
      <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-primary-300/90 border-b-[2px] border-secondary-950">
        <div className="flex justify-between px-4 w-full">
          <div className="flex items-center">
            <Link to="/" className="no-underline text-secondary-950">
              <div className="flex items-center gap-3">
                <img src={"/logo-circle-hollow-dark.svg"} alt={"wt.dev"} className="w-8 hover:cursor-pointer" />
                <h6 className="font-bold font-mono hover:underline hover:cursor-pointer">wt.dev</h6>
              </div>
            </Link>
          </div>

          <UserDropdown />
        </div>
      </header>

      <div className="p-4">
        <Outlet />
      </div>
    </div>
  );
};
