import { WaitlistForm } from "@/lib/landing-page/waitlist-form";
import React from "react";
import { cn } from "@/utils/ui.util";
import { LinkProps, Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

export const LandingPageLayout: React.FC = () => {
  const docsURL = import.meta.env.VITE_DOCS_URL ?? "https://sand-docs.wt.dev";

  return (
    <>
      <div className="flex flex-col justify-between min-h-full w-full bg-primary-100">
        <div>
          <Outlet />
        </div>

        <footer className="bg-primary-100">
          <div className="container px-4 sm:px-8 flex flex-col gap-10 text-sm text-center py-10">
            <div className="grid grid-cols-3 md:grid-cols-4 gap-4 items-center justify-between">
              <FooterSection title="Get Started">
                <FooterSectionItem title="Dashboard" path="/dashboard" />
                <FooterSectionItem title="Sign In" path="/auth" />
                <FooterSectionItem title="Sign Up" path="/auth/register" />
              </FooterSection>
              <FooterSection title="Get Help">
                <FooterSectionItem title="Docs" path={docsURL} target="_blank" />
                <FooterSectionItem title="CLI" path={docsURL + "/cli"} target="_blank" />
                <FooterSectionItem title="Contact Us" path="mailto:info@wt.dev" />
              </FooterSection>
              <FooterSection title="Legal">
                <FooterSectionItem title="Privacy Policy" path="/privacy-policy" target="_blank" />
                <FooterSectionItem title="Terms & Conditions" path="/terms-and-conditions" target="_blank" />
                <FooterSectionItem title="Cookie Policy" path="/cookie-policy" target="_blank" />
              </FooterSection>
              <FooterSection title="Waitlist" className="col-span-3 md:col-span-1">
                <WaitlistForm />

                <div className="flex gap-2">
                  <a target="_blank" href={"https://discord.gg/6hXaw8sATm"}>
                    <img
                      alt="Discord"
                      className={"pt-2"}
                      src="https://img.shields.io/discord/1257985752000757852?style=flat&logo=discord&logoColor=%23ff9f58&label=Discord&labelColor=090919&color=%23ff9f58&link=https%3A%2F%2Fdiscord.gg%2F6hXaw8sATm"
                    />
                  </a>

                  <a target="_blank" href={"https://x.com/wtdevslo"}>
                    <img
                      alt="Twitter"
                      className={"pt-2"}
                      src="https://img.shields.io/twitter/follow/wtdevslo?style=social&logoColor=ff9f58&labelColor=ff9f58&color=ff9f58
"
                    />
                  </a>
                </div>
              </FooterSection>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

interface FooterSectionProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, children, className }) => {
  return (
    <div className={cn("flex flex-col gap-2 text-left", className)}>
      <div className="font-semibold">{title}</div>
      <div className="flex flex-col">{children}</div>
    </div>
  );
};

interface FooterSectionItemProps {
  title: string;
  path: LinkProps["to"];
  target?: LinkProps["target"];
}

const FooterSectionItem: React.FC<FooterSectionItemProps> = ({ title, path, target }) => {
  return (
    <Link to={path} target={target} className="text-black/60 no-underline hover:underline cursor-pointer">
      {title}
    </Link>
  );
};
