/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ApiKeyListItemDto,
  ApiKeyResponseDto,
  ApiKeysControllerGetApiKeysParams,
  CreateApiKeyDto,
  CreateSignedUrlDto,
  PaginatedApiKeysResponseDto,
  PresigningRequestDto,
  PresigningResponseDto,
  UpdateApiKeyDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Lists API keys
 */
export const apiKeysControllerGetApiKeys = (
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedApiKeysResponseDto>(
    { url: `/v1/api-keys/${teamCode}`, method: "GET", params, signal },
    options
  );
};

export const getApiKeysControllerGetApiKeysQueryKey = (
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams
) => {
  return [`/v1/api-keys/${teamCode}`, ...(params ? [params] : [])] as const;
};

export const getApiKeysControllerGetApiKeysQueryOptions = <
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiKeysControllerGetApiKeysQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>> = ({ signal }) =>
    apiKeysControllerGetApiKeys(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ApiKeysControllerGetApiKeysQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>
>;
export type ApiKeysControllerGetApiKeysQueryError = ErrorType<unknown>;

export function useApiKeysControllerGetApiKeys<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: undefined | ApiKeysControllerGetApiKeysParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useApiKeysControllerGetApiKeys<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useApiKeysControllerGetApiKeys<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Lists API keys
 */

export function useApiKeysControllerGetApiKeys<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params?: ApiKeysControllerGetApiKeysParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKeys>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getApiKeysControllerGetApiKeysQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Creates new API key
 */
export const apiKeysControllerCreateApiKey = (
  teamCode: string,
  createApiKeyDto: CreateApiKeyDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ApiKeyResponseDto>(
    {
      url: `/v1/api-keys/${teamCode}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createApiKeyDto,
      signal,
    },
    options
  );
};

export const getApiKeysControllerCreateApiKeyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>,
    TError,
    { teamCode: string; data: CreateApiKeyDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>,
  TError,
  { teamCode: string; data: CreateApiKeyDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>,
    { teamCode: string; data: CreateApiKeyDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return apiKeysControllerCreateApiKey(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysControllerCreateApiKeyMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>
>;
export type ApiKeysControllerCreateApiKeyMutationBody = CreateApiKeyDto;
export type ApiKeysControllerCreateApiKeyMutationError = ErrorType<unknown>;

/**
 * @summary Creates new API key
 */
export const useApiKeysControllerCreateApiKey = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>,
    TError,
    { teamCode: string; data: CreateApiKeyDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysControllerCreateApiKey>>,
  TError,
  { teamCode: string; data: CreateApiKeyDto },
  TContext
> => {
  const mutationOptions = getApiKeysControllerCreateApiKeyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets API key by apiKeyId
 */
export const apiKeysControllerGetApiKey = (
  teamCode: string,
  apiKeyId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ApiKeyResponseDto>(
    { url: `/v1/api-keys/${teamCode}/${apiKeyId}`, method: "GET", signal },
    options
  );
};

export const getApiKeysControllerGetApiKeyQueryKey = (teamCode: string, apiKeyId: number) => {
  return [`/v1/api-keys/${teamCode}/${apiKeyId}`] as const;
};

export const getApiKeysControllerGetApiKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  apiKeyId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiKeysControllerGetApiKeyQueryKey(teamCode, apiKeyId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>> = ({ signal }) =>
    apiKeysControllerGetApiKey(teamCode, apiKeyId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && apiKeyId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ApiKeysControllerGetApiKeyQueryResult = NonNullable<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>>;
export type ApiKeysControllerGetApiKeyQueryError = ErrorType<unknown>;

export function useApiKeysControllerGetApiKey<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  apiKeyId: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useApiKeysControllerGetApiKey<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  apiKeyId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useApiKeysControllerGetApiKey<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  apiKeyId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets API key by apiKeyId
 */

export function useApiKeysControllerGetApiKey<
  TData = Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  apiKeyId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiKeysControllerGetApiKey>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getApiKeysControllerGetApiKeyQueryOptions(teamCode, apiKeyId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates API key
 */
export const apiKeysControllerUpdateApiKey = (
  teamCode: string,
  apiKeyId: number,
  updateApiKeyDto: UpdateApiKeyDto,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ApiKeyResponseDto>(
    {
      url: `/v1/api-keys/${teamCode}/${apiKeyId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateApiKeyDto,
    },
    options
  );
};

export const getApiKeysControllerUpdateApiKeyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>,
    TError,
    { teamCode: string; apiKeyId: number; data: UpdateApiKeyDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>,
  TError,
  { teamCode: string; apiKeyId: number; data: UpdateApiKeyDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>,
    { teamCode: string; apiKeyId: number; data: UpdateApiKeyDto }
  > = (props) => {
    const { teamCode, apiKeyId, data } = props ?? {};

    return apiKeysControllerUpdateApiKey(teamCode, apiKeyId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysControllerUpdateApiKeyMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>
>;
export type ApiKeysControllerUpdateApiKeyMutationBody = UpdateApiKeyDto;
export type ApiKeysControllerUpdateApiKeyMutationError = ErrorType<unknown>;

/**
 * @summary Updates API key
 */
export const useApiKeysControllerUpdateApiKey = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>,
    TError,
    { teamCode: string; apiKeyId: number; data: UpdateApiKeyDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysControllerUpdateApiKey>>,
  TError,
  { teamCode: string; apiKeyId: number; data: UpdateApiKeyDto },
  TContext
> => {
  const mutationOptions = getApiKeysControllerUpdateApiKeyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Creates a pre-signed url
 */
export const apiKeysControllerCreateSignedUrl = (
  createSignedUrlDto: CreateSignedUrlDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<string>(
    {
      url: `/v1/api-keys/signed-url`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createSignedUrlDto,
      signal,
    },
    options
  );
};

export const getApiKeysControllerCreateSignedUrlMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>,
    TError,
    { data: CreateSignedUrlDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>,
  TError,
  { data: CreateSignedUrlDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>,
    { data: CreateSignedUrlDto }
  > = (props) => {
    const { data } = props ?? {};

    return apiKeysControllerCreateSignedUrl(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysControllerCreateSignedUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>
>;
export type ApiKeysControllerCreateSignedUrlMutationBody = CreateSignedUrlDto;
export type ApiKeysControllerCreateSignedUrlMutationError = ErrorType<unknown>;

/**
 * @summary Creates a pre-signed url
 */
export const useApiKeysControllerCreateSignedUrl = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>,
    TError,
    { data: CreateSignedUrlDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysControllerCreateSignedUrl>>,
  TError,
  { data: CreateSignedUrlDto },
  TContext
> => {
  const mutationOptions = getApiKeysControllerCreateSignedUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns valid api-keys for specific pre-signing request
 */
export const apiKeysControllerGetAllValidKeys = (
  teamCode: string,
  presigningRequestDto: PresigningRequestDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ApiKeyListItemDto>(
    {
      url: `/v1/api-keys/teams/${teamCode}/valid-keys`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: presigningRequestDto,
      signal,
    },
    options
  );
};

export const getApiKeysControllerGetAllValidKeysMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>,
    TError,
    { teamCode: string; data: PresigningRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>,
  TError,
  { teamCode: string; data: PresigningRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>,
    { teamCode: string; data: PresigningRequestDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return apiKeysControllerGetAllValidKeys(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysControllerGetAllValidKeysMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>
>;
export type ApiKeysControllerGetAllValidKeysMutationBody = PresigningRequestDto;
export type ApiKeysControllerGetAllValidKeysMutationError = ErrorType<unknown>;

export const useApiKeysControllerGetAllValidKeys = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>,
    TError,
    { teamCode: string; data: PresigningRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysControllerGetAllValidKeys>>,
  TError,
  { teamCode: string; data: PresigningRequestDto },
  TContext
> => {
  const mutationOptions = getApiKeysControllerGetAllValidKeysMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns URLs pre-signed with specified API key
 */
export const apiKeysControllerGetPresignedUrls = (
  teamCode: string,
  presigningRequestDto: PresigningRequestDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PresigningResponseDto>(
    {
      url: `/v1/api-keys/teams/${teamCode}/presign`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: presigningRequestDto,
      signal,
    },
    options
  );
};

export const getApiKeysControllerGetPresignedUrlsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>,
    TError,
    { teamCode: string; data: PresigningRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>,
  TError,
  { teamCode: string; data: PresigningRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>,
    { teamCode: string; data: PresigningRequestDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return apiKeysControllerGetPresignedUrls(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysControllerGetPresignedUrlsMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>
>;
export type ApiKeysControllerGetPresignedUrlsMutationBody = PresigningRequestDto;
export type ApiKeysControllerGetPresignedUrlsMutationError = ErrorType<unknown>;

export const useApiKeysControllerGetPresignedUrls = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>,
    TError,
    { teamCode: string; data: PresigningRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysControllerGetPresignedUrls>>,
  TError,
  { teamCode: string; data: PresigningRequestDto },
  TContext
> => {
  const mutationOptions = getApiKeysControllerGetPresignedUrlsMutationOptions(options);

  return useMutation(mutationOptions);
};
