import { objectKeys } from "@/utils";
import { ColorDefinition, ENV_COLORS } from "@/utils/colors/color-definitions";
import { cn } from "@/utils/ui.util";
import { CheckIcon } from "lucide-react";
import React from "react";

type EnvColorKey = keyof typeof ENV_COLORS;
const ENV_COLORS_KEYS = objectKeys(ENV_COLORS);

interface ColorSelectProps {
  value?: string;
  onValueChange?: (value?: EnvColorKey) => void;
  className?: string;
}

export const ColorSelect: React.FC<ColorSelectProps> = ({ value, onValueChange, className }) => {
  const isCustom = React.useMemo(() => {
    return value && !(ENV_COLORS_KEYS as string[]).includes(value);
  }, [value]);

  return (
    <div
      className={cn(
        "grid grid-cols-[repeat(auto-fill,minmax(20px,1fr))] w-full gap-x-2 gap-y-1 place-items-center",
        className
      )}
    >
      {ENV_COLORS_KEYS.map((key) => {
        return (
          <ColorCheckbox
            key={`color-${key}`}
            color={ENV_COLORS[key]}
            selected={value === key}
            onClick={() => {
              if (value === key) {
                onValueChange?.(undefined);
              } else {
                onValueChange?.(key);
              }
            }}
          />
        );
      })}

      {isCustom && (
        <button
          type="button"
          className={cn(
            "overflow-hidden font-bold col-span-5 justify-self-start inline-flex min-w-5 max-w-full h-5 pl-1 pr-2 gap-1 rounded-full [&_svg]:size-3 items-center justify-center text-secondary-950 active:translate-y-px border-2 border-secondary-950 text-xs"
          )}
          style={{
            background: value,
          }}
          onClick={() => {
            onValueChange?.(undefined);
          }}
        >
          <CheckIcon className="block shrink-0" />

          <div className="truncate">{value}</div>
        </button>
      )}
    </div>
  );
};

interface ColorCheckboxProps {
  color: ColorDefinition;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ColorCheckbox: React.FC<ColorCheckboxProps> = ({ color, selected, onClick }) => {
  return (
    <button
      type="button"
      className={cn(
        "inline-flex w-5 h-5 rounded-full transition-colors [&_svg]:size-3 items-center justify-center text-secondary-950 active:translate-y-px [&_svg]:opacity-0 [&_svg]:hover:opacity-100",
        selected && "[&_svg]:opacity-100 border-2 border-secondary-950"
      )}
      style={{
        background: color.fg,
      }}
      onClick={onClick}
    >
      <CheckIcon />
    </button>
  );
};
