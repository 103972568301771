import {CellContext, ColumnDef} from "@tanstack/react-table";
import {
  MonitorEventInfoDto,
  SingleHyperlinkWithEnvironmentsMonitorDto,
} from "@/api/generated";
import { MonitoringJobs } from "@/lib/projects/monitoring/utils";
import { LayersIcon } from "lucide-react";
import {
  HyperlinkMonitoringEnvironmentEventsCell
} from "@/lib/projects/monitoring/hyperlink-monitor/hyperlink-monitoring-environment-events-cell.tsx";

type HyperlinkInfo = {
  code: string;
  hyperlinkItemValueId: number;
};

type EnvironmentInfo = {
  code: string;
  lastModifiedAt?: string;
};

type EnvironmentDetails = {
  teamCode: string;
  projectCode: string;
  environment: EnvironmentInfo;
  hyperlink: HyperlinkInfo;
  monitorEvents: MonitorEventInfoDto[];
};

export type HyperlinkMonitorRow = {
  monitoringJobLabel: string;
  monitoringJobKey: string;
  data: Record<string, EnvironmentDetails>;
};

export const constructHyperlinkMonitoringColumns = (
  hyperlinkCode: string,
  hyperlinkData: SingleHyperlinkWithEnvironmentsMonitorDto,
  teamCode: string,
  projectCode: string
): { columns: ColumnDef<HyperlinkMonitorRow>[]; data: HyperlinkMonitorRow[] } => {
  const environments = hyperlinkData.environments;

  const environmentColumns = Object.keys(environments).map((envCode) => ({
    accessorKey: envCode,
    header: () => (
      <div className="font-mono">
        <LayersIcon className="inline-block mr-2" />
        {envCode}
      </div>
    ),
    cell: (ctx: CellContext<HyperlinkMonitorRow, unknown>) => <HyperlinkMonitoringEnvironmentEventsCell {...ctx} envCode={envCode} />,
  }));

  const rows: HyperlinkMonitorRow[] = Object.values(MonitoringJobs).flatMap((job) => {
    const row: HyperlinkMonitorRow = { monitoringJobLabel: job.label, monitoringJobKey: job.key, data: {} };

    Object.entries(environments).forEach(([envCode, envData]) => {
      const monitorEvents = envData.monitorEvents;
      row.data[envCode] = {
        teamCode,
        projectCode,
        environment: {
          code: envCode,
        },
        hyperlink: {
          code: hyperlinkCode,
          hyperlinkItemValueId: envData.hyperlinkItemValueId,
        },
        monitorEvents: monitorEvents[job.key] ?? [],
      };
    });

    if (Object.values(row.data).every((envData) => envData.monitorEvents.length === 0)) {
      return [];
    }

    return [row];
  });

  return {
    columns: [
      {
        accessorKey: "monitoringJobLabel",
        header: "Monitoring Job",
        cell: ({ row }) => <div style={{ padding: 0 }}>{row.getValue("monitoringJobLabel")}</div>,
      },
      ...environmentColumns,
    ],
    data: rows,
  };
};
