import { RequestFullDto } from "@/api/generated";
import { APIGatewayProxyEvent } from "aws-lambda";

export function getIdFromUrl(url: string): string | null {
  const urlParts = url.split("/");
  const id = urlParts[urlParts.length - 1];

  return /^[a-zA-Z0-9_-]{1,20}$/.test(id) ? id : null;
}

/**
 * Temporary solution to convert a RequestFullDto to an APIGatewayProxyEvent
 *
 * @param request RequestFullDto
 * @returns APIGatewayProxyEvent
 */
export const requestToApiGatewayEvent = (request: RequestFullDto): APIGatewayProxyEvent => {
  return {
    resource: request.resource,
    path: request.path,
    httpMethod: request.httpMethod,
    headers: request.headers as Record<string, string | undefined>,
    multiValueHeaders: Object.entries(request.headers).reduce(
      (acc, [key, value]) => {
        acc[key] = value ? (Array.isArray(value) ? value : [value]) : undefined;
        return acc;
      },
      {} as Record<string, string[] | undefined>
    ),
    queryStringParameters: request.queryStringParameters as Record<string, string> | null,
    multiValueQueryStringParameters: request.queryStringParameters
      ? Object.fromEntries(Object.entries(request.queryStringParameters).map(([k, v]) => [k, [String(v)]]))
      : null,
    pathParameters: request.pathParameters as Record<string, string> | null,
    isBase64Encoded: false,
    body: request.body ?? null,
    stageVariables: null,
    requestContext: {
      accountId: "",
      apiId: "",
      authorizer: {},
      protocol: "HTTP/1.1",
      httpMethod: request.httpMethod,
      identity: {
        accessKey: null,
        accountId: null,
        apiKey: null,
        apiKeyId: null,
        caller: null,
        cognitoAuthenticationProvider: null,
        cognitoAuthenticationType: null,
        cognitoIdentityId: null,
        cognitoIdentityPoolId: null,
        principalOrgId: null,
        sourceIp: "",
        user: null,
        userAgent: "",
        userArn: null,
        clientCert: null,
      },
      path: request.path,
      stage: "",
      requestId: "",
      requestTimeEpoch: 0,
      resourceId: "",
      resourcePath: "",
    },
  };
};
