import { ApiKeyResponseDto, useApiKeysControllerUpdateApiKey } from "@/api/generated";
import { TextAreaField } from "@/components/form/elements/text-area-field";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { onErrorToast } from "@/utils/api.util";
import { ifNotEqual } from "@/utils/misc.util";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

export const EditKeySchema = z.object({
  permissions: z.string().min(1, { message: "Permissions must be at least 1 character long" }),
  description: z.optional(z.string().max(128, { message: "Description must be at most 128 character long" })),
});

export type EditKeySchemaInputs = z.infer<typeof EditKeySchema>;

interface EditApiKeyDialogProps {
  teamCode: string;
  apiKey: ApiKeyResponseDto;
  onSuccess?: () => void;
  children: React.ReactNode;
}

export const EditApiKeyDialog: React.FC<EditApiKeyDialogProps> = ({ children, onSuccess, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Edit API Key</DialogTitle>
        <DialogDescription>Update the details for the API key.</DialogDescription>

        <EditApiKey
          onSuccess={() => {
            setOpen(false);
            onSuccess?.();
          }}
          {...props}
        />
      </DialogContent>
    </Dialog>
  );
};

const EditApiKey: React.FC<Omit<EditApiKeyDialogProps, "children">> = ({ teamCode, apiKey, onSuccess }) => {
  const form = useForm<EditKeySchemaInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(EditKeySchema),
    defaultValues: {
      description: apiKey.description,
      permissions: apiKey.permissions,
    },
  });

  const editMutation = useApiKeysControllerUpdateApiKey({
    mutation: {
      onSuccess: () => {
        onSuccess?.();
        form.reset();
      },
      onError: onErrorToast,
    },
  });

  const onSubmit: SubmitHandler<EditKeySchemaInputs> = (values) => {
    editMutation.mutate({
      teamCode,
      apiKeyId: apiKey.id,
      data: {
        description: ifNotEqual(apiKey.description, values.description),
        permissions: ifNotEqual(apiKey.permissions, values.permissions),
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <div className="relative max-w-md break-all">
          <Label className="text-sm font-semibold">Key</Label>

          <div>{apiKey.key}</div>
        </div>

        <div>
          <TextAreaField label="Description" name="description" />
        </div>

        <div>
          <TextAreaField label="Permissions" name="permissions" />
        </div>

        <div className="flex justify-end mt-4">
          <Button
            variant="neu-flat"
            type="submit"
            disabled={!form.formState.isDirty}
            loading={editMutation.isPending}
            className="bg-tertiary-200"
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
