import { ExceptionEventResponseDtoStatus } from "@/api/generated";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/utils/ui.util";

// eslint-disable-next-line react-refresh/only-export-components
export const exceptionEventStatusclasses: Record<ExceptionEventResponseDtoStatus, string> = {
  received: "bg-secondary-950/20 text-secondary-950/80",
  acknowledged: "bg-secondary-200 text-secondary-950/80",
  investigating: "bg-primary-200 text-secondary-950/80",
  resolved: "bg-tertiary-200 text-secondary-950/80",
};

interface ExceptionStatusBadgeProps {
  status: ExceptionEventResponseDtoStatus;
}

export const ExceptionStatusBadge: React.FC<ExceptionStatusBadgeProps> = ({ status }) => {
  return <Badge className={cn("uppercase", exceptionEventStatusclasses[status])}>{status}</Badge>;
};
