/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CompleteUploadDto,
  DownloadLinkResponseDto,
  FileResponseDto,
  FilesControllerDeleteFileByIdParams,
  FilesControllerGetFileByIdParams,
  FilesControllerGetFileDownloadLinkParams,
  FilesControllerGetFilesForTeamAndProjectParams,
  FilesControllerGetFilesForTeamParams,
  FilesControllerUpdateFileParams,
  FilesResponseDto,
  InitiateUploadDto,
  InitiateUploadResponseDto,
  UpdateFileDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Starts file upload to the team
 */
export const filesControllerInitiateUploadForTeam = (
  teamCode: string,
  initiateUploadDto: InitiateUploadDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<InitiateUploadResponseDto>(
    {
      url: `/v1/files/teams/${teamCode}/initiate-upload`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: initiateUploadDto,
      signal,
    },
    options
  );
};

export const getFilesControllerInitiateUploadForTeamMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>,
    TError,
    { teamCode: string; data: InitiateUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>,
  TError,
  { teamCode: string; data: InitiateUploadDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>,
    { teamCode: string; data: InitiateUploadDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return filesControllerInitiateUploadForTeam(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesControllerInitiateUploadForTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>
>;
export type FilesControllerInitiateUploadForTeamMutationBody = InitiateUploadDto;
export type FilesControllerInitiateUploadForTeamMutationError = ErrorType<unknown>;

export const useFilesControllerInitiateUploadForTeam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>,
    TError,
    { teamCode: string; data: InitiateUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeam>>,
  TError,
  { teamCode: string; data: InitiateUploadDto },
  TContext
> => {
  const mutationOptions = getFilesControllerInitiateUploadForTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Starts file upload to the project
 */
export const filesControllerInitiateUploadForTeamAndProject = (
  teamCode: string,
  projectCode: string,
  initiateUploadDto: InitiateUploadDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<InitiateUploadResponseDto>(
    {
      url: `/v1/files/teams/${teamCode}/projects/${projectCode}/initiate-upload`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: initiateUploadDto,
      signal,
    },
    options
  );
};

export const getFilesControllerInitiateUploadForTeamAndProjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>,
    TError,
    { teamCode: string; projectCode: string; data: InitiateUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>,
  TError,
  { teamCode: string; projectCode: string; data: InitiateUploadDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>,
    { teamCode: string; projectCode: string; data: InitiateUploadDto }
  > = (props) => {
    const { teamCode, projectCode, data } = props ?? {};

    return filesControllerInitiateUploadForTeamAndProject(teamCode, projectCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesControllerInitiateUploadForTeamAndProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>
>;
export type FilesControllerInitiateUploadForTeamAndProjectMutationBody = InitiateUploadDto;
export type FilesControllerInitiateUploadForTeamAndProjectMutationError = ErrorType<unknown>;

export const useFilesControllerInitiateUploadForTeamAndProject = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>,
    TError,
    { teamCode: string; projectCode: string; data: InitiateUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof filesControllerInitiateUploadForTeamAndProject>>,
  TError,
  { teamCode: string; projectCode: string; data: InitiateUploadDto },
  TContext
> => {
  const mutationOptions = getFilesControllerInitiateUploadForTeamAndProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Marks the upload as complete
 */
export const filesControllerCompleteUpload = (
  teamCode: string,
  completeUploadDto: CompleteUploadDto,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FileResponseDto>(
    {
      url: `/v1/files/teams/${teamCode}/upload-complete`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: completeUploadDto,
      signal,
    },
    options
  );
};

export const getFilesControllerCompleteUploadMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
    TError,
    { teamCode: string; data: CompleteUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
  TError,
  { teamCode: string; data: CompleteUploadDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
    { teamCode: string; data: CompleteUploadDto }
  > = (props) => {
    const { teamCode, data } = props ?? {};

    return filesControllerCompleteUpload(teamCode, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesControllerCompleteUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerCompleteUpload>>
>;
export type FilesControllerCompleteUploadMutationBody = CompleteUploadDto;
export type FilesControllerCompleteUploadMutationError = ErrorType<unknown>;

export const useFilesControllerCompleteUpload = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
    TError,
    { teamCode: string; data: CompleteUploadDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
  TError,
  { teamCode: string; data: CompleteUploadDto },
  TContext
> => {
  const mutationOptions = getFilesControllerCompleteUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets file details
 */
export const filesControllerGetFileById = (
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FileResponseDto>(
    { url: `/v1/files/teams/${teamCode}/file`, method: "GET", params, signal },
    options
  );
};

export const getFilesControllerGetFileByIdQueryKey = (teamCode: string, params: FilesControllerGetFileByIdParams) => {
  return [`/v1/files/teams/${teamCode}/file`, ...(params ? [params] : [])] as const;
};

export const getFilesControllerGetFileByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof filesControllerGetFileById>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesControllerGetFileByIdQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesControllerGetFileById>>> = ({ signal }) =>
    filesControllerGetFileById(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesControllerGetFileById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FilesControllerGetFileByIdQueryResult = NonNullable<Awaited<ReturnType<typeof filesControllerGetFileById>>>;
export type FilesControllerGetFileByIdQueryError = ErrorType<unknown>;

export function useFilesControllerGetFileById<
  TData = Awaited<ReturnType<typeof filesControllerGetFileById>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFileById<
  TData = Awaited<ReturnType<typeof filesControllerGetFileById>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFileById<
  TData = Awaited<ReturnType<typeof filesControllerGetFileById>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFilesControllerGetFileById<
  TData = Awaited<ReturnType<typeof filesControllerGetFileById>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileByIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFilesControllerGetFileByIdQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update file metadata
 */
export const filesControllerUpdateFile = (
  teamCode: string,
  updateFileDto: UpdateFileDto,
  params: FilesControllerUpdateFileParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<FileResponseDto>(
    {
      url: `/v1/files/teams/${teamCode}/file`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateFileDto,
      params,
    },
    options
  );
};

export const getFilesControllerUpdateFileMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerUpdateFile>>,
    TError,
    { teamCode: string; data: UpdateFileDto; params: FilesControllerUpdateFileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesControllerUpdateFile>>,
  TError,
  { teamCode: string; data: UpdateFileDto; params: FilesControllerUpdateFileParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesControllerUpdateFile>>,
    { teamCode: string; data: UpdateFileDto; params: FilesControllerUpdateFileParams }
  > = (props) => {
    const { teamCode, data, params } = props ?? {};

    return filesControllerUpdateFile(teamCode, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesControllerUpdateFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerUpdateFile>>
>;
export type FilesControllerUpdateFileMutationBody = UpdateFileDto;
export type FilesControllerUpdateFileMutationError = ErrorType<unknown>;

export const useFilesControllerUpdateFile = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerUpdateFile>>,
    TError,
    { teamCode: string; data: UpdateFileDto; params: FilesControllerUpdateFileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof filesControllerUpdateFile>>,
  TError,
  { teamCode: string; data: UpdateFileDto; params: FilesControllerUpdateFileParams },
  TContext
> => {
  const mutationOptions = getFilesControllerUpdateFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets download link
 */
export const filesControllerGetFileDownloadLink = (
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<DownloadLinkResponseDto>(
    { url: `/v1/files/teams/${teamCode}/download`, method: "GET", params, signal },
    options
  );
};

export const getFilesControllerGetFileDownloadLinkQueryKey = (
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams
) => {
  return [`/v1/files/teams/${teamCode}/download`, ...(params ? [params] : [])] as const;
};

export const getFilesControllerGetFileDownloadLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesControllerGetFileDownloadLinkQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>> = ({ signal }) =>
    filesControllerGetFileDownloadLink(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FilesControllerGetFileDownloadLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>
>;
export type FilesControllerGetFileDownloadLinkQueryError = ErrorType<unknown>;

export function useFilesControllerGetFileDownloadLink<
  TData = Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFileDownloadLink<
  TData = Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFileDownloadLink<
  TData = Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFilesControllerGetFileDownloadLink<
  TData = Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFileDownloadLinkParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFileDownloadLink>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFilesControllerGetFileDownloadLinkQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Deletes file
 */
export const filesControllerDeleteFileById = (
  teamCode: string,
  params: FilesControllerDeleteFileByIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/v1/files/teams/${teamCode}/files`, method: "DELETE", params }, options);
};

export const getFilesControllerDeleteFileByIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerDeleteFileById>>,
    TError,
    { teamCode: string; params: FilesControllerDeleteFileByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesControllerDeleteFileById>>,
  TError,
  { teamCode: string; params: FilesControllerDeleteFileByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesControllerDeleteFileById>>,
    { teamCode: string; params: FilesControllerDeleteFileByIdParams }
  > = (props) => {
    const { teamCode, params } = props ?? {};

    return filesControllerDeleteFileById(teamCode, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesControllerDeleteFileByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerDeleteFileById>>
>;

export type FilesControllerDeleteFileByIdMutationError = ErrorType<unknown>;

export const useFilesControllerDeleteFileById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesControllerDeleteFileById>>,
    TError,
    { teamCode: string; params: FilesControllerDeleteFileByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof filesControllerDeleteFileById>>,
  TError,
  { teamCode: string; params: FilesControllerDeleteFileByIdParams },
  TContext
> => {
  const mutationOptions = getFilesControllerDeleteFileByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List team files
 */
export const filesControllerGetFilesForTeam = (
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FilesResponseDto>(
    { url: `/v1/files/teams/${teamCode}/files`, method: "GET", params, signal },
    options
  );
};

export const getFilesControllerGetFilesForTeamQueryKey = (
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams
) => {
  return [`/v1/files/teams/${teamCode}/files`, ...(params ? [params] : [])] as const;
};

export const getFilesControllerGetFilesForTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesControllerGetFilesForTeamQueryKey(teamCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>> = ({ signal }) =>
    filesControllerGetFilesForTeam(teamCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamCode, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FilesControllerGetFilesForTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>
>;
export type FilesControllerGetFilesForTeamQueryError = ErrorType<unknown>;

export function useFilesControllerGetFilesForTeam<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFilesForTeam<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFilesForTeam<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFilesControllerGetFilesForTeam<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  params: FilesControllerGetFilesForTeamParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFilesControllerGetFilesForTeamQueryOptions(teamCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * List project files
 */
export const filesControllerGetFilesForTeamAndProject = (
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<FilesResponseDto>(
    { url: `/v1/files/teams/${teamCode}/projects/${projectCode}/files`, method: "GET", params, signal },
    options
  );
};

export const getFilesControllerGetFilesForTeamAndProjectQueryKey = (
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams
) => {
  return [`/v1/files/teams/${teamCode}/projects/${projectCode}/files`, ...(params ? [params] : [])] as const;
};

export const getFilesControllerGetFilesForTeamAndProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFilesControllerGetFilesForTeamAndProjectQueryKey(teamCode, projectCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>> = ({ signal }) =>
    filesControllerGetFilesForTeamAndProject(teamCode, projectCode, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(teamCode && projectCode), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type FilesControllerGetFilesForTeamAndProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>
>;
export type FilesControllerGetFilesForTeamAndProjectQueryError = ErrorType<unknown>;

export function useFilesControllerGetFilesForTeamAndProject<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFilesForTeamAndProject<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useFilesControllerGetFilesForTeamAndProject<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useFilesControllerGetFilesForTeamAndProject<
  TData = Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  params: FilesControllerGetFilesForTeamAndProjectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesControllerGetFilesForTeamAndProject>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getFilesControllerGetFilesForTeamAndProjectQueryOptions(teamCode, projectCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
