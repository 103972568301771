import { EnvironmentMiniResponseDto } from "@/api/generated";
import { TextFieldBase } from "@/components/form/elements/text-field";
import { HoverInfo } from "@/components/hover-info";
import { Button } from "@/components/ui/button";
import { formatCount, objectKeys } from "@/utils";
import { CellContext } from "@tanstack/react-table";
import { Edit, Info, Plus } from "lucide-react";
import React from "react";
import { Fixed__ProjectSecretResponseDto } from "../item/project-items-helpers";
import { EditEnvSecretDialog } from "./edit-env-secret-dialog";
import { useSecretsContext } from "./secrets-context";
import { parseEnvColor } from "@/utils/colors/color-definitions";

export const SecretEnvCell: React.FC<
  CellContext<Fixed__ProjectSecretResponseDto, unknown> & {
    environment: EnvironmentMiniResponseDto;
    refetch: () => void;
  }
> = ({ row, environment, refetch }) => {
  const envValue = row.original.envValues[environment.code];
  const envColor = parseEnvColor(environment.color);

  if (!envValue) {
    return (
      <EditEnvSecretDialog item={row.original} environment={environment} onSuccess={() => refetch()}>
        <div
          className="flex items-center gap-2 justify-between"
          style={{
            color: envColor?.fg,
          }}
        >
          <div className="truncate opacity-40 brightness-[.2]">No secrets</div>
          <Button size="icon" className="bg-tertiary-100 hover:neu-flat w-8 h-8">
            <Plus size={16} />
          </Button>
        </div>
      </EditEnvSecretDialog>
    );
  }

  return (
    <div
      className="flex items-center gap-2 justify-between"
      style={{
        color: envColor?.fg,
      }}
    >
      <div className="truncate">{formatCount(objectKeys(envValue).length, "secret")}</div>
      <EditEnvSecretDialog item={row.original} environment={environment} onSuccess={() => refetch()}>
        <Button size="icon" className="bg-secondary-100 hover:neu-flat w-8 h-8">
          <Edit size={16} />
        </Button>
      </EditEnvSecretDialog>
    </div>
  );
};

export const SecretCodeCell: React.FC<CellContext<Fixed__ProjectSecretResponseDto, unknown>> = ({ row }) => {
  const { editing } = useSecretsContext();

  const form = editing[row.original.id];

  if (form) {
    return (
      <div>
        <TextFieldBase
          variant="neu-flat"
          placeholder="Code..."
          defaultValue={row.original.code ?? ""}
          error={form.formState.errors.code}
          {...form.register("code", { required: true })}
        />
      </div>
    );
  }

  return (
    <div className="text-md text-primary-600 [word-wrap:break-word]">
      {row.original.code}{" "}
      {row.original.description && (
        <HoverInfo
          content={
            <div className="text-xs text-secondary-950/70 [word-wrap:break-word] max-w-full">
              {row.original.description}
            </div>
          }
        >
          <Info className="w-4 inline-block text-secondary-950" />
        </HoverInfo>
      )}
    </div>
  );
};
