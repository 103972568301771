import React from "react";
import { MonitorEventInfoDto } from "@/api/generated";
import { cn } from "@/utils/ui.util";
import { format, formatDistanceToNowStrict } from "date-fns";
import { RunDetails } from "@/lib/projects/monitoring/run-details/run-details";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface DisplayMonitorEventsProps {
  monitor: string;
  hyperlinkCode: string;
  hyperlinkItemValueId: number;
  monitorEvents: MonitorEventInfoDto[];
  limit?: number;
  description?: boolean;
  variant?: "hover" | "button";
}

export const DisplayMonitorEvents: React.FC<DisplayMonitorEventsProps> = ({
  monitor,
  hyperlinkCode,
  hyperlinkItemValueId,
  monitorEvents,
  limit,
  description = false,
  variant = "button",
}) => {
  const limitedMonitorEvents = React.useMemo(
    () => (limit ? monitorEvents.slice(-limit) : monitorEvents),
    [monitorEvents, limit]
  );

  const lastMonitorEventDate = limitedMonitorEvents[limitedMonitorEvents.length - 1]?.checkStartedAt;

  return (
    <div className="space-y-1">
      <div className="flex space-x-1">
        {limitedMonitorEvents.length === 0 && <p className="text-xs text-gray-600">N/A</p>}

        {limitedMonitorEvents.map((monitorEvent, index) => {
          return (
            <TooltipProvider delayDuration={250} key={monitorEvent.checkStartedAt || index}>
              <Tooltip>
                {variant === "button" ? (
                  <RunDetails
                    event={monitorEvent}
                    monitor={monitor}
                    hyperlinkCode={hyperlinkCode}
                    hyperlinkItemValueId={hyperlinkItemValueId}
                    monitorRunId={monitorEvent.monitorRunId}
                  >
                    <TooltipTrigger asChild>
                      <div
                        className={cn(
                          "w-2.5 h-8 rounded-sm hover:cursor-pointer hover:w-4 transition-all duration-300",
                          "bg-gray-400 hover:bg-gray-600",
                          monitorEvent.result === "success" && "bg-green-500 hover:bg-green-700",
                          monitorEvent.result === "success-with-warning" && "bg-yellow-500 hover:bg-yellow-700",
                          monitorEvent.result === "failure" && "bg-red-500 hover:bg-red-700"
                        )}
                        aria-label={`Monitor event ${monitorEvent.result}`}
                      />
                    </TooltipTrigger>
                  </RunDetails>
                ) : (
                  <TooltipTrigger asChild>
                    <div
                      className={cn(
                        "w-2.5 h-8 rounded-sm",
                        "bg-gray-400",
                        monitorEvent.result === "success" && "bg-green-500",
                        monitorEvent.result === "success-with-warning" && "bg-yellow-500",
                        monitorEvent.result === "failure" && "bg-red-500"
                      )}
                      aria-label={`Monitor event ${monitorEvent.result}`}
                    />
                  </TooltipTrigger>
                )}
                <TooltipContent>
                  <EventDetails event={monitorEvent} />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        })}
      </div>
      {description && lastMonitorEventDate && (
        <p className="text-xs text-gray-600">
          Last check: {formatDistanceToNowStrict(new Date(lastMonitorEventDate))} ago
        </p>
      )}
    </div>
  );
};

interface EventDetailsProps {
  event: MonitorEventInfoDto;
}

const EventDetails: React.FC<EventDetailsProps> = ({ event }) => (
  <div className="neu rounded-lg text-sm bg-white p-2 space-y-1">
    <EventDetailItem label="Status" value={event.monitorRunStatus || "Unknown"} />
    <EventDetailItem label="Result" value={event.result || "Unknown"} />
    <EventDetailItem
      label="Started At"
      value={event.checkStartedAt ? format(new Date(event.checkStartedAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
    />
    <EventDetailItem
      label="Completed At"
      value={event.checkCompletedAt ? format(new Date(event.checkCompletedAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
    />
    <EventDetailItem label="Duration" value={event.checkDurationMillis ? `${event.checkDurationMillis} ms` : "N/A"} />
    <EventDetailItem label="Target" value={event.checkTarget || "N/A"} />
    {event.resultMessage && <EventDetailItem label="Message" value={event.resultMessage} />}
  </div>
);

const EventDetailItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <p className="text-xs font-mono">
    <span className="font-semibold">{label}:</span> {value}
  </p>
);
