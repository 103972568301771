import {
  DocumentResponseDto,
  DocumentVersionResponseDto,
  useDocumentsControllerCreateDocumentVersion,
  useDocumentsControllerDeleteDocument,
  useDocumentsControllerGetDocument,
  useDocumentsControllerGetDocumentVersions,
  useDocumentsControllerSetDocumentVersionAsCurrent,
  useDocumentsControllerUpdateDocumentInfo,
} from "@/api/generated";
import { useAuth } from "@/auth/useAuth";
import { LoadingSpinner } from "@/components/loading-spinner";
import { MarkdownEditor } from "@/components/md-editor";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { toast, useToast } from "@/components/ui/use-toast";
import { ConfirmDialog } from "@/lib/dialog/confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { DocumentProvider, useDocument } from "@/lib/projects/documents/document-context";
import { generateFilename } from "@/lib/projects/documents/document-utils";
import { PresignDocumentDialog } from "@/lib/projects/documents/presign-document-dialog";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { DocumentsEvent } from "@/lib/websockets/websocket-events";
import { SocketProvider } from "@/lib/websockets/websocket-provider";
import { onErrorToast } from "@/utils/api.util";
import { ToastAction } from "@radix-ui/react-toast";
import { isString, isUndefined } from "@wt-dev/common/utils";
import { ServiceType } from "@wt-dev/common/ws";
import { formatDistanceToNow } from "date-fns";
import { History, LinkIcon, Save, Trash } from "lucide-react";
import React from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

export const Document: React.FC = () => {
  const { dismiss, toast } = useToast();
  const { documentId: stringDocumentId } = useParams();
  const documentId = React.useMemo(() => Number.parseInt(stringDocumentId!), [stringDocumentId]);
  const navigate = useNavigate();

  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const { getLoggedInUser } = useAuth();
  const userId = getLoggedInUser();

  const {
    data: document,
    refetch,
    isPending,
    isError,
  } = useDocumentsControllerGetDocument(teamCode, projectCode, documentId!);

  React.useEffect(() => {
    if (document) {
      const newUrl = `/dashboard/${teamCode}/${projectCode}/documents/${document.id}/${document.filename}`;
      navigate(newUrl, { replace: true });
    }
  }, [document, projectCode, teamCode, navigate]);

  const [updatedContent, setUpdatedContent] = React.useState<string | undefined>(() => document?.content);

  const handleNewEvent = React.useCallback(
    (event: DocumentsEvent) => {
      if (
        event.data.documentId === document?.id &&
        event.data.authorEmail !== userId &&
        document?.contentVersionId !== event.data.documentVersionId
      ) {
        toast({
          title: "Document Updated",
          description: (
            <p className="text-sm">
              {event.data.authorName
                ? `New changes were made by ${event.data.authorName}.`
                : "New document version available."}
            </p>
          ),
          action: (
            <>
              {isUndefined(updatedContent) || updatedContent === document?.content ? (
                <ToastAction asChild altText="Reload">
                  <Button size="sm" className="bg-tertiary-200" onClick={() => refetch()}>
                    Update
                  </Button>
                </ToastAction>
              ) : (
                <AskToSaveChangesDialog refetch={refetch} dismiss={dismiss}>
                  <Button size="sm" className="bg-tertiary-200">
                    Update
                  </Button>
                </AskToSaveChangesDialog>
              )}
            </>
          ),
        });
      }
    },
    [dismiss, document, refetch, toast, updatedContent, userId]
  );

  return (
    <SocketProvider<DocumentsEvent>
      config={{
        service: ServiceType.DOCUMENTS,
        teamCode,
        projectCode,
        handleEvent: handleNewEvent,
      }}
    >
      {isPending && (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner message={"Loading..."} />
        </div>
      )}
      {isError && <Navigate to="/404" replace={true} />}
      {!isPending && !isError && document && (
        <DocumentProvider>
          <DocumentCore
            document={document}
            refetch={refetch}
            updatedContent={updatedContent}
            setUpdatedContent={setUpdatedContent}
          />
        </DocumentProvider>
      )}
    </SocketProvider>
  );
};

interface DocumentCoreProps {
  document: DocumentResponseDto;
  refetch: () => void;
  updatedContent?: string;
  setUpdatedContent: (content?: string) => void;
}

export const DocumentCore: React.FC<DocumentCoreProps> = ({ document, refetch, updatedContent, setUpdatedContent }) => {
  const { state } = useLocation();
  const isNew = React.useMemo(() => {
    if (state && "newDocument" in state) {
      return state.newDocument === document.id;
    }

    return false;
  }, [document.id, state]);
  const documentContext = useDocument();

  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const navigate = useNavigate();

  const createVersionMutation = useDocumentsControllerCreateDocumentVersion({
    mutation: {
      onSuccess: () => {
        refetch();
        setUpdatedContent(undefined);
      },
      onError: onErrorToast,
    },
  });

  const setVersionMutation = useDocumentsControllerSetDocumentVersionAsCurrent({
    mutation: {
      onSuccess: () => {
        refetch();
        setUpdatedContent(undefined);
      },
      onError: onErrorToast,
    },
  });

  const deleteMutation = useDocumentsControllerDeleteDocument({
    mutation: {
      onSuccess: () => {
        toast({
          title: `Document "${document.filename}" deleted.`,
        });
        navigate("../");
      },
      onError: onErrorToast,
    },
  });

  const handleSave = React.useCallback(() => {
    if (isUndefined(updatedContent) || updatedContent === document?.content) {
      return;
    }

    createVersionMutation.mutate({
      teamCode,
      projectCode,
      documentId: document.id,
      data: {
        content: updatedContent,
      },
    });
  }, [createVersionMutation, document?.content, document.id, projectCode, teamCode, updatedContent]);

  return (
    <FeaturePage
      title={
        document && (
          <TitleInput
            key={`title-${document.id}-${document.createdAt}`}
            value={document.title}
            refetch={refetch}
            teamCode={teamCode}
            projectCode={projectCode}
            document={document}
          />
        )
      }
      description={
        document?.lastModifiedAt && (
          <p className="font-mono font-semibold text-sm break-all line-clamp-1 text-wrap truncate text-secondary-950/60">
            Updated {formatDistanceToNow(document.lastModifiedAt)} by {document.currentAuthorName}
          </p>
        )
      }
      isLoading={createVersionMutation.isPending}
      actions={
        <div className="flex gap-2">
          <PresignDocumentDialog teamCode={teamCode} projectCode={projectCode} document={document}>
            <Button size="icon" className="bg-secondary-200 w-9 h-9">
              <LinkIcon size={16} />
            </Button>
          </PresignDocumentDialog>

          <Button
            size="sm"
            className="bg-tertiary-200"
            disabled={isUndefined(updatedContent) || updatedContent === document?.content}
            onClick={handleSave}
          >
            {createVersionMutation.isPending ? (
              <LoadingSpinner message={"Updating"} />
            ) : (
              <>
                <Save className="size-4 mr-2" />
                Save
              </>
            )}
          </Button>

          {documentContext.preview !== "preview" && (
            <ConfirmDialog
              title="Delete Confirmation"
              desciption={`Are you sure you want to delete "${document.title}"?`}
              onConfirm={() => deleteMutation.mutate({ teamCode, projectCode, documentId: document.id })}
            >
              <Button size="sm" className="bg-red-200" loading={deleteMutation.isPending} onClick={handleSave}>
                <Trash className="size-4 mr-2" />
                Delete
              </Button>
            </ConfirmDialog>
          )}

          <DocumentHistory
            key={`history-${document?.id}`}
            documentId={document.id}
            onRestore={(restoreDocument) => {
              setVersionMutation.mutate({
                teamCode,
                projectCode,
                documentId: document.id,
                versionId: restoreDocument.id,
              });
            }}
          >
            <Button size="sm" className="bg-secondary-200">
              <History className="size-4 mr-2" />
              History
            </Button>
          </DocumentHistory>
        </div>
      }
    >
      {document?.content && (
        <DocumentEditor
          key={`${document.contentVersionId}-${document.createdAt}`}
          defaultValue={document.content}
          onChange={(value) => {
            setUpdatedContent(value);
          }}
          onSave={handleSave}
          showLive={isNew || !createVersionMutation.isIdle}
        />
      )}
    </FeaturePage>
  );
};

interface DocumentEditorProps {
  defaultValue: string;
  showLive?: boolean;
  onChange: (value?: string) => void;
  onSave: () => void;
}

const DocumentEditor: React.FC<DocumentEditorProps> = ({ defaultValue, showLive: isNew, onChange, onSave }) => {
  const [internal, setInternal] = React.useState<string | undefined>(() => defaultValue);

  return (
    <MarkdownEditor
      onKeyDown={(e) => {
        if (e.ctrlKey && e.code === "KeyS") {
          e.preventDefault();
          onSave();
        }
      }}
      className="min-h-full"
      preview={isNew ? "live" : "preview"}
      value={internal}
      onChange={(value) => {
        setInternal(value);
        onChange(value);
      }}
    />
  );
};

interface DocumentHistoryProps {
  documentId: number;
  onRestore: (document: DocumentVersionResponseDto) => void;
  children?: React.ReactNode;
}

const DocumentHistory: React.FC<DocumentHistoryProps> = ({ children, ...props }) => {
  const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);

  return (
    <Sheet open={isHistoryOpen} onOpenChange={setIsHistoryOpen}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent side="right" className="w-96 overflow-hidden grid grid-rows-[min-content,1fr] p-0">
        <SheetHeader className="px-6 pt-6">
          <SheetTitle>Document History</SheetTitle>
        </SheetHeader>

        <ScrollArea>
          <div className="mx-6 mb-6 mt-2">
            <DocumentHistoryContent {...props} />
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const DocumentHistoryContent: React.FC<Omit<DocumentHistoryProps, "children">> = ({ onRestore, documentId }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const pagination = usePagination();

  const {
    data: documents,
    isLoading,
    error,
  } = useDocumentsControllerGetDocumentVersions(teamCode, projectCode, documentId, pagination.params, {
    query: {
      refetchOnMount: true,
    },
  });

  return (
    <>
      {error && <h3>No History</h3>}
      {isLoading && <LoadingSpinner />}
      {documents && (
        <div className="space-y-2">
          {documents.results.map((document) => (
            <div key={document.id} className="p-4 neu rounded-lg bg-white">
              <div className="flex justify-between items-start">
                <div>
                  {document?.createdAt && (
                    <p className="text-sm font-medium">Created {formatDistanceToNow(document.createdAt)} ago</p>
                  )}

                  {document?.createdAt && <p className="text-xs text-secondary-950/60">by {document.authorName}</p>}
                </div>
                <Button size="sm" onClick={() => onRestore(document)} className="bg-secondary-300">
                  Restore
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Pagination
        className="pt-4"
        pagination={pagination}
        numberOfPages={documents?.numberOfPages}
        showOnlyChevrons={true}
      />
    </>
  );
};

interface TitleInputProps {
  value: string;
  teamCode: string;
  projectCode: string;
  document: DocumentResponseDto;
  refetch: () => void;
}

const TitleInput: React.FC<TitleInputProps> = ({ value, teamCode, projectCode, document, refetch }) => {
  const updateMutation = useDocumentsControllerUpdateDocumentInfo({
    mutation: {
      onSuccess: () => {
        refetch();
      },
      onError: onErrorToast,
    },
  });

  const updateTitle = React.useCallback(
    (title: string) => {
      if (value !== title) {
        updateMutation.mutate({
          teamCode,
          projectCode,
          documentId: document.id,
          data: {
            title,
            filename: generateFilename(title),
          },
        });
      }
    },
    [document, projectCode, teamCode, updateMutation, value]
  );

  return (
    <div className="flex gap-2 items-center">
      <Input
        variant="ghost"
        className="p-0 h-fit rounded-none font-bold tracking-tight text-xl leading-7 focus-visible:ring-offset-4"
        defaultValue={value}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            const element = e.target as HTMLInputElement;
            const title = element.value;

            if (isString(title)) {
              updateTitle(title);
            }

            element?.blur?.();
          }
        }}
        onBlur={(e) => updateTitle(e.target.value)}
      />

      {updateMutation.isPending && <LoadingSpinner />}
    </div>
  );
};

interface AskToSaveChangesDialogProps {
  refetch: () => void;
  dismiss: () => void;
  children: React.ReactNode;
}

const AskToSaveChangesDialog: React.FC<AskToSaveChangesDialogProps> = ({ refetch, dismiss, children }) => {
  const [open, setOpen] = React.useState(false);

  const closeDialog = () => {
    dismiss();
    setOpen(false);
  };

  const handleContinue = () => {
    refetch();
    closeDialog();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent onOpenAutoFocus={(event) => event.preventDefault()}>
        <DialogTitle>All changes will be lost</DialogTitle>
        <DialogDescription>You have unsaved changes. Are you sure you want to continue?</DialogDescription>

        <div className="flex justify-end mt-4 gap-2">
          <Button size="sm" className="bg-tertiary-200" onClick={handleContinue}>
            Continue
          </Button>
          <Button size="sm" className="bg-secondary-300" onClick={closeDialog}>
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
