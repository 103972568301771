import { ColumnDef } from "@tanstack/react-table";

import { WebhookResponseDto } from "@/api/generated";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { useCurrentProject } from "@/lib/projects/context/project-context";

export const webhooksColumns: ColumnDef<WebhookResponseDto>[] = [
  {
    accessorKey: "endpointCode",
    header: "ID",
    cell: ({ row }) => {
      const endpointCode = row.getValue("endpointCode") as string;
      return <EndpointCodeLink endpointCode={endpointCode} />;
    },
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const createdAt = row.getValue("createdAt") as string;

      return <span>{formatDistanceToNow(createdAt)} ago</span>;
    },
  },
  {
    accessorKey: "maxItems",
    header: "Max Items",
    cell: ({ row }) => {
      const maxItems = row.getValue("maxItems") as number;
      return <span>{maxItems}</span>;
    },
  },
  {
    accessorKey: "enabled",
    header: "Enabled",
    cell: ({ row }) => {
      const enabled = row.getValue("enabled") as boolean;
      return <span>{enabled ? "Yes" : "No"}</span>;
    },
  },
];

interface EndpointCodeLinkProps {
  endpointCode: string;
}

// eslint-disable-next-line react-refresh/only-export-components
const EndpointCodeLink: React.FC<EndpointCodeLinkProps> = ({ endpointCode }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  return (
    <Link to={`/dashboard/${teamCode}/${projectCode}/webhooks/${endpointCode}`} className="font-mono font-semibold">
      {endpointCode}
    </Link>
  );
};
