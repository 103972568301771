import { Badge } from "@/components/ui/badge";
import { LandingPageWrapper } from "@/lib/landing-page/landing-page-wrapper";
import { usePricingTiers } from "@/lib/landing-page/pricing-tiers";
import { cn } from "@/utils/ui.util";
import { isNumber } from "@wt-dev/common/utils";
import { CheckCircle2 } from "lucide-react";
import { LayeredTitle } from "./landing/components/layered-title";

export const PricingPage: React.FC = () => {
  const pricingTiers = usePricingTiers();

  return (
    <LandingPageWrapper
      head={
        <div className="container px-4 sm:px-8 pt-20 2xl:pt-32">
          <LayeredTitle
            title="Pricing Plans"
            className="text-6xl lg:text-8xl/none font-black font-[system-ui] tracking-wide mb-12"
          />
          <p className="mx-auto text-muted-foreground md:text-xl">Find the plan that suits your needs!</p>
        </div>
      }
    >
      <div className="pb-16 pt-16 flex flex-col gap-16 container px-4 sm:px-8">
        <div
          className="neu rounded-lg bg-white/80 flex flex-col lg:flex-row min-h-[600px]"
          style={{ gridTemplateColumns: `repeat(${pricingTiers.length}, 1fr)` }}
        >
          {pricingTiers.map((pricingTier, index) => {
            const isPopular = pricingTier.options?.popular;

            const parsePrice = (price: (typeof pricingTier)["price"]) => {
              if (!isNumber(price)) {
                return price;
              }

              if (!price) {
                return "Free";
              }

              return (
                <>
                  <span className="line-through">
                    {pricingTier.currency}
                    {price}
                  </span>
                  <span className="text-base ml-2 opacity-75">/year</span>
                  <span className="text-2xl ml-2">Free</span>
                </>
              );
            };

            return (
              <div
                className={cn(
                  "[&:not(:last-of-type)]:border-b lg:[&:not(:last-of-type)]:border-r-2 border-secondary-950 w-full",
                  isPopular && "bg-white"
                )}
                key={index}
              >
                <div className="py-6 px-6 flex flex-col gap-4">
                  <h4 className={"flex items-center justify-between font-semibold"}>
                    {pricingTier.name}
                    {isPopular && (
                      <Badge className="bg-secondary-700 hover:bg-secondary-700 text-white">Most popular</Badge>
                    )}
                  </h4>

                  <h2 className="my-2 font-semibold">{parsePrice(pricingTier.price)}</h2>

                  {pricingTier.actionButton}

                  <small>{pricingTier.description}</small>

                  <ul className="space-y-2 list-none">
                    {pricingTier.features.map((feature, index) => {
                      return (
                        <li className="flex items-center gap-2" key={index}>
                          <CheckCircle2 className="h-6 w-6" />
                          <p className="w-full">{feature}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </LandingPageWrapper>
  );
};
