import { DocumentResponseDto, PresigningRequestDtoOneOfSix } from "@/api/generated";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import React from "react";
import { PresignedUrlBuilder } from "../presigned-url-builder";

interface PresignDocumentDialogProps extends ApiPresignedUrlBuilderProps {
  children: React.ReactNode;
}

export const PresignDocumentDialog: React.FC<PresignDocumentDialogProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild children={children} />

      <DialogContent
        className="overflow-hidden max-w-3xl max-h-screen grid grid-cols-1 grid-rows-[min-content,1fr]"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>Presigned URL for "{props.document.filename}"</DialogTitle>

        <div className="overflow-y-auto max-h-full pb-1 -mx-5 px-5">
          <ApiPresignedUrlBuilder {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ApiPresignedUrlBuilderProps {
  document: DocumentResponseDto;
  teamCode: string;
  projectCode: string;
}

export const ApiPresignedUrlBuilder: React.FC<ApiPresignedUrlBuilderProps> = ({ document, teamCode, projectCode }) => {
  const presignedRequest = React.useMemo(
    () =>
      ({
        type: "document",
        createUrl: false,
        documentId: String(document.id),
        projectCode,
        readUrl: true,
        teamCode,
        updateUrl: true,
      }) satisfies Omit<PresigningRequestDtoOneOfSix, "apiKey">,
    [document.id, projectCode, teamCode]
  );

  return (
    <PresignedUrlBuilder
      className="flex flex-col gap-4 w-full"
      presigningRequest={presignedRequest}
      omit={{
        environment: true,
      }}
      createTestData={({ presignedURLs }) => {
        if (!presignedURLs.get) return null;

        return {
          config: {
            method: "GET",
            url: presignedURLs.get.url,
          },
        };
      }}
    />
  );
};
