/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  HyperlinkMonitorRunFullDto,
  HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  ProjectHyperlinkMonitorResultsByEnvironmentsResponseDto,
  ProjectHyperlinkMonitorResultsByHyperlinksResponseDto,
} from ".././model";
import { customInstance } from "../../client";
import type { ErrorType } from "../../client";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Enqueues a Hyperlink Monitor Run for the given Check type and (saved!) Hyperlink environment value
 */
export const hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  jobType: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<HyperlinkMonitorRunFullDto>(
    {
      url: `/v1/hyperlink-monitor/management/teams/${teamCode}/projects/${projectCode}/hyperlinks/${hyperlinkCode}/environments/${envCode}/checks/${jobType}/enqueue`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRunsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>,
    TError,
    { teamCode: string; projectCode: string; hyperlinkCode: string; envCode: string; jobType: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>,
  TError,
  { teamCode: string; projectCode: string; hyperlinkCode: string; envCode: string; jobType: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>,
    { teamCode: string; projectCode: string; hyperlinkCode: string; envCode: string; jobType: string }
  > = (props) => {
    const { teamCode, projectCode, hyperlinkCode, envCode, jobType } = props ?? {};

    return hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns(
      teamCode,
      projectCode,
      hyperlinkCode,
      envCode,
      jobType,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type HyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRunsMutationResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>
>;

export type HyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRunsMutationError = ErrorType<unknown>;

/**
 * @summary Enqueues a Hyperlink Monitor Run for the given Check type and (saved!) Hyperlink environment value
 */
export const useHyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>,
    TError,
    { teamCode: string; projectCode: string; hyperlinkCode: string; envCode: string; jobType: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRuns>>,
  TError,
  { teamCode: string; projectCode: string; hyperlinkCode: string; envCode: string; jobType: string },
  TContext
> => {
  const mutationOptions = getHyperlinksHyperlinkMonitorControllerEnqueueHyperlinkMonitorJobRunsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Hyperlink Monitor results (grouped by Hyperlinks first) by wild-carded hyperlink code, for all environments, and optionally for a specific Check Type
 */
export const hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectHyperlinkMonitorResultsByHyperlinksResponseDto>(
    {
      url: `/v1/hyperlink-monitor/${teamCode}/${projectCode}/hyperlinks/${hyperlinkCode}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryKey = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams
) => {
  return [
    `/v1/hyperlink-monitor/${teamCode}/${projectCode}/hyperlinks/${hyperlinkCode}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryKey(
      teamCode,
      projectCode,
      hyperlinkCode,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>
  > = ({ signal }) =>
    hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments(
      teamCode,
      projectCode,
      hyperlinkCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && hyperlinkCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>
>;
export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryError =
  ErrorType<unknown>;

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Hyperlink Monitor results (grouped by Hyperlinks first) by wild-carded hyperlink code, for all environments, and optionally for a specific Check Type
 */

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments<
  TData = Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironments>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsForAllEnvironmentsQueryOptions(
    teamCode,
    projectCode,
    hyperlinkCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Hyperlink Monitor results (grouped by Hyperlinks first) by wild-carded hyperlink and environment codes, and optionally for a specific Check Type
 */
export const hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectHyperlinkMonitorResultsByHyperlinksResponseDto>(
    {
      url: `/v1/hyperlink-monitor/${teamCode}/${projectCode}/hyperlinks/${hyperlinkCode}/environments/${envCode}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryKey = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams
) => {
  return [
    `/v1/hyperlink-monitor/${teamCode}/${projectCode}/hyperlinks/${hyperlinkCode}/environments/${envCode}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryOptions = <
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryKey(
      teamCode,
      projectCode,
      hyperlinkCode,
      envCode,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>>
  > = ({ signal }) =>
    hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst(
      teamCode,
      projectCode,
      hyperlinkCode,
      envCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && hyperlinkCode && envCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>>
  >;
export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryError =
  ErrorType<unknown>;

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
          >,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
          >,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Hyperlink Monitor results (grouped by Hyperlinks first) by wild-carded hyperlink and environment codes, and optionally for a specific Check Type
 */

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  envCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByHyperlinksFirstQueryOptions(
      teamCode,
      projectCode,
      hyperlinkCode,
      envCode,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Hyperlink Monitor results (grouped by Environment first) by wild-carded hyperlink and environment codes, and optionally for a specific Check Type
 */
export const hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst = (
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProjectHyperlinkMonitorResultsByEnvironmentsResponseDto>(
    {
      url: `/v1/hyperlink-monitor/${teamCode}/${projectCode}/environments/${envCode}/hyperlinks/${hyperlinkCode}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryKey = (
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams
) => {
  return [
    `/v1/hyperlink-monitor/${teamCode}/${projectCode}/environments/${envCode}/hyperlinks/${hyperlinkCode}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryOptions = <
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryKey(
      teamCode,
      projectCode,
      envCode,
      hyperlinkCode,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>>
  > = ({ signal }) =>
    hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst(
      teamCode,
      projectCode,
      envCode,
      hyperlinkCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && envCode && hyperlinkCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>>
  >;
export type HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryError =
  ErrorType<unknown>;

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
          >,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
          >,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Hyperlink Monitor results (grouped by Environment first) by wild-carded hyperlink and environment codes, and optionally for a specific Check Type
 */

export function useHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst<
  TData = Awaited<
    ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
  >,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  envCode: string,
  hyperlinkCode: string,
  params: HyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof hyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirst>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getHyperlinksHyperlinkMonitorControllerGetHyperlinkMonitorResultsGroupedByEnvironmentsFirstQueryOptions(
      teamCode,
      projectCode,
      envCode,
      hyperlinkCode,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get detailed information on a specific Hyperlink Monitor Run for a specific Hyperlink (including the Run it was compared to)
 */
export const hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<HyperlinkMonitorRunFullDto>(
    {
      url: `/v1/hyperlink-monitor/${teamCode}/${projectCode}/${hyperlinkCode}/run-details`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getHyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryKey = (
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams
) => {
  return [
    `/v1/hyperlink-monitor/${teamCode}/${projectCode}/${hyperlinkCode}/run-details`,
    ...(params ? [params] : []),
  ] as const;
};

export const getHyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryKey(teamCode, projectCode, hyperlinkCode, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>> = ({
    signal,
  }) =>
    hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails(
      teamCode,
      projectCode,
      hyperlinkCode,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(teamCode && projectCode && hyperlinkCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>
>;
export type HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryError = ErrorType<unknown>;

export function useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails<
  TData = Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails<
  TData = Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails<
  TData = Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get detailed information on a specific Hyperlink Monitor Run for a specific Hyperlink (including the Run it was compared to)
 */

export function useHyperlinkMonitorControllerGetHyperlinkMonitorRunDetails<
  TData = Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
  TError = ErrorType<unknown>,
>(
  teamCode: string,
  projectCode: string,
  hyperlinkCode: string,
  params: HyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof hyperlinkMonitorControllerGetHyperlinkMonitorRunDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHyperlinkMonitorControllerGetHyperlinkMonitorRunDetailsQueryOptions(
    teamCode,
    projectCode,
    hyperlinkCode,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
