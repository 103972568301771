import {
  CreateHyperlinkDtoEnvValues,
  CreateHyperlinkDtoMonitoringProfile,
  getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey,
  UpdateHyperlinkDto,
  useHyperlinksHyperlinksControllerDeleteHyperlinks,
  useHyperlinksHyperlinksControllerUpdateHyperlink,
} from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Button } from "@/components/ui/button";
import { ConfirmDialog } from "@/lib/dialog/confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import {
  HyperlinksEditorInputs,
  HyperlinksEditorSchema,
  useHyperlinksContext,
} from "@/lib/projects/hyperlinks/hyperlinks-context";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { objectKeys } from "@/utils";
import { onErrorToast } from "@/utils/api.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { CellContext, Row } from "@tanstack/react-table";
import { Edit, LinkIcon, Save, Trash, X } from "lucide-react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Fixed__ProjectHyperlinkResponseDto } from "../item/project-items-helpers";
import { PresignHyperlinkDialog } from "./presign-hyperlink-dialog";

export const HyperlinksActionCell: React.FC<CellContext<Fixed__ProjectHyperlinkResponseDto, unknown>> = ({ row }) => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();
  const queryClient = useQueryClient();

  const invalidateQuery = React.useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: getHyperlinksHyperlinksControllerGetHyperlinksForProjectQueryKey(teamCode, projectCode),
    });
  }, [projectCode, queryClient, teamCode]);

  return (
    <div className="flex items-center gap-2 justify-end">
      <HyperlinkEditor {...{ invalidateQuery, teamCode, projectCode, row }} />

      <PresignHyperlinkDialog item={row.original} teamCode={teamCode}>
        <Button size="icon" className="bg-secondary-200 w-8 h-8">
          <LinkIcon size={16} />
        </Button>
      </PresignHyperlinkDialog>

      <HyperlinkDelete {...{ invalidateQuery, teamCode, projectCode, row }} />
    </div>
  );
};

interface HyperlinkEditorProps {
  invalidateQuery: () => Promise<void>;
  teamCode: string;
  projectCode: string;
  row: Row<Fixed__ProjectHyperlinkResponseDto>;
}

const HyperlinkEditor: React.FC<HyperlinkEditorProps> = ({ invalidateQuery, teamCode, projectCode, row }) => {
  const { editing, setEdit } = useHyperlinksContext();

  const editor = editing[row.original.id];

  const createMutation = useHyperlinksHyperlinksControllerUpdateHyperlink({
    mutation: {
      onSuccess: () => {
        invalidateQuery();
        handleEnd();
      },
      onError: onErrorToast,
    },
  });

  const form = useForm<HyperlinksEditorInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(HyperlinksEditorSchema),
    disabled: createMutation.isPending,
    defaultValues: {
      code: row.original.code,
      description: row.original.description ?? "",
      monitoringProfile: row.original.monitoringProfile,
    },
  });

  const handleEnd = React.useCallback(() => {
    form.reset();
    form.unregister();

    setEdit(row.original.id, null);
  }, [form, row.original.id, setEdit]);

  const onSubmit: SubmitHandler<HyperlinksEditorInputs> = (values) => {
    const envValuesKeys = objectKeys(values.envValues);

    const data: UpdateHyperlinkDto = {
      description: values.description,
      envValues: envValuesKeys
        .filter((k) => values.envValues[k])
        .reduce((p, envCode) => {
          p[envCode] = { url: values.envValues[envCode] };

          return p;
        }, {} as CreateHyperlinkDtoEnvValues),
    };

    if (values.monitoringProfile !== row.original.monitoringProfile) {
      data.monitoringProfile = values.monitoringProfile as CreateHyperlinkDtoMonitoringProfile;
    }

    createMutation.mutate({
      teamCode,
      projectCode,
      data,
      itemCode: row.original.code,
    });
  };

  if (createMutation.isPending) {
    return <LoadingSpinner className="w-fit h-fit" />;
  }

  if (editor) {
    return (
      <>
        <Button size="icon" className="bg-tertiary-200 w-8 h-8" onClick={form.handleSubmit(onSubmit)}>
          <Save size={16} />
        </Button>

        <Button size="icon" className="bg-primary-200 w-8 h-8" onClick={handleEnd}>
          <X size={16} />
        </Button>
      </>
    );
  }

  return (
    <Button size="icon" className="bg-primary-200 w-8 h-8" onClick={() => setEdit(row.original.id, form)}>
      <Edit size={16} />
    </Button>
  );
};

interface HyperlinkDeleteProps {
  invalidateQuery: () => Promise<void>;
  teamCode: string;
  projectCode: string;
  row: Row<Fixed__ProjectHyperlinkResponseDto>;
}

const HyperlinkDelete: React.FC<HyperlinkDeleteProps> = ({ invalidateQuery, teamCode, projectCode, row }) => {
  const { editing } = useHyperlinksContext();

  const editor = editing[row.original.id];

  const deleteMutation = useHyperlinksHyperlinksControllerDeleteHyperlinks({
    mutation: {
      onSuccess: () => {
        invalidateQuery();
      },
      onError: onErrorToast,
    },
    request: {
      paramsSerializer: {
        indexes: null,
      },
    },
  });

  if (editor) {
    return <></>;
  }

  return (
    <ConfirmDialog
      title="Delete Confirmation"
      desciption={`Are you sure you want to delete "${row.original.code}" hyperlink?`}
      onConfirm={() => {
        deleteMutation.mutate({
          teamCode,
          projectCode,
          params: {
            itemCodes: [row.original.code],
          },
        });
      }}
    >
      <Button size="icon" className="bg-red-200 w-8 h-8">
        <Trash size={16} />
      </Button>
    </ConfirmDialog>
  );
};
