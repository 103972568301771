import { apiKeysControllerGetPresignedUrls, PresigningRequestDto, PresigningResponseDto } from "@/api/generated";
import { CopyableValue } from "@/components/copyable-input";
import { LoadingSpinner } from "@/components/loading-spinner";
import { MillisecondsInput } from "@/components/milliseconds-input";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ApiKeySelectValid } from "@/lib/projects/api-key-select";
import { TestApiProps, TestPresignedApi } from "@/lib/projects/test-presigned-api";
import { objectKeys } from "@/utils";
import { cn } from "@/utils/ui.util";
import { useQuery } from "@tanstack/react-query";
import { XIcon } from "lucide-react";
import React from "react";
import { useCurrentProject } from "./context/project-context";

export type PartialPresigningRequest = Omit<PresigningRequestDto, "apiKey" | "expires" | "environment">;

interface PresignedUrlBuilderProps {
  presigningRequest: PartialPresigningRequest;
  className?: string;
  createTestData?: (data: {
    presignedURLs: PresigningResponseDto;
    apiKey: string;
    expires?: number;
    environment?: string;
  }) => TestApiProps | null | undefined;
  validate?: (data: { expires?: number; environment?: string }) => boolean;
  children?: (data: {
    presignedURLs?: PresigningResponseDto;
    apiKey?: string;
    expires?: number;
    environment?: string;
  }) => React.ReactNode;
  omit?: Partial<{
    environment: boolean;
  }>;
}

export const PresignedUrlBuilder: React.FC<PresignedUrlBuilderProps> = ({
  presigningRequest,
  className,
  createTestData,
  validate,
  children,
  omit,
}) => {
  const { projectEnvironments } = useCurrentProject();

  const [apiKey, setApiKey] = React.useState<string | undefined>("");
  const [expires, setExpires] = React.useState<number | undefined>();
  const [environment, setEnvironment] = React.useState<string | undefined>();

  const presignedRequestDto = React.useMemo(
    () =>
      ({
        ...presigningRequest,
        apiKey: apiKey ?? "",
        expires,
        environmentCode: environment ?? undefined,
      }) as PresigningRequestDto,
    [apiKey, environment, expires, presigningRequest]
  );

  const isValid = React.useMemo(() => {
    if (!validate) return true;

    return validate({ expires, environment });
  }, [environment, expires, validate]);

  const { isFetching, data: presignedURLs } = useQuery({
    queryKey: ["apiKeysControllerGetPresignedUrls", presignedRequestDto.teamCode, presignedRequestDto],
    queryFn: async () => {
      return await apiKeysControllerGetPresignedUrls(presignedRequestDto.teamCode, presignedRequestDto);
    },
    enabled: !!apiKey && isValid,
  });

  const testData = React.useMemo(() => {
    if (!createTestData || !apiKey || !presignedURLs) return null;

    return createTestData({ presignedURLs, apiKey, expires, environment });
  }, [apiKey, createTestData, environment, expires, presignedURLs]);

  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <div className="space-y-2">
        <ApiKeySelectValid options={presignedRequestDto} selected={apiKey} setSelected={setApiKey} />

        <div className="neu rounded-md bg-primary-100 px-4 py-2 grid grid-cols-2 gap-x-4">
          <div>
            <Label className="text-sm font-semibold">Expires</Label>

            <div className="flex justify-between items-center mb-2 gap-x-2">
              <MillisecondsInput onChange={(v) => setExpires(v)} />
            </div>
          </div>

          {!omit?.environment && (
            <div>
              <Label className="text-sm font-semibold">Environment</Label>

              <div className="flex items-center gap-2">
                <Select value={environment} onValueChange={(value) => setEnvironment(value)}>
                  <SelectTrigger className="pr-2 pl-4 rounded-md text-sm h-10">
                    <SelectValue placeholder="Select Environment" />
                  </SelectTrigger>
                  <SelectContent>
                    {projectEnvironments.map((env) => {
                      return (
                        <SelectItem key={`env-${env.code}`} value={env.code}>
                          {env.code}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>

                <Button
                  type="button"
                  size="icon"
                  variant="neu-flat"
                  className="bg-red-200 h-10 w-10 flex-shrink-0"
                  onClick={() => setEnvironment("")}
                >
                  <XIcon size={18} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {isValid && (
        <div className="neu rounded-md pt-2 grid grid-cols-1 grid-rows-[min-content,1fr] bg-secondary-200">
          <div className="border-b-2 border-b-secondary-950 px-4 pb-2">
            <h5 className="font-semibold tracking-tight whitespace-nowrap">Presigned URLs</h5>
          </div>

          <div className="relative bg-white/50 flex flex-col gap-2 px-4 py-2">
            <div className="flex flex-col gap-2">
              {!presignedURLs && <div>No URLs...</div>}

              {presignedURLs &&
                objectKeys(presignedURLs).map((type) => {
                  return (
                    <CopyableValue
                      key={`presigned-url-${type}`}
                      value={presignedURLs[type]!.url}
                      className="w-full overflow-hidden neu-flat px-4 py-2 rounded-md bg-white"
                    >
                      <div className="grid grid-cols-[min-content,1fr] grid-rows-[min-content,1fr]">
                        <Badge
                          className={cn(
                            "uppercase mr-2 row-span-2 h-fit self-center",
                            {
                              create: "bg-tertiary-600 hover:bg-tertiary-500",
                              get: "bg-secondary-600 hover:bg-secondary-500",
                              update: "bg-primary-600 hover:bg-primary-500",
                              list: "bg-secondary-600 hover:bg-secondary-500",
                            }[type]
                          )}
                        >
                          {
                            {
                              create: "POST",
                              get: "GET",
                              update: "PUT",
                              list: "GET",
                            }[type]
                          }
                        </Badge>

                        <a className="truncate" href={presignedURLs[type]?.url}>
                          {presignedURLs[type]?.url}
                        </a>

                        <div className="text-xs text-justify">{presignedURLs[type]?.description}</div>
                      </div>
                    </CopyableValue>
                  );
                })}
            </div>

            {isFetching && (
              <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
                <LoadingSpinner size={32} />
              </div>
            )}
          </div>
        </div>
      )}

      {testData && <TestPresignedApi {...testData} />}

      {children && children({ presignedURLs, apiKey, expires, environment })}
    </div>
  );
};
