/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * wt.dev public API
 * API for wt.dev services
 * OpenAPI spec version: 1.0
 */

export type CreateHyperlinkDtoMonitoringProfile =
  (typeof CreateHyperlinkDtoMonitoringProfile)[keyof typeof CreateHyperlinkDtoMonitoringProfile];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateHyperlinkDtoMonitoringProfile = {
  "public-api": "public-api",
  "public-http-get-only": "public-http-get-only",
  "public-website": "public-website",
  "host-only": "host-only",
  none: "none",
} as const;
