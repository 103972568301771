import {
  DocumentResponseDto,
  useDocumentsControllerDeleteDocument,
  useDocumentsControllerGetDocuments,
} from "@/api/generated";
import { Pagination, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { toast } from "@/components/ui/use-toast";
import { ConfirmDialog } from "@/lib/dialog/confirm-dialog";
import { useCurrentProject } from "@/lib/projects/context/project-context";
import { AddDocumentDialog } from "@/lib/projects/documents/add-document-dialog";
import { PresignDocumentsDialog } from "@/lib/projects/documents/presign-documents-dialog";
import { FeaturePage } from "@/lib/projects/layout/feature-page";
import { useCurrentTeam } from "@/lib/teams/context/team-context";
import { onErrorToast } from "@/utils/api.util";
import { formatDistanceToNow } from "date-fns";
import { FileText, LinkIcon, MoreVertical, Plus, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const Documents: React.FC = () => {
  const { teamCode } = useCurrentTeam();
  const { projectCode } = useCurrentProject();

  const navigate = useNavigate();

  const pagination = usePagination();
  const {
    isPending,
    isFetching,
    data: documents,
    refetch,
  } = useDocumentsControllerGetDocuments(teamCode, projectCode, pagination.params);

  return (
    <FeaturePage
      title="Documents"
      description="Store and manage your project documents in one place."
      isLoading={isPending || isFetching}
      actions={
        <div className="flex items-center gap-2">
          <PresignDocumentsDialog teamCode={teamCode} projectCode={projectCode}>
            <Button size="icon" className="bg-secondary-200 w-9 h-9">
              <LinkIcon size={16} />
            </Button>
          </PresignDocumentsDialog>

          <AddDocumentDialog
            teamCode={teamCode}
            projectCode={projectCode}
            onSuccess={(newDocument) => {
              navigate(`${newDocument.id}`, { state: { newDocument: newDocument.id } });
            }}
          >
            <Button size="sm" className="bg-secondary-300">
              <Plus className="size-4 mr-2" />
              New Document
            </Button>
          </AddDocumentDialog>
        </div>
      }
    >
      <div className="grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {documents?.results.map((document) => (
          <DocumentPreview key={document.id} refetch={refetch} {...{ document, projectCode, teamCode }} />
        ))}
      </div>

      <Pagination className="pt-4" pagination={pagination} numberOfPages={documents?.numberOfPages} />
    </FeaturePage>
  );
};

interface DocumentPreviewProps {
  document: DocumentResponseDto;
  teamCode: string;
  projectCode: string;
  refetch?: () => void;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document, teamCode, projectCode, refetch }) => {
  const deleteMutation = useDocumentsControllerDeleteDocument({
    mutation: {
      onSuccess: () => {
        toast({
          title: `Document "${document.filename}" deleted.`,
        });
        refetch?.();
      },
      onError: onErrorToast,
    },
  });

  return (
    <Button key={document.id} className="rounded-lg h-full bg-secondary-200" asChild>
      <Link to={`/dashboard/${teamCode}/${projectCode}/documents/${document.id}`} className="no-underline">
        <div className="flex flex-col items-center gap-4 w-full">
          <div className="flex justify-between gap-2 w-full">
            <div>
              <p className="font-mono font-semibold text-sm break-all line-clamp-1 text-wrap truncate">
                {document.title}
              </p>

              {document.lastModifiedAt ? (
                <p className="font-mono font-semibold text-xs break-all line-clamp-1 text-wrap truncate text-secondary-950/60">
                  Edited {formatDistanceToNow(document.lastModifiedAt)} ago
                </p>
              ) : (
                document.createdAt && (
                  <p className="font-mono font-semibold text-xs break-all line-clamp-1 text-wrap truncate text-secondary-950/60">
                    Created {formatDistanceToNow(document.createdAt)} ago
                  </p>
                )
              )}
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="bg-transparent hover:bg-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <MoreVertical className="size-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                className="w-fit"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <DropdownMenuLabel>Details</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  Author: <span className="font-bold ml-1">{document.currentAuthorName}</span>
                </DropdownMenuItem>
                {document.createdAt && (
                  <DropdownMenuItem>
                    Created: <span className="font-bold ml-1">{formatDistanceToNow(document.createdAt)} ago</span>
                  </DropdownMenuItem>
                )}
                {document.lastModifiedAt && (
                  <DropdownMenuItem>
                    Edited: <span className="font-bold ml-1">{formatDistanceToNow(document.lastModifiedAt)} ago</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="text-red-500 gap-2 items-center hover:bg-red-200 hover:text-secondary-950 cursor-pointer"
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                >
                  <ConfirmDialog
                    title="Delete Confirmation"
                    desciption={`Are you sure you want to delete "${document.title}"?`}
                    onConfirm={() => deleteMutation.mutate({ teamCode, projectCode, documentId: document.id })}
                  >
                    <div className="flex items-center gap-2">
                      <Trash size={16} />
                      <span>Delete</span>
                    </div>
                  </ConfirmDialog>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="rounded-lg w-full p-4 xl:p-8 flex items-center justify-center bg-white/50 flex-col gap-2">
            <FileText className="size-5 xl:size-8" />
            <div className="font-mono text-xs text-center">{document.mimeType}</div>
          </div>
        </div>
      </Link>
    </Button>
  );
};
