import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CreateTeamForm } from "@/lib/teams/common/create-team-form";
import React from "react";
import { Link } from "react-router-dom";

export const CreateTeam: React.FC = () => {
  return (
    <Card className="w-full max-w-xl">
      <CardHeader>
        <CardTitle className="font-semibold text-base">
          <div className="flex flex-col space-y-4 lg:space-y-8">
            <div className="flex flex-col gap-2">
              <div className="text-4xl lg:text-5xl">🚀</div>
              <div>
                <h3 className="text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">The beginning</h3>
                <h3 className="text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">of something great</h3>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-700">Create new team</h3>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          <CreateTeamForm />
          <div className="flex justify-center gap-2">
            <p className="text-sm text-gray-700">Back to Homepage?</p>
            <Link to="/dashboard" className="underline hover:cursor-pointer text-secondary-950">
              <p className="text-sm text-gray-700">Home</p>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
