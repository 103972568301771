import { cn } from "@/utils/ui.util";
import React from "react";
import classes from "./vetical-table.module.scss";

interface VerticalTableProps {
  data: Array<{
    header: React.ReactNode;
    value: React.ReactNode;
  }>;
  className?: string;
}

export const VerticalTable: React.FC<VerticalTableProps> = ({ data, className }) => {
  return (
    <div className={cn(classes["vertical-table"], "grid grid-cols-[min-content,1fr] h-fit items-center", className)}>
      {data.map(({ header, value }, i) => {
        return (
          <React.Fragment key={`row-${i}`}>
            <div>{header}</div>
            <div>{value}</div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
