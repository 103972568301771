import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
} from "@/components/ui/sidebar";
import { SendInvitationDialog } from "@/lib/teams/invitations/send-invitation-dialog";
import { ChevronRight, UserPlus } from "lucide-react";
import { TeamSelectDropdown } from "@/lib/teams/team-select-dropdown";
import { SidebarPlatformGroup } from "@/layouts/dashboard/sidebar-elements/sidebar-platform-group";
import { SidebarProjectsGroup } from "@/layouts/dashboard/sidebar-elements/sidebar-projects-group";
import { DashboardLayoutContent } from "@/layouts/dashboard/elements/dashboard-layout-content";

export const TeamDashboardLayout: React.FC = () => {
  return (
    <>
      <SidebarProvider>
        <TeamLayoutSidebar />
        <DashboardLayoutContent />
      </SidebarProvider>
    </>
  );
};

const TeamLayoutSidebar: React.FC = () => {
  return (
    <Sidebar collapsible="icon" className="z-10">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <TeamSelectDropdown />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarSeparator />
      <SidebarContent>
        <SidebarPlatformGroup />
        <SidebarProjectsGroup />
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SendInvitationDialog>
              <SidebarMenuButton className="h-12 border-2 border-transparent">
                <UserPlus />
                <span>Invite Members</span>
                <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
              </SidebarMenuButton>
            </SendInvitationDialog>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};
