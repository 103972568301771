import {withRetry} from "@wt-dev/common/utils";
import { createTask } from "@lilbunnyrabbit/task-manager";
import axios, { AxiosError } from "axios";
import prepareUploadTask from "./prepare-upload.task";

export interface UploadedPart {
  partNumber: number;
  ETag: any;
}

export default createTask<File, UploadedPart[]>({
  name: "Upload File Parts",

  parse() {
    switch (this.status) {
      default:
      case "idle": {
        return {
          status: "Upload file parts.",
        };
      }
      case "in-progress": {
        return {
          status: `Uploading parts (${this.data.size}B)...`,
        };
      }
      case "error": {
        return {
          status: "Failed to upload parts...",
        };
      }
      case "success": {
        if (this.result.isPresent()) {
          return {
            status: "Parts uploaded!",
            result: JSON.stringify(this.result.get(), null, 2),
          };
        }

        return {
          status: "Parts uploaded!",
        };
      }
    }
  },

  async execute(file) {
    const initiateResponse = this.manager.getLastTaskResult(prepareUploadTask);

    const partSize = Math.ceil(file.size / initiateResponse.parts.length);

    const createChunk = (part: (typeof initiateResponse)["parts"][number]): Blob => {
      const start = (part.partNumber! - 1) * partSize;
      const end = Math.min(start + partSize, file.size);

      return file.slice(start, end);
    };

    let uploadedCount = 0;
    return await Promise.all(
      initiateResponse.parts.map(async (partData) => {
        const chunk = createChunk(partData);
        const response = await uploadPart(chunk, partData.url);

        const etag = response?.headers.etag;

        if (!etag) {
          throw new Error(`Missing ETag for part '${partData.partNumber}'`);
        }

        this.setProgress(++uploadedCount / initiateResponse.parts.length);

        return {
          partNumber: partData.partNumber!,
          ETag: etag,
        };
      })
    );
  },
});

const uploadPart = withRetry({
  async callback(chunk: Blob, url: string) {
    return await axios.put<unknown>(url, chunk, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  retry(retryCount, error) {
    if (retryCount <= 5) return true;
    if (error instanceof AxiosError && error.status && error.status > 407) return true;
    return false;
  },
});
