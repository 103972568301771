import {
  ApiKeyListItemDto,
  apiKeysControllerGetAllValidKeys,
  PresigningRequestDto,
  useApiKeysControllerGetApiKeys,
} from "@/api/generated";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Pagination, useSimplePagination } from "@/components/pagination";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AddApiKeyDialog } from "@/lib/teams/api-keys/add-api-key-dialog";
import { formatCount, objectKeys } from "@/utils";
import { cn } from "@/utils/ui.util";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { Plus } from "lucide-react";
import React from "react";
import { useDebounce } from "use-debounce";

export interface ApiKeySelectProps {
  teamCode: string;
  selected?: string;
  setSelected: (selected?: string) => void;
}

export const ApiKeySelect: React.FC<ApiKeySelectProps> = ({ teamCode, selected, setSelected }) => {
  const pagination = useSimplePagination({ pageSize: 3 });
  const {
    isFetching,
    data: apiKeys,
    refetch,
  } = useApiKeysControllerGetApiKeys(teamCode, {
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });

  const emptyRows = React.useMemo(() => {
    return Math.max(0, pagination.pageSize - (apiKeys?.results.length ?? 0));
  }, [apiKeys?.results.length, pagination.pageSize]);

  return (
    <div className="w-full relative pb-1">
      <h4 className="mb-2 text-base">Select API Key</h4>

      <div className="flex flex-col gap-2">
        {!isFetching && !apiKeys?.results.length && (
          <AddApiKeyDialog teamCode={teamCode} onSuccess={refetch}>
            <Button size="sm" className="bg-secondary-200">
              Create <Plus size={16} className="ml-1" />
            </Button>
          </AddApiKeyDialog>
        )}

        <RadioGroup value={selected} onValueChange={setSelected} className="flex flex-col gap-2">
          {apiKeys?.results.map((apiKey) => {
            const id = `api-key-${apiKey.id}`;

            return (
              <div
                key={id}
                className={cn("flex gap-4 neu-flat p-2 px-4 rounded-md items-center", apiKey.disabled && "opacity-60")}
              >
                <RadioGroupItem id={id} value={apiKey.key} disabled={apiKey.disabled} />
                <Label htmlFor={id} className="truncate">
                  <div className="truncate">
                    {apiKey.disabled && <span>[DISABLED] </span>}
                    <span>{apiKey.key}</span>
                  </div>

                  {apiKey.description ? (
                    <div className="text-xs font-thin text-secondary-950/60 mt-1 truncate">{apiKey.description}</div>
                  ) : (
                    apiKey.lastModifiedAt && (
                      <div className="text-xs font-thin text-secondary-950/60 mt-1 truncate">
                        Edited {formatDistanceToNow(apiKey.lastModifiedAt)} ago
                      </div>
                    )
                  )}
                </Label>
              </div>
            );
          })}
        </RadioGroup>

        {Array(emptyRows)
          .fill(0)
          .map((_, i) => {
            return <div key={`empty-${i}`} className="h-9 w-full bg-secondary-950/10 rounded-md"></div>;
          })}
      </div>

      <Pagination className="pb-4 pt-2" pagination={pagination} numberOfPages={apiKeys?.numberOfPages} />

      {isFetching && (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
          <LoadingSpinner size={48} />
        </div>
      )}
    </div>
  );
};

export interface ApiKeySelectValidProps {
  options: PresigningRequestDto;
  selected?: string;
  setSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ApiKeySelectValid: React.FC<ApiKeySelectValidProps> = ({ options, selected, setSelected }) => {
  const [search, setSearch] = React.useState<string>("");
  const [debouncedSearch] = useDebounce(search, 150);

  const {
    isFetching,
    data: rawApiKeys,
    refetch,
  } = useQuery({
    queryKey: ["apiKeysControllerGetAllValidKeys", options.teamCode, options],
    queryFn: async () => {
      const keys = (await apiKeysControllerGetAllValidKeys(options.teamCode, options)) as unknown as Record<
        "create" | "get" | "update",
        ApiKeyListItemDto[]
      >;

      setSelected((selected) => {
        if (selected) return selected;

        for (const type in keys) {
          if (Object.prototype.hasOwnProperty.call(keys, type)) {
            const typeKeys = keys[type as keyof typeof keys];
            if (typeKeys.length) {
              return typeKeys[0].key;
            }
          }
        }

        return;
      });

      return keys;
    },
  });

  const apiKeys = React.useMemo(() => {
    if (!rawApiKeys) return [];

    const keysMap: Record<string, { types: Array<"create" | "get" | "update">; key: string; description?: string }> =
      {};

    for (const type in rawApiKeys) {
      if (Object.prototype.hasOwnProperty.call(rawApiKeys, type)) {
        const keyType = type as keyof typeof rawApiKeys;

        const keys = rawApiKeys[keyType];

        for (const apiKey of keys) {
          if (apiKey.key in keysMap) {
            keysMap[apiKey.key].types.push(keyType);
          } else {
            keysMap[apiKey.key] = {
              ...apiKey,
              types: [keyType],
            };
          }
        }
      }
    }

    return objectKeys(keysMap).map((k) => keysMap[k]);
  }, [rawApiKeys]);

  const filteredKeys = React.useMemo(() => {
    if (!debouncedSearch) return apiKeys;

    const regex = new RegExp(debouncedSearch, "i");

    return apiKeys.filter((key) => {
      return regex.test(key.key) || (key.description && regex.test(key.description));
    });
  }, [apiKeys, debouncedSearch]);

  return (
    <div className="neu rounded-md py-2 grid grid-cols-1 grid-rows-[min-content,1fr,min-content] bg-primary-200">
      <div className="border-b-2 border-b-secondary-950 px-4 pb-2 grid items-center grid-cols-[min-content,1fr] gap-4">
        <h5 className="font-semibold tracking-tight whitespace-nowrap">API Keys</h5>

        <Input placeholder="Search by key or description:" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>

      <div className="relative bg-white/50 flex flex-col gap-2 px-4 py-2">
        {!isFetching && !apiKeys.length && (
          <AddApiKeyDialog teamCode={options.teamCode} onSuccess={refetch}>
            <Button size="sm" className="bg-secondary-200">
              Create <Plus size={16} className="ml-1" />
            </Button>
          </AddApiKeyDialog>
        )}

        <RadioGroup value={selected} onValueChange={setSelected} className="flex flex-col gap-2">
          {!filteredKeys.length && <div>No API keys...</div>}

          {filteredKeys.map((apiKey) => {
            const id = `api-key-${apiKey.key}`;

            return (
              <div key={id} className="flex gap-4 neu-flat p-2 px-4 rounded-md items-center bg-white">
                <RadioGroupItem id={id} value={apiKey.key} />
                <Label htmlFor={id} className="truncate">
                  <div className="truncate">
                    {!!apiKey.types.length && (
                      <span className="space-x-1 mr-2">
                        {apiKey.types.map((type) => {
                          return (
                            <Badge
                              key={`${apiKey.key}-${type}`}
                              className={cn(
                                "uppercase",
                                {
                                  create: "bg-tertiary-600 hover:bg-tertiary-500",
                                  get: "bg-secondary-600 hover:bg-secondary-500",
                                  update: "bg-primary-600 hover:bg-primary-500",
                                }[type]
                              )}
                            >
                              {type}
                            </Badge>
                          );
                        })}
                      </span>
                    )}
                    <span>{apiKey.key}</span>
                  </div>

                  {apiKey.description && (
                    <div className="text-xs font-thin text-secondary-950/60 mt-1 truncate">{apiKey.description}</div>
                  )}
                </Label>
              </div>
            );
          })}
        </RadioGroup>

        {isFetching && (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/60 flex items-center justify-center pointer-events-none !m-0 text-primary-400">
            <LoadingSpinner size={32} />
          </div>
        )}
      </div>

      <div className="border-t-2 border-t-secondary-950 px-4 pt-2 flex justify-end items-center text-sm">
        <div>
          {filteredKeys.length} of {formatCount(apiKeys.length, "key")}
        </div>
      </div>
    </div>
  );
};
