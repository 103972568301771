import { WorkflowSelection, PipelineView, Role } from "./common";
import { WebhookResponse } from "./response";

export type WebhookIdParams = {
  webhook_id: string;
};

export type RequestByIdParams = {
  webhook_id: string;
  request_id: string;
};

export type EmailAddressParams = {
  email_address: string;
};

export type EmailByIdParams = {
  email_address: string;
  email_id: string;
};

export type LoginRequest = {
  email: string;
  password: string;
};

export type RegisterRequest = {
  name: string;
  email: string;
  password: string;
  role?: Role;
  regToken?: string;
};

export type UpdatePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export type RegisterMarketplaceSubscriberRequest = {
  regToken: string;
  companyName: string;
  contactPerson: string;
  contactEmail: string;
  contactPhone: string;
};

export enum VerificationType {
  VerifyUser = "verify-user",
  ResetPassword = "reset-password",
}

export type VerificationRequest = {
  email: string;
  type: VerificationType.ResetPassword | VerificationType.VerifyUser;
};

export type UserVerificationRequest = {
  email: string;
  token: string;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  token: string;
};

export type DynamicWebhookRequest = {
  specifiedRetries: number;
  responseCodes: number[];
  delayBetweenRetries: number[];
  contentTypes: string[];
  responseData: string[];
};

export type UpdateWebhookParams = {
  webhook_id: string;
  response: WebhookResponse;
};

export type SortingOrder = "asc" | "desc";

export type RecentWebhooksRequest = {
  startKey?: string;
  order?: SortingOrder;
  fromDate?: string;
  toDate?: string;
};

export type RecentEmailsRequest = {
  startKey?: string;
  order?: SortingOrder;
  fromDate?: string;
  toDate?: string;
};

export type WaitlistRequest = {
  email: string;
};

export type UserKey = {
  email: string;
};

export type UpdateAccountUserRequest = {
  newName?: string;
  newPassword?: string;
  newRole?: Role;
};

export type AdminRequestParams = {
  customerIdentifier: string;
};

export type AdminUpdateAccountUserRequest = {
  newName?: string;
  newPassword?: string;
  newRole?: Role;
  newStatus?: "active" | "inactive" | "banned";
  newTier?: "free" | "pro" | "enterprise";
  newVerified?: boolean;
};

export type CustomerPortalSessionRequest = {
  returnUrl: string;
};

/* Pipelines */
export type WorkflowsRequest = {
  owner: string;
  repo: string;
};

export type LatestRunJobsRequest = {
  owner: string;
  repo: string;
  workflowId: string;
  resourceJwt: string;
};

export type PipelineInfoRequest = {
  pipelineId: string;
};

export type PipelineInfoKey = {
  owner: string;
  repo: string;
};

export type UpdatePipelineVisibilityReqeust = {
  isPublic: boolean;
};

export type UpdatePipelineInfoRequest = {
  defaultView: PipelineView;
  selectedWorkflows: number[] | "all";
};

export type PipelinePanelKey = {
  panelId: string;
};

export type CreatePipelinePanelRequest = {
  panelName: string;
  defaultView: PipelineView;
  selectedWorkflows: WorkflowSelection[];
  isPublic: boolean;
};

export type PipelinePanelRequest = {
  panelId: string;
};

export type UpdatePipelinePanelSettingsRequest = {
  panelName: string;
  defaultView: PipelineView;
  selectedWorkflows: WorkflowSelection[];
};

export type verifyGithubUserRequest = {
  token: string;
};

/* V2 */
export type Pagination = {
  page: number;
  pageSize: number;
};

export type ProjectsRequest = {
  teamCode: string;
};

export type ProjectByCodeRequest = {
  teamCode: string;
  projectCode: string;
};

export type ProjectItemsRequest = {
  teamCode: string;
  projectCode: string;
  environmentCode: string;
};

export type NotificationTopicsRequest = {
  teamCode: string;
};

export type CreateNotificationTopicRequest = {
  teamCode: string;
  topicName: string;
  description: string;
};
